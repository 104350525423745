import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "mt-2"
};
const _hoisted_2 = {
  class: "i-layer"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = {
  class: "col-12 mb-4"
};
const _hoisted_5 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_6 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_7 = {
  class: "code-style fw-medium fsi-14 position-relative d-flex justify-content-between align-items-center"
};
const _hoisted_8 = {
  style: {
    "max-width": "70%"
  }
};
const _hoisted_9 = {
  key: 0,
  class: "col-12 mb-3"
};
const _hoisted_10 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_11 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_12 = {
  class: "code-style fw-medium fsi-14 position-relative d-flex justify-content-between align-items-center"
};
const _hoisted_13 = {
  style: {
    "max-width": "70%"
  }
};
const _hoisted_14 = {
  key: 1,
  class: "col-12 mb-3"
};
const _hoisted_15 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_16 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_17 = {
  key: 2,
  class: "col-12 mt-2"
};
const _hoisted_18 = {
  class: "col-12 mb-3 mt-3"
};
const _hoisted_19 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2 justify-content-between",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_20 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_21 = {
  class: "d-flex align-items-center"
};
const _hoisted_22 = {
  key: 0,
  class: "err-tip"
};
const _hoisted_23 = {
  key: 0,
  class: "col-12 mt-2"
};
const _hoisted_24 = {
  key: 1,
  class: "col-12 mb-3 mt-3"
};
const _hoisted_25 = {
  key: 4,
  class: "col-12 mt-3"
};
const _hoisted_26 = {
  key: 5,
  class: "col-12 mt-4"
};
const _hoisted_27 = {
  class: "col-12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CopyDocument = _resolveComponent("CopyDocument");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_Tip = _resolveComponent("Tip");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_input = _resolveComponent("el-input");
  const _component_SingerV2 = _resolveComponent("SingerV2");
  const _component_AdCard2 = _resolveComponent("AdCard2");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('subpool.poolAddress')), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString($props.poolAddress), 1), _createElementVNode("div", {
    class: "cursor-pointer copy-text fsi-18",
    onClick: _cache[0] || (_cache[0] = $event => $options.copy($props.poolAddress)),
    style: {
      "border": "1px solid #dcdcdc",
      "padding": "6px",
      "display": "inline-flex",
      "border-radius": "5px"
    }
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_CopyDocument)]),
    _: 1
  })])])]), !$data.poolAddressErr ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('subpool.adminAddress')), 1)]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString($data.poolAdmin), 1), _createElementVNode("div", {
    class: "cursor-pointer copy-text fsi-18",
    onClick: _cache[1] || (_cache[1] = $event => $options.copy($data.poolAdmin)),
    style: {
      "border": "1px solid #dcdcdc",
      "padding": "6px",
      "display": "inline-flex",
      "border-radius": "5px"
    }
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_CopyDocument)]),
    _: 1
  })])])), [[_directive_loading, $data.loading.initPoolAdminLoading]])])) : _createCommentVNode("", true), $data.poolAddressErr ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('subpool.adminAddress')), 1)]), _createVNode(_component_Tip, {
    level: "warring"
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.$t('subpool.poolAddressErr')), 1)]),
    _: 1
  })])) : _createCommentVNode("", true), $data.poolAdmin !== '' && !$options.isConnected ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_el_button, {
    class: "btn btn-primary w-100 i-button",
    onClick: $options.connectWallet
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('farm.console.ConnectAdminWallet')), 1)]),
    _: 1
  }, 8, ["onClick"])])) : _createCommentVNode("", true), $data.poolAdmin !== '' && $options.isConnected ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 3
  }, [$data.poolAdmin === $options.address ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('subpool.newPoolAdmin')), 1)]), _createElementVNode("div", _hoisted_21, [_createVNode(_component_el_input, {
    size: "large",
    class: _normalizeClass($data.newPoolAddressErr ? 'err-input' : ''),
    onInput: _cache[2] || (_cache[2] = $event => $data.newPoolAddressErr = false),
    style: {
      "height": "50px"
    },
    onKeyup: _withKeys($options.affirm, ["enter"]),
    disabled: $data.loading.initPoolAdminLoading && $data.startSign,
    modelValue: $data.newPoolAdmin,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.newPoolAdmin = $event),
    placeholder: _ctx.$t('subpool.newPoolAdminTip')
  }, null, 8, ["class", "onKeyup", "disabled", "modelValue", "placeholder"])]), $data.newPoolAddressErr ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t('subpool.newPoolAdminErrTip')), 1)) : _createCommentVNode("", true)]), !$data.startSign ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createVNode(_component_el_button, {
    class: "btn btn-primary w-100 i-button",
    onClick: $options.affirm,
    disabled: $data.newPoolAdmin === '',
    loading: $data.loading.initPoolAdminLoading && $data.poolAdmin !== ''
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('Onboarding.Confirm')), 1)]),
    _: 1
  }, 8, ["onClick", "disabled", "loading"])])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_Tip, {
    level: "warring"
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.$t('subpool.You are not the creator of the loan pool')), 1)]),
    _: 1
  })]))], 64)) : _createCommentVNode("", true), !$data.resultOk && $data.startSign ? (_openBlock(), _createElementBlock("div", _hoisted_25, [_createVNode(_component_SingerV2, {
    ref: "singer",
    singerType: "approval",
    btnProposal: "Send Proposal",
    "contract-method": $data.sign.contractMethod,
    contractParams: $data.sign.contractParams,
    "is-call-evm": true,
    "contract-a-b-i": $data.sign.ABI,
    "from-address": $data.sign.fromAddress,
    "to-eth-address": $data.sign.toEthAddress,
    "to-f0-address": $data.sign.toF0Address,
    onNext: $options.signOk
  }, null, 8, ["contract-method", "contractParams", "contract-a-b-i", "from-address", "to-eth-address", "to-f0-address", "onNext"])])) : _createCommentVNode("", true), $data.resultOk ? (_openBlock(), _createElementBlock("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createVNode(_component_AdCard2, {
    title: _ctx.$t('subpool.transferOwnerOk'),
    "btn-text": _ctx.$t('subpool.ViewPool'),
    onBtnClick: $options.viewNode
  }, null, 8, ["title", "btn-text", "onBtnClick"])])])) : _createCommentVNode("", true)])])]);
}