const referralsContracts = {
    test: {
        whitelist: {
            eth: "0x31D8cdDA241b2e088B7244B6602f43F15C034210",
            id: 't021016',
            address: 't410fghmm3wredmxarc3sis3gal2d6foagqqqovtloyq',
        },
        digitalFrogs: {
            eth: "0x1c7bbb0E1B5967d8a5D86dcc40A80874d768191C",
            id: 't021018',
            address: 't410fdr53wdq3lft5rjoynxgebkaiotlwqgi4rpiyzwa',
        },
        referral: {
            eth: "0x53a9b338C69A0D3fe0E8365a2Abe181120b5A647",
            id: 't019693',
            address: 't410fkou3goggtigt7yhigzncvpqyceqlljsh7yl7xma',
        },
        stakingNFT: {
            eth: "0x9b35739775B66A3d3c62499Cd3aDEa2c673a77fa",
            id: 't021020',
            address: 't410ftm2xhf3vwzvd2pdcjgonhlpkfrttu572koho63y',
        },
    },
    main: {
        whitelist: {
            eth: "0xda04B93801B115452c1c8eE4B7C53FB88B48E924",
            id: 'f02219494',
            address: 'f410f3iclsoabwekukla4r3slprj7xcfur2jergf7sdq',
        },
        digitalFrogs: {
            eth: "0x4342bCbD39c9238B125be67b40656d94EcC5233a",
            id: 'f02219497',
            address: 'f410finblzpjzzerywes34z5uazlnstwmkiz2zw7644q',
        },
        referral: {
            eth: "0x4EA1Cdd7e6867183fFE88E033719e6765Cd0010c",
            id: 'f02199020',
            address: 'f410fj2q43v7gqzyyh77irybtogpgozonaaim7njo6vi',
        },
        stakingNFT: {
            eth: "0x70D62c00D672a01bC22dF437B186B026629B4a6B",
            id: 'f02219509',
            address: 'f410fodlcyagwokqbxqrn6q33dbvqezrjwstlxuev27a',
        },
    }
}

export {referralsContracts}
