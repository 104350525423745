import "core-js/modules/es.array.push.js";
import ExitReserve from './ExitReserve';
import SupPoolWithdraw from './SupPoolWithdraw';
import SupPoolAdjust from './SupPoolAdjust';
import { externalFormatFilPrice, formatSubEthPrice, formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { mapState } from 'vuex';
import { getPrefix } from "@/utils/common";
import RadioBtn from '@/components/RadioBtn';
import Tip from '@/components/tip/Tip';
import { isEmpty } from "@/utils/model";
import AfterValue from '@/components/farm/overview/indicator/AfterValue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SubPoolRightBorrow",
  components: {
    SupPoolAdjust,
    RadioBtn,
    Tip,
    ExitReserve,
    SupPoolWithdraw,
    AfterValue
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabOptions: [{
        label: 'Borrowing & Repayment',
        value: 'Borrowing & Repayment'
      }, {
        label: 'Repayment',
        value: 'Repayment'
      }, {
        label: 'Withdraw From Node',
        value: 'Withdraw From Node'
      }, {
        label: 'Withdraw',
        value: 'LoanWithdrawal'
      }],
      sliderValue: 50,
      closeTip: false,
      options: [{
        value: 'Adjust',
        label: 'Borrowing & Repayment'
      }, {
        value: 'Withdraw',
        label: 'Withdraw From Node'
      }],
      optionValue: 'Borrowing & Repayment',
      selectFarmSlideTabsIndex: 0,
      nodeInterestData: 0n,
      nodeInterestLoading: false
    };
  },
  created() {
    this.initTabOption();
    this.getNodeInterest();
  },
  watch: {
    '$i18n.locale'() {
      this.initTabOption();
    },
    optionValue() {
      this.$store.commit('RESET_YOU_ARE_BORROWING');
      this.$store.commit('RESET_YOU_ARE__WITHDRAW');
      this.$store.commit('SET_RIGHT_PANEL_TYPE', {
        rightPanelType: this.optionValue
      });
    }
  },
  methods: {
    initTabOption() {
      this.tabOptions = [{
        label: this.$t('farm.console.BorrowingBtn'),
        value: 'Borrowing & Repayment'
      }, {
        label: this.$t('farm.console.Loan withdrawal btn'),
        value: 'LoanWithdrawal'
      }, {
        label: this.$t('farm.console.Withdrawal income btn'),
        value: 'Withdraw From Node'
      }, {
        label: this.$t('farm.console.RepaymentBtn'),
        value: 'Repayment'
      }];
    },
    gotoRepay() {
      this.optionValue = 'Repayment';
    },
    async getNodeInterest() {
      if (isEmpty(this.nodeId)) {
        return;
      }
      this.nodeInterestLoading = true;
      let interest = await this.$store.dispatch('getNodeInterest', {
        nodeId: this.nodeId
      });
      this.nodeInterestData = toBigInt(interest);
      this.nodeInterestLoading = false;
    },
    updateInfo() {
      this.$emit('updateInfo');
    },
    externalFormatFilPrice,
    formatWalletFilPrice,
    formatSubEthPrice,
    back() {
      this.$router.push({
        path: '/'
      });
    },
    borrowBtn() {
      document.getElementById("BorrowModalBtn").click();
    },
    approveOperator() {
      this.$emit('approveOperator');
    },
    learnMore() {
      window.open('https://docs.filecoin.io/smart-contracts/filecoin-evm-runtime/differences-with-ethereum/#bare-value-sends');
    },
    connectWallet() {},
    selectTab() {},
    selectFarmSlideTabs(index) {
      this.selectFarmSlideTabsIndex = index;
    },
    openNode() {
      if (this.$store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${this.$i18n.locale}/address/${getPrefix() + this.nodeId}`);
      } else {
        window.open(`https://filfox.info/${this.$i18n.locale}/address/${getPrefix() + this.nodeId}`);
      }
    }
  },
  computed: mapState({
    equityValue() {
      let res = this.positionValue - this.debtValue + this.withdrawLoadAmount;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    afterEquityValue() {
      let res = this.afterPositionValue - this.afterDebtValue + this.afterWithdrawLoadAmount;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    afterEquityValueByWithDraw() {
      let res = this.positionValue - this.debtValue - this.youAreWithdraw + this.withdrawLoadAmount;
      if (res < 0n) {
        return 0;
      }
      return res;
    },
    afterDebtValue() {
      return this.debtValue + this.youAreBorrowing;
    },
    withdrawLoadAmount() {
      return this.currentPoolNode.withdrawLoadAmount;
    },
    afterWithdrawLoadAmount() {
      if (this.withdrawLoadAmount > 0n && this.youAreBorrowing < 0n) {
        return this.withdrawLoadAmount + this.youAreBorrowing;
      } else {
        return this.withdrawLoadAmount;
      }
    },
    debtValue() {
      return this.currentPoolNode.debt_value;
    },
    positionValue() {
      return this.currentPoolNode.position_value;
    },
    afterPositionValue() {
      return this.positionValue + this.youAreBorrowing;
    },
    youAreBorrowing() {
      return this.$store.state.nodeData.youAreBorrowing;
    },
    youAreWithdraw() {
      return this.$store.state.nodeData.youAreWithdraw;
    },
    afterPositionValueByWithDraw() {
      let res = this.positionValue - this.youAreWithdraw;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    rightPanelType() {
      return this.$store.state.nodeData.rightPanelType;
    },
    poolInfoLoading: state => state.subpool.loading.initCurrentPoolInfoLoading,
    poolNodeInfoLoading: state => state.subpool.loading.initCurrentPoolNodeDataLoading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.poolInfoLoading || this.poolNodeInfoLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    currentPoolNode() {
      return this.$store.state.subpool.currentPoolNode;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    role() {
      return parseInt(this.currentNode.role);
    }
  })
};