import {errors as walletProviderErrors} from "@glif/filecoin-wallet-provider/dist/errors";
import {
    LedgerAddAddressExistErrors,
    LedgerFailedOpenExpertModeErrors, LedgerOpenExpertModeModeErrors,
    LedgerSignFairErrors, LedgerTransactionRejectedErrors
} from "@/components/header/ledgerHelper/innerLedgerError";

let parseErr = (err,t) => {
    if (err instanceof Error) {
        if (err instanceof walletProviderErrors.LedgerFilecoinAppNotOpenError) {
            return t('ledgerSp.filecoin app not open on device')
        } else if (err instanceof walletProviderErrors.LedgerDeviceBusyError) {
            return t('ledgerSp.Is your Ledger device locked or busy')
        } else if (err instanceof walletProviderErrors.LedgerNotFoundError) {
            return t('ledgerSp.Is your Ledger device plugged in')
        } else if (err instanceof walletProviderErrors.LedgerLostConnectionError) {
            return t('ledgerSp.Is your Ledger device unlocked')
        } else if (err instanceof walletProviderErrors.TransportNotSupportedError) {
            return t('ledgerSp.Were sorry but we cant communicate with your')
        } else if (err instanceof walletProviderErrors.LedgerReplugError) {
            return t('ledgerSp.Please quit the Filecoin appand unplugreplug your Ledger deviceand try again')
        } else if (err instanceof walletProviderErrors.LedgerDisconnectedError) {
            return t('ledgerSp.Is your Ledger device unlocked')
        } else if (err instanceof walletProviderErrors.LedgerInUseByAnotherApp) {
            return t('ledgerSp.Please quit any other App using your Ledger device')
        } else if (err instanceof walletProviderErrors.LedgerDeviceLockedError) {
            return t('ledgerSp.Is your Ledger device unlocked')
        } else if (err instanceof walletProviderErrors.LedgerFilecoinAppBadVersionError) {
            return t('ledgerSp.Please update your Filecoin')
        } else if (err instanceof walletProviderErrors.TransactionRejectedError) {
            return t('ledgerSp.Transaction rejected')
        } else if(err instanceof LedgerSignFairErrors){
            return t('ledgerSp.signFair')
        } else if(err instanceof LedgerFailedOpenExpertModeErrors){
            return t('ledgerSp.openExpertMode')
        }else if(err instanceof LedgerTransactionRejectedErrors){
            return t('ledgerSp.LedgerTransactionRejectedErrors')
        }else if (err instanceof LedgerOpenExpertModeModeErrors){
            return t('ledgerSp.LedgerOpenExpertModeModeErrors')
        }else if (err instanceof LedgerAddAddressExistErrors) {
            return t('ledgerSp.LedgerAddAddressExistErrors')
        }
        else {
            if (err.message.toString().indexOf("Locked") >= 0){
                return t('ledgerSp.Is your Ledger device unlocked')
            }else {
                return t('ledgerSp.Please quit the Fi')
            }
        }
    } else {
        console.error(`UNHANDLED LEDGER ERROR: ${err.message}`)
        return t('ledgerSp.Please quit the Fi')
    }
}

export {parseErr}
