import Tip from '@/components/tip/Tip';
import { mapState } from "vuex";
import { externalFormatFilPrice, toBigInt } from "@/utils/NumU";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { isEmpty } from "@/utils/model";
import { getLocal, getPrefix, gotoAddress, gotoHash, NotificationErr, NotificationSuccess, simpleValue } from "@/utils/common";
import { iWriteContract } from "@/utils/contracts/opertion";
import { ethers } from "ethers";
import IndicatorValue from '@/components/farm/overview/indicator/IndicatorValue';
import { formatUnits } from "ethers/lib.esm/utils";
import { E23, WadRayMath } from "@/utils/contracts/WadRayMath";
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
export default {
  name: "SupPoolWithdraw",
  components: {
    Tip,
    IndicatorValue
  },
  data() {
    return {
      sliderValue: 0,
      amount: '',
      amountFocus: false,
      loading: false,
      hash: '',
      btnText: ''
    };
  },
  watch: {
    sliderValue() {
      if (!this.amountFocus) {
        this.amount = parseFloat(ethers.utils.formatEther(PercentageMath.mul(this.maximumWithdrawal, toBigInt(this.sliderValue * 100)).toString())).toFixed(6);
      }
    },
    amount() {
      this.$store.commit('SET_YOU_ARE_WITHDRAW', {
        youAreWithdraw: this.amountByBigInt
      });
    }
  },
  methods: {
    formatUnits,
    gotoHash,
    simpleValue,
    gotoAddress,
    externalFormatFilPrice,
    sliderFormatTip(val) {
      return parseFloat(ethers.utils.formatEther(PercentageMath.mul(this.maximumWithdrawal, toBigInt(val * 100)).toString())).toFixed(6) + ' FIL';
    },
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    goToRepay() {
      this.$emit('gotoRepay');
    },
    max() {
      this.amountFocus = true;
      this.amount = parseFloat(ethers.utils.formatEther(this.maximumWithdrawal));
      this.sliderValue = parseFloat((PercentageMath.div(ethers.utils.parseEther(this.amount.toString()).toBigInt(), this.maximumWithdrawal) / toBigInt(100)).toString());
      setTimeout(() => {
        this.amountFocus = false;
      }, 500);
    },
    amountValueChange() {
      if (this.amount < 0 || isEmpty(this.amount)) {
        this.amount = '';
        this.sliderValue = 0;
        return;
      }
      let max = parseFloat(ethers.utils.formatEther(this.maximumWithdrawal));
      if (this.amount > max) {
        this.amount = max.toFixed(6);
      }
      this.sliderValue = parseFloat((PercentageMath.div(ethers.utils.parseEther(this.amount.toString()).toBigInt(), this.maximumWithdrawal) / toBigInt(100)).toString());
    },
    amountValueFocus() {
      this.amountFocus = true;
    },
    amountValueBlur() {
      this.amountFocus = false;
    },
    withdraw() {
      if (this.approveOperator()) {
        return;
      }
      if (this.amount <= 0) {
        NotificationErr(this.$t('farm.console.Please enter the amount withdraw'));
        return;
      }
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: this.currentPool.address.eth,
        f0address: this.currentPool.address.f0address,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: "withdrawProfits",
        args: [this.currentPoolNode.node, ethers.utils.parseEther(this.amount.toString())]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        setTimeout(() => {
          NotificationSuccess(`You have successfully withdraw ${this.amount.toString()} FIL !`);
          this.loading = false;
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentPoolNode.node
          });
          this.$emit('updateInfo');
          this.clearContent();
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message, "subpool withdraw" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.clearContent();
      });
    },
    approveOperator() {
      if (this.operator === '0x0000000000000000000000000000000000000000') {
        this.$emit('approveOperator');
        return true;
      }
      if (this.isConnected && this.operator !== this.address) {
        NotificationErr(this.$t('subpool.You are not the creator of the loan pool'));
        return true;
      }
      return false;
    },
    connectWallet() {
      if (this.approveOperator()) {
        return;
      }
      this.$store.state.refs['iHeader'].connectWallet();
    }
  },
  computed: mapState({
    poolInfoLoading: state => state.subpool.loading.initCurrentPoolInfoLoading,
    poolNodeInfoLoading: state => state.subpool.loading.initCurrentPoolNodeDataLoading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.poolInfoLoading || this.poolNodeInfoLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    assetsLoading: state => state.assetsLoading,
    poolDebtRatio() {
      let debtValue = this.totalDebtValue;
      if (debtValue === 0n) {
        return 0n;
      }
      return PercentageMath.div(debtValue, this.totalPositionValue);
    },
    oldOwnerShow() {
      if (this.oldOwner && this.oldOwner.toString().toLowerCase().startsWith("0xff00000000000")) {
        return getPrefix() + eval(this.oldOwner.toString().toLowerCase().replace("0xff", "0x")).toString(10);
      } else {
        return simpleValue(this.oldOwner ? this.oldOwner.toString() : null, 8, 8);
      }
    },
    currentPool() {
      return this.$store.state.subpool.currentPool;
    },
    operator() {
      return this.currentPool.admin;
    },
    marks() {
      return {
        0: '0',
        100: {
          style: {
            transform: 'translateX(-90%)'
          },
          label: externalFormatFilPrice(this.maximumWithdrawal, 4)
        }
      };
    },
    address() {
      return this.$store.state.address;
    },
    isExceedMaxDebtRate() {
      return this.poolDebtRatio > this.poolMaxDebtRatio;
    },
    maximumWithdrawal() {
      let minPositionValue = PercentageMath.div(this.totalDebtValue, this.poolMaxDebtRatio);
      let max = this.positionValue - this.safetyBuffer;
      let max2 = this.totalPositionValue - minPositionValue;
      max = max > max2 ? max2 : max;
      max = max < 0 ? 0 : max;
      if (max > this.availableBalance) {
        return this.availableBalance;
      }
      return max;
    },
    poolMaxDebtRatio() {
      return this.currentPool.maxDebtRatio / WadRayMath.WAD_2;
    },
    availableBalance() {
      return toBigInt(this.currentPoolNode.availableBalance);
    },
    expectedAvailableBalance() {
      let res = this.availableBalance - this.amountByBigInt;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    afterAvailableBorrowingAmount() {
      let position_value = toBigInt(this.currentPoolNode.position_value) - this.amountByBigInt;
      let debt_value = toBigInt(this.currentPoolNode.debt_value);
      let equityValue = position_value - debt_value;
      let res = PercentageMath.mul(equityValue, this.maxLeverage - toBigInt(10000)) - debt_value;
      if (res < 0) {
        return 0n;
      }
      return res;
    },
    availableBorrowingAmount() {
      let debt_value = this.debtValue;
      let equityValue = this.equityValue;
      console.log('equityValue', equityValue);
      console.log('this.maxLeverage', this.maxLeverage);
      console.log('debt_value', this.debtValue);
      let res = PercentageMath.mul(equityValue, this.maxLeverage - toBigInt(10000)) - debt_value;
      if (res < 0) {
        return 0n;
      }
      console.log('res ', res);
      return res;
    },
    firstExpectedInitialBalance() {
      let debt = this.debtValue;
      let tmp = PercentageMath.div(debt, this.maxLeverage - toBigInt(10000));
      return debt + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    expectedInitialBalance() {
      let debt = this.debtValue - this.amountByBigInt;
      let tmp = PercentageMath.div(debt, this.maxLeverage - toBigInt(10000));
      return debt + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    youAreBorrowing() {
      return this.$store.state.nodeData.youAreBorrowing;
    },
    afterSafetyBuffer() {
      if (this.youAreBorrowing <= 0) {
        if (this.youAreBorrowing + this.debtValue <= 0) {
          return 0n;
        }
        return this.safetyBuffer + this.youAreBorrowing;
      } else {
        let _debtValue = this.debtValue + this.youAreBorrowing;
        let tmp = PercentageMath.div(_debtValue, this.maxLeverage - toBigInt(10000));
        let sb1 = _debtValue + tmp;
        let sb2 = this.positionValue - this.availableBalance + this.youAreBorrowing;
        return sb1 > sb2 ? sb1 : sb2;
      }
    },
    amountByBigInt() {
      if (this.amount === '') {
        return 0n;
      }
      return ethers.utils.parseEther(this.amount.toString()).toBigInt();
    },
    positionValue() {
      return toBigInt(this.currentPoolNode.position_value);
    },
    maxLeverage() {
      return this.currentPool.max_leverage / WadRayMath.WAD_2;
    },
    debtValue() {
      return this.currentPoolNode.debt_value;
    },
    totalPositionValue() {
      return this.currentPool.totalPosition;
    },
    totalDebtValue() {
      return this.currentPool.totalDebt;
    },
    oldOwner() {
      return this.currentPoolNode.oldOwner;
    },
    currentPoolNode() {
      return this.$store.state.subpool.currentPoolNode;
    },
    safetyBuffer() {
      return toBigInt(this.currentPoolNode.safetyBuffer);
    },
    stableRateEnabled: state => state.publicInfo.stableRateEnabled,
    poolIsPaused: state => state.publicInfo.poolIsPaused,
    isActive: state => state.currentPoolNode.is_active === '1',
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    maxDebtRatio() {
      return PercentageMath.div(this.maxLeverage - toBigInt(10000), this.maxLeverage);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    summaryPositionValue() {
      let res = this.positionValue - this.amountByBigInt;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    summaryDebtValue() {
      return this.debtValue;
    },
    debtRatio() {
      if (this.debtValue === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(this.debtValue, this.positionValue);
    },
    summaryDebtRatio() {
      let debt = this.debtValue;
      if (debt === toBigInt(0)) {
        return toBigInt(0);
      }
      return toBigInt(parseInt(formatUnits(WadRayMath.rayDiv(debt, this.summaryPositionValue).toString(), 23)));
    },
    summaryLeverage() {
      return toBigInt(this.sliderValue / 100 * (this.maxLeverage - 10000) + 10000);
    },
    currentLeverage() {
      let equityValue = this.positionValue - this.debtValue;
      return PercentageMath.div(equityValue + this.debtValue, toBigInt(equityValue));
    },
    summarySafetyBuffer() {
      return 0;
    },
    equityValue() {
      return this.positionValue - this.debtValue;
    },
    variableBorrowRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    userStableRate() {
      return this.currentPoolNode.userStableRate;
    },
    currentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.positionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    afterCurrentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.summaryPositionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    maxBorrowRate: state => state.publicInfo.maxBorrowRate
  })
};