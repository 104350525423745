import { Bottom, Top } from '@element-plus/icons-vue';
import { externalFormatFilPrice } from "@/utils/NumU";
export default {
  name: "IndicatorValue",
  components: {
    Bottom,
    Top
  },
  props: {
    oldValue: {
      // eslint-disable-next-line no-undef
      type: BigInt,
      default: 0n
    },
    newValue: {
      // eslint-disable-next-line no-undef
      type: BigInt,
      default: 0n
    },
    fixed: {
      type: Number,
      default: 4
    }
  },
  methods: {
    externalFormatFilPrice
  }
};