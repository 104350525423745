/*eslint-disable*/
export class PoolConfiguration {
    static #STABLE_RATE_ENABLED_MASK = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFE";
    static #FEE_MASK                 = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF0000F";
    static #LIQUIDATION_FACTOR_MASK  = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF0000FFFFF";

    static #IS_STABLE_RATE_ENABLED_POSITION = 0;
    static #FEE_START_BIT_POSITION = 4;
    static #LIQUIDATION_FACTOR_START_BIT_POSITION = 20;

    data

    constructor(_data) {
        this.data = BigInt(_data);
    }

    getStableRateEnabled() {
        const stableRateEnabledMask = BigInt(PoolConfiguration.#STABLE_RATE_ENABLED_MASK);
        return (this.data & ~stableRateEnabledMask) !== BigInt(0)
    }

    getFee() {
        const feeMASK = BigInt(PoolConfiguration.#FEE_MASK);
        return (this.data & ~feeMASK) >> BigInt(PoolConfiguration.#FEE_START_BIT_POSITION)
    }

    getLiquidationFactor() {
        const liquidationFactorMask = BigInt(PoolConfiguration.#LIQUIDATION_FACTOR_MASK);
        return (this.data & ~liquidationFactorMask) >> BigInt(PoolConfiguration.#LIQUIDATION_FACTOR_START_BIT_POSITION)
    }
}
