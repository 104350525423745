import {getNetwork, prepareWriteContract, UserRejectedRequestError, writeContract} from "@wagmi/core";
import {ethers} from "ethers";
import ErrCode from './ErrCode'
import {contracts} from "@/utils/contracts/config";
import store from "@/store";
import {getLocal, setLocal} from "@/utils/common";
import {filChain} from "@/utils/filChain";
import ErrCodeCH from "@/utils/contracts/ErrCodeCH";

let ledgerWriteContract = async (options, confirmCall) => {
    return store.state.ledger.ledgerFileCoinSuper.signMessage(options, confirmCall)
}

let metamaskFlaskWriteContract = async (options, confirmCall) => {
    return store.state.metamaskFlask.metamaskFlaskFilsnapSuper.signMessage(options, confirmCall)
}

let parseMsg = (lang, e) => {
    let code = contractParseCode(e)
    let msg
    if (lang === 'zh') {
        msg = ErrCodeCH[code]
    } else {
        msg = ErrCode[code]
    }
    if (!msg) {
        if (e.data && e.data.message) {
            msg = e.data.message
        } else {
            msg = e.message
        }
    }
    if (!msg) {
        msg = e.toString()
    }
    if (msg === 'User rejected the transaction') {
        msg = ''
    }
    if (msg === 'Failed to fetch') {
        if (lang === 'zh') {
            msg = `Failed to fetch (请检查您的钱包APP版本是否最新，以及检查您的网络)`
        } else {
            msg = `Failed to fetch (Please check if your wallet app is the latest version and also check your network)`
        }
    }
    return msg
}

let iWriteContract = async (options, confirmCall = undefined) => {
    let connectType = getLocal('connectType')
    if (connectType === 'Ledger') {
        return ledgerWriteContract(options, confirmCall)
    }
    if (connectType === 'MetaMaskFlask') {
        return metamaskFlaskWriteContract(options, confirmCall)
    }

    let isOk = false
    return new Promise((resolve, reject) => {

        let lang = localStorage.getItem("localeLanguage")

        let netWork = getNetwork()
        if (netWork.chain.id !== filChain.id) {
            reject({
                message: lang === 'zh' ? '钱包切换网络失败' : "Failed to switch networks switch the network in your wallet’s settings."
            })
            return
        }

        // 防止特殊情况，钱包失败超时不提示
        setTimeout(() => {
            if (!isOk) {
                isOk = true
                reject({
                    message: lang === 'zh' ? '等待时间超时，请手动刷新钱包记录' : 'Waiting time is too long, Please manually refresh the wallet record!'
                })
            }
        }, 600000)

        let promise = prepareWriteContract(options);

        promise.then(config => {
            writeContract(config).then(res => {
                if (confirmCall) {
                    confirmCall(res)
                }
                console.log('writeContract', res.hash)
                res.wait(1).then(async (data) => {
                    isOk = true
                    console.log('data', data)
                    resolve(data)

                }).catch((e) => {
                    console.log('writeContract err')

                    if (isOk) {
                        return
                    }
                    isOk = true
                    let msg = parseMsg(lang, e)
                    reject({
                        message: msg
                    })
                })

            }).catch((e) => {
                console.log('writeContract -----', e)
                if (e instanceof UserRejectedRequestError) {
                    reject({
                        message: ''
                    })
                    return
                }
                if (isOk) {
                    return
                }
                isOk = true
                let msg = parseMsg(lang, e)
                reject({
                    message: msg
                })
            })

        }).catch((e) => {
            if (isOk) {
                return
            }

            console.log('iWriteContract 1', e)

            isOk = true

            let msg = parseMsg(lang, e)

            reject({
                message: msg
            })
        })

    })

}

const RE = new RegExp("Error\\((\\d+)\\)");
const RE2 = new RegExp("RetCode=(\\d+)?");

let contractParseCode = (err) => {
    if (typeof err === "object") {
        err = JSON.stringify(err)
    }
    let regExpExecArray = RE.exec(err);
    if (regExpExecArray && regExpExecArray.length > 1) {
        return regExpExecArray[1]
    }

    let regExpExecArray2 = RE2.exec(err);
    if (regExpExecArray2 && regExpExecArray2.length > 1) {
        return regExpExecArray2[1]
    }
    return -1
}

let getActorAddress = (actorId) => {
    return ethers.utils.solidityPack(
        ["uint32", "uint64", "uint64"],
        [4278190080, 0, actorId]
    )
}


function isEthBrowser() {
    if (window.ethereum || window.web3) {
        return true;
    }
    return false;
}

let isInstallMetamask = () => {
    return isEthBrowser()
}

async function getWalletSnaps() {
    return await window.ethereum.request({
        method: "wallet_getSnaps",
    });
}

let isInstallMetamaskFlask = async () => {
    try {
        let res = await getWalletSnaps()
        console.log('getWalletSnaps', res)
        return true
    } catch (e) {
        return false
    }
}

let isInstallFoxWallet = () => {
    const provider = window.foxwallet && window.foxwallet.ethereum;
    return !!provider
}

let isInstallOkxWallet = () => {
    return typeof window.okxwallet !== 'undefined'
}

let installToken = async (address) => {

    let wasAdded = false

    if (window.foxwallet && window.foxwallet.ethereum) {
        wasAdded = await window.foxwallet.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: contracts[store.state.evn].STFILToken.eth, // The address that the token is at.
                    symbol: "stFIL", // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 18, // The number of decimals in the token
                    image: "https://portal.stfil.io/images/logo/logo-light.png", // A string url of the token logo
                },
            },
        });
    } else {
        wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: contracts[store.state.evn].STFILToken.eth, // The address that the token is at.
                    symbol: "stFIL", // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 18, // The number of decimals in the token
                    image: "https://portal.stfil.io/images/logo/logo-light.png", // A string url of the token logo
                },
            },
        });
    }

    if (wasAdded) {
        setLocal('wasAdded:' + address, true)
    } else {
        console.log('Your loss!');
    }
}

let installWSTFILToken = async (address) => {

    let wasAdded = false

    if (window.foxwallet && window.foxwallet.ethereum) {
        wasAdded = await window.foxwallet.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: contracts[store.state.evn].WSTFILToken.eth, // The address that the token is at.
                    symbol: "wstFIL", // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 18, // The number of decimals in the token
                    image: "https://portal.stfil.io/images/logo/logo-light.png", // A string url of the token logo
                },
            },
        });
    } else {
        wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: contracts[store.state.evn].WSTFILToken.eth, // The address that the token is at.
                    symbol: "wstFIL", // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 18, // The number of decimals in the token
                    image: "https://portal.stfil.io/images/logo/logo-light.png", // A string url of the token logo
                },
            },
        });
    }

    if (wasAdded) {
        setLocal('wasAdded:' + address, true)
    } else {
        console.log('Your loss!');
    }
}

export {
    iWriteContract,
    getActorAddress,
    isInstallMetamask,
    installToken,
    installWSTFILToken,
    isInstallFoxWallet,
    isInstallMetamaskFlask,
    isInstallOkxWallet
}
