import Migrator from '@/components/Migrator';
export default {
  name: "MigratorPage",
  components: {
    Migrator
  },
  data() {
    return {
      nodeId: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.nodeId = this.$route.params.provider;
    }
  },
  computed: {
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};