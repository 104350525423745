import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37341098"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "radio-btn-group"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.tabs.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.tabs, (tab, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["radio-btn", $options.innerClass(tab)]),
      onClick: $event => $options.checked(tab),
      style: _normalizeStyle({
        minWidth: this.aRow ? 'auto' : '130px'
      }),
      key: index
    }, _toDisplayString(tab), 15, _hoisted_2);
  }), 128)) : _createCommentVNode("", true), $props.tabArr.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($props.tabArr, (tab, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["radio-btn", $options.innerClass(tab.value)]),
      onClick: $event => $options.checked(tab.value),
      style: _normalizeStyle({
        minWidth: this.aRow ? 'auto' : '130px'
      }),
      key: index
    }, _toDisplayString(tab.label), 15, _hoisted_3);
  }), 128)) : _createCommentVNode("", true)]);
}