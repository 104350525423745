import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "row"
};
const _hoisted_2 = {
  class: "col-12 mt-4"
};
const _hoisted_3 = {
  class: "d-flex justify-content-end",
  style: {
    "gap": "14px"
  }
};
const _hoisted_4 = {
  key: 2,
  style: {
    "width": "50%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _directive_shake = _resolveDirective("shake");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.showPre ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    onClick: $options.preFun,
    style: {
      "width": "50%",
      "padding": "23px 10px",
      "box-shadow": "4px 4px 8px rgba(0, 0, 0, 0.1)",
      "border-radius": "20px"
    },
    color: "rgba(255, 255, 255, 0.7)"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('Onboarding.Pre')), 1)]),
    _: 1
  }, 8, ["onClick"])), [[_directive_shake]]) : _createCommentVNode("", true), $props.showNext ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    onClick: $options.nextFun,
    style: {
      "width": "50%",
      "padding": "23px 10px",
      "box-shadow": "4px 4px 8px rgba(0, 0, 0, 0.1)",
      "border-radius": "20px"
    },
    color: "#626aef"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('Onboarding.Next')), 1)]),
    _: 1
  }, 8, ["onClick"])), [[_directive_shake]]) : _createCommentVNode("", true), !$props.showNext ? (_openBlock(), _createElementBlock("div", _hoisted_4, [$props.needConnect && !$options.isConnected ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "btn btn-primary w-100 border-radius-20",
    type: "submit",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.connectWallet && $options.connectWallet(...args))
  }, _toDisplayString(_ctx.$t('common.Connect Wallet')) + " " + _toDisplayString(_ctx.isConnecting ? ' (connecting...)' : ''), 1)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])])]);
}