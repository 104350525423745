import { mapState } from "vuex";
import { externalFormatFilPrice, formatPrice, toBigInt } from "@/utils/NumU";
import { formatUnits } from "ethers/lib.esm/utils";
import { blockU, dateFormatAll, getPrefix, gotoAddress, gotoHash, simpleValue } from "@/utils/common";
import { BigNumber } from "ethers";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "HistoryPage",
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      loading: false,
      radio: 1,
      nowDate: new Date()
    };
  },
  created() {
    this.init();
  },
  methods: {
    calcAge(age) {
      let blockTime = blockU.getByBlockNumberToTime(age);
      let nowTime = this.nowDate.getTime();
      return blockU.formatSeconds(Math.floor((nowTime - blockTime) / 1000), this.$i18n.locale);
    },
    getByBlockNumberToTime(age) {
      return dateFormatAll(blockU.getByBlockNumberToTime(age));
    },
    async init() {
      this.loading = true;
      await this.$store.dispatch('getNodeHistory', {
        actorId: this.nodeId,
        event: this.radio
      });
      this.loading = false;
    },
    externalFormatFilPrice,
    formatUnits,
    formatPrice,
    eventChange() {
      this.init();
    },
    roleLabel(role, action) {
      if (role === 1) {
        return 'Owner';
      } else if (role === 2) {
        return 'Beneficiary';
      } else {
        if (action === 'NodeUndelegateOwner') {
          return 'Owner';
        } else if (action === 'NodeUndelegateBeneficiary') {
          return 'Beneficiary';
        } else {
          return '--';
        }
      }
    },
    actionLabel(action) {
      if (action === 'NodeDelegate') {
        return this.$t('farm.console.NodeDelegate');
      } else if (action === 'NodeUndelegateOwner' || action === 'NodeUndelegateBeneficiary') {
        return this.$t('farm.console.NodeUndelegate');
      } else if (action === 'ProvideGuarantee') {
        return this.$t('farm.console.ProvideGuarantee');
      } else if (action === 'TerminateGuarantee') {
        return this.$t('farm.console.TerminateGuarantee');
      } else if (action === 'NodeUpgrade') {
        return this.$t('farm.console.NodeUpgrade');
      } else {
        return 'Unknow';
      }
    },
    simpleValue,
    toOwner(address) {
      if (!address) {
        return address;
      }
      return getPrefix() + (BigNumber.from(address).toBigInt() & toBigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
    },
    gotoHash,
    gotoAddress
  },
  computed: mapState({
    history: state => state.nodeData.history,
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    }
  })
};