import {HttpJsonRpcConnector, LotusClient, LotusWalletProvider} from "filecoin.js";

const url = process.env.VUE_APP_CURRENTMODE === 'test' ? "https://api.calibration.node.glif.io/" : 'https://api.node.glif.io/'


let loutsRpc = async (param) => {
    let maxErrCount = 3

    // 闭包
    function _innerLoutsRpc(param) {

        return _loutsRpc(param).then(value => {
            return value
        }).catch(err => {
            maxErrCount--
            console.log('louts retry maxErrCount=', maxErrCount, maxErrCount)
            if (maxErrCount < 1) {
                throw err
            } else {
                return _innerLoutsRpc(param)
            }
        })

    }

    return _innerLoutsRpc(param);

}

let _loutsRpc = (param) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'post',
            body: JSON.stringify(param)
        }).then(res => {
            console.log('res status = ', res.status, res)
            if (res.status === 200) {
                res.json().then(res => {
                    if (res.error) {
                        console.log("params: ", param, res)
                        reject(res)
                    } else {
                        resolve(res)
                    }
                }).catch(err => {
                    console.log("catch err params: ", param, res)
                    reject(err)
                })
            } else {
                reject(res.json())
            }
        }).catch(err => {
            console.log('_loutsRpc err = ', err)
            reject(err)
        })
    })
}

let retryFun = (promise, max = 3) => {

    let maxErrCount = max

    function _retry(promise) {

        return promise().then(value => {
            return value
        }).catch(err => {
            maxErrCount--
            console.log('retryFun maxErrCount=', maxErrCount, maxErrCount)
            if (maxErrCount < 1) {
                throw err
            } else {
                return _retry(promise)
            }
        })

    }

    return _retry(promise)
}

let filPrice = () => {
    return new Promise((resolve, reject) => {
        let url = "https://api.binance.com/api/v3/ticker/price?symbol=FILUSDT"
        fetch(url).then(async res => {
            let data = await res.json();
            resolve(parseFloat(data['price']).toFixed(2))
        }).catch(() => {
            reject('0.00')
        })

    })
}

const httpConnector = new HttpJsonRpcConnector({
    url: url
});
const lotusClient = new LotusClient(httpConnector);
const walletProvider = new LotusWalletProvider(lotusClient);

export {loutsRpc, filPrice, walletProvider, retryFun}
