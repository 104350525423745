import nodejsHttp from "@/utils/http/nodejsHttp";
import {mergeArrAdd} from "@/utils/common";
import {toBigIntDefault} from "@/utils/NumU";

let chartData = {
    state: {
        params: {
            stake: {
                resolutionInHours: 1
            },
            borrow: {
                resolutionInHours: 1
            },
            rate: {
                resolutionInHours: 1
            },
            liquidityRate: {
                resolutionInHours: 1
            },
            poolBorrow: {
                resolutionInHours: 1
            }
        },
        totalStakeToken: {
            data: [],
            time: []
        },
        totalVariableBorrow: {
            data: [],
            time: []
        },
        totalBorrow: {
            data: [],
            time: []
        },
        totalStableBorrow: {
            data: [],
            time: []
        },
        variableRateAvg: {
        },
        stableRateAvg: {
        },
        chartVariableRate: {
            data: [],
            time: []
        },
        chartStableRate: {
            data: [],
            time: []
        },
        chartLiquidityRate: {
            data: [],
            time: []
        },
        liquidityRateAvg: {
        },
        totalPoolVariableData: 0n,
        totalPoolStableData: 0n,
        totalPoolVariableBorrow: {
            data: [],
            time: []
        },
        totalPoolStableBorrow: {
            data: [],
            time: []
        },
    },
    getters: {},
    mutations: {
        SET_VARIABLE_RATE_AVG(state, {variableRateAvg, day}) {
            state.variableRateAvg[day] = variableRateAvg
        },
        SET_LIQUIDITY_RATE_AVG(state, {liquidityRateAvg, day}) {
            state.liquidityRateAvg[day] = liquidityRateAvg
        },
        SET_STABLE_RATE_AVG(state, {stableRateAvg, day}) {
            state.stableRateAvg[day] = stableRateAvg
        },
        SET_TOTAL_STAKE_TOKEN_CHART(state, totalStakeToken) {
            state.totalStakeToken = totalStakeToken
        },
        SET_TOTAL_BORROW_CHART(state, {variable, stable}) {
            state.totalVariableBorrow = variable
            state.totalStableBorrow = stable
        },
        SET_TOTAL_BORROW_CHART_2(state, totalBorrow) {
            state.totalBorrow = totalBorrow
        },
        SET_RATE_CHART(state, {variable, stable}) {
            state.chartVariableRate = variable
            state.chartStableRate = stable
        },
        SET_LIQUIDITY_RATE_CHART(state, chartLiquidityRate) {
            state.chartLiquidityRate = chartLiquidityRate
        },
        SET_STAKE_RESOLUTIONINHOURS(state, {resolutionInHours}) {
            state.params.stake.resolutionInHours = resolutionInHours
        },
        SET_BORROW_RESOLUTIONINHOURS(state, {resolutionInHours}) {
            state.params.borrow.resolutionInHours = resolutionInHours
        },
        SET_RATE_RESOLUTIONINHOURS(state, {resolutionInHours}) {
            state.params.rate.resolutionInHours = resolutionInHours
        },
        SET_LIQUIDITY_RATE_RESOLUTIONINHOURS(state, {resolutionInHours}) {
            state.params.liquidityRate.resolutionInHours = resolutionInHours
        },
        SET_POOL_BORROW_RESOLUTIONINHOURS(state, {resolutionInHours}) {
            state.params.poolBorrow.resolutionInHours = resolutionInHours
        },
        SET_TOTAL_POOL_BORROW_CHART(state, {variable, stable}) {
            state.totalPoolVariableBorrow = variable
            state.totalPoolStableBorrow = stable
        },
        SET_TOTAL_POOL_BORROW_DATA(state, {debt}) {
            state.totalPoolVariableData = toBigIntDefault(debt.variable, 0n)
            state.totalPoolStableData = toBigIntDefault(debt.stable, 0n)
        },
    },
    actions: {
        async initPoolBorrowedChart({commit, state}, {poolAddress}) {
            try {
                let resolutionInHours = 1
                let delayTime = 0
                if (state.params.poolBorrow.resolutionInHours === 1) {
                    delayTime = 60 * 60 * 24 * 30 * 1000
                    resolutionInHours = 6
                } else if (state.params.poolBorrow.resolutionInHours === 3) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 3
                    resolutionInHours = 24
                } else if (state.params.poolBorrow.resolutionInHours === 6) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 6
                    resolutionInHours = 24
                }
                let data = await nodejsHttp.get('chart/pool/' + poolAddress + '?resolutionInHours=' + resolutionInHours + "&delayTime=" + delayTime)
                commit('SET_TOTAL_POOL_BORROW_DATA', data)
                commit('SET_TOTAL_POOL_BORROW_CHART', data)
            }catch (e) {
                console.log('err chart/borrow')
            }
        },
        async initTotalStakeToken({commit, state}) {
            try {
                let resolutionInHours = 1
                let delayTime = 0
                if (state.params.stake.resolutionInHours === 1) {
                    delayTime = 60 * 60 * 24 * 30 * 1000
                    resolutionInHours = 6
                } else if (state.params.stake.resolutionInHours === 3) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 3
                    resolutionInHours = 24
                } else if (state.params.stake.resolutionInHours === 6) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 6
                    resolutionInHours = 24
                }
                let data = await nodejsHttp.get('chart/stake?resolutionInHours=' + resolutionInHours + "&delayTime=" + delayTime)
                commit('SET_TOTAL_STAKE_TOKEN_CHART', data)
            } catch (e) {
                console.log('err chart/stake')
            }
        },
        async initTotalBorrowed({commit, state}) {
            try {
                let resolutionInHours = 1
                let delayTime = 0
                if (state.params.borrow.resolutionInHours === 1) {
                    delayTime = 60 * 60 * 24 * 30 * 1000
                    resolutionInHours = 6
                } else if (state.params.borrow.resolutionInHours === 3) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 3
                    resolutionInHours = 24
                } else if (state.params.borrow.resolutionInHours === 6) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 6
                    resolutionInHours = 24
                }
                let data = await nodejsHttp.get('chart/borrow?resolutionInHours=' + resolutionInHours + "&delayTime=" + delayTime)
                commit('SET_TOTAL_BORROW_CHART', data)
            }catch (e) {
                console.log('err chart/borrow')
            }
        },
        async initTotalBorrowed2({commit, state}) {
            try {
                let resolutionInHours = 1
                let delayTime = 0
                if (state.params.stake.resolutionInHours === 1) {
                    delayTime = 60 * 60 * 24 * 30 * 1000
                    resolutionInHours = 6
                } else if (state.params.stake.resolutionInHours === 3) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 3
                    resolutionInHours = 24
                } else if (state.params.stake.resolutionInHours === 6) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 6
                    resolutionInHours = 24
                }
                let data = await nodejsHttp.get('chart/borrow?resolutionInHours=' + resolutionInHours + "&delayTime=" + delayTime)
                let {variable, stable} = data
                let res = mergeArrAdd(variable.data, stable.data)
                let totalBorrow = {data: res, time: variable.time}
                commit('SET_TOTAL_BORROW_CHART_2', totalBorrow)
            }catch (e) {
                console.log('err chart/borrow')
            }
        },
        async initRateChart({commit, state}) {
            try {
                let resolutionInHours = 1
                let delayTime = 0
                if (state.params.rate.resolutionInHours === 1) {
                    delayTime = 60 * 60 * 24 * 30 * 1000
                    resolutionInHours = 6
                } else if (state.params.rate.resolutionInHours === 3) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 3
                    resolutionInHours = 24
                } else if (state.params.rate.resolutionInHours === 6) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 6
                    resolutionInHours = 24
                }
                let data = await nodejsHttp.get('chart/rate?resolutionInHours=' + resolutionInHours + "&delayTime=" + delayTime)
                commit('SET_RATE_CHART', data)

                let variable = data.variable
                let stable = data.stable
                let sum = variable.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
                let avg = sum / variable.data.length;
                commit('SET_VARIABLE_RATE_AVG', {variableRateAvg: avg.toFixed(2), day: state.params.rate.resolutionInHours})

                let stableSum = stable.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
                let stableAvg = stableSum / stable.data.length;
                commit('SET_STABLE_RATE_AVG', {stableRateAvg: stableAvg.toFixed(2), day: state.params.rate.resolutionInHours})
            }catch (e) {
                console.log('err chart/rate')
            }
        },
        async liquidityRateChart({commit, state}) {
            try {
                let resolutionInHours = 1
                let delayTime = 0
                if (state.params.liquidityRate.resolutionInHours === 1) {
                    delayTime = 60 * 60 * 24 * 30 * 1000
                    resolutionInHours = 6
                } else if (state.params.liquidityRate.resolutionInHours === 3) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 3
                    resolutionInHours = 24
                } else if (state.params.liquidityRate.resolutionInHours === 6) {
                    delayTime = 60 * 60 * 24 * 30 * 1000 * 6
                    resolutionInHours = 24
                }
                let data = await nodejsHttp.get('chart/liquidity?resolutionInHours=' + resolutionInHours + "&delayTime=" + delayTime)
                commit('SET_LIQUIDITY_RATE_CHART', data)

                let liquidityRateSum = data.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
                let liquidityRateAvg = liquidityRateSum / data.data.length;
                commit('SET_LIQUIDITY_RATE_AVG', {liquidityRateAvg: liquidityRateAvg.toFixed(2), day: state.params.liquidityRate.resolutionInHours})
            }catch (e) {
                console.log('err chart/liquidity')
            }
        },
    },
    modules: {}
}

export default chartData
