import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import { CircleCheckFilled } from '@element-plus/icons-vue';
import Tip from '@/components/tip/Tip';
import AdCard2 from '@/components/tip/AdCard2';
import { ElStep, ElSteps } from 'element-plus';
import { loutsRpc, retryFun, walletProvider } from "@/utils/LoutsRpc";
import Singer from '@/components/induction/singer/Singer';
import { ChainHead, ChainReadObj, GasEstimateMessageGas, StateAccountKey, StateGetActor } from "@/utils/FilecoinApi";
import { decode as dagCborDecode, encode as dagCborEncode } from '@ipld/dag-cbor';
import { contracts } from '@/utils/contracts/config';
import { ethers } from "ethers";
import StakingPoolABI from '@/utils/abi/StakingPool.json';
import { encode as addressToString, newFromString as newAddressFromString } from '@glif/filecoin-address';
import { addressToBytes, bigNumberToBytes, newAddressFromBytes } from "@/utils/contracts/singer/filecoin_utils";
import { CreateMessage, CreateSinger, PushProposal, WaitProposal } from "@/utils/contracts/singer/singer";
import { base64Encode } from "@/utils/contracts/singer/utils";
import { isEmpty } from "@/utils/model";
import { gotoAddress, NotificationErr } from "@/utils/common";
import { readContract } from "@wagmi/core";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "NodeInfo",
  components: {
    Tip,
    AdCard2,
    ElSteps,
    ElStep,
    Singer
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      isBeneficiary: true,
      contracts,
      radio1: 'Owner',
      radio2: 'Content',
      radio3: 'DATA',
      isError: false,
      mode: 'Mode 1',
      isConnected: false,
      step: 1,
      active: 1,
      CircleCheckFilled,
      singerTables: [],
      singerTablesCheckIndex: -1,
      confirmSingerFlag: false,
      singerMsg: {
        "From": '',
        "To": '',
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": 2,
        "Params": '',
        'Version': 0,
        'Nonce': 0,
        'Value': '0'
      },
      bytesToBeSign: '',
      singerThresholdNumber: 0,
      inputSingerResult: '',
      transactionId: -1,
      loading: false,
      contractMethod: 'beneficiaryToOwnerUpgrade',
      contractParams: [],
      changeAddress: '',
      signMessageMaxWidth: '0px',
      showNextStepBtn: false
    };
  },
  created() {
    this.init();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        setTimeout(() => {
          this.initSignMessageMaxWidth();
        }, 200);
      })();
    };
    this.initSignMessageMaxWidth();
  },
  methods: {
    async init() {
      try {
        await this.getNode();
        if (this.isBeneficiary) {
          this.initActorState();
        }
      } catch (e) {
        NotificationErr("Network request error, please try again!");
        this.pre();
      }
    },
    async getNode() {
      const data = await readContract({
        address: contracts[this.env].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [this.nodeId]
      });
      let _nodeInfo = {
        id: '',
        beneficiary: '',
        owner: '',
        ownerAddress: '',
        beneficiaryAddress: ''
      };
      let role = data[0].role;
      let owner = '';
      if (role === 1) {
        this.isBeneficiary = false;
        return;
      } else {
        let minerInfo = await retryFun(() => walletProvider.minerInfo(this.getNetworkPrefix() + '0' + this.nodeId.toString()), 3);
        let nowOwner = minerInfo.Owner.toString().substr(2);
        owner = this.getNetworkPrefix() + '0' + nowOwner;
      }
      _nodeInfo.id = this.getNetworkPrefix() + '0' + this.nodeId;
      _nodeInfo.owner = owner;
      _nodeInfo.ownerAddress = owner;
      this.$store.commit('SET_NODE_INFO', _nodeInfo);
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.env === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    initSignMessageMaxWidth() {
      let width = this.$refs['nodeinfo3'].offsetWidth;
      this.signMessageMaxWidth = Math.floor(width * 0.615) + 'px';
    },
    cellClick(row) {
      let {
        index
      } = row;
      this.singerTablesCheckIndex = index;
    },
    viewNode() {
      this.$router.push({
        path: '/farm/provider/' + this.nodeInfo.id.toString().substr(2)
      });
    },
    ApproveOperator() {
      this.$router.push({
        path: '/farm/operator/' + this.nodeInfo.id.toString().substr(2)
      });
    },
    initActorState() {
      this.changeAddress = contracts[this.env].StakingPool.id;
      let networkPrefix = this.nodeInfo['owner'][0];
      // owner 和受益人 地址
      let roleNodeId = this.nodeInfo['owner'];
      loutsRpc(StateGetActor(roleNodeId)).then(res => {
        if (res.result.Address && res.result.Address.toString().charAt(1) === '4') {
          NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
          this.$emit('back');
          return;
        }
        loutsRpc(ChainReadObj(res.result.Head)).then(async res => {
          let stateRawbytes = Uint8Array.from(Buffer.from(res.result, 'base64'));
          let msgSigState = dagCborDecode(stateRawbytes);
          // 有更简单的直接调用api接口得方式
          let signersRawbytes = msgSigState[0];
          if (Array.isArray(signersRawbytes)) {
            this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
              singerMultipleThreshold: signersRawbytes.length
            });
            this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
              singerMultipleNum: msgSigState[1]
            });
            let head = loutsRpc(ChainHead());
            this.singerTables = [];
            for (let i = 0; i < signersRawbytes.length; i++) {
              let signerBytes = signersRawbytes[i];
              let signerIdAddress = newAddressFromBytes(signerBytes);
              let idAddressStr = addressToString(networkPrefix, signerIdAddress);
              let nonIdAddressStr = await loutsRpc(StateAccountKey(idAddressStr, head.Cids)); // 测试网用t, 主网用f
              this.singerTables.push({
                isOk: false,
                isCheck: false,
                address: nonIdAddressStr.result,
                id: idAddressStr,
                index: i
              });
            }
            this.$store.commit('SET_SINGER_MULTIPLE', {
              singerMultiple: true
            });
          } else {
            this.$store.commit('SET_SINGER_MULTIPLE', {
              singerMultiple: false
            });
          }
          setTimeout(() => {
            this.contractParams = [];
            this.contractMethod = 'beneficiaryToOwnerUpgrade';
            this.contractParams.push(parseInt(this.nodeInfo.id.toString().substr(2)));
            this.$refs['singer'].init();
          }, 100);
        });
      });
    },
    async multipleCreateSing() {
      if (this.nodeRole === 'Owner') {
        this.bytesToBeSign = await this.changeMultipleOwner(this.nodeInfo);
      } else {
        this.bytesToBeSign = await this.changeMultipleBeneficiary(this.nodeInfo);
      }
    },
    async changeMultipleOwner(nodeInfo) {
      const Interface = new ethers.utils.Interface(StakingPoolABI.abi);
      if (this.singerThresholdNumber === 0) {
        let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(nodeInfo.id)), bigNumberToBytes(ethers.BigNumber.from(0)), 23,
        //changeOwner 固定值
        dagCborEncode(addressToBytes(newAddressFromString(contracts[this.env].StakingPool.id)))]));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.owner, '0', 2, params);
        return await CreateSinger(this.singerMsg, Interface);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.owner, '0', 3, params);
        return await CreateSinger(this.singerMsg, Interface);
      }
    },
    async changeOwner(nodeInfo) {
      const Interface = new ethers.utils.Interface(StakingPoolABI.abi);
      let params = base64Encode(dagCborEncode(addressToBytes(newAddressFromString(contracts[this.env].StakingPool.id))));
      this.singerMsg = await CreateMessage(nodeInfo.ownerAddress, nodeInfo.id, '0', 23, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeBeneficiary(nodeInfo) {
      const Interface = new ethers.utils.Interface(StakingPoolABI.abi);
      // eslint-disable-next-line no-undef
      let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(contracts[this.env].StakingPool.id)), ethers.BigNumber.from(2000000000), BigInt(9223372036854775807)]));
      this.singerMsg = await CreateMessage(nodeInfo.ownerAddress, nodeInfo.id, '0', 23, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeMultipleBeneficiary(nodeInfo) {
      const Interface = new ethers.utils.Interface(StakingPoolABI.abi);
      // eslint-disable-next-line no-undef
      let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(contracts[this.env].StakingPool.id)), ethers.BigNumber.from(2000000000), BigInt(9223372036854775807)]));
      this.singerMsg = await CreateMessage(nodeInfo.ownerAddress, nodeInfo.id, '0', 23, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async createMessage(msg) {
      let promise = await loutsRpc(GasEstimateMessageGas(msg));
      return promise.result;
    },
    retest() {
      this.isError = false;
    },
    connectWallet() {
      this.isConnected = true;
      this.retest();
      this.active++;
    },
    next() {
      this.$emit('next');
    },
    pre() {
      this.$emit('pre');
    },
    approvedProposal3() {
      this.step = 3;
    },
    gotoAddress,
    singleSignatureInitErr() {
      this.pre();
    },
    pushProposalNext() {
      this.showNextStepBtn = false;
      this.step = 2;
      this.active++;
      this.$nextTick(async () => {
        await this.$refs['singer2'].init();
      });
    },
    async showNextStepBtnFun() {
      this.showNextStepBtn = true;
    },
    async pushProposalNext2() {
      this.step = 3;
      this.active++;
    },
    async pushProposal() {
      if (isEmpty(this.inputSingerResult)) {
        return;
      }
      this.loading = true;
      PushProposal(this.singerMsg, this.inputSingerResult).then(async res => {
        this.inputSingerResult = '';
        setTimeout(async () => {
          let data = await WaitProposal(res.result);
          this.singerTables[this.singerTablesCheckIndex].isOk = true;
          this.loading = false;
          this.transactionId = data.result.ReturnDec.TxnID;
          if (this.transactionId || data.result.ReturnDec.Applied === true) {
            this.singerThresholdNumber++;
          }
          if (this.singerMultiple && this.singerThresholdNumber < this.singerMultipleThreshold) {
            this.confirmSingerReset();
          } else {
            this.step = 2;
            this.active++;
          }
        }, 500);
      }).catch(err => {
        console.log(err);
      });
    },
    async confirmSinger() {
      if (this.singerTablesCheckIndex >= 0) {
        await this.multipleCreateSing();
      }
      this.confirmSingerFlag = true;
    },
    confirmSingerReset() {
      this.confirmSingerFlag = false;
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    nodeRole: {
      get() {
        return this.$store.state.node.nodeRole;
      },
      set(nodeRole) {
        this.$store.commit('SET_NODE_ROLE', {
          nodeRole
        });
      }
    },
    singerMultiple() {
      return this.$store.state.node.singerMultiple;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    }
  }
};