import {loutsRpc} from "@/utils/LoutsRpc";
import {GasEstimateMessageGas, MpoolGetNonce, MpoolPush, StateWaitMsg} from "@/utils/FilecoinApi";
import {CID} from 'multiformats/cid'
import {idFromAddress, newFromString, newIDAddress, Protocol} from "@glif/filecoin-address";
import varint from 'varint';
import {ethers} from "ethers";
import {base64Encode, hexDecode, hexEncode} from "@/utils/contracts/singer/utils";

const invokeContractMethod = 3844450837;
const sendMethod = 0;

let CreateMessage = async (from, to, value, method, params) => {
    let msg = {
        "From": from,
        "To": to,
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": method,
        "Params": params,
        'Version': 0,
        'Nonce': (await loutsRpc(MpoolGetNonce(from))).result,
        'Value': value
    }
    let data = await loutsRpc(GasEstimateMessageGas(msg))
    if (data.error) {
        throw new Error(data.error)
    }
    return data.result
}

let CreateSinger = async (msg, ABIInterface) => {
    let bytesToBeSign;
    if (msg.From[1] !== '4') {
        bytesToBeSign = CID.parse(msg.CID['/']).bytes;
    } else {
        let toAddress = newFromString(msg.To);
        let methodNum = msg['Method'];
        let ethereumAddressManagerActorAddr = newIDAddress(10);
        let toEthAddressBytes;
        if (toAddress.equals(ethereumAddressManagerActorAddr)) {
            if (methodNum !== 4) {
                throw new Error("unsupported EAM method");
            }
        } else if (methodNum === invokeContractMethod || methodNum === sendMethod) {

            if (toAddress.protocol() === Protocol.ID) {
                let id = idFromAddress(toAddress);
                // eslint-disable-next-line no-undef
                let idBytes = new BigUint64Array(1);
                idBytes[0] = id;
                toEthAddressBytes = new Uint8Array(20);
                toEthAddressBytes[0] = 0xff;
                toEthAddressBytes.set(idBytes, 12);
            } else if (toAddress.protocol() === Protocol.DELEGATED) {
                let namespace = varint.decode(toAddress.payload(), 0);
                if (namespace !== 10) {
                    throw new Error('invalid namespace');
                }
                let size = varint.encodingLength(namespace);
                toEthAddressBytes = toAddress.payload().slice(size);
            } else {
                throw new Error("invalid Filecoin Eth address");
            }
        } else {
            throw new Error(`invalid methodnum ${methodNum}: only allowed method is InvokeContract(${invokeContractMethod})`);
        }

        let hexStr = ethers.utils.RLP.encode([
            ethers.utils.hexlify(3141),// 测试网是3141, 主网是314
            ethers.utils.hexlify(msg['Nonce']),
            ethers.utils.hexlify(ethers.BigNumber.from(msg['GasPremium'])),
            ethers.utils.hexlify(ethers.BigNumber.from(msg['GasFeeCap'])),
            ethers.utils.hexlify(msg['GasLimit']),
            toEthAddressBytes,
            ethers.utils.hexlify(ethers.utils.parseUnits(msg.Value, 'wei')),
            ABIInterface,
            []
        ]);
        bytesToBeSign = hexDecode(hexStr);
    }
    return hexEncode(bytesToBeSign)
}

let PushProposal = async (msg, result) => {
    let signBytes = hexDecode(result);
    let signType = Number.parseInt(signBytes[0]);
    let signData = base64Encode(signBytes.slice(1));
    let sign = {
        Type: signType,
        Data: signData
    };

    let data = {
        Message: msg,
        Signature: sign
    }
    console.log(JSON.stringify(data))
    return await loutsRpc(MpoolPush(data));

}

let PushProposalValue = async (result) => {
    return await loutsRpc(MpoolPush(result));
}

let WaitProposal = async (cid) => {
    return await loutsRpc(StateWaitMsg(cid))
}

let tryAgainMessage = async (cid) => {
    return await loutsRpc(StateWaitMsg(cid))
}

export {CreateSinger, CreateMessage, PushProposal, WaitProposal, PushProposalValue, tryAgainMessage}
