import "core-js/modules/es.array.push.js";
import { CircleCheckFilled } from '@element-plus/icons-vue';
import Tip from '@/components/tip/Tip';
import AdCard2 from '@/components/tip/AdCard2';
import { ElStep, ElSteps } from 'element-plus';
import NextBtnGroup from '@/components/induction/NextBtnGroup';
import { loutsRpc } from "@/utils/LoutsRpc";
import SingerV2 from '@/components/induction/singer/SingerV2';
import { StateReadState } from "@/utils/FilecoinApi";
import { contracts } from '@/utils/contracts/config';
import StorageProviderLendingPoolABI from '@/utils/abi/StorageProviderLendingPool.json';
import { PushProposal, WaitProposal } from "@/utils/contracts/singer/singer";
import { isEmpty } from "@/utils/model";
import { gotoAddress, gotoHash, NotificationErr } from "@/utils/common";
import { mapState } from "vuex";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "SubPoolNodeInfo3",
  components: {
    Tip,
    AdCard2,
    ElSteps,
    ElStep,
    NextBtnGroup,
    SingerV2
  },
  mixins: [ScreenWithAutMixin],
  props: {},
  data() {
    return {
      contracts,
      radio1: 'Owner',
      radio2: 'Content',
      radio3: 'DATA',
      isError: false,
      mode: 'Mode 1',
      step: 0,
      active: 1,
      CircleCheckFilled,
      singerTables: [],
      singerTablesCheckIndex: -1,
      confirmSingerFlag: false,
      singerMsg: {
        "From": '',
        "To": '',
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": 2,
        "Params": '',
        'Version': 0,
        'Nonce': 0,
        'Value': '0'
      },
      bytesToBeSign: '',
      singerThresholdNumber: 0,
      inputSingerResult: '',
      transactionId: -1,
      loading: false,
      contractMethod: 'delegate',
      contractParams: [],
      changeAddress: '',
      signMessageMaxWidth: '0px',
      showNextStepBtn: false,
      contractAddress: '',
      contractF0Address: '',
      contractABI: StorageProviderLendingPoolABI,
      btnText: '',
      hash: '',
      oneStepFromAddress: '',
      oneStepToF0Address: '',
      originalOwnerF0: '',
      originalOwner: '',
      singerMultiple: false,
      towToEthAddress: '',
      towToF0Address: ''
    };
  },
  created() {
    this.initContractInfo();
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    initContractInfo() {
      this.contractAddress = this.currentPoolAddress;
      this.contractF0Address = this.currentPoolF0Address;
    },
    lisInitSignMessageMaxWidth() {
      window.onresize = () => {
        return (() => {
          setTimeout(() => {
            this.initSignMessageMaxWidth();
          }, 200);
        })();
      };
      this.initSignMessageMaxWidth();
    },
    initSignMessageMaxWidth() {
      let width = this.$refs['nodeinfo3']?.offsetWidth;
      this.signMessageMaxWidth = Math.floor(width * 0.615) + 'px';
    },
    cellClick(row) {
      let {
        index
      } = row;
      this.singerTablesCheckIndex = index;
    },
    viewNode() {
      this.$router.push({
        path: '/farm/lendingPool/provider/' + this.currentPoolAddress + '/' + this.nodeInfo.id.toString().substr(2)
      });
    },
    ApproveOperator() {
      this.$router.push({
        path: '/farm/operator/' + this.nodeInfo.id.toString().substr(2)
      });
    },
    async initOriginalOwner(originalOwnerF0) {
      this.originalOwnerF0 = originalOwnerF0;
      let stateReadState = await loutsRpc(StateReadState(this.originalOwnerF0));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        this.singerMultiple = true;
        this.originalOwner = this.originalOwnerF0;
      } else {
        if (!stateReadStateRes.State.Address) {
          NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
          this.singleSignatureInitErr();
          return;
        }
        this.originalOwner = stateReadStateRes.State.Address;
      }
    },
    async initActorState() {
      let ownerId = this.nodeInfo[this.nodeRole.toString().toLowerCase()];
      await this.initOriginalOwner(ownerId);
      if (!this.originalOwner) {
        return;
      }
      this.oneStepFromAddress = this.originalOwner;
      this.oneStepToF0Address = this.nodeInfo.id;
      this.changeAddress = this.currentPoolF0Address;
      this.contractParams = [];
      this.contractMethod = 'delegate';
      this.contractParams.push(parseInt(this.nodeInfo.id.toString().substr(2)));
      this.$nextTick(async () => {
        this.$refs['singer'].init();
      });
    },
    retest() {
      this.isError = false;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    gotoHash,
    nextStep1() {
      this.step = 1;
      this.active++;
      this.$nextTick(() => {
        this.lisInitSignMessageMaxWidth();
        try {
          this.initActorState();
        } catch (e) {
          NotificationErr("Network request error, please try again!");
          this.pre();
        }
      });
    },
    next() {
      this.$emit('next');
    },
    pre() {
      this.$emit('pre');
    },
    approvedProposal3() {
      this.step = 3;
    },
    gotoAddress,
    singleSignatureInitErr() {
      this.pre();
    },
    pushProposalNext() {
      this.towToF0Address = this.currentPoolF0Address;
      this.towToEthAddress = this.currentPoolAddress;
      this.showNextStepBtn = false;
      this.step = 2;
      this.active++;
      this.$nextTick(async () => {
        await this.$refs['singer2'].init();
      });
    },
    async showNextStepBtnFun() {
      this.showNextStepBtn = true;
    },
    async pushProposalNext2() {
      this.step = 3;
      this.active++;
    },
    async pushProposal() {
      if (isEmpty(this.inputSingerResult)) {
        return;
      }
      this.loading = true;
      PushProposal(this.singerMsg, this.inputSingerResult).then(async res => {
        this.inputSingerResult = '';
        setTimeout(async () => {
          let data = await WaitProposal(res.result);
          this.singerTables[this.singerTablesCheckIndex].isOk = true;
          this.loading = false;
          this.transactionId = data.result.ReturnDec.TxnID;
          if (this.transactionId || data.result.ReturnDec.Applied === true) {
            this.singerThresholdNumber++;
          }
          if (this.singerMultiple && this.singerThresholdNumber < this.singerMultipleThreshold) {
            this.confirmSingerReset();
          } else {
            this.step = 2;
            this.active++;
          }
        }, 500);
      }).catch(err => {
        console.log(err);
      });
    },
    async confirmSinger() {
      if (this.singerTablesCheckIndex >= 0) {
        await this.multipleCreateSing();
      }
      this.confirmSingerFlag = true;
    },
    confirmSingerReset() {
      this.confirmSingerFlag = false;
    }
  },
  computed: mapState({
    isCanNext() {
      return this.address === this.poolAdmin;
    },
    currentPoolAddress: state => state.subpool.currentPool.address.eth,
    currentPoolF0Address: state => state.subpool.currentPool.address.f0address,
    currentPoolF4Address: state => state.subpool.currentPool.address.f4address,
    env() {
      return this.$store.state.evn;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    nodeRole() {
      return this.$store.state.node.nodeRole;
    },
    singerMultiple() {
      return this.$store.state.node.singerMultiple;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    poolInfoLoading: state => state.subpool.loading.initCurrentPoolInfoLoading,
    address() {
      return this.$store.state.address;
    },
    poolAdmin() {
      return this.currentPoolInfo.admin;
    },
    currentPoolInfo: state => state.subpool.currentPool
  })
};