import "core-js/modules/es.array.push.js";
import Adjust from './Adjust';
import Withdraw from './Withdraw';
import { externalFormatFilPrice, formatSubEthPrice } from "@/utils/NumU";
import { mapState } from 'vuex';
import { getPrefix } from "@/utils/common";
import RadioBtn from '@/components/RadioBtn';
import Tip from '@/components/tip/Tip';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RightBorrow",
  components: {
    Adjust,
    Withdraw,
    RadioBtn,
    Tip
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabOptions: [{
        label: 'Borrowing & Repayment',
        value: 'Borrowing & Repayment'
      }, {
        label: 'Withdraw From Node',
        value: 'Withdraw From Node'
      }],
      sliderValue: 50,
      closeTip: false,
      options: [{
        value: 'Adjust',
        label: 'Borrowing & Repayment'
      }, {
        value: 'Withdraw',
        label: 'Withdraw From Node'
      }],
      optionValue: 'Borrowing & Repayment',
      selectFarmSlideTabsIndex: 0
    };
  },
  created() {
    this.initTabOption();
  },
  watch: {
    '$i18n.locale'() {
      this.initTabOption();
    },
    optionValue() {
      this.$store.commit('RESET_YOU_ARE_BORROWING');
      this.$store.commit('RESET_YOU_ARE__WITHDRAW');
      this.$store.commit('SET_RIGHT_PANEL_TYPE', {
        rightPanelType: this.optionValue
      });
    }
  },
  methods: {
    initTabOption() {
      this.tabOptions = [{
        label: this.$t('farm.console.BorrowingRepayment'),
        value: 'Borrowing & Repayment'
      }, {
        label: this.$t('farm.console.WithdrawFromNode'),
        value: 'Withdraw From Node'
      }];
    },
    gotoRepay() {
      this.optionValue = 'Adjust';
      this.$nextTick(() => {
        this.$refs.adjust.radio1 = 'By Amount';
        this.$refs.adjust.radio2 = 'Repayment';
      });
    },
    updateInfo() {
      this.$emit('updateInfo');
    },
    externalFormatFilPrice,
    formatSubEthPrice,
    back() {
      this.$router.push({
        path: '/'
      });
    },
    borrowBtn() {
      document.getElementById("BorrowModalBtn").click();
    },
    approveOperator() {
      this.$emit('approveOperator');
    },
    learnMore() {
      window.open('https://docs.filecoin.io/smart-contracts/filecoin-evm-runtime/differences-with-ethereum/#bare-value-sends');
    },
    connectWallet() {},
    selectTab() {},
    selectFarmSlideTabs(index) {
      this.selectFarmSlideTabsIndex = index;
    },
    openNode() {
      if (this.$store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${this.$i18n.locale}/address/${getPrefix() + this.nodeId}`);
      } else {
        window.open(`https://filfox.info/${this.$i18n.locale}/address/${getPrefix() + this.nodeId}`);
      }
    }
  },
  computed: mapState({
    currentLoading: state => state.nodeData.loading,
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    role() {
      return parseInt(this.currentNode.role);
    }
  })
};