import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
/* eslint-disable */
import RadioBtn from '@/components/RadioBtn';
import { Aim, CloseBold, CopyDocument, SuccessFilled } from '@element-plus/icons-vue';
import { BigNumber, ethers } from "ethers";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { base64Encode, hexDecode } from "@/utils/contracts/singer/utils";
import { encode as dagCborEncode } from "@ipld/dag-cbor";
import { addressToBytes, bigNumberToBytes } from "@/utils/contracts/singer/filecoin_utils";
import { newFromString as newAddressFromString } from "@glif/filecoin-address";
import { contracts } from "@/utils/contracts/config";
import { CreateMessage, CreateSinger, PushProposal, PushProposalValue, WaitProposal } from "@/utils/contracts/singer/singer";
import { isEmpty } from "@/utils/model";
import { loutsRpc } from "@/utils/LoutsRpc";
import { ChainHead, StateReadState } from "@/utils/FilecoinApi";
import { getLocal, gotoAddress, gotoHash, NotificationErr } from "@/utils/common";
import Clipboard from "clipboard";
import QRCode from 'qrcodejs2';
import { QrcodeStream } from "vue3-qrcode-reader";
import Ledger from './ledger/Ledger';
import { iWriteContract } from "@/utils/contracts/opertion";
export default {
  name: "SingerC",
  components: {
    RadioBtn,
    CopyDocument,
    SuccessFilled,
    Aim,
    QrcodeStream,
    CloseBold,
    Ledger
  },
  props: {
    waitTime: {
      type: Number,
      default: 5000
    },
    singerMultiple: {
      type: Boolean,
      default: false
    },
    originalSingerTables: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    nodeRole: {
      type: String,
      default: 'Owner'
    },
    contractMethod: {
      type: String,
      default: ''
    },
    contractParams: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    btnProposal: {
      type: String,
      default: 'Send Proposal'
    },
    changeAddress: {
      type: String,
      default: ''
    },
    isBeneficiaryUndelegate: {
      type: Boolean,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: false
    },
    contractAddress: {
      type: String,
      default: ''
    },
    contractF0Address: {
      type: String,
      default: ''
    },
    contractABI: {
      type: Object,
      default: () => {}
    },
    isCallEvm: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.initRadioOptions();
  },
  data() {
    return {
      _contractAddress: '',
      _contractF0Address: '',
      _contractABI: {},
      loading: false,
      singerTables: [],
      radio3: 'DATA',
      radio4: 'Lotus',
      tabOptions1: [],
      confirmSingerFlag: false,
      singMessageLoading: true,
      isCopy: false,
      singerTablesCheckIndex: -1,
      singerMsg: {
        "From": '',
        "To": '',
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": 2,
        "Params": '',
        'Version': 0,
        'Nonce': 0,
        'Value': '0'
      },
      singerThresholdNumber: 0,
      inputSingerResult: '',
      finish: false,
      bytesToBeSign: '',
      parentBaseFee: 100,
      openScanQr: false,
      cameraOpen: false,
      qrInputIsScan: true,
      qrSingerInput: '',
      signMessageMaxWidth: 430,
      pushResultCid: '',
      btnText: '',
      hash: ''
    };
  },
  watch: {
    radio4() {
      if (this.radio4 === 'STFIL Wallet') {
        this.$nextTick(() => {
          this.initBytesToBeSignQr();
        });
      } else {
        if (!this.loading) {
          this.openScanQr = false;
          this.cameraOpen = false;
        }
      }
    },
    '$i18n.locale'() {
      this.initRadioOptions();
    },
    address() {
      if (this.isCallEvm) {
        this.init();
      }
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.initSignMessageMaxWidth();
      })();
    };
    this.initSignMessageMaxWidth();
  },
  methods: {
    initContractInfo() {
      if (isEmpty(this.contractAddress)) {
        this._contractAddress = contracts[this.env].StakingPool.address;
      } else {
        this._contractAddress = this.contractAddress;
      }
      if (isEmpty(this.contractF0Address)) {
        this._contractF0Address = contracts[this.env].StakingPool.id;
      } else {
        this._contractF0Address = this.contractF0Address;
      }
      if (isEmpty(this.contractABI)) {
        this._contractABI = StakingPoolABI;
      } else {
        this._contractABI = this.contractABI;
      }
    },
    initRadioOptions() {
      this.tabOptions1 = [{
        label: 'Lotus',
        value: 'Lotus'
      }, {
        label: 'Ledger',
        value: 'Ledger'
      }, {
        label: this.$t('Onboarding.STFILWallet'),
        value: 'STFIL Wallet'
      }, {
        label: "Venus Wallet",
        value: 'Venus Wallet'
      }];
    },
    isHavePushResultCid() {
      return this.pushResultCid !== '' && this.pushResultCid['/'];
    },
    initSignMessageMaxWidth() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.$refs['signContent']) {
            return;
          }
          let width = this.$refs['signContent'].offsetWidth;
          this.signMessageMaxWidth = Math.floor(width * 0.56);
        }, 200);
      });
    },
    gotoAddress,
    gotoHash,
    isEmpty,
    nextQrCode() {
      this.openScanQr = true;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    callEvmMethod() {
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: this._contractAddress,
        f0address: this._contractF0Address,
        abi: this._contractABI.abi,
        functionName: this.contractMethod,
        args: this.contractParams
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');

        // 这边等待一会。等待接口同步
        setTimeout(() => {
          this.loading = false;
          this.btnText = '';
          this.$emit('next');
          this.finish = true;
        }, this.waitTime);
      }).catch(err => {
        NotificationErr(err.message, "callEvmMethod" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.btnText = '';
        this.hash = '';
        this.clearContent();
      });
    },
    pushLedgerSinger(message) {
      this.loading = true;
      PushProposalValue(message).then(async res => {
        if (!res.result) {
          if (res.error) {
            NotificationErr(res.error.message, "pushLedgerSinger");
          } else {
            NotificationErr('Invalid Signature!', "pushLedgerSinger2");
          }
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        this.innerWaitProposal(res.result);
      }).catch(err => {
        console.log('PushProposal', err);
        NotificationErr('Invalid Signature!', err.message);
        this.loading = false;
      });
    },
    pushSinger() {
      this.loading = true;
      let resData = '';
      try {
        resData = JSON.parse(this.qrSingerInput);
      } catch (e) {
        NotificationErr('Invalid Signature!');
        this.loading = false;
        return;
      }
      PushProposalValue(resData).then(async res => {
        if (!res.result) {
          NotificationErr('Invalid Signature!');
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        this.innerWaitProposal(res.result);
        this.openScanQr = false;
      }).catch(err => {
        console.log('PushProposal', err);
        NotificationErr('Invalid Signature!', err.message);
        this.loading = false;
      });
    },
    textImport() {
      this.qrInputIsScan = false;
    },
    scanQr() {
      this.qrInputIsScan = true;
    },
    onDecode(result) {
      if (isEmpty(result)) {
        return;
      }
      this.loading = true;
      let resData = JSON.parse(result);
      PushProposalValue(resData).then(async res => {
        if (!res.result) {
          NotificationErr('Invalid Signature!');
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        this.innerWaitProposal(res.result);
        this.openScanQr = false;
      }).catch(err => {
        console.log('PushProposal', err);
        NotificationErr('Invalid Signature!');
        this.loading = false;
      });
    },
    async onInit(promise) {
      this.cameraOpen = true;
      try {
        await promise;
        this.cameraOpen = false;
      } catch (error) {
        this.cameraOpen = false;
        if (error.name === 'NotAllowedError') {
          NotificationErr('you need to grant camera access permission');
        } else if (error.name === 'NotFoundError') {
          NotificationErr('no camera on this device');
        } else if (error.name === 'NotSupportedError') {
          NotificationErr('secure context required (HTTPS, localhost)');
        } else if (error.name === 'NotReadableError') {
          NotificationErr('is the camera already in use');
        } else if (error.name === 'OverconstrainedError') {
          NotificationErr('installed cameras are not suitable');
        } else if (error.name === 'StreamApiNotSupportedError') {
          NotificationErr('Stream API is not supported in this browser');
        } else if (error.name === 'InsecureContextError') {
          NotificationErr('Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP');
        } else {
          NotificationErr('Camera error (${error.name})');
        }
      }
    },
    initBytesToBeSignQr() {
      if (isEmpty(this.bytesToBeSign)) {
        return;
      }
      let _singerMsg = Object.assign({}, this.singerMsg);
      delete _singerMsg['CID'];
      new QRCode(this.$refs.qrCodeUrl, {
        text: JSON.stringify(_singerMsg),
        // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.Q
      });
    },
    async init() {
      this.initContractInfo();
      if (this.isCallEvm) {
        this.singMessageLoading = false;
        return;
      }
      this.singMessageLoading = true;
      this.finish = false;
      let len = this.originalSingerTables.length;
      this.singerTables = [];
      for (let i = 0; i < len; i++) {
        this.singerTables.push({
          isOk: false,
          isCheck: false,
          address: this.originalSingerTables[i].address,
          id: this.originalSingerTables[i].id,
          index: this.originalSingerTables[i].index
        });
      }
      this.initBaseFee();
      if (!this.singerMultiple) {
        try {
          await this.singleSignatureInit();
        } catch (e) {
          console.error(e);
          NotificationErr('Network request error, please try again!');
          this.$emit('initErr');
        }
      }
      this.singMessageLoading = false;
      this.initSignMessageMaxWidth();
    },
    async singleSignatureInit() {
      let roleNodeId = this.nodeInfo[this.nodeRole.toString().toLowerCase()];
      console.log('this.nodeInfo', this.nodeInfo);
      let nodeRoleAddress = await loutsRpc(StateReadState(roleNodeId));
      if (this.nodeRole === 'Owner') {
        let nodeInfo = Object.assign(this.nodeInfo, {
          ownerAddress: nodeRoleAddress.result.State.Address
        });
        this.$store.commit('SET_NODE_INFO', nodeInfo);
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeOwner(nodeInfo);
        } else {
          this.bytesToBeSign = await this.approvedOwner(nodeInfo);
        }
      } else {
        let nodeInfo = Object.assign(this.nodeInfo, {
          beneficiaryAddress: nodeRoleAddress.result.State.Address
        });
        this.$store.commit('SET_NODE_INFO', nodeInfo);
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeBeneficiary(nodeInfo);
        } else {
          this.bytesToBeSign = await this.approvedBeneficiary(nodeInfo);
        }
      }
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return `lotus wallet sign ${this.singerMsg.From} ${this.bytesToBeSign}`;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    async initBaseFee() {
      let nodeRoleAddress = await loutsRpc(ChainHead());
      let head = nodeRoleAddress.result;
      let blocks = head['Blocks'];
      if (blocks.length > 0) {
        let min = blocks[0];
        this.parentBaseFee = parseInt(min.ParentBaseFee === undefined ? 100 : min.ParentBaseFee);
      }
    },
    async confirmSinger() {
      if (this.singerTablesCheckIndex < 0) {
        NotificationErr('Please select an address to initiate this proposal!');
        return;
      }
      if (this.singerTables[this.singerTablesCheckIndex].isOk) {
        NotificationErr('Please select an address to initiate this proposal!');
        return;
      }
      this.singMessageLoading = true;
      if (this.singerTablesCheckIndex >= 0) {
        try {
          await this.multipleCreateSing();
        } catch (e) {
          NotificationErr('Network request error, please try again!');
          this.singMessageLoading = false;
          return;
        }
      }
      this.singMessageLoading = false;
      this.confirmSingerFlag = true;
      this.initSignMessageMaxWidth();
    },
    async multipleCreateSing() {
      if (this.nodeRole === 'Owner') {
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeMultipleOwner(this.nodeInfo);
        } else {
          this.bytesToBeSign = await this.approvedMultipleOwner(this.nodeInfo);
        }
      } else {
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeMultipleBeneficiary(this.nodeInfo);
        } else {
          this.bytesToBeSign = await this.approvedMultipleBeneficiary(this.nodeInfo);
        }
      }
    },
    async changeOwner(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      let params = base64Encode(dagCborEncode(addressToBytes(newAddressFromString(this.changeAddress))));
      this.singerMsg = await CreateMessage(nodeInfo.ownerAddress, nodeInfo.id, '0', 23, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeMultipleOwner(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      if (this.singerThresholdNumber === 0) {
        let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(nodeInfo.id)), bigNumberToBytes(ethers.BigNumber.from(0)), 23,
        //changeOwner 固定值
        dagCborEncode(addressToBytes(newAddressFromString(this.changeAddress)))]));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.owner, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.owner, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeMultipleBeneficiary(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      if (this.singerThresholdNumber === 0) {
        // eslint-disable-next-line no-undef
        let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(nodeInfo.id)), bigNumberToBytes(ethers.BigNumber.from(0)), 30,
        //changeBe 固定值
        // eslint-disable-next-line no-undef
        dagCborEncode([addressToBytes(newAddressFromString(this.changeAddress)), bigNumberToBytes(BigNumber.from(this.isBeneficiaryUndelegate ? '0' : '340282366920938463463374607431768211455')), BigInt(this.isBeneficiaryUndelegate ? '0' : '9223372036854775807')])]));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.beneficiary, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.beneficiary, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeBeneficiary(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      // eslint-disable-next-line no-undef
      let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(this.changeAddress)), bigNumberToBytes(BigNumber.from(this.isBeneficiaryUndelegate ? '0' : '340282366920938463463374607431768211455')), BigInt(this.isBeneficiaryUndelegate ? '0' : '9223372036854775807')]));
      this.singerMsg = await CreateMessage(nodeInfo.beneficiaryAddress, nodeInfo.id, '0', 30, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async pushProposal() {
      if (isEmpty(this.inputSingerResult)) {
        return;
      }
      this.loading = true;
      PushProposal(this.singerMsg, this.inputSingerResult).then(async res => {
        if (!res.result || res.error) {
          NotificationErr('Invalid Signature!');
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        console.log('res.result', res.result);
        this.innerWaitProposal(res.result);
      }).catch(err => {
        NotificationErr('Invalid Signature!');
        this.loading = false;
        console.log('PushProposal', err);
      });
    },
    async tryAgainMessage() {
      this.loading = true;
      try {
        let data = await WaitProposal(this.pushResultCid);
        this.resProposal(data);
      } catch (e) {
        NotificationErr(e.message);
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    innerWaitProposal(result) {
      setTimeout(async () => {
        let data = {};
        try {
          data = await WaitProposal(result);
        } catch (e) {
          NotificationErr(e.message);
          this.loading = false;
          return;
        }
        this.resProposal(data);
      }, 10000);
    },
    resProposal(data) {
      if (data.error !== undefined) {
        NotificationErr(data.error.message, "resProposal");
        this.loading = false;
        return;
      }
      if (this.singerMultiple) {
        this.singerTables[this.singerTablesCheckIndex].isOk = true;
        if (parseInt(data.result.ReturnDec.Code) === 0) {
          if (this.singerThresholdNumber === 0) {
            this.transactionId = data.result.ReturnDec.TxnID;
          }
          this.singerThresholdNumber++;
        }
        if (this.singerMultiple && this.singerThresholdNumber < this.singerMultipleNum) {
          this.loading = false;
          this.confirmSingerReset();
        } else {
          // 这边等待一会。等待接口同步
          setTimeout(() => {
            this.loading = false;
            this.$emit('next');
            this.finish = true;
          }, this.waitTime);
        }
      } else {
        if (data.result.Receipt && data.result.Receipt.ExitCode === 0) {
          setTimeout(() => {
            this.$emit('next');
            this.finish = true;
          }, this.waitTime);
        } else {
          NotificationErr('Invalid Signature!');
          this.loading = false;
        }
      }
    },
    cellClick(row) {
      let {
        index
      } = row;
      if (this.singerTables[index].isOk) {
        return;
      }
      this.qrSingerInput = '';
      this.pushResultCid = '';
      this.inputSingerResult = '';
      this.singerTablesCheckIndex = index;
    },
    confirmSingerReset() {
      this.radio4 = 'Lotus';
      this.cameraOpen = false;
      this.openScanQr = false;
      this.confirmSingerFlag = false;
    },
    closeCameraOpen() {
      this.cameraOpen = false;
      this.openScanQr = false;
    },
    async approvedOwner(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      const invokeContractMethod = 3844450837; // 固定值
      let params = base64Encode(dagCborEncode(hexDecode(Interface.encodeFunctionData(this.contractMethod, this.contractParams))));
      console.log('params----', this.contractMethod, this.contractParams);
      this.singerMsg = await CreateMessage(this.isCallEvm ? this.address : nodeInfo.ownerAddress, this._contractF0Address, '0', invokeContractMethod, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async approvedMultipleOwner(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      const invokeContractMethod = 3844450837; // 固定值

      if (this.singerThresholdNumber === 0) {
        let depositCalldata = hexDecode(Interface.encodeFunctionData(this.contractMethod, this.contractParams));

        // 合约的传入参数
        let params = [addressToBytes(newAddressFromString(this._contractAddress)), bigNumberToBytes(ethers.BigNumber.from(0)), invokeContractMethod,
        //changeOwner 固定值
        dagCborEncode(depositCalldata)];
        params = base64Encode(dagCborEncode(params));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.owner, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.owner, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    },
    async approvedMultipleBeneficiary(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      const invokeContractMethod = 3844450837; // 固定值

      if (this.singerThresholdNumber === 0) {
        let depositCalldata = hexDecode(Interface.encodeFunctionData(this.contractMethod, this.contractParams));

        // 合约的传入参数
        let params = [addressToBytes(newAddressFromString(this._contractAddress)), bigNumberToBytes(ethers.BigNumber.from(0)), invokeContractMethod, dagCborEncode(depositCalldata)];
        params = base64Encode(dagCborEncode(params));
        console.log('params', params);
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.beneficiary, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, nodeInfo.beneficiary, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    },
    async approvedBeneficiary(nodeInfo) {
      const Interface = new ethers.utils.Interface(this._contractABI.abi);
      const invokeContractMethod = 3844450837; // 固定值
      let params = base64Encode(dagCborEncode(hexDecode(Interface.encodeFunctionData(this.contractMethod, this.contractParams))));
      this.singerMsg = await CreateMessage(this.isCallEvm ? this.address : nodeInfo.beneficiaryAddress, this._contractF0Address, '0', invokeContractMethod, params);
      return await CreateSinger(this.singerMsg, Interface);
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    fee() {
      return (this.singerMsg.GasLimit + parseInt(this.singerMsg.GasPremium)) * this.parentBaseFee;
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    },
    notInstalled() {
      return this.$i18n.locale === "zh" ? "https://docs.fivetoken.co/cn/" : "https://docs.fivetoken.co/";
    },
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    }
  }
};