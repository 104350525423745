import calcTextareaHeight from '@/utils/calcTextareaHeight';
export default {
  name: "TransferInput",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showValueTip: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'xxxx'
    },
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    valueTip: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    valueFontSize: {
      type: String,
      default: '24px'
    },
    valueFontColor: {
      type: String,
      default: 'rgb(0, 0, 0)'
    }
  },
  data() {
    return {};
  },
  mounted() {
    if (this.type === 'number') {
      this.initInput();
    }
    setTimeout(() => {
      this.autoResize();
    }, 100);
  },
  methods: {
    blurHandleInput() {
      this.$emit('blurInput');
    },
    initInput() {
      let elementsByTagNames = document.getElementsByTagName("input");
      for (let i = 0; i < elementsByTagNames.length; i++) {
        let target = elementsByTagNames[i];
        target.addEventListener('keypress', function (e) {
          let restricted = "+-e";
          let key = e.keyCode || e.which;
          for (let i = 0; i < restricted.length; i++) {
            if (restricted.charCodeAt(i) === key) {
              e.returnValue = false;
            }
          }
          return true;
        });
      }
    },
    autoResize() {
      let ref = this.$refs['transfer-textarea-' + this.id];
      if (!ref) {
        return;
      }
      ref.style.height = calcTextareaHeight(ref, 1, 3).height;
    },
    handleInput(event) {
      if (this.type === 'textarea') {
        this.autoResize();
      }
      this.$emit('update:modelValue', event.target.value);
    }
  }
};