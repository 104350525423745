import "core-js/modules/es.array.push.js";
import { formatBit, formatWalletFilPrice } from "@/utils/NumU";
import { getPrefix, isMobile, simpleValue } from "@/utils/common";
import { mapState } from "vuex";
import { formatUnits } from "ethers/lib.esm/utils";
import { Search } from '@element-plus/icons-vue';
import Sort from '@/views/farm/Sort';
import nodejsHttp from "@/utils/http/nodejsHttp";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "SubPoolList",
  components: {
    Sort
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      fixedSortType: {},
      loading: false,
      showViewBtn: {},
      size: 10,
      serch: {
        showAll: false,
        poolType: 'lendingPool',
        data: '',
        generateCodeFinish: false,
        generateCodeInputTime: 0,
        checkSerchLoading: false,
        nodeId: '',
        sortFixed: '',
        sort: 0,
        nodeOptions: []
      },
      Search: Search
    };
  },
  created() {
    this.init();
  },
  watch: {
    address() {
      this.initPools();
    },
    'serch.nodeId'() {
      this.initPools();
    }
  },
  methods: {
    init() {
      this.initPools();
    },
    pageChange(page) {
      this.$store.commit('SET_CURRENT_PAGE', {
        currentPage: page
      });
      this.initPools();
      document.getElementById('poolList').scrollIntoView();
    },
    switchShowAll() {
      this.$store.commit('SET_CURRENT_PAGE', {
        currentPage: 1
      });
      this.initPools();
    },
    initPools() {
      this.$store.dispatch('getPoolList', {
        admin: !this.address ? "" : this.address,
        poolAddress: this.serch.data,
        page: this.page,
        size: this.size,
        sortFixed: this.serch.sortFixed,
        sort: this.serch.sort,
        nodeId: this.serch.nodeId,
        showAll: this.serch.showAll ? 1 : 0
      });
    },
    getPrefix,
    formatBit,
    simpleValue,
    formatUnits,
    formatWalletFilPrice,
    isMobile,
    sortFixed(fixed) {
      let sortType = this.fixedSortType[fixed];
      this.fixedSortType = {};
      this.fixedSortType[fixed] = sortType;
      if (sortType === undefined) {
        sortType = 1;
        this.fixedSortType[fixed] = 1;
      } else {
        sortType = sortType + 1;
        if (sortType > 2) {
          sortType = 0;
          this.fixedSortType[fixed] = sortType;
          this.nodesTemplateData = Object.assign([], this.poolNodes);
        } else {
          this.fixedSortType[fixed] = sortType;
        }
      }
      this.serch.sort = sortType;
      this.serch.sortFixed = fixed;
      this.$store.commit('SET_CURRENT_PAGE', {
        currentPage: 1
      });
      this.initPools();
    },
    changeSearch() {
      this.serch.generateCodeFinish = false;
      let wait = 500;
      this.serch.generateCodeInputTime = new Date().getTime() + wait;
      setTimeout(() => {
        let now = new Date().getTime();
        if (now - this.serch.generateCodeInputTime >= 0) {
          // this.inputTime拿的是最新的值，e.timeStamp拿的是零点五秒前函数的e.timeStamp
          this.serch.checkSerchLoading = true;
          this.$store.commit('SET_CURRENT_PAGE', {
            currentPage: 1
          });
          this.initPools();
          this.serch.checkSerchLoading = false;
        }
      }, wait);
    },
    clearIos(async) {
      this.$nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            const {
              select
            } = this.$refs;
            const input = select.$el.querySelector('.el-input__inner');
            input.removeAttribute('readonly');
          }, 200);
        }
      });
    },
    async changeSearchNodeId(nodeId) {
      let _nodeId = nodeId.toString().replace('f0', '').replace('t0', '').replace('f', '').replace('t', '');
      let poolRes = await nodejsHttp.get('pool/nodes', {
        params: {
          nodeId: _nodeId
        }
      });
      if (!!poolRes && Array.isArray(poolRes)) {
        let len = poolRes.length;
        this.serch.nodeOptions = [];
        for (let i = 0; i < len; i++) {
          let node = poolRes[i];
          this.serch.nodeOptions.push({
            label: `f0${node.node}`,
            value: node.node
          });
        }
      } else {
        this.serch.nodeOptions = [];
      }
    },
    handleEnter(node) {
      this.showViewBtn[node] = true;
    },
    handleLeave(node) {
      this.showViewBtn[node] = false;
    },
    debtRatioColorValue() {
      return [{
        color: '#00CE92',
        percentage: 100
      }, {
        color: '#D94A1E',
        percentage: 200
      }];
    },
    setCollect(poolAddress, collect, index) {
      if (this.isConnected) {
        this.$store.dispatch('setPoolCollect', {
          poolAddress,
          collect,
          user: this.address
        });
        this.$store.commit('SET_POOL_COLLECT', {
          index,
          collect
        });
      } else {
        this.$store.state.refs['iHeader'].connectWallet();
      }
    },
    showView(poolInfo) {
      this.$router.push({
        path: '/farm/lendingPool/' + poolInfo.address
      });
    }
  },
  computed: mapState({
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    },
    pools: state => state.subpool.pools,
    page: state => state.subpool.currentPage,
    total: state => state.subpool.currentTotal,
    poolsLoading: state => state.subpool.poolsLoading
  })
};