import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import { isEmpty } from "@/utils/model";
import { externalFormatFilPrice, toBigInt } from "@/utils/NumU";
import { formatUnits } from "ethers/lib.esm/utils";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { SuccessFilled } from '@element-plus/icons-vue';
import { readContract } from "@wagmi/core";
import { contracts } from "@/utils/contracts/config";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { getPrefix, NotificationErr } from "@/utils/common";
import { BigNumber } from "ethers";
import { loutsRpc } from "@/utils/LoutsRpc";
import { ChainHead, ChainReadObj, StateAccountKey, StateGetActor } from "@/utils/FilecoinApi";
import { decode as dagCborDecode } from "@ipld/dag-cbor";
import { newAddressFromBytes } from "@/utils/contracts/singer/filecoin_utils";
import { encode as addressToString } from "@glif/filecoin-address";
import Singer from '@/components/induction/singer/Singer';
export default {
  name: "BindNodeComponent",
  components: {
    SuccessFilled,
    Singer
  },
  data() {
    return {
      sameOwnerNodes: [],
      loadingNode: true,
      loading: false,
      checkIndex: -1,
      contractMethod: '',
      readySign: false,
      nodeRole: '',
      singerMultiple: false,
      contractParams: [],
      singerTables: [],
      showCancel: false,
      completeFlag: false,
      nodeHealthyErr: false,
      guaranteeNodeId: '',
      noGuaranteeNode: true // 是否已经担保
    };
  },

  props: {
    nodeId: {
      type: String,
      default: '',
      required: true
    }
  },
  watch: {},
  created() {},
  methods: {
    back() {
      this.$router.push({
        path: `/farm/provider/${this.nodeId}`
      });
    },
    isEmpty,
    externalFormatFilPrice,
    async init() {
      this.loadingNode = true;
      await this.getNode();
      await this.getNodesByOwner();
      this.loadingNode = false;
    },
    async getNodesByOwner() {
      let oldOwner = '';
      if (this.currentNode.oldOwner.toLowerCase().startsWith("0xff")) {
        oldOwner = (BigNumber.from(this.currentNode.oldOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      } else {
        oldOwner = this.currentNode.oldOwner.substr(2);
      }
      if (!oldOwner) {
        return;
      }
      let _sameOwnerNodes = await this.$store.dispatch('getNodesByOwner', {
        owner: oldOwner,
        role: this.currentNode.role
      });
      this.sameOwnerNodes = _sameOwnerNodes.filter(n => n.node.toString() !== this.nodeId.toString());
    },
    formatUnits,
    debtRatio({
      debt_value,
      position_value
    }) {
      let debtValue = toBigInt(debt_value);
      if (debtValue === 0n) {
        return 0n;
      }
      let positionValue = toBigInt(position_value);
      return PercentageMath.div(debtValue, positionValue);
    },
    debtRatioColor({
      debt_value,
      position_value,
      max_debt_ratio,
      liq_threshold
    }) {
      let _debtRatio = this.debtRatio({
        debt_value,
        position_value
      });
      position_value = toBigInt(position_value);
      max_debt_ratio = this.maxDebtRatio(max_debt_ratio);
      liq_threshold = toBigInt(liq_threshold);
      if (_debtRatio === 0n || _debtRatio < max_debt_ratio) {
        return 'debtRatio-color-green';
      } else if (_debtRatio >= max_debt_ratio && _debtRatio < liq_threshold) {
        return 'debtRatio-color-yellow';
      } else {
        return 'debtRatio-color-red';
      }
    },
    isHealthyNode(debt_value, position_value, max_leverage) {
      let _debtRatio = this.debtRatio({
        debt_value,
        position_value
      });
      let _maxDebtRatio = this.maxDebtRatio(max_leverage);
      return _debtRatio <= _maxDebtRatio;
    },
    maxDebtRatio(max_leverage) {
      return PercentageMath.div(toBigInt(max_leverage) - 10000n, toBigInt(max_leverage));
    },
    async initNode() {
      try {
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    async getNode() {
      const data = await readContract({
        address: contracts[this.env].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [this.nodeId]
      });
      let _guaranteeNodeId = BigNumber.from(data[4]).toBigInt() & toBigInt("0xFFFFFFFFFFF");
      if (_guaranteeNodeId !== 0n) {
        this.guaranteeNodeId = getPrefix() + _guaranteeNodeId.toString();
        this.noGuaranteeNode = false;
      } else {
        this.noGuaranteeNode = true;
      }
      let _nodeInfo = {
        id: '',
        beneficiary: '',
        owner: '',
        ownerAddress: '',
        beneficiaryAddress: ''
      };
      // eslint-disable-next-line no-undef
      let owner = getPrefix() + (BigNumber.from(data[0].owner).toBigInt() & BigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
      _nodeInfo.id = this.nodeId;
      _nodeInfo.owner = owner;
      let role = data[0].role;
      if (role === 1) {
        this.nodeRole = 'Owner';
      } else {
        this.nodeRole = 'Beneficiary';
      }
      this.$store.commit('SET_NODE_ROLE', {
        nodeRole: role === 1 ? 'Owner' : 'Beneficiary'
      });
      this.contractMethod = 'provideGuaranteeForNode';
      this.$store.commit('UPDATE_NODE_INFO', _nodeInfo);
    },
    initActorState() {
      loutsRpc(StateGetActor(this.nodeInfo.owner)).then(res => {
        loutsRpc(ChainReadObj(res.result.Head)).then(async res => {
          let stateRawbytes = Uint8Array.from(Buffer.from(res.result, 'base64'));
          let msgSigState = dagCborDecode(stateRawbytes);
          // 有更简单的直接调用api接口得方式
          let signersRawbytes = msgSigState[0];
          if (Array.isArray(signersRawbytes)) {
            let head = loutsRpc(ChainHead());
            this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
              singerMultipleThreshold: signersRawbytes.length
            });
            this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
              singerMultipleNum: msgSigState[1]
            });
            this.singerTables = [];
            for (let i = 0; i < signersRawbytes.length; i++) {
              let signerBytes = signersRawbytes[i];
              let signerIdAddress = newAddressFromBytes(signerBytes);
              let idAddressStr = addressToString(getPrefix()[0], signerIdAddress);
              let nonIdAddressStr = await loutsRpc(StateAccountKey(idAddressStr, head.Cids)); // 测试网用t, 主网用f
              this.singerTables.push({
                isOk: false,
                isCheck: false,
                address: nonIdAddressStr.result,
                id: idAddressStr,
                index: i
              });
            }
            this.singerMultiple = true;
          } else {
            this.singerMultiple = false;
          }
          setTimeout(() => {
            this.contractParams = [];
            this.contractParams.push(this.nodeId, this.sameOwnerNodes[this.checkIndex].node);
            this.showCancel = false;
            this.$refs['singer'].init().then(() => {
              this.showCancel = true;
            });
          }, 100);
        });
      });
    },
    checkNode(index) {
      this.checkIndex = index;
      let item = this.sameOwnerNodes[this.checkIndex];
      this.nodeHealthyErr = !this.isHealthyNode(item.debt_value, item.position_value, item.max_leverage);
    },
    async reapprove() {
      this.readySign = false;
      this.loading = true;
      await this.initNode();
      this.loading = false;
      this.readySign = true;
    },
    complete() {
      this.completeFlag = true;
    },
    viewNode() {
      this.$store.commit('SET_FARM_ACTIVE_NAME', {
        farmActiveName: 'first'
      });
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    reset() {
      this.readySign = false;
      this.showCancel = false;
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};