import { externalFormatFilPrice } from "@/utils/NumU";
import { formatUnits } from "ethers/lib.esm/utils";
export default {
  name: "AfterValue",
  props: {
    compare: {
      type: Boolean,
      default: true
    },
    isEth: {
      type: Boolean,
      default: true
    },
    suffix: {
      type: String,
      default: 'FIL'
    },
    units: {
      type: Number,
      default: 4
    },
    oldValue: {
      // eslint-disable-next-line no-undef
      type: BigInt,
      default: 0n
    },
    newValue: {
      // eslint-disable-next-line no-undef
      type: BigInt,
      default: 0n
    },
    toFixed: {
      type: Number,
      default: 2
    }
  },
  methods: {
    externalFormatFilPrice,
    formatUnits
  }
};