import "core-js/modules/es.array.push.js";
import { isEmpty } from "@/utils/model";
import { iWriteContract } from "@/utils/contracts/opertion";
import { contracts } from "@/utils/contracts/config";
import StorageProviderLendingPoolFactory from "@/utils/abi/StorageProviderLendingPoolFactory.json";
import { getLocal, gotoAddress, gotoHash, NotificationErr, NotificationSuccess, simpleValue } from "@/utils/common";
import { readContract } from "@wagmi/core";
import { isAddress } from "ethers/lib/utils";
export default {
  name: "CreatePoolComponents",
  data() {
    return {
      admin: '',
      onlyAcceptsNewNodes: false,
      loading: false,
      loadingInfo: false,
      btnText: '',
      hash: '',
      defaultMaxSealLoadLeverage: 0n,
      defaultMaxSealLoadLeverageOnlyAcceptsNewNodes: 0n,
      defaultMaxWithdrawLoadLeverage: 0n,
      maxNodeCount: 20,
      poolTypeOption: [{
        label: '借贷池',
        tip: '',
        value: 1
      }],
      poolType: 1,
      lendingTypeOption: [{
        label: '普通借贷池子',
        tip: '',
        value: 0,
        defaultMaxSealLoadLeverage: 0n,
        defaultMaxWithdrawLoadLeverage: 0n
      }, {
        label: '只接受新节点',
        tip: '',
        value: 1,
        defaultMaxSealLoadLeverage: 0n,
        defaultMaxWithdrawLoadLeverage: 0n
      }],
      lendingType: 0,
      isLocalAdminOption: [{
        label: '使用当前钱包创建',
        tip: '',
        value: 0
      }, {
        label: '指定钱包创建',
        tip: '',
        value: 1
      }],
      isLocalAdmin: 0,
      poolAdmin: '',
      adminErrTip: ''
    };
  },
  watch: {
    address() {
      this.init();
    },
    isLocalAdmin() {
      this.adminErrTip = '';
    },
    '$i18n.locale'() {
      this.initOptions();
    },
    lendingType() {
      if (this.lendingType === 1) {
        this.onlyAcceptsNewNodes = true;
      } else {
        this.onlyAcceptsNewNodes = false;
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    simpleValue,
    gotoAddress,
    isEmpty,
    async init() {
      this.initOptions();
      this.loadingInfo = true;
      if (!isEmpty(this.address)) {
        this.admin = this.address;
      }
      await this.initLevel();
      this.loadingInfo = false;
    },
    initOptions() {
      this.poolTypeOption = [{
        label: this.$t('subpool.created.Storage Provider'),
        tip: this.$t('subpool.created.Storage Provider Tip'),
        value: 1
      }, {
        label: this.$t('subpool.created.coming soon'),
        tip: '',
        value: 2
      }];
      this.lendingTypeOption = [{
        label: '3.00x',
        tip: this.$t('subpool.created.lendingType Tip2'),
        value: 0,
        defaultMaxSealLoadLeverage: this.lendingTypeOption[0].defaultMaxSealLoadLeverage,
        defaultMaxWithdrawLoadLeverage: this.lendingTypeOption[0].defaultMaxWithdrawLoadLeverage
      }, {
        label: '4.00x',
        tip: this.$t('subpool.created.lendingType Tip2_2'),
        value: 1,
        defaultMaxSealLoadLeverage: this.lendingTypeOption[1].defaultMaxSealLoadLeverage,
        defaultMaxWithdrawLoadLeverage: this.lendingTypeOption[1].defaultMaxWithdrawLoadLeverage
      }];
      this.isLocalAdminOption = [{
        label: this.$t('subpool.created.self'),
        tip: '',
        value: 0
      }, {
        label: this.$t('subpool.created.other'),
        tip: '',
        value: 1
      }];
    },
    async initLevel() {
      let defaultMaxSealLoadLeverage = await readContract({
        address: contracts[this.env].StorageProviderLendingPoolFactory.eth,
        abi: StorageProviderLendingPoolFactory.abi,
        functionName: 'defaultMaxSealLoadLeverage',
        args: []
      });
      this.lendingTypeOption[0].defaultMaxSealLoadLeverage = (parseFloat((defaultMaxSealLoadLeverage.toBigInt() / 100000000000000n).toString()) / 10000).toFixed(2);
      let defaultMaxSealLoadLeverageOnlyAcceptsNewNodes = await readContract({
        address: contracts[this.env].StorageProviderLendingPoolFactory.eth,
        abi: StorageProviderLendingPoolFactory.abi,
        functionName: 'defaultMaxSealLoadLeverageOnlyAcceptsNewNodes',
        args: []
      });
      this.lendingTypeOption[1].defaultMaxSealLoadLeverage = (parseFloat((defaultMaxSealLoadLeverageOnlyAcceptsNewNodes.toBigInt() / 100000000000000n).toString()) / 10000).toFixed(2);
      let defaultMaxWithdrawLoadLeverage = await readContract({
        address: contracts[this.env].StorageProviderLendingPoolFactory.eth,
        abi: StorageProviderLendingPoolFactory.abi,
        functionName: 'defaultMaxWithdrawLoadLeverage',
        args: []
      });
      this.lendingTypeOption[1].defaultMaxWithdrawLoadLeverage = (parseFloat((defaultMaxWithdrawLoadLeverage.toBigInt() / 100000000000000n).toString()) / 10000).toFixed(2);
      this.lendingTypeOption[0].defaultMaxWithdrawLoadLeverage = (parseFloat((defaultMaxWithdrawLoadLeverage.toBigInt() / 100000000000000n).toString()) / 10000).toFixed(2);
      this.maxNodeCount = 20;
    },
    selectPoolType(type) {
      this.onlyAcceptsNewNodes = type;
    },
    setCurrentWallet() {
      if (!this.isConnected) {
        this.connectWallet();
        return;
      }
      this.init();
    },
    affirm() {
      if (!this.isConnected) {
        this.connectWallet();
        return;
      }
      if (this.isLocalAdmin === 1 && !isAddress(this.poolAdmin)) {
        this.adminErrTip = '请输入有效钱包地址！';
        return;
      }
      this.adminErrTip = '';
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: contracts[this.$store.state.evn].StorageProviderLendingPoolFactory.eth,
        f0address: contracts[this.$store.state.evn].StorageProviderLendingPoolFactory.id,
        abi: StorageProviderLendingPoolFactory.abi,
        functionName: "createStorageProviderLendingPool",
        args: [this.isLocalAdmin === 0 ? "0x0000000000000000000000000000000000000000" : this.poolAdmin, this.lendingType === 1]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(res => {
        let logs = res['logs'];
        let _poolAddress = '';
        if (Array.isArray(logs) && logs.length > 0) {
          let len = logs.length;
          _poolAddress = '0x' + logs[len - 1]['data'].slice(-104, -64);
        }
        setTimeout(() => {
          this.btnText = '';
          this.hash = '';
          NotificationSuccess(this.$t('subpool.createPoolOkTip'));
          this.loading = false;
          this.$router.push({
            path: '/farm/lendingPool/' + _poolAddress
          });
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message, "affirm" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.btnText = '';
        this.hash = '';
      });
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    gotoHash
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};