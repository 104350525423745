import ComponentsLoading from "@/components/Loading";
import CID from 'cids';
import { proposalStatusMap, voteSignModel } from "@/utils/model";
export default {
  name: "ProposalDetails",
  components: {
    ComponentsLoading
  },
  data() {
    return {
      connect: undefined,
      connectors: [],
      isConnecting: false,
      pendingConnector: undefined,
      activeConnector: undefined,
      isConnected: false,
      loading: false,
      address: '',
      voteSelectIndex: -1,
      proposalStatusMap: proposalStatusMap,
      voteCheckInfo: {
        proposalId: '',
        proposalIpfs: '',
        choice: '',
        blockNumber: 0,
        vp: '',
        // 投票权
        voter: '',
        reason: '',
        created: 0
      },
      loadingVoteCheckInfo: false,
      walletSignInfo: {
        data: undefined,
        error: undefined,
        isError: false,
        isLoading: false,
        isSuccess: false,
        signTypedData: undefined
      },
      voteResuleOk: false,
      voteResuleFail: false,
      voteResuleFailMes: '投票失败',
      voteResult: [],
      showVoucherInfo: {}
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.voteResuleFail = false;
      this.voteResuleOk = false;
      if (this.detail.id === undefined) {
        await this.initProposal();
      }
      this.initVoteData();
    },
    async initProposal() {
      await this.$store.dispatch('getProposal', {
        id: this.$route.params.id
      });
    },
    async initVoteData() {
      this.loading = true;
      await this.$store.dispatch('getVoteList', {
        proposalId: this.detail.id
      });
      this.loading = false;
      this.voteResult = await this.$store.dispatch('getVoteResult', {
        proposalId: this.detail.id
      });
    },
    async voteFun() {
      this.loadingVoteCheckInfo = true;
      document.getElementById("showVoteModeBtn").click();
      let res = await this.$store.dispatch('getVoterInfo', {
        address: this.address,
        blockNumber: this.detail.blockNumber
      });
      this.voteCheckInfo.blockNumber = res['blockNumber'];
      this.voteCheckInfo.voter = res['address'];
      this.voteCheckInfo.choice = this.voteSelectIndex + 1;
      this.voteCheckInfo.vp = res['vp'];
      this.voteCheckInfo.proposalIpfs = this.detail.ipfs;
      this.voteCheckInfo.proposalId = this.detail.id;
      this.loadingVoteCheckInfo = false;
    },
    voteSelect(index) {
      this.voteSelectIndex = index;
    },
    connectWallet() {
      if (this.isConnecting && this.pendingConnector && this.connectors[0].id === this.pendingConnector?.id) {
        return;
      }
      this.connect(this.connectors[0]);
    },
    simpleCid(cid) {
      if (!cid) {
        return "";
      }
      let cidv1 = new CID(cid).toV1().toString('base32');
      return cidv1.substr(0, 5) + '...' + cidv1.substr(cidv1.length - 5);
    },
    gotoIpfs(cid) {
      if (!cid) {
        return;
      }
      let cidv1 = new CID(cid).toV1().toString('base32');
      window.open(`https://ipfs.io/ipfs/${cidv1}`);
    },
    goToSignatorIO(cid) {
      let cidv1 = new CID(cid).toV1().toString('base32');
      window.open(`https://signator.io/view?ipfs=${cidv1}`);
    },
    subVote() {
      if (this.walletSignInfo.isLoading) {
        return;
      }
      let signMessageJson = {};
      signMessageJson['voter'] = this.voteCheckInfo.voter;
      signMessageJson['choice'] = this.voteCheckInfo.choice;
      signMessageJson['vp'] = this.voteCheckInfo.vp;
      signMessageJson['proposalId'] = this.voteCheckInfo.proposalId;
      this.voteCheckInfo.created = new Date().getTime();
      signMessageJson['created'] = this.voteCheckInfo.created;
      signMessageJson['reason'] = this.voteCheckInfo.reason;
      console.log(JSON.stringify(signMessageJson));
      this.walletSignInfo.signTypedData({
        domain: voteSignModel.domain,
        types: voteSignModel.types,
        value: signMessageJson
      });
    },
    async onSignOk(val) {
      console.log(val);
      await this.$store.dispatch('addVote', {
        ...this.voteCheckInfo,
        sign: val
      });
      this.initVoteData();
      document.getElementById("voteCloseModal").click();
      this.showTip('ok');
    },
    onSignFail(e) {
      document.getElementById("voteCloseModal").click();
      if (e.code && e.msg) {
        this.showTip('fail', e.msg);
      } else {
        this.showTip('fail');
      }
    },
    simpleAddress(address) {
      if (!address) {
        return '';
      }
      return address.toString().substring(0, 6) + '...' + address.toString().substring(address.length - 6);
    },
    showTip(type, msg = '') {
      if (type === 'ok') {
        this.voteResuleFail = false;
        this.voteResuleOk = true;
        setTimeout(() => {
          this.voteResuleOk = false;
        }, 1500);
      }
      if (type === 'fail') {
        if (msg === '') {
          msg = '投票失败';
        }
        this.voteResuleFailMes = msg;
        this.voteResuleOk = false;
        this.voteResuleFail = true;
        setTimeout(() => {
          this.voteResuleFail = false;
        }, 1500);
      }
    },
    showVoucher(info) {
      this.showVoucherInfo = info;
      document.getElementById("showVoteInfoModeBtn").click();
    }
  },
  computed: {
    detail() {
      return this.$store.state.proposal.detail;
    },
    voteList() {
      return this.$store.state.vote.data;
    }
  }
};