import { mapState } from "vuex";
import { formatBit, formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { BigNumber } from "ethers";
import { simpleValue } from "@/utils/common";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "MinerOverview",
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {};
  },
  methods: {
    formatWalletFilPrice,
    formatBit,
    simpleValue
  },
  computed: mapState({
    nodeDataLoading: state => state.nodeData.loading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.nodeDataLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    operator() {
      let op = this.currentNode.operator;
      if (!op || op === "0x0000000000000000000000000000000000000000") {
        return "";
      }
      return op;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    averagePledge() {
      let pledge = this.pledge;
      let qualityAdjPower = toBigInt(this.qualityAdjPower) / 1099511627776n;
      if (qualityAdjPower <= 0) {
        return 0n;
      }
      return pledge / qualityAdjPower;
    },
    lockedRewards() {
      return toBigInt(this.currentNode.lockedRewards);
    },
    pledge() {
      return toBigInt(this.currentNode.pledge);
    },
    sectorSize() {
      return this.currentNode.sector_size;
    },
    qualityAdjPower() {
      return this.currentNode.quality_adj_power;
    },
    rawBytePower() {
      return this.currentNode.raw_byte_power;
    },
    availableBalance() {
      return toBigInt(this.currentNode.availableBalance);
    },
    positionValue() {
      return toBigInt(this.currentNode.position_value);
    },
    sectorActive() {
      return this.currentNode.sector_active;
    },
    sectorFaulty() {
      return this.currentNode.sector_faulty;
    },
    sectorLive() {
      return this.currentNode.sector_live;
    },
    sectorRecoveries() {
      return this.sectorLive - this.sectorActive - this.sectorFaulty;
    },
    owner() {
      let oldOwner = this.currentNode.oldOwner;
      if (oldOwner.toLowerCase().startsWith("0xff")) {
        oldOwner = (BigNumber.from(oldOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      }
      return oldOwner;
    }
  })
};