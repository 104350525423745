import {readContract} from "@wagmi/core";
import ReferralABI from "@/utils/abi/referrals/ReferralABI.json";
import {referralsContracts} from "@/utils/contracts/referrals/config";
import {iWriteContract} from "@/utils/contracts/opertion";
import nodejsHttp from "@/utils/http/nodejsHttp";
import {toBigInt, toBigIntDefault} from "@/utils/NumU";
import {isEmpty} from "@/utils/model";

let referrals = {
    state: {
        perMaxReferralCodeNum: 10,
        minReferralCode: 10,
        maxReferralCode: 100,
        addressReferralCodes: [],
        addressReferralCodesLoading: false,
        addressReferralHistory: [],
        addressReferralHistoryLoading: false,
        withdrawInfo: {
            1: {
                withdrawHistory: [],
                cursor: 0,
                size: 10,
                total: 0
            },
            2: {
                withdrawHistory: [],
                cursor: 0,
                size: 10,
                total: 0
            }
        },
        snapshootInfo: {
            1: {
                loading: false,
                snapshootList: [],
                cursor: 0,
                size: 10,
                total: 0
            },
            2: {
                loading: false,
                snapshootList: [],
                cursor: 0,
                size: 10,
                total: 0
            }
        },
        snapshootInfoLoading: false,
        withdrawHistoryLoading: false,
        info: {
            totalInterest: 0n,
            allTotalInterest: 0n,
            referralsCount: 0,
            commission: 0n,
            withdrawn: 0n,
            superTarget: 0n,
            totalStake: 0n
        },
        myInfoLoading: false,
        myInfo: {
            withdrawn: 0n,
            referral: '',
            superiorRatio: 0,
            subordinateRatio: 0,
            supLevel: 1,
            level: 1,
            interest: 0n,
            discount: 0n,
            stfilBalance: 0n,
            targetRebates: 0n,
            isRemote: true,
            isFinalSuperTarget: false,
            superTargetStFilBalance: 0n,
            isSkipFinalSuperTarget: false
        },
        cursor: 0,
        size: 10,
        isHaveWhiteList: false,
        isHaveHeightWhiteList: false,
        isFineWhiteListMint: false,
        isFineHeightWhiteListMint: false,
        addWhileListLoading: false,
        addWithdrawLoading: false,
        whiteListAddress: '',
        userRateInfo: {
            minRebateRatio: 0,
            maxRebateRatio: 0,
            fixedTarget: null
        },
        settlementInfo: {
            nextEpochShow: '',
            nextEpoch: 0,
            settlementIng: false
        }
    },
    getters: {},
    mutations: {
        SET_SETTLEMENT_NEXT_EPOCH_SHOW(state, {nextEpochShow}) {
            state.settlementInfo.nextEpochShow = nextEpochShow
        },
        SET_SETTLEMENT_NEXT_EPOCH(state) {
            state.settlementInfo.nextEpoch = state.settlementInfo.nextEpoch - 1000
        },
        SET_SETTLEMENT_INFO(state, settlementInfo) {
            state.settlementInfo.nextEpoch = settlementInfo.nextEpoch
            state.settlementInfo.settlementIng = settlementInfo.settlementInfo
        },
        SET_MY_INFO_LOADING(state, {myInfoLoading}) {
            state.myInfoLoading = myInfoLoading
        },
        SET_REFERRAL_MY_INFO(state, myInfo) {
            state.myInfo.referral = myInfo.referral
            state.myInfo.superiorRatio = parseInt(myInfo.superiorRatio === null ? 0 : myInfo.superiorRatio)
            state.myInfo.subordinateRatio = parseInt(myInfo.subordinateRatio)
            state.myInfo.supLevel = parseInt(myInfo.supLevel === null ? 0 : myInfo.supLevel)
            state.myInfo.level = parseInt(myInfo.level)
            state.myInfo.interest = toBigInt(myInfo.interest)
            state.myInfo.stfilBalance = toBigInt(myInfo.stfilBalance)
            state.myInfo.targetRebates = toBigIntDefault(myInfo.targetRebates, null)
            state.myInfo.isRemote = myInfo.isRemote
            state.myInfo.discount = toBigIntDefault(myInfo.discount, 0n)
            state.myInfo.isFinalSuperTarget = myInfo.isFinalSuperTarget
            state.myInfo.superTargetStFilBalance = myInfo.superTargetStFilBalance
            state.myInfo.withdrawn = toBigInt(myInfo.withdrawn)
            state.myInfo.isSkipFinalSuperTarget = myInfo.isSkipFinalSuperTarget
        },
        SET_USER_RATE_INFO(state, userRateInfo) {
            state.userRateInfo = userRateInfo
        },
        SET_WHITE_LIST_ADDRESS(state, {whiteListAddress}) {
            state.whiteListAddress = whiteListAddress
        },
        SET_IS_HAVE_HEIGHT_WHITE_LIST(state,{isHaveHeightWhiteList}){
            state.isHaveHeightWhiteList = isHaveHeightWhiteList
        },
        SET_IS_FINE_WHITE_LIST_MINT(state, {isFineWhiteListMint}) {
            state.isFineWhiteListMint = isFineWhiteListMint
        },
        SET_IS_FINE_HEIGHT_WHITE_LIST_MINT(state,{isFineHeightWhiteListMint}){
            state.isFineHeightWhiteListMint = isFineHeightWhiteListMint
        },
        SET_WITHDRAW_HISTORY_LOADING(state, {withdrawHistoryLoading}) {
            state.withdrawHistoryLoading = withdrawHistoryLoading
        },
        SET_ADD_WITHDRAW_LOADING(state, {addWithdrawLoading}) {
            state.addWithdrawLoading = addWithdrawLoading
        },
        SET_WITHDRAW_HISTORY(state, {withdrawHistoryInfo, type}) {
            state.withdrawInfo[type].withdrawHistory = withdrawHistoryInfo.withdrawHistory
            state.withdrawInfo[type].cursor = withdrawHistoryInfo.cursor
            state.withdrawInfo[type].size = withdrawHistoryInfo.size
            state.withdrawInfo[type].total = withdrawHistoryInfo.total
        },
        SET_SNAPSHOOT_INFO(state, {snapshootInfo, type}) {
            state.snapshootInfo[type].snapshootList = snapshootInfo.snapshootList
            state.snapshootInfo[type].cursor = snapshootInfo.cursor
            state.snapshootInfo[type].size = snapshootInfo.size
            state.snapshootInfo[type].total = snapshootInfo.total
        },
        SET_SNAPSHOOT_INFO_LOADING(state, {snapshootInfoLoading,type}) {
            state.snapshootInfo[type].loading = snapshootInfoLoading
        },
        CLEAR_REFERRALS_VALUE(state) {
            state.addressReferralHistory = []
            state.addressReferralCodes = []
            state.info.totalInterest = 0n
            state.info.allTotalInterest = 0n
            state.info.totalStake = 0n
            state.info.referralsCount = 0
            state.info.commission = 0n
            state.myInfo.withdrawn = 0n
            state.myInfo.referral = ''
            state.myInfo.superiorRatio = 0
            state.myInfo.subordinateRatio = 0
            state.myInfo.supLevel = 1
            state.myInfo.level = 1
            state.myInfo.interest = 0n
            state.myInfo.discount = 0n
            state.myInfo.stfilBalance = 0n
            state.myInfo.targetRebates = 0n
            state.myInfo.superTargetStFilBalance = 0n
            state.myInfo.isFinalSuperTarget = false
            state.myInfo.isRemote = true
            state.myInfo.isSkipFinalSuperTarget = false
            state.withdrawInfo[1].withdrawHistory = []
            state.withdrawInfo[1].total = 0
            state.withdrawInfo[2].withdrawHistory = []
            state.withdrawInfo[2].total = 0
            state.snapshootInfo[1].snapshootList = []
            state.snapshootInfo[2].snapshootList = []
            state.snapshootInfo[1].total = 0
            state.snapshootInfo[2].total = 0
            state.isHaveWhiteList = false
        },
        SET_IS_HAVE_WHITE_LIST_LOADING(state, {addWhileListLoading}) {
            state.addWhileListLoading = addWhileListLoading
        },
        SET_IS_HAVE_WHITE_LIST(state, {isHaveWhiteList}) {
            state.isHaveWhiteList = isHaveWhiteList
        },
        SET_ADDRESS_REFERRAL_CODES_LOADING(state, {addressReferralCodesLoading}) {
            state.addressReferralCodesLoading = addressReferralCodesLoading
        },
        SET_ADDRESS_REFERRAL_HISTORY_LOADING(state, {addressReferralHistoryLoading}) {
            state.addressReferralHistoryLoading = addressReferralHistoryLoading
        },
        SET_ADDRESS_REFERRAL_CODES(state, addressReferralCodes) {
            state.addressReferralCodes = addressReferralCodes
        },
        SET_ADDRESS_REFERRAL_HISTORY(state, {
            totalInterest,
            allTotalInterest,
            referralsCount,
            history,
            commission,
            withdrawn,
            superTarget,
            totalStake
        }) {
            state.addressReferralHistory = history
            state.info.totalInterest = toBigInt(totalInterest)
            state.info.allTotalInterest = toBigInt(allTotalInterest)
            state.info.referralsCount = referralsCount
            state.info.commission = toBigInt(commission)
            state.info.withdrawn = toBigInt(withdrawn)
            state.info.superTarget = toBigInt(superTarget)
            state.info.totalStake = toBigInt(totalStake)
        },
        SET_ADDRESS_REFERRAL_CURSOR(state, {cursor}) {
            state.cursor = cursor
        },
        SET_PER_MAX_REFERRAL_CODE_NUM(state, {per_max_referral_code_num}) {
            state.perMaxReferralCodeNum = parseInt(per_max_referral_code_num)
        },
        SET_MIN_REFERRAL_CODE(state, {min_referral_code}) {
            state.minReferralCode = parseInt(min_referral_code)
        },
        SET_MAX_REFERRAL_CODE(state, {max_referral_code}) {
            state.maxReferralCode = parseInt(max_referral_code)
        },
    },
    actions: {
        async initSettlementInfo({commit}) {
            let data = await nodejsHttp.get("referral/settlement")
            commit('SET_SETTLEMENT_INFO', data)
        },
        async initMyReferralInfo({commit, rootState, dispatch}, {address}) {
            commit('SET_MY_INFO_LOADING', {myInfoLoading: true})
            try {
                let data = await nodejsHttp.get("referral/myInfo", {params: {address}})
                let {referral} = data
                if (isEmpty(referral)) {
                    let localReferral = rootState.stakeInfo.referralCode
                    let res = await dispatch('selectReferralCode', {referralCode: localReferral})
                    if (res !== null) {
                        let {level, superiorRatio} = res
                        data.supLevel = level
                        data.superiorRatio = superiorRatio
                        data.referral = localReferral
                    }else {
                        data.referral = ''
                    }
                    data.isRemote = false
                } else {
                    data.isRemote = true
                }
                commit('SET_REFERRAL_MY_INFO', data)
            }finally {
                commit('SET_MY_INFO_LOADING', {myInfoLoading: false})
            }
        },
        async initUserRateInfo({commit}, {address}) {
            let res = await nodejsHttp.get('referral/rate', {params: {address}})
            commit('SET_USER_RATE_INFO', res)
        },
        async getReferralCodeLimit({commit, rootState}) {
            let per_max_referral_code_num = await readContract({
                address: referralsContracts[rootState.evn].referral.eth,
                abi: ReferralABI.abi,
                functionName: 'PER_MAX_REFERRAL_CODE_NUM',
                args: []
            })
            commit('SET_PER_MAX_REFERRAL_CODE_NUM', {per_max_referral_code_num})
            let min_referral_code = await readContract({
                address: referralsContracts[rootState.evn].referral.eth,
                abi: ReferralABI.abi,
                functionName: 'MIN_REFERRAL_CODE',
                args: []
            })
            commit('SET_MIN_REFERRAL_CODE', {min_referral_code})
            let max_referral_code = await readContract({
                address: referralsContracts[rootState.evn].referral.eth,
                abi: ReferralABI.abi,
                functionName: 'MAX_REFERRAL_CODE',
                args: []
            })
            commit('SET_MAX_REFERRAL_CODE', {max_referral_code})
        },
        async checkReferralCode({rootState}, {referralCode}) {
            return await readContract({
                address: referralsContracts[rootState.evn].referral.eth,
                abi: ReferralABI.abi,
                functionName: 'getAddrByReferralCode',
                args: [referralCode]
            })
        },
        async addWhileList({commit}, {address, fromAddress, signature, f1Address}) {
            commit('SET_IS_HAVE_WHITE_LIST_LOADING', {addWhileListLoading: true})
            try {
                return await nodejsHttp.post('referral/addWhitelist', {data: {address, fromAddress, signature, f1Address}})
            } finally {
                commit('SET_IS_HAVE_WHITE_LIST_LOADING', {addWhileListLoading: false})
            }
        },
        async getWhileList({commit}, {address}) {
            let res = await nodejsHttp.get('referral/getWhitelist', {params: {address}})
            if (res!==null && res.length > 0){
                res.forEach(wl => {
                    let {proof, is_special,address} = wl
                    if (is_special === 1){
                        commit('SET_IS_HAVE_HEIGHT_WHITE_LIST', {isHaveHeightWhiteList: true})
                        commit('SET_IS_FINE_HEIGHT_WHITE_LIST_MINT', {isFineHeightWhiteListMint: Boolean(proof)})
                    }else {
                        commit('SET_IS_HAVE_WHITE_LIST', {isHaveWhiteList: true})
                        commit('SET_IS_FINE_WHITE_LIST_MINT', {isFineWhiteListMint: Boolean(proof)})
                        commit('SET_WHITE_LIST_ADDRESS', {whiteListAddress: address})
                    }
                })
            }else {
                commit('SET_IS_HAVE_WHITE_LIST', {isHaveWhiteList: false})
                commit('SET_IS_HAVE_HEIGHT_WHITE_LIST', {isHaveHeightWhiteList: false})
            }
        },
        async registerReferralCode({rootState}, {referralCode}) {
            return await iWriteContract({
                address: referralsContracts[rootState.evn].referral.eth,
                f0address: referralsContracts[rootState.evn].referral.id,
                abi: ReferralABI.abi,
                functionName: "registerReferralCode",
                args: [referralCode]
            })
        },
        // eslint-disable-next-line no-empty-pattern
        async updateReferralCode({}, {address}) {
            await nodejsHttp.put('referral/code?address=' + address)
        },
        async viewOwnerCollections({commit}, {address}) {
            commit('SET_ADDRESS_REFERRAL_CODES_LOADING', {addressReferralCodesLoading: true})
            let _addressReferralCodes = await nodejsHttp.get('referral/info?address=' + address,)
            commit('SET_ADDRESS_REFERRAL_CODES', _addressReferralCodes)
            commit('SET_ADDRESS_REFERRAL_CODES_LOADING', {addressReferralCodesLoading: false})
        },
        async historyCollections({commit}, {address, page = 1, size = 10}) {
            commit('SET_ADDRESS_REFERRAL_HISTORY_LOADING', {addressReferralHistoryLoading: true})
            let _addressReferralHistory = await nodejsHttp.get('referral/history', {params: {address, page, size}})
            commit('SET_ADDRESS_REFERRAL_HISTORY', _addressReferralHistory)
            commit('SET_ADDRESS_REFERRAL_HISTORY_LOADING', {addressReferralHistoryLoading: false})
        },
        // eslint-disable-next-line no-empty-pattern
        async addUserWithdrawn({}, {address, type, signature, f1Address}) {
            return await nodejsHttp.post('referral/addUserWithdrawn', {data: {address, type, signature, f1Address}})
        },
        async initWithdrawList({commit}, {address, type = 1, page = 1, size = 10}) {
            commit('SET_WITHDRAW_HISTORY_LOADING', {withdrawHistoryLoading: true})
            let res = await nodejsHttp.get('referral/getUserWithdrawn', {params: {address, type, page, size}})
            commit('SET_WITHDRAW_HISTORY', {withdrawHistoryInfo:res, type} )
            commit('SET_WITHDRAW_HISTORY_LOADING', {withdrawHistoryLoading: false})
        },
        async initSnapshoot({commit}, {address, type = 1, page = 1, size = 10}) {
            commit('SET_SNAPSHOOT_INFO_LOADING', {snapshootInfoLoading: true, type})
            let res = await nodejsHttp.get('referral/getSnapshoot', {params: {address, type, page, size}})
            commit('SET_SNAPSHOOT_INFO', {snapshootInfo: res, type})
            commit('SET_SNAPSHOOT_INFO_LOADING', {snapshootInfoLoading: false,type})
        },
        // eslint-disable-next-line no-empty-pattern
        async selectReferralCode({}, {referralCode}) {
            return await nodejsHttp.get('referral/getReferralCode', {params: {referralCode}})
        },
        // eslint-disable-next-line no-empty-pattern
        async setReferralCode({}, {address, referralCode, signature, f1Address}) {
            let url = `referral/setReferralCode?address=${address}&referralCode=${referralCode}&signature=${signature}`
            if (!isEmpty(f1Address)) {
                url = url + `&f1Address=${f1Address}`
            }
            return await nodejsHttp.put(url)
        }
    },
    modules: {}
}

export default referrals
