import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bcd12e9e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "position-relative transfer-input-content"
};
const _hoisted_2 = {
  class: "d-flex justify-content-between"
};
const _hoisted_3 = {
  class: "transfer-input-title"
};
const _hoisted_4 = {
  class: "transfer-input-title cursor-pointer"
};
const _hoisted_5 = {
  class: "transfer-input-root"
};
const _hoisted_6 = {
  class: "transfer-input-root-base"
};
const _hoisted_7 = ["disabled", "value", "type", "placeholder"];
const _hoisted_8 = ["disabled", "value", "type", "placeholder"];
const _hoisted_9 = {
  class: "transfer-input-root-base-position-end"
};
const _hoisted_10 = {
  key: 0,
  class: "transfer-input-root-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_4, [_renderSlot(_ctx.$slots, "subTitle", {}, undefined, true)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [$props.type === 'textarea' ? (_openBlock(), _createElementBlock("textarea", {
    key: 0,
    disabled: $props.disabled,
    class: "form-control shadow-none transfer-textarea",
    ref: 'transfer-textarea-' + $props.id,
    onInput: _cache[0] || (_cache[0] = (...args) => $options.handleInput && $options.handleInput(...args)),
    onBlur: _cache[1] || (_cache[1] = (...args) => $options.blurHandleInput && $options.blurHandleInput(...args)),
    style: _normalizeStyle({
      fontSize: $props.valueFontSize,
      color: $props.valueFontColor
    }),
    value: $props.modelValue,
    type: $props.type,
    placeholder: $props.placeholder,
    autocomplete: "off",
    required: ""
  }, null, 44, _hoisted_7)) : (_openBlock(), _createElementBlock("input", {
    key: 1,
    disabled: $props.disabled,
    class: "form-control shadow-none transfer-input",
    style: _normalizeStyle({
      fontSize: $props.valueFontSize,
      color: $props.valueFontColor
    }),
    value: $props.modelValue,
    onInput: _cache[2] || (_cache[2] = (...args) => $options.handleInput && $options.handleInput(...args)),
    type: $props.type,
    placeholder: $props.placeholder,
    autocomplete: "off",
    required: ""
  }, null, 44, _hoisted_8)), _createElementVNode("div", _hoisted_9, [_renderSlot(_ctx.$slots, "suffix", {}, undefined, true)])]), $props.showValueTip ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("p", null, _toDisplayString($props.valueTip), 1)])) : _createCommentVNode("", true)])]);
}