import PageHead from "@/components/header/PageHead";
import PoolTransferOwnership from '@/components/subpool/change/PoolTransferOwnership';
import { isAddress } from "ethers/lib/utils";
import Tip from '@/components/tip/Tip';
export default {
  name: "PoolTransferOwnershipPage",
  components: {
    PageHead,
    PoolTransferOwnership,
    Tip
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    isAddress
  },
  computed: {
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};