export default {
  name: "InfoBlock",
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: '0'
    }
  }
};