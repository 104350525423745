import "core-js/modules/es.array.push.js";
import ChartBlock from '@/components/ChartBlock.vue';
import PageHead from '@/components/header/PageHead.vue';
import AdCard from '@/components/tip/AdCard';
import { mapState } from "vuex";
import { ethTo, externalFormatFilPrice, formatPrice, formatPriceToMaxFixed, formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { formatUnits } from "ethers/lib.esm/utils";
import { E23, WadRayMath } from "@/utils/contracts/WadRayMath";
import Tooltip from '@/components/tip/Tooltip';
import { contracts } from "@/utils/contracts/config";
import { dateFormat, simpleDateByDayFormat } from "@/utils/common";
import ScrollNumber from '@/components/ScrollNumber.vue';
import StakeLeftChart from '@/components/stake/StakeLeftChart';
export default {
  name: "DashboardPage",
  components: {
    ChartBlock,
    PageHead,
    AdCard,
    Tooltip,
    ScrollNumber,
    StakeLeftChart
  },
  data() {
    return {
      optionSupplyInfo: {
        title: {
          show: false
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          selectedMode: true,
          data: ['Supply', 'Borrow']
        },
        xAxis: {
          data: [],
          axisTick: {
            show: false
          },
          axisLabel: {
            formatter: function (value) {
              return simpleDateByDayFormat(value);
            }
          }
        },
        grid: {
          left: '40px',
          right: '25px',
          top: '70px'
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            formatter: function (value) {
              let _uds = Math.floor(value);
              if (_uds >= 1000000) {
                let res = parseFloat(value) / 1000000;
                if (res === 1) {
                  return '1M';
                }
                return (parseFloat(value) / 1000000).toFixed(1) + 'M';
              }
              if (_uds >= 100000) {
                return Math.floor(_uds / 1000) + 'K';
              }
              return _uds;
            }
          },
          axisLine: {
            show: true
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            let utilizationRate = (parseFloat(params[1].value) / parseFloat(params[0].value) * 100).toFixed(2);
            if (params[0].dataIndex === this.optionSupplyInfo.xAxis.data.length - 1) {
              return `${dateFormat(params[0].name, true)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e38e8e;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value} FIL<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #862bc6;margin-right: 5px;margin-bottom: 1px;"></div>${params[1].seriesName}  ${params[1].value} FIL</br><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #39dccf;margin-right: 5px;margin-bottom: 1px;"></div>${this.$t('stake.UtilizationRate')}  ${utilizationRate}%`;
            } else {
              return `${dateFormat(params[0].name)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e38e8e;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value} FIL<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #862bc6;margin-right: 5px;margin-bottom: 1px;"></div>${params[1].seriesName}  ${params[1].value} FIL</br><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #39dccf;margin-right: 5px;margin-bottom: 1px;"></div>${this.$t('stake.UtilizationRate')}  ${utilizationRate}%`;
            }
          }
        },
        series: [{
          name: 'Supply',
          type: 'line',
          data: [],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e38e8e'
          }
        }, {
          name: 'Borrow',
          type: 'line',
          data: [],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#862bc6'
          }
        }]
      },
      optionBorrowInfo: {
        title: {
          show: false
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          data: ['Borrow,stable', 'Borrow,variable']
        },
        xAxis: {
          data: ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
          axisLabel: {
            formatter: function (value) {
              return simpleDateByDayFormat(value);
            }
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          top: '70px;',
          left: '40px',
          right: '25px'
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: function (value) {
              let _uds = Math.floor(value);
              if (_uds >= 1000000) {
                let res = parseFloat(value) / 1000000;
                if (res === 1) {
                  return '1M';
                }
                return (parseFloat(value) / 1000000).toFixed(1) + 'M';
              }
              if (_uds >= 100000) {
                return Math.floor(_uds / 1000) + 'K';
              }
              return _uds;
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            if (params[0].dataIndex === this.optionBorrowInfo.xAxis.data.length - 1) {
              return `${dateFormat(params[0].name, true)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e86016;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value} FIL <br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #1619d7;margin-right: 5px;margin-bottom: 2px;"></div>${params[1].seriesName}  ${params[1].value} FIL`;
            } else {
              return `${dateFormat(params[0].name)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e86016;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value} FIL <br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #1619d7;margin-right: 5px;margin-bottom: 2px;"></div>${params[1].seriesName}  ${params[1].value} FIL`;
            }
          }
        },
        series: [{
          name: 'Borrow,stable',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e86016'
          }
        }, {
          name: 'Borrow,variable',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#1619d7'
          }
        }]
      },
      optionInterestRateMode: {
        title: {
          show: false
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          data: ['Borrow APR,stable', 'Borrow APR,variable']
        },
        xAxis: {
          data: ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
          axisLabel: {
            formatter: function (value) {
              return simpleDateByDayFormat(value);
            }
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          top: '70px;',
          left: '35px',
          right: '55px'
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: '{value}%'
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            if (params[0].dataIndex === this.optionInterestRateMode.xAxis.data.length - 1) {
              return `${dateFormat(params[0].name, true)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e86016;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value}% <br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #1619d7;margin-right: 5px;margin-bottom: 2px;"></div>${params[1].seriesName}  ${params[1].value}%`;
            } else {
              return `${dateFormat(params[0].name)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e86016;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value}% <br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #1619d7;margin-right: 5px;margin-bottom: 2px;"></div>${params[1].seriesName}  ${params[1].value}%`;
            }
          }
        },
        series: [{
          name: 'Borrow APR,stable',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e86016'
          }
        }, {
          name: 'Borrow APR,variable',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: false,
          //设置折线图颜色
          itemStyle: {
            color: '#1619d7'
          },
          markLine: {
            lineStyle: {
              color: '#1619d7',
              width: 1
            },
            symbol: ['none', 'none'],
            label: {
              show: true,
              position: 'end',
              color: '#1619d7',
              distance: 7,
              formatter: params => {
                return `${this.$t('common.AVG')}\n${params.data.coord[1]}%`;
              }
            },
            data: [{
              yAxis: 0
            }]
          }
        }]
      },
      totalSupplyCount: 0
    };
  },
  watch: {
    '$i18n.locale'() {
      this.initOptionBorrowInfo();
    },
    supplyBalance() {
      if (this.optionSupplyInfo.xAxis.data.length > 0 && this.supplyBalance > 0n) {
        if (this.totalSupplyCount > 0) {
          this.optionSupplyInfo.xAxis.data.pop();
          this.optionSupplyInfo.series[0].data.pop();
        }
        this.optionSupplyInfo.xAxis.data.push(new Date().getTime());
        this.optionSupplyInfo.series[0].data.push(ethTo(this.supplyBalance).toFixed(2));
        this.totalSupplyCount++;
      }
    },
    borrowBalance() {
      if (this.optionSupplyInfo.xAxis.data.length > 0 && this.borrowBalance > 0n) {
        this.optionSupplyInfo.series[1].data.push(ethTo(this.borrowBalance).toFixed(2));
      }
    },
    totalStakeToken(val) {
      this.optionSupplyInfo.xAxis.data = val.time;
      this.optionSupplyInfo.series[0].data = val.data;
    },
    chartTotalVariableBorrow(val) {
      this.optionBorrowInfo.xAxis.data = val.time;
      this.optionBorrowInfo.series[1].data = val.data;
      this.optionBorrowInfo.series[0].data = this.chartTotalStableBorrow.data;
    },
    chartTotalBorrow() {
      this.optionSupplyInfo.series[1].data = this.chartTotalBorrow.data;
    },
    chartVariableRate(val) {
      this.optionInterestRateMode.xAxis.data = val.time;
      this.optionInterestRateMode.series[1].data = val.data;
      this.optionInterestRateMode.series[0].data = this.chartStableRate.data;

      // this.optionInterestRateMode.series[0].markLine.data[0].yAxis = this.stableRateAvg[this.$store.state.chartData.params.rate.resolutionInHours]
      this.optionInterestRateMode.series[1].markLine.data[0].yAxis = this.variableRateAvg[this.$store.state.chartData.params.rate.resolutionInHours];
    }
  },
  mounted() {
    this.init();
    this.clientHeightInit(1000);
    this.clientHeightChange();
  },
  methods: {
    init() {
      this.initOverview();
      this.initOverviewTiming();
      this.initOptionBorrowInfo();
    },
    clientHeightInit(time) {
      setTimeout(() => {
        let firstImg = this.$refs['firstImg'];
        if (firstImg) {
          let _clientHeight = 0;
          if (firstImg.clientHeight > 0) {
            _clientHeight = firstImg.clientHeight + 1;
          }
          let screenHeight = `${_clientHeight}px`;
          this.$store.commit('SET_SCREEN_HEIGHT', {
            screenHeight
          });
        }
      }, time);
    },
    clientHeightChange() {
      window.onresize = () => {
        this.clientHeightInit(500);
      };
    },
    initOptionBorrowInfo() {
      this.optionBorrowInfo.legend.data = [this.$t('dashboard.BorrowStable'), this.$t('dashboard.BorrowVariable')];
      this.optionBorrowInfo.series[0].name = this.$t('dashboard.BorrowStable');
      this.optionBorrowInfo.series[1].name = this.$t('dashboard.BorrowVariable');
      this.optionInterestRateMode.legend.data = [this.$t('dashboard.BorrowAPYstable'), this.$t('dashboard.BorrowAPYvariable')];
      this.optionInterestRateMode.series[0].name = this.$t('dashboard.BorrowAPYstable');
      this.optionInterestRateMode.series[1].name = this.$t('dashboard.BorrowAPYvariable');
      this.optionSupplyInfo.legend.data = [this.$t('dashboard.supply'), this.$t('dashboard.dashTotalBorrowed')];
      this.optionSupplyInfo.series[0].name = this.$t('dashboard.supply');
      this.optionSupplyInfo.series[1].name = this.$t('dashboard.dashTotalBorrowed');
    },
    chartStakeSelect({
      value
    }) {
      this.$store.commit('SET_STAKE_RESOLUTIONINHOURS', {
        resolutionInHours: value
      });
      this.initTotalStakeToken(true);
    },
    onboarding() {
      this.$router.push({
        path: '/farm/createPool'
      });
    },
    chartBorrowSelect({
      value
    }) {
      this.$store.commit('SET_BORROW_RESOLUTIONINHOURS', {
        resolutionInHours: value
      });
      this.initTotalBorrowed(true);
    },
    chartRateSelect({
      value
    }) {
      this.$store.commit('SET_RATE_RESOLUTIONINHOURS', {
        resolutionInHours: value
      });
      this.initRateChart(true);
    },
    initOverviewTiming() {
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.initOverview,
        pageNames: ['analytics']
      });
    },
    initOverview() {
      this.initTotalStakeToken();
      this.initTotalBorrowed();
      this.initRateChart();
    },
    async initTotalStakeToken(operation = false) {
      let _timingFlag = this.timingFlag;
      if (!_timingFlag || operation) {
        this.$refs.chartStake?.loading();
      }
      let p = [];
      p.push(this.$store.dispatch('initTotalStakeToken'));
      p.push(this.$store.dispatch('initTotalBorrowed2'));
      await Promise.all(p);
      if (!_timingFlag || operation) {
        setTimeout(() => {
          this.$refs.chartStake?.unLoading();
        }, 500);
      }
    },
    async initTotalBorrowed(operation = false) {
      let _timingFlag = this.timingFlag;
      if (!_timingFlag || operation) {
        this.$refs.chartBorrow?.loading();
      }
      await this.$store.dispatch('initTotalBorrowed');
      if (!_timingFlag || operation) {
        setTimeout(() => {
          this.$refs.chartBorrow?.unLoading();
        }, 500);
      }
    },
    async initRateChart(operation = false) {
      let _timingFlag = this.timingFlag;
      if (!_timingFlag || operation) {
        this.$refs.chartRate?.loading();
      }
      await this.$store.dispatch('initRateChart');
      if (!_timingFlag || operation) {
        setTimeout(() => {
          this.$refs.chartRate?.unLoading();
        }, 500);
      }
    },
    gotNetAPYTip() {
      window.open(`${this.docsUrlPrefix}tokenomics/stfil`);
    },
    formatWalletFilPrice,
    formatPriceToMaxFixed,
    externalFormatFilPrice,
    showComputer() {
      this.$store.state.refs['iHeader'].showApyComputerShowDialog();
    },
    formatUnits,
    formatPrice,
    toUsd(val, showOriginal = false) {
      let _fil = parseFloat(this.filPrice);
      if (_fil <= 0) {
        if (showOriginal) {
          return val;
        }
        return '';
      }
      let usd = toBigInt(val) * toBigInt(Math.floor(parseFloat(this.filPrice) * 100));
      let _uds = parseFloat(formatUnits(usd.toString(), 20));
      if (_uds > 1000000) {
        return '$' + (_uds / 1000000).toFixed(2) + 'M';
      }
      if (_uds > 100000) {
        return '$' + (_uds / 1000).toFixed(2) + 'K';
      }
      return '$' + _uds.toFixed(4);
    },
    toUsdByObj(val, showOriginal = false) {
      let _fil = parseFloat(this.filPrice);
      if (_fil <= 0) {
        if (showOriginal) {
          return val;
        }
        return '';
      }
      let value = '0';
      let suffix = '';
      let usd = toBigInt(val) * toBigInt(Math.floor(parseFloat(this.filPrice) * 100));
      let _uds = parseFloat(formatUnits(usd.toString(), 20));
      if (isNaN(_uds)) {
        return {
          prefix: '$',
          value: value,
          suffix: suffix
        };
      }
      if (_uds > 1000000) {
        value = (_uds / 1000000).toFixed(2);
        suffix = 'M';
      } else if (_uds > 100000) {
        value = (_uds / 1000).toFixed(2);
        suffix = 'K';
      } else {
        value = _uds.toFixed(4);
        suffix = '';
      }
      return {
        prefix: '$',
        value: value,
        suffix: suffix
      };
    },
    toUsdByUsd(usd) {
      let _uds = parseFloat(usd);
      if (_uds > 1000000) {
        return '$' + (_uds / 1000000).toFixed(2) + 'M';
      }
      if (_uds > 100000) {
        return '$' + (_uds / 1000).toFixed(2) + 'K';
      }
      return '$' + _uds.toFixed(4);
    },
    toUsdByUsdObj(usd) {
      let _uds = parseFloat(usd);
      let value = '0';
      let suffix = '';
      if (isNaN(_uds)) {
        return {
          prefix: '$',
          value: value,
          suffix: suffix
        };
      }
      if (_uds > 1000000) {
        value = (_uds / 1000000).toFixed(2);
        suffix = 'M';
      } else if (_uds > 100000) {
        value = (_uds / 1000).toFixed(2);
        suffix = 'K';
      } else {
        value = _uds.toFixed(4);
        suffix = '';
      }
      return {
        prefix: '$',
        value: value,
        suffix: suffix
      };
    },
    toBigInt,
    openAvailableliquidity() {
      if (this.$store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${this.$i18n.locale}/address/${contracts[this.$store.state.evn].StakingPool.eth}`);
      } else {
        window.open(`https://filfox.info/${this.$i18n.locale}/address/${contracts[this.$store.state.evn].StakingPool.eth}`);
      }
    },
    openStakers() {
      let url = '';
      if (this.$store.state.evn === 'test') {
        url = `https://calibration.filfox.info/${this.$i18n.locale}/token/${contracts[this.$store.state.evn].STFILToken.eth}?t=holder`;
      } else {
        url = `https://filfox.info/${this.$i18n.locale}/token/${contracts[this.$store.state.evn].STFILToken.eth.toLowerCase()}?t=holder`;
      }
      window.open(url);
    }
  },
  computed: mapState({
    screenHeight: state => state.screenHeight,
    reserveDataLoading: state => state.publicInfo.reserveDataLoading,
    stableRateEnabledLoading: state => state.publicInfo.stableRateEnabledLoading,
    poolIsPausedLoading: state => state.publicInfo.poolIsPausedLoading,
    variableDebtTokenTotalSupplyLoading: state => state.publicInfo.variableDebtTokenTotalSupplyLoading,
    stableDebtTokenTotalSupplyLoading: state => state.publicInfo.stableDebtTokenTotalSupplyLoading,
    poolStFilBalanceLoading: state => state.publicInfo.poolStFilBalanceLoading,
    rewardFeeLoading: state => state.publicInfo.rewardFeeLoading,
    riskReserveLoading: state => state.publicInfo.riskReserveLoading,
    tvlLoading: state => state.publicInfo.tvlLoading,
    tvl: state => state.publicInfo.tvl,
    tvlFIL: state => {
      return toBigInt(state.publicInfo.tvlPoolStFilBalance) + toBigInt(state.publicInfo.tvlAllMinerValue);
    },
    nodeCount: state => state.publicInfo.nodeCount,
    stakeCount: state => state.publicInfo.stakeCount,
    tvlPoolStFilBalance: state => {
      return toBigInt(state.publicInfo.tvlPoolStFilBalance);
    },
    tvlAllMinerValue: state => {
      console.log('state.publicInfo', toBigInt(state.publicInfo.tvlAllMinerValue) - toBigInt(state.publicInfo.tvlTotalBorrowed));
      return toBigInt(state.publicInfo.tvlAllMinerValue) - toBigInt(state.publicInfo.tvlTotalBorrowed);
    },
    tvlPoolStFilBalanceUsd: state => {
      return state.publicInfo.tvlPoolStFilBalanceUsd;
    },
    tvlAllMinerValueUsd: state => {
      return state.publicInfo.tvlAllMinerValueUsd;
    },
    tvlWithdrawLoadAmountUsd: state => {
      return state.publicInfo.tvlWithdrawLoadAmountUsd;
    },
    publicInfoLoading: state => state.publicInfo.loading,
    optimalUtilizationRateIntervalEnd: state => {
      return parseFloat(formatUnits(state.publicInfo.optimalUtilizationRateIntervalEnd, 25));
    },
    optimalUtilizationRateIntervalStart: state => {
      return parseFloat(formatUnits(state.publicInfo.optimalUtilizationRateIntervalStart, 25));
    },
    liquidityReserveRatio: state => {
      return state.publicInfo.liquidityReserveRatio / E23;
    },
    // 扣除流动性储备最大可借
    maxBorrowableLiquidityAmount: state => state.publicInfo.maxBorrowableLiquidityAmount,
    maxBorrowableLiquidityAmountRate() {
      let _utilizationRate = parseInt(formatUnits(WadRayMath.rayDiv(this.borrowBalance + this.maxBorrowableLiquidityAmount, this.totalSupply).toString(), 23));
      if (_utilizationRate > 10000) {
        _utilizationRate = 10000;
      }
      return _utilizationRate / 100.0;
    },
    dailyEarnings: state => state.dashboard.dailyEarnings,
    totalStakeToken: state => state.chartData.totalStakeToken,
    chartTotalVariableBorrow: state => state.chartData.totalVariableBorrow,
    chartTotalBorrow: state => state.chartData.totalBorrow,
    chartTotalStableBorrow: state => state.chartData.totalStableBorrow,
    chartVariableRate: state => state.chartData.chartVariableRate,
    variableRateAvg: state => state.chartData.variableRateAvg,
    stableRateAvg: state => state.chartData.stableRateAvg,
    chartStableRate: state => state.chartData.chartStableRate,
    totalStakeTokens: state => state.stakeInfo.totalStakingTokens,
    totalSupply() {
      return this.borrowBalance + this.poolStFilBalanceBig;
    },
    liquidityRate: state => state.publicInfo.liquidityRate,
    liquidityRateApr() {
      let _liquidityRate = parseFloat(formatUnits(this.liquidityRate, 27));
      let rate = Math.pow(_liquidityRate / 365 + 1, 365) - 1;
      return formatPrice(rate * 100, 2);
    },
    variableBorrowRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    utilizationRate() {
      let _utilizationRate = parseInt(formatUnits(WadRayMath.rayDiv(this.borrowBalance, this.totalSupply).toString(), 23));
      if (_utilizationRate > 10000) {
        _utilizationRate = 10000;
      }
      return toBigInt(_utilizationRate);
    },
    borrowBalance: state => {
      return state.publicInfo.variableDebtTokenTotalSupply + state.publicInfo.stableDebtTokenTotalSupply;
    },
    poolStFilBalanceBig: state => state.publicInfo.poolStFilBalance,
    stableDebtTokenTotalSupply: state => state.publicInfo.stableDebtTokenTotalSupply,
    variableDebtTokenTotalSupply: state => state.publicInfo.variableDebtTokenTotalSupply,
    rewardFee: state => state.publicInfo.rewardFee,
    riskReserve: state => state.publicInfo.riskReserve,
    supplyBalance: state => state.stakeInfo.totalStakingTokens,
    supplyBalanceLoading: state => state.stakeInfo.loading,
    filPrice() {
      return this.$store.state.filPrice;
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    },
    tvlUsdObj() {
      return this.toUsdByUsdObj(this.tvl);
    },
    poolStFilBalanceBigObj() {
      return this.toUsdByObj(this.poolStFilBalanceBig);
    },
    tvlAllMinerValueBigObj() {
      return this.toUsdByObj(this.tvlAllMinerValue);
    },
    borrowBalanceObj() {
      return this.toUsdByObj(this.borrowBalance);
    },
    supplyBalanceObj() {
      return this.toUsdByObj(this.supplyBalance);
    },
    variableDebtTokenTotalSupplyObj() {
      return this.toUsdByObj(this.variableDebtTokenTotalSupply);
    },
    stableDebtTokenTotalSupplyObj() {
      return this.toUsdByObj(this.stableDebtTokenTotalSupply);
    },
    timingFlag: state => state.timingFlag
  })
};