
function base64Decode(str) {
    return Uint8Array.from(Buffer.from(str, 'base64'));
}

function base64Encode(bytes) {
    return Buffer.from(bytes).toString('base64');
}

function hexDecode(str) {
    return Uint8Array.from(Buffer.from(str.replace(/0x/g, ''), 'hex'));
}

function hexEncode(bytes) {
    return Buffer.from(bytes).toString('hex');
}

export {
    base64Decode,
    base64Encode,
    hexDecode,
    hexEncode
}
