import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/icons/ok.png';
import _imports_1 from '@/assets/images/icons/record.png';
const _withScopeId = n => (_pushScopeId("data-v-376ca396"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "prompt-card-left"
};
const _hoisted_2 = {
  class: "prompt-card-center"
};
const _hoisted_3 = {
  class: "prompt-card-center-title"
};
const _hoisted_4 = {
  class: "prompt-card-center-content"
};
const _hoisted_5 = {
  key: 0,
  class: "prompt-card-right"
};
const _hoisted_6 = {
  class: "d-flex flex-column"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pl-4 pr-4 pt-3 pb-3 border-radius-20 dashboard-card prompt-card", $props.effect === 'dark' ? 'prompt-card-dark' : ''])
  }, [_createElementVNode("div", _hoisted_1, [$props.icon === 'ok' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _imports_0,
    alt: "",
    style: _normalizeStyle({
      width: $props.iconWidth
    })
  }, null, 4)) : _createCommentVNode("", true), $props.icon === 'record' ? (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _imports_1,
    alt: "",
    style: _normalizeStyle({
      width: $props.iconWidth
    })
  }, null, 4)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1), _createElementVNode("div", _hoisted_4, _toDisplayString($props.content), 1)]), $props.btnText ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [$props.mainBtnText !== '' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "fs-12 fw-medium text-black-600 cursor-pointe prompt-card-right-main-btn mb-2 text-center",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.mainBtnClick && $options.mainBtnClick(...args))
  }, _toDisplayString($props.mainBtnText), 1)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: "fs-12 fw-medium text-black-600 cursor-pointe prompt-card-right-btn text-center",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.btnClick && $options.btnClick(...args))
  }, _toDisplayString($props.btnText), 1)])])) : _createCommentVNode("", true)], 2);
}