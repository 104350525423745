import { Top, Bottom } from '@element-plus/icons-vue';
import { formatPrice } from "@/utils/NumU";
import { formatUnits } from "ethers/lib/utils";
export default {
  name: "IndicatorRay",
  components: {
    Top,
    Bottom
  },
  props: {
    prefix: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    suffix: {
      type: String,
      default: ''
    },
    units: {
      type: Number,
      default: 2
    },
    oldValue: {
      // eslint-disable-next-line no-undef
      type: BigInt,
      default: 0n
    },
    newValue: {
      // eslint-disable-next-line no-undef
      type: BigInt,
      default: 0n
    },
    toFixed: {
      type: Number,
      default: 2
    }
  },
  methods: {
    formatPrice,
    formatUnits
  }
};