// 判断节点是否已经入职
import {readContract} from "@wagmi/core";
import {contracts} from "@/utils/contracts/config";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import {NodeConfiguration} from "@/utils/contracts/NodeConfiguration";
import {isEmpty} from "@/utils/model";
import StorageProviderLendingPool from "@/utils/abi/StorageProviderLendingPool.json";
import store from '@/store/index'
import {WadRayMath} from "@/utils/contracts/WadRayMath";

let checkNodeIsEntry = async (nodeId, poolAddress) => {
    if (nodeId.toString().startsWith("f0") || nodeId.toString().startsWith("t0")) {
        nodeId = nodeId.toString().substr(2)
    }
    // 先判断是否已经入职旧节点
    let nodeData = await readContract({
        address: contracts[store.state.evn].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [nodeId],
    })
    let configuration = nodeData[0]['configuration']['data']
    let nodeConfiguration = new NodeConfiguration(configuration)
    let isActive = nodeConfiguration.isActive();
    if (isActive) {
        return {
            isEntry: true,
            isStakingPool: true,
        }
    }

    if (isEmpty(poolAddress)) {
        return {
            isEntry: false,
            isStakingPool: false,
        }
    }
    console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxx ')
    // 判断节点是否已经入职该池子
    let isDelegated = await readContract({
        address: poolAddress,
        abi: StorageProviderLendingPool.abi,
        functionName: 'isDelegated',
        args: [nodeId],
    })
    return {
        isEntry: isDelegated,
        isStakingPool: false
    }
}

let _calcDebtRatio = (_debt, _position) => {
    if (_debt === 0) {
        return 0n;
    }
    if (_position === 0) {
        return WadRayMath.WAD;
    }
    return WadRayMath.wadDiv(_debt, _position);
}

export {checkNodeIsEntry, _calcDebtRatio}
