import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue";
import _imports_0 from '@/assets/images/logo/ip.png';
import _imports_1 from '@/assets/images/icons/tips.png';
import _imports_2 from '@/assets/images/icons/warring.png';
import _imports_3 from '@/assets/images/icons/error.png';
const _hoisted_1 = {
  class: "row mb-1 mt-1",
  ref: "tipRef"
};
const _hoisted_2 = {
  class: "col-12"
};
const _hoisted_3 = {
  class: "fw-extra-bold d-flex align-items-start"
};
const _hoisted_4 = {
  key: 0,
  src: _imports_1,
  style: {
    "width": "24px"
  },
  class: "mr-1",
  alt: ""
};
const _hoisted_5 = {
  key: 1,
  src: _imports_2,
  style: {
    "width": "24px"
  },
  class: "mr-1",
  alt: ""
};
const _hoisted_6 = {
  key: 2,
  src: _imports_3,
  style: {
    "width": "24px"
  },
  class: "mr-1",
  alt: ""
};
const _hoisted_7 = {
  key: 3
};
const _hoisted_8 = {
  key: 4
};
const _hoisted_9 = {
  key: 5
};
const _hoisted_10 = {
  class: "fw-medium fsi-14",
  style: {
    "line-height": "2"
  }
};
const _hoisted_11 = {
  class: "d-flex fs-12 justify-content-end",
  style: {
    "z-index": "2",
    "position": "relative"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "position-relative d-flex pt-3 flex-column",
    style: _normalizeStyle([`background-color: ${$options.bgColor()}`, {
      "border-radius": "10px",
      "width": "100%",
      "overflow": "hidden",
      "padding-bottom": "0.9rem"
    }])
  }, [_createElementVNode("img", {
    style: _normalizeStyle([{
      opacity: $data.opacity
    }, {
      "height": "120px",
      "position": "absolute",
      "right": "30px",
      "top": "10%",
      "z-index": "0"
    }]),
    src: _imports_0,
    alt: ""
  }, null, 4), _createElementVNode("div", {
    style: _normalizeStyle([{
      "position": "absolute",
      "left": "0",
      "top": "0",
      "width": "6px",
      "height": "100%"
    }, `background-color: ${$options.leftColor()}`])
  }, null, 4), _createElementVNode("div", {
    style: _normalizeStyle({
      width: $data.contentWidth
    }),
    class: "pl-4"
  }, [_createElementVNode("div", _hoisted_3, [$props.level === 'tips' ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), $props.level === 'warring' ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true), $props.level === 'error' ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : _createCommentVNode("", true), $props.level === 'tips' ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('common.Tips')), 1)) : _createCommentVNode("", true), $props.level === 'warring' ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('common.Warning')), 1)) : _createCommentVNode("", true), $props.level === 'error' ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('common.Errors')), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("div", _hoisted_11, [_renderSlot(_ctx.$slots, "btnGroup")])], 4)], 4)])], 512);
}