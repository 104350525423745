import {createApp} from 'vue'
import '@/assets/scss/style.scss'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/assets/language/index'
import preReClick from '@/utils/preReClick.js';
import Skeleton from '@/components/Skeleton'
import 'element-plus/theme-chalk/el-notification.css'

const app = createApp(App);
app.component("i-skeleton", Skeleton)
app.use(i18n)
app.use(store)
app.use(router)
app.use(preReClick)
app.mount('#app')
