let StateMinerPower = (id) => {

    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateMinerPower",
        params: [id, null],
        id: 3
    }

}

let StateMinerInfo = (id) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateMinerInfo",
        params: [id, null],
        id: 3
    }
}

let StateMinerSectorCount = (id) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateMinerSectorCount",
        params: [id, null],
        id: 3
    }
}

let StateReadState = (id) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateReadState",
        params: [id, null],
        id: 3
    }
}

let GasEstimateMessageGas = (message) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.GasEstimateMessageGas",
        params: [message, {MaxFee: "250000000000000000"}, []],
        id: 3
    }
}

let StateGetActor = (id) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateGetActor",
        params: [id, null],
        id: 3
    }
}

let ChainReadObj = (cid) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.ChainReadObj",
        params: [cid],
        id: 3
    }
}

let MpoolGetNonce = (id) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.MpoolGetNonce",
        params: [id],
        id: 3
    }
}

let StateAccountKey = (id, cids) => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateAccountKey",
        params: [id, cids],
        id: 3
    }
}

let ChainHead = () => {
    return {
        jsonrpc: "2.0",
        method: "Filecoin.ChainHead",
        id: 3
    }
}

let MpoolPush = (msg) => {

    return {
        jsonrpc: "2.0",
        method: "Filecoin.MpoolPush",
        params: [msg],
        id: 3
    }

}

let StateWaitMsg = (cid) => {

    return {
        jsonrpc: "2.0",
        method: "Filecoin.StateWaitMsg",
        params: [cid, 0, 100, true],
        id: 3
    }

}

export {
    StateMinerPower,
    StateMinerInfo,
    StateMinerSectorCount,
    StateReadState,
    StateGetActor,
    ChainReadObj,
    GasEstimateMessageGas,
    MpoolGetNonce,
    StateAccountKey,
    ChainHead,
    MpoolPush,
    StateWaitMsg
}
