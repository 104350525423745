import "core-js/modules/es.array.push.js";
import RightBorrow from '@/components/farm/overview/RightBorrow';
import PageHead from '@/components/header/PageHead.vue';
import { ElTabPane, ElTabs } from 'element-plus';
import History from '@/components/farm/History';
import MinerOverview from '@/components/farm/MinerOverview';
import LeftBox from '@/components/farm/overview/LeftBox';
import VaultInfo from '@/components/farm/VaultInfo';
import { getPrefix } from "@/utils/common";
import { isEmpty } from "@/utils/model";
export default {
  name: "ConsolePage",
  components: {
    RightBorrow,
    PageHead,
    ElTabs,
    ElTabPane,
    History,
    LeftBox,
    VaultInfo,
    MinerOverview
  },
  data() {
    return {
      nodeId: '',
      showNoOperatorDialog: false,
      is_active: true
    };
  },
  created() {
    this.init();
  },
  unmounted() {
    this.$store.commit('RESET_CURRENT_NODE_DATA');
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initActive();
    });
  },
  watch: {
    is_active() {
      if (!this.is_active) {
        this.$store.commit('SET_FARM_ACTIVE_NAME', {
          farmActiveName: 'third'
        });
      } else {
        this.$store.commit('SET_FARM_ACTIVE_NAME', {
          farmActiveName: 'first'
        });
      }
    },
    currentNode: {
      handler() {
        this.is_active = Boolean(this.currentNode.is_active);
      },
      deep: true
    }
  },
  methods: {
    initActive() {
      let isActive = this.$route.query.isActive;
      if (!isEmpty(isActive) && isActive === 'false') {
        this.is_active = false;
      }
    },
    openNode() {
      if (this.$store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${this.$i18n.locale}/address/${getPrefix() + this.nodeId}`);
      } else {
        window.open(`https://filfox.info/${this.$i18n.locale}/address/${getPrefix() + this.nodeId}`);
      }
    },
    async init() {
      this.nodeId = this.$route.params.provider;
      this.$store.dispatch('initCurrentNodeData', {
        nodeId: this.$route.params.provider
      });
      this.$store.dispatch('updateAllData');
      this.$store.dispatch('initStakeInfo');
      this.$store.dispatch('initMaxStableRateBorrowSizePercent');
    },
    handleClick(val) {
      if (val === 'third') {
        this.$refs['history'].init();
      }
    },
    showApproveOperatorDialog() {
      this.showNoOperatorDialog = true;
    },
    approveOperator() {
      this.showNoOperatorDialog = false;
      this.$router.push({
        path: '/farm/operator/' + this.$route.params.provider
      });
    },
    isShowNoOperatorDialog() {
      this.showNoOperatorDialog = this.currentNode.operator === '0x0000000000000000000000000000000000000000';
    },
    closeNoOperatorDialog() {
      this.showNoOperatorDialog = false;
    },
    gotSetOperator() {
      this.showNoOperatorDialog = false;
      this.$router.push({
        path: '/farm/operator/' + this.$route.params.provider
      });
    }
  },
  computed: {
    activeName: {
      get() {
        return this.$store.state.node.farmActiveName;
      },
      set(val) {
        this.$store.commit('SET_FARM_ACTIVE_NAME', {
          farmActiveName: val
        });
      }
    },
    evn() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    filPrice() {
      return this.$store.state.filPrice;
    }
  }
};