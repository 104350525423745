export default {
    100: '调用者必须是池管理员',
    101: '调用者必须是紧急管理员',
    102: 'P_IMPL_GET_FAIL',
    103: '调用者必须是合约管理员',
    104: '该函数的调用者不是质押池配置器。',
    105: '该函数的调用者不是借贷池工厂。',
    106: 'NON_CONTRACT_CALL',
    107: 'VL_PROXY_CONTRACT_UNINITIALIZED',
    108: 'MATH_MULTIPLICATION_OVERFLOW',
    109: 'MATH_DIVISION_BY_ZERO',
    110: 'INVALID_FEE',
    111: 'NO_INVALID_PENDING_OWNER',
    112: 'NO_NO_NODE_PERMISSION',
    113: 'NO_NOT_DELEGATED',
    114: 'NO_DELEGATED',
    115: 'NO_INVALID_OWNER_PROPOSAL',
    116: 'NO_EXIST_OTHER_BENEFICIARY',
    151: '金额必须大于0。',
    152: '节点可用金额必须大于0。',
    153: '节点实际提取金额必须大于0。',
    154: 'SPLP_AMOUNT_NOT_EXCEED_MAX_SEAL_LOAD',
    155: 'SPLP_AMOUNT_NOT_EXCEED_MAX_WITHDRAW_LOAD',
    156: 'SPLP_SAFETY_BUFFER_LIMIT',
    157: 'SPLP_NO_DEBT',
    158: 'SPLP_EXIST_DEBT',
    159: 'SPLP_DEBT_RATIO_TOO_HIGH',
    160: 'SPLP_POSITION_EMPTY',
    161: 'SPLP_AMOUNT_AT_LEAST_MIN_LOAD_AMOUNT',
    162: '池子已暂停。',
    163: 'SPLP_ONLY_ACCEPTS_NEW_NODES',
    164: 'SPLP_POSITION_NOT_ENOUGH',
    165: 'SPLP_IS_PAUSED',
    166: 'SPLP_NOT_EXIST',
    167: 'SPLP_NODE_CAPACITY_EXCEEDED',
    168: 'SPLP_NODE_EXIST',
    201: '金额必须大于0。',
    202: '最低质押数量为1FIL',
    203: '可用流动资金不足',
    204: 'SP_NOT_ENOUGH_AVAILABLE_USER_BALANCE',
    205: 'SP_INVALID_INTEREST_RATE_MODE_SELECTED',
    206: 'SP_AMOUNT_BIGGER_THAN_MAX_LOAN_SIZE_STABLE',
    207: 'SP_INVALID_ADDRESS',
    208: '质押池已暂停。',
    251: '该函数的调用者必须是一个质押池。',
    252: 'CT_INVALID_MINT_AMOUNT',
    253: 'CT_INVALID_BURN_AMOUNT',
    254: 'CT_INVALID_TREASURY_ADDRESS',
    255: 'SDT_STABLE_DEBT_OVERFLOW',
    86: 'INVALID_LIQUIDATION_FACTOR',
    83: 'INVALID_LEVERAGE',
    84: 'INVALID_LIQ_THRESHOLD',
    93: 'N_INVALID_BENEFICIARY_PROPOSAL',
    60: 'N_MUST_BENEFICIARY_ROLE',
    61: 'N_MUST_EMPTY_GUARANTEE',
    94: 'N_INVALID_OWNER',
    99: 'N_MUST_OWNER_ROLE',
    97: 'N_SENDER_NOT_OPERATOR',
    63: 'VL_INVALID_BORROWING_AUTHENTICATION',
    70: 'VL_AVAILABLE_QUOTA_AT_LEAST_MAX_FIL_SUPPLY',
    66: 'VL_DEBT_RATE_LESS_THAN_LIQUIDATION_THRESHOLD',

    300: '无效的权限。',
    301: '无效的地址 ',
    302: '已将白名单地址添加到铸币。',
    303: '当前地址未列入白名单。',
    304: 'MINT目前未开放。',
    305: 'MINT价格不足。',
    306: '当前地址数量已超过最大铸币数量！',
    307: '当前地址数量已超过最大白名单铸币数量！',
    308: '无效的矿池地址。',
    309: 'STFIL矿池调用失败。',
    310: '百分比范围错误。',
    311: '最小算力超过最大算力的一半。 ',
    312: '无效的地址。',
    313: '推荐码必须在有效范围内。',
    314: '推荐码已存在。',
    315: '推荐码数量有限。',
    316: '计算异常。',
    317: '计算异常。',
}
