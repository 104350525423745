import RadioBtn from '@/components/RadioBtn';
import { mapState } from "vuex";
import { createIcon } from '@download/blockies';
import { isEmpty } from "@/utils/model";
import { getLocal } from "@/utils/common";
import { formatWalletFilPrice } from "@/utils/NumU";
export default {
  name: "HomePage",
  components: {
    RadioBtn
  },
  data() {
    return {
      form: {
        token: 'FIL',
        from: '0x9afa3feBDc382a94493a85103d763d09B5112aBC',
        to: '',
        Amount: ''
      },
      connectType: '',
      radioOptions: []
    };
  },
  watch: {
    address() {
      this.initAddressBlockies();
    },
    '$i18n.locale'() {
      this.initRadioOptions();
    }
  },
  created() {
    this.connectType = getLocal('connectType');
    this.initRadioOptions();
  },
  mounted() {
    this.initAddressBlockies();
  },
  methods: {
    formatWalletFilPrice,
    initAddressBlockies() {
      if (!isEmpty(this.address)) {
        let icon = createIcon({
          // All options are optional
          seed: this.address.toString().toLowerCase(),
          // seed used to generate icon data, default: random
          color: '#03DD28',
          // to manually specify the icon color, default: random
          bgcolor: '#CFE992',
          // choose a different background color, default: white
          spotcolor: '#90B6F9',
          size: 8,
          // width/height of the icon in blocks, default: 10
          scale: 7 // width/height of each block in pixels, default: 5
        });

        console.log('icon', icon);
        document.getElementById("avatar").append(icon);
      }
    },
    initRadioOptions() {
      this.radioOptions = [{
        label: 'FIL',
        value: 'FIL'
      }, {
        label: 'stFIL',
        value: 'stFIL'
      }];
    }
  },
  computed: mapState({
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    stFilBalance() {
      return this.$store.state.stFilBalance;
    },
    filBalance() {
      return this.$store.state.balance;
    }
  })
};