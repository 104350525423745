import * as echarts from "echarts";
export default {
  name: "ChartBlock",
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    height: {
      type: String,
      default: '300px'
    },
    showTools: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectIndex: 0,
      chart: null,
      selectValue: 1,
      select: [{
        label: '1m',
        value: 1
      }, {
        label: '3m',
        value: 3
      }, {
        label: '6m',
        value: 6
      }],
      data: [5, 20, 36, 10, 10, 20, 5, 20, 36, 10, 20, 36, 10, 10, 20, 5, 20, 36, 10],
      instanceByDom: undefined
    };
  },
  watch: {
    option: {
      handler(newValue) {
        let el = this.$refs.chartEl;
        if (!el) {
          return;
        }
        let instanceByDom = echarts.getInstanceByDom(el);
        this.instanceByDom = instanceByDom;
        if (instanceByDom) {
          instanceByDom.setOption(newValue);
        } else {
          this.instanceByDom = echarts.init(el);
          this.instanceByDom.setOption(newValue);
        }
      },
      // 因为option是个对象，而我们对于echarts的配置项，要更改的数据往往不在一级属性里面
      // 所以这里设置了deep:true，vue文档有说明
      deep: true
    }
  },
  mounted() {
    echarts.init(this.$refs.chartEl).setOption(this.option);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.instanceByDom) {
          this.instanceByDom.resize();
        }
      }, 1500);
    });
  },
  methods: {
    loading() {
      if (!this.instanceByDom) {
        return;
      }
      this.instanceByDom.showLoading({
        text: 'Loading...',
        color: '#6752F0',
        textColor: '#6752F0',
        maskColor: 'rgb(255,255,255)',
        zlevel: 90
      });
    },
    noDataShow() {
      let el = this.$refs.chartEl;
      if (!el) {
        return;
      }
      let instanceByDom = echarts.getInstanceByDom(el);
      instanceByDom.showLoading({
        text: this.$t('common.NoData'),
        color: '#ffffff',
        textColor: '#bababa',
        maskColor: 'rgb(255,255,255)',
        zlevel: 10
      });
    },
    unLoading() {
      if (!this.instanceByDom) {
        return;
      }
      this.instanceByDom.hideLoading();
    },
    chartSelect(val, index) {
      this.selectIndex = index;
      this.$emit('chartSelect', {
        value: val,
        index
      });
    },
    chartSelect2(val) {
      let index = this.select.findIndex(item => item.value === val);
      this.selectIndex = index;
      this.$emit('chartSelect', {
        value: this.select[index].value,
        index
      });
    },
    changeOption(option) {
      let el = this.$refs.chartEl;
      echarts.getInstanceByDom(el).setOption(option);
    }
  }
};