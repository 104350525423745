import "core-js/modules/es.array.push.js";
import { toBigInt } from "@/utils/NumU";
import { NotificationErr } from "@/utils/common";
export default {
  name: "VaultInfo",
  // eslint-disable-next-line no-undef
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  methods: {
    setOperator() {
      this.$router.push({
        path: '/farm/operator/' + this.nodeId
      });
    },
    offboarding() {
      this.checkIsCanOffboarding();
    },
    beneficiaryToOwnerUpgrade() {
      this.$router.push({
        path: '/farm/beneficiaryToOwnerUpgrade/' + this.nodeId
      });
    },
    migratorToLendingPool() {
      this.$router.push({
        path: '/farm/migrator/' + this.nodeId
      });
    },
    changeWorker() {
      this.$router.push({
        path: '/farm/changeWorker/' + this.nodeId
      });
    },
    changeControl() {
      this.$router.push({
        path: '/farm/changeControl/' + this.nodeId
      });
    },
    // 检测是否满足离职条件
    async checkIsCanOffboarding() {
      if (this.debtValue > 0n) {
        NotificationErr(this.$t('farm.console.Make sure that all debts have been repaid'));
      } else {
        this.$router.push({
          path: '/farm/offboarding/' + this.nodeId
        });
      }
    },
    bindNode() {
      this.$router.push({
        path: '/farm/collateralNode/' + this.nodeId
      });
    },
    unBindNode() {
      this.$router.push({
        path: '/farm/unCollateralNode/' + this.nodeId
      });
    }
  },
  computed: {
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    guaranteeNodeId() {
      return this.currentNode.guaranteeNodeId;
    },
    nodeDataLoading() {
      return this.$store.state.nodeData.loading;
    },
    paused: state => state.SPLP_IS_PAUSED
  }
};