import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-31e86884"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "block-cart-content"
};
const _hoisted_2 = {
  class: "block-cart"
};
const _hoisted_3 = {
  class: "block-cart-left"
};
const _hoisted_4 = {
  class: "block-cart-title"
};
const _hoisted_5 = {
  class: "block-cart-value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.title), 1), _createElementVNode("div", _hoisted_5, _toDisplayString($props.value), 1)])])]);
}