import PageHead from "@/components/header/PageHead";
import CreatePoolComponents from '@/components/subpool/CreatePoolComponents';
export default {
  name: "CreatePool",
  components: {
    PageHead,
    CreatePoolComponents
  },
  created() {
    this.init();
  },
  methods: {
    init() {}
  },
  computed: {}
};