import { QuestionFilled } from '@element-plus/icons-vue';
export default {
  name: "tooltipC",
  components: {
    QuestionFilled
  },
  props: {
    tips: {
      type: String,
      default: ''
    },
    effect: {
      type: String,
      default: 'dark'
    },
    html: {
      type: String,
      default: ''
    }
  }
};