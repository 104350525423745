import { ElButton, ElInput } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
import Tip from '@/components/tip/Tip';
import { isEmpty } from "@/utils/model";
export default {
  name: "SubPoolSearchNode",
  components: {
    ElInput,
    ElButton,
    Tip
  },
  props: {},
  data() {
    return {
      isLock: false,
      search: Search,
      searchInput: '',
      // searchInput: 'f0827006',
      searchTip: {
        show: false,
        content: ''
      },
      searchErr: '',
      poolAddress: ''
    };
  },
  created() {
    this.poolAddress = this.$route.params.poolAddress;
  },
  methods: {
    searchChange() {
      this.searchErr = '';
    },
    async searchFun() {
      if (isEmpty(this.searchInput)) {
        this.searchErr = 'Please enter the node id!';
        this.$emit('searchFail');
        return;
      }
      let nodeId = this.searchInput.toString().trim();
      this.$store.dispatch('getNodePublicInfoBySubPool', {
        nodeId,
        poolAddress: this.poolAddress
      }).then(({
        haveSp,
        isStakingPool
      }) => {
        this.searchErr = '';
        this.searchTip.show = false;
        this.$emit('searchOK', {
          haveSp,
          isStakingPool
        });
      }).catch(err => {
        this.$emit('searchFail');
        this.searchErr = err;
      });
    },
    reset() {
      this.searchInput = '';
      this.isLock = false;
      this.$emit('reset');
    },
    closeTip() {
      this.searchTip.show = false;
    }
  },
  computed: {
    loading() {
      return this.$store.state.node.searchLoading;
    }
  }
};