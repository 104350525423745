import "core-js/modules/es.array.push.js";
import { toBigInt } from "@/utils/NumU";
export default {
  name: "SubPoolVaultInfo",
  // eslint-disable-next-line no-undef
  props: {
    nodeId: {
      type: String,
      default: ''
    },
    poolAddress: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    setOperator() {
      this.$router.push({
        path: '/farm/operator/' + this.nodeId
      });
    },
    offboarding() {
      this.checkIsCanOffboarding();
    },
    changeWorker() {
      this.$router.push({
        path: `/farm/lendingPool/changeWorker/${this.poolAddress}/${this.nodeId}`
      });
    },
    changeControl() {
      this.$router.push({
        path: `/farm/lendingPool/changeControl/${this.poolAddress}/${this.nodeId}`
      });
    },
    changeOwner() {
      this.$router.push({
        path: `/farm/lendingPool/changeOwner/${this.poolAddress}/${this.nodeId}`
      });
    },
    // 检测是否满足离职条件
    async checkIsCanOffboarding() {
      if (this.debtValue > 0n) {
        // NotificationErr(this.$t('farm.console.Make sure that all debts have been repaid'))
      } else {
        this.$router.push({
          path: `/farm/lendingPool/offboarding/${this.poolAddress}/${this.nodeId}`
        });
      }
    },
    bindNode() {
      this.$router.push({
        path: '/farm/collateralNode/' + this.nodeId
      });
    },
    unBindNode() {
      this.$router.push({
        path: '/farm/unCollateralNode/' + this.nodeId
      });
    }
  },
  computed: {
    debtValue() {
      return toBigInt(this.currentPoolNode.debt_value);
    },
    currentPoolNode() {
      return this.$store.state.subpool.currentPoolNode;
    },
    poolNodeInfoLoading() {
      return this.$store.state.subpool.loading.initCurrentPoolNodeDataLoading;
    }
  }
};