import DigitalFrogsABI from "@/utils/abi/referrals/DigitalFrogsABI.json";
import StakingNFTABI from "@/utils/abi/referrals/StakingNFTABI.json";
import {referralsContracts} from "@/utils/contracts/referrals/config";
import {iWriteContract} from "@/utils/contracts/opertion";
import {fetchBalance, readContract} from "@wagmi/core";
import nodejsHttp from "@/utils/http/nodejsHttp";
import {contracts} from "@/utils/contracts/config";
import {isEmpty} from "@/utils/model";

let nft = {
    state: {
        myNftList: [],
        myStakeNftList: [],
        myStakeNftListTotal: 0,
        myNftListLoading: false,
        myStakeNftListLoading: false,
        highWlMintState: false, // 判断是否已经通稀有过白名单mint
        canHighWlMintState: false, // 判断是否可以通过稀有白名单mint
        isWaitHighWlMintState: false, // 判断是否在等待添加白名单
        wlMintState: false, // 判断是否已经通过白名单mint
        canWlMintState: false, // 判断是否可以通过白名单mint
        isWaitWlMintState: false, // 判断是否在等待添加白名单
        whiteListBtnMintLoading: false,
        whiteListInfo: {
            proof: undefined,
            leaf: undefined,
        },
        heightWhiteListInfo: {
            proof: undefined,
            leaf: undefined,
        },
        treasuryBalance: 0n,
        treasuryBalanceLoading: false,
        mints: [],
        mintBtnLoading: false,
        isNftIsOpen: false, // 判断是否可以mint
        nftBaseUrl: '',
        saleInfo: {
            open: false,
            startTime: 0,
            endTime: 0,
            limit: 0,
            over: false,
            index: 0,
            publicSale: true
        }
    },
    getters: {},
    mutations: {
        SET_NFT_SALE_INFO(state, saleInfo){
            state.saleInfo = saleInfo
        },
        SET_MY_STAKE_NFT_LIST(state,myStakeNftList){
            state.myStakeNftList = myStakeNftList
        },
        SET_MY_STAKE_NFT_LIST_TOTAL(state, {myStakeNftListTotal}){
            state.myStakeNftListTotal = myStakeNftListTotal
        },
        SET_MY_STAKE_NFT_LIST_LOADING(state, {myStakeNftListLoading}){
            state.myStakeNftListLoading = myStakeNftListLoading
        },
        SET_NFT_BASE_URL(state, {nftBaseUrl}) {
            state.nftBaseUrl = nftBaseUrl
        },
        SET_MY_NFT_LIST_IMAGE(state, {url, index}) {
            state.myNftList[index]['img'] = url
        },
        SET_MY_STAKE_NFT_LIST_IMAGE(state, {url, index}) {
            state.myStakeNftList[index]['img'] = url
        },
        SET_IS_NFT_IS_OPEN(state, {isNftIsOpen}) {
            state.isNftIsOpen = isNftIsOpen
        },
        SET_MY_NFT_LIST_LOADING(state, {myNftListLoading}) {
            state.myNftListLoading = myNftListLoading
        },
        SET_MINT_BTN_LOADING(state, {mintBtnLoading}) {
            state.mintBtnLoading = mintBtnLoading
        },
        SET_NFT_MINTS(state, mints) {
            state.mints = mints
        },
        SET_TREASURY_BALANCE_LOADING(state, {treasuryBalanceLoading}) {
            state.treasuryBalanceLoading = treasuryBalanceLoading
        },
        SET_IS_WAIT_WL_MINT_STATE(state, {isWaitWlMintState}) {
            state.isWaitWlMintState = isWaitWlMintState
        },
        SET_TREASURY_BALANCE(state, {treasuryBalance}) {
            state.treasuryBalance = treasuryBalance
        },
        SET_WHITE_LIST_INFO(state, info) {
            state.whiteListInfo = info
        },
        SET_HEIGHT_WHITE_LIST_INFO(state,info){
            state.heightWhiteListInfo = info
        },
        SET_CAN_WL_MINT_STATE(state, {canWlMintState}) {
            state.canWlMintState = canWlMintState
        },
        SET_WHITE_LIST_BTN_MINT_LOADING(state, {loading}) {
            state.whiteListBtnMintLoading = loading
        },
        SET_MY_NFT_LIST(state, myNftList) {
            state.myNftList = myNftList
        },
        SET_WL_MINT_STATE(state, {wlMintState}) {
            state.wlMintState = wlMintState
        },
        SET_HIGH_WL_MINT_STATE(state, {highWlMintState}){
            state.highWlMintState = highWlMintState
        },
        SET_CAN_HIGH_WL_MINT_STATE(state,{canHighWlMintState}){
            state.canHighWlMintState = canHighWlMintState
        },
        SET_IS_WAIT_HIGH_WL_MINT_STATE(state,{isWaitHighWlMintState}){
            state.isWaitHighWlMintState = isWaitHighWlMintState
        }
    },
    actions: {
        async initTreasuryBalance({commit, rootState}) {
            commit('SET_TREASURY_BALANCE_LOADING', {treasuryBalanceLoading: true})
            const stFilBalance = await fetchBalance({
                address: '0xff00000000000000000000000000000000000063',
                token: contracts[rootState.evn].STFILToken.eth
            })
            commit('SET_TREASURY_BALANCE', {treasuryBalance: stFilBalance.value.toBigInt()})
            commit('SET_TREASURY_BALANCE_LOADING', {treasuryBalanceLoading: false})
        },
        async initLeftMintNumber({commit, rootState}, {address}) {
            commit('SET_MINT_BTN_LOADING', {mintBtnLoading: true})
            let leftMintNumber = await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'getActualMintQuantity',
                args: [address]
            })
            let mints = []
            for (let i = 1; i <= leftMintNumber; i++) {
                let price = await readContract({
                    address: referralsContracts[rootState.evn].digitalFrogs.eth,
                    abi: DigitalFrogsABI.abi,
                    functionName: 'getActualMintPrice',
                    args: [i]
                })
                mints.push({
                    value: i,
                    label: i.toString(),
                    simpleLabel: `${parseFloat((price.toBigInt() / 1000000000000000000n).toString()).toFixed(2)}FIL`,
                    price: price,
                    amount: `Price: ${parseFloat((price.toBigInt() / 1000000000000000000n).toString()).toFixed(2)}FIL`,
                })
            }
            commit('SET_MINT_BTN_LOADING', {mintBtnLoading: false})
            commit('SET_NFT_MINTS', mints)
        },
        async initMintWlCheck({rootState, commit, dispatch}, {address}) {
            commit('SET_WHITE_LIST_BTN_MINT_LOADING', {loading: true})
            let wlMintState = await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'getWlMintState',
                args: [address]
            })

            let highWlMintState = await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'getHighWlMintState',
                args: [address]
            })

            console.log('wlMintState', wlMintState,highWlMintState)

            if (!wlMintState || !highWlMintState) {
                await dispatch('updateWhileInfo', {address})
            }
            commit('SET_HIGH_WL_MINT_STATE', {highWlMintState})
            commit('SET_WL_MINT_STATE', {wlMintState})
            commit('SET_WHITE_LIST_BTN_MINT_LOADING', {loading: false})
        },
        async updateWhileInfo({commit}, {address}) {
            let whitelist = await nodejsHttp.get('referral/getWhitelistByFrom?address=' + address)
            if (whitelist !== undefined && whitelist !== null && whitelist.length > 0) {
                whitelist.forEach(wlInfo => {
                    let {proof, leaf, is_special} = wlInfo
                    if (is_special){
                        if (!isEmpty(proof)) {
                            commit('SET_CAN_HIGH_WL_MINT_STATE', {canHighWlMintState: true})
                            commit('SET_HEIGHT_WHITE_LIST_INFO', {proof: proof.toString().split(","), leaf})
                            commit('SET_IS_WAIT_HIGH_WL_MINT_STATE', {isWaitHighWlMintState: false})
                        } else {
                            commit('SET_CAN_HIGH_WL_MINT_STATE', {canHighWlMintState: false})
                            commit('SET_IS_WAIT_HIGH_WL_MINT_STATE', {isWaitHighWlMintState: true})
                        }
                    }else {
                        if (!isEmpty(proof)) {
                            commit('SET_CAN_WL_MINT_STATE', {canWlMintState: true})
                            commit('SET_WHITE_LIST_INFO', {proof: proof.toString().split(","), leaf})
                            commit('SET_IS_WAIT_WL_MINT_STATE', {isWaitWlMintState: false})
                        } else {
                            commit('SET_CAN_WL_MINT_STATE', {canWlMintState: false})
                            commit('SET_IS_WAIT_WL_MINT_STATE', {isWaitWlMintState: true})
                        }
                    }

                })

            } else {
                commit('SET_CAN_WL_MINT_STATE', {canWlMintState: false})
                commit('SET_IS_WAIT_WL_MINT_STATE', {isWaitWlMintState: false})
                commit('SET_CAN_HIGH_WL_MINT_STATE', {canHighWlMintState: false})
                commit('SET_IS_WAIT_HIGH_WL_MINT_STATE', {isWaitHighWlMintState: false})
            }
        },
        async mintWlNFT({rootState, state}) {
            return await iWriteContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                f0address: referralsContracts[rootState.evn].digitalFrogs.id,
                abi: DigitalFrogsABI.abi,
                functionName: "wlMint",
                args: [state.whiteListInfo.proof]
            })
        },
        async mintHightWlNFT({rootState, state}) {
            return await iWriteContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                f0address: referralsContracts[rootState.evn].digitalFrogs.id,
                abi: DigitalFrogsABI.abi,
                functionName: "highWlMint",
                args: [state.heightWhiteListInfo.proof]
            })
        },
        async mintNFT({rootState, state}, {mintCount}) {
            let price = state.mints[mintCount - 1].price
            return await iWriteContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                f0address: referralsContracts[rootState.evn].digitalFrogs.id,
                abi: DigitalFrogsABI.abi,
                functionName: "mint",
                overrides: {
                    value: price,
                },
                args: [mintCount]
            })
        },
        async getApproved({rootState}, {tokenId}){
            return await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'getApproved',
                args: [tokenId]
            })
        },
        async approveNFT({rootState, dispatch}, {tokenId}){
            let res = await dispatch('getApproved',{tokenId} )
            if (res === referralsContracts[rootState.evn].stakingNFT.eth){
                return
            }
            return await iWriteContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                f0address: referralsContracts[rootState.evn].digitalFrogs.id,
                abi: DigitalFrogsABI.abi,
                functionName: "approve",
                args: [referralsContracts[rootState.evn].stakingNFT.eth,tokenId]
            })
        },
        async stakeNFT({rootState}, {tokenId}) {
            console.log('address,tokenId',tokenId)
            return await iWriteContract({
                address: referralsContracts[rootState.evn].stakingNFT.eth,
                f0address: referralsContracts[rootState.evn].stakingNFT.id,
                abi: StakingNFTABI.abi,
                functionName: "stake",
                args: [tokenId]
            })
        },
        async unStakeNFT({rootState}, {tokenId}) {
            return await iWriteContract({
                address: referralsContracts[rootState.evn].stakingNFT.eth,
                f0address: referralsContracts[rootState.evn].stakingNFT.id,
                abi: StakingNFTABI.abi,
                functionName: "unstake",
                args: [tokenId]
            })
        },
        async initNftIsOpen({rootState, commit}) {
            let isNftIsOpen = await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'PUBLIC_SALE_ON',
                args: []
            })
            commit('SET_IS_NFT_IS_OPEN', {isNftIsOpen})
        },

        async getNftBaseUrl({commit, rootState}) {
            let nftBaseUrl = await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'BASE_URI',
                args: []
            })
            console.log(nftBaseUrl)
            commit('SET_NFT_BASE_URL', {nftBaseUrl})
        },
        async initMyNftImages({state, commit}) {
            for (let i = 0; i < state.myNftList.length; i++) {
                let nft = state.myNftList[i]
                if (nft['img'] === '') {
                    fetch(state.nftBaseUrl + nft.tokenId).then(async res => {
                        let metadata = await res.json()
                        let url = metadata.image.toString()
                        console.log('url', url)
                        commit('SET_MY_NFT_LIST_IMAGE', {url, index: i})
                    })
                }
            }
        },
        async initMyStakeNftImages({state, commit}) {
            for (let i = 0; i < state.myStakeNftList.length; i++) {
                let nft = state.myStakeNftList[i]
                if (nft['img'] === '') {
                    fetch(state.nftBaseUrl + nft.tokenId).then(async res => {
                        let metadata = await res.json()
                        let url = metadata.image.toString()
                        commit('SET_MY_STAKE_NFT_LIST_IMAGE', {url, index: i})
                    })
                }
            }
        },
        async getMyStakeNft({rootState, commit,dispatch}, {address,cursor,size}) {
            commit('SET_MY_STAKE_NFT_LIST_LOADING', {myStakeNftListLoading: true})
            commit('SET_MY_STAKE_NFT_LIST', [])
            let stakeNftList = await readContract({
                address: referralsContracts[rootState.evn].stakingNFT.eth,
                abi: StakingNFTABI.abi,
                functionName: 'viewOwnerCollections',
                args: [address,cursor,size]
            })
            let myStakeNftListTotal = await readContract({
                address: referralsContracts[rootState.evn].stakingNFT.eth,
                abi: StakingNFTABI.abi,
                functionName: 'viewOwnerCollectionsSize',
                args: [address]
            })
            let myStakeNftList = []
            stakeNftList.forEach(nft => {
                let {power,tokenId} = nft
                myStakeNftList.push({
                    power,
                    tokenId,
                    img: '',
                    isStake: true
                })
            })
            commit('SET_MY_STAKE_NFT_LIST_TOTAL', {myStakeNftListTotal: parseInt(myStakeNftListTotal.toString())})
            commit('SET_MY_STAKE_NFT_LIST', myStakeNftList)
            commit('SET_MY_STAKE_NFT_LIST_LOADING', {myStakeNftListLoading: false})
            dispatch('initMyStakeNftImages')
        },
        async getMyNft({rootState, commit, dispatch}, {address}) {
            commit('SET_MY_NFT_LIST_LOADING', {myNftListLoading: true})
            commit('SET_MY_NFT_LIST', [])
            let indexs = await readContract({
                address: referralsContracts[rootState.evn].digitalFrogs.eth,
                abi: DigitalFrogsABI.abi,
                functionName: 'balanceOf',
                args: [address]
            })
            indexs = indexs.toBigInt()
            let myNftList = []
            for (let i = 0; i < indexs; i++) {

                const tokenId = await readContract({
                    address: referralsContracts[rootState.evn].digitalFrogs.eth,
                    abi: DigitalFrogsABI.abi,
                    functionName: 'tokenOfOwnerByIndex',
                    args: [address, i]
                })

                const power = await readContract({
                    address: referralsContracts[rootState.evn].digitalFrogs.eth,
                    abi: DigitalFrogsABI.abi,
                    functionName: 'getPowerByTokenId',
                    args: [tokenId]
                })

                console.log('power', power.toBigInt())
                myNftList.push({
                    power,
                    tokenId,
                    img: '',
                    isStake: false
                })

            }
            console.log('myNftList', myNftList)
            commit('SET_MY_NFT_LIST', myNftList)
            commit('SET_MY_NFT_LIST_LOADING', {myNftListLoading: false})
            dispatch('initMyNftImages')
        },
        async getNftPublicSaleInfo({commit}) {
            let saleInfo = await nodejsHttp.get('nft/info')
            commit('SET_NFT_SALE_INFO',saleInfo)
        }
    },
    modules: {}
}

export default nft
