import PageHead from "@/components/header/PageHead";
import UnBindNodeComponent from '@/components/bindNode/UnBindNodeComponent';
import { getPrefix } from "@/utils/common";
export default {
  name: "CollateralNode",
  components: {
    PageHead,
    UnBindNodeComponent
  },
  data() {
    return {
      nodeId: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.nodeId = getPrefix() + this.$route.params.provider;
      await this.$store.dispatch('getNodePublicInfo', {
        nodeId: this.nodeId
      });
      await this.$refs['unBindNodeComponent'].init();
    }
  },
  computed: {
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};