import {isEmpty} from "@/utils/model";
import {fetchBalance} from "@wagmi/core";

let metamaskFlask = {
    state: {
        metamaskFlaskFilsnapSuper: undefined, // f1 钱包质押使用
    },
    getters: {},
    mutations: {
        SET_METAMASK_FLASK_FILSNAP_SUPER(state, metamaskFlaskFilsnapSuper) {
            state.metamaskFlaskFilsnapSuper = metamaskFlaskFilsnapSuper
        },
    },
    actions: {
        async initByMetamaskFlaskAccount({commit, dispatch, state}) {
            commit('INIT_FNS_NAME')
            let isConnected = state.metamaskFlaskFilsnapSuper.getConnected()
            let address = state.metamaskFlaskFilsnapSuper.getEthAddress()
            if (address === '') {
                await state.metamaskFlaskFilsnapSuper.initAccount()
                address = state.metamaskFlaskFilsnapSuper.getEthAddress()
            }
            let f1Address = state.metamaskFlaskFilsnapSuper.getF1Address()
            let f0Address = state.metamaskFlaskFilsnapSuper.getF0Address()
            commit('SET_METAMASK_FLASK_F1_ADDRESS', {f1Address})
            commit('SET_ADDRESS', address)
            commit('SET_F0_ADDRESS', {f0Address})
            commit('SET_ISCONNECTED', {isConnected})
            if (!isEmpty(address)) {
                commit('SET_BALANCE_LOADING', {balanceLoading: true})
                const balance = await fetchBalance({
                    address: address,
                })
                commit('SET_BALANCE_LOADING', {balanceLoading: false})
                commit('SET_BALANCE', balance.value.toBigInt())
                dispatch('initStFilBalance', {address: address})
                dispatch('initWstFilBalance', {address: address})
                dispatch('initFnsName', {address: address})
            } else {
                commit('SET_BALANCE', 0)
            }

        },
    },
    modules: {}
}

export default metamaskFlask
