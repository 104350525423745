import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
import { configureChains, connect, createClient, disconnect, getNetwork, mainnet, switchNetwork, watchAccount, watchNetwork } from "@wagmi/core";
import { LedgerConnector } from '@wagmi/connectors/ledger';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { filChain } from "@/utils/filChain";
import { EthereumClient, modalConnectors, walletConnectProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { ArrowRightBold, Bottom, CaretBottom, CircleClose, Download, EditPen, Select, SwitchButton, Tools, Top, Upload } from '@element-plus/icons-vue';
import { ElDialog, ElIcon } from 'element-plus';
import { delLocal, getLocal, isMobile, NotificationErr, setLocal, simpleValue } from "@/utils/common";
import Clipboard from 'clipboard';
import { isInstallFoxWallet, isInstallMetamask, isInstallMetamaskFlask, isInstallOkxWallet } from "@/utils/contracts/opertion";
import { formatFilPrice, formatPrice, formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { isEmpty } from "@/utils/model";
import { formatUnits } from "ethers/lib.esm/utils";
import { parseErr } from "@/components/induction/singer/ledger/Errors";
import { LedgerFileCoinSuper } from "@/components/header/LedgerFileCoinSuper";
import { MetamaskFlaskFilsnapSuper } from "@/components/header/MetamaskFlaskFilsnapSuper";
import Transfer from './Transfer';
import ScrollNumber from '@/components/ScrollNumber.vue';
import QRCode from "qrcodejs2";
import RadioBtn from '@/components/RadioBtn';
import BrowserWalletImg from './BrowserWalletImg';
import Identicon from 'identicon.js';
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
import { LedgerAddAddressFormatErrors } from "@/components/header/ledgerHelper/innerLedgerError";
const LOCALE_DIC = {
  'en': 'en',
  'zh-CN': 'zh',
  'zh': 'zh'
};
export default {
  name: "IHeader",
  components: {
    BrowserWalletImg,
    ElIcon,
    ArrowRightBold,
    ElDialog,
    SwitchButton,
    Tools,
    Bottom,
    EditPen,
    Select,
    Top,
    CaretBottom,
    Transfer,
    CircleClose,
    ScrollNumber,
    Upload,
    Download,
    RadioBtn
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      innerDialog: 'none',
      receiveOptions: [],
      isAddLedgerAccount: false,
      addLedgerAddress: 'm/44\'/461\'/5\'/0/0',
      addLedgerAddressLoading: false,
      addLedgerAddressErrTip: '',
      receiveValue: undefined,
      computeIconAnimatio: false,
      editComputer: false,
      bottomSize: 22,
      computerFilValue: '',
      computerFilDayIndex: 0,
      computerFilDays: [{
        value: 1,
        label: '1天'
      }, {
        value: 15,
        label: '15天'
      }, {
        value: 30,
        label: '30天'
      }, {
        value: 365,
        label: '1年'
      }, {
        value: 1095,
        label: '3年'
      }],
      computerFilResultValue: '0.00',
      computerAPYValue: '0.00',
      computerFilResultValueUsd: '0.00',
      connect: undefined,
      pendingConnector: undefined,
      activeConnector: undefined,
      menus: [{
        label: 'Dashboard',
        path: '/'
      }, {
        label: 'Stake',
        path: '/stake'
      }, {
        label: 'Farm',
        path: '/farm'
      }],
      locale: window.localStorage.getItem('localeLanguage'),
      chain: undefined,
      connectWalletHoverFlag: false,
      hoverFlag: [false, false, false, false, false, false, false, false, false],
      connectWalletModelShow: false,
      switchNetModelShow: false,
      apyComputerShow: false,
      web3modal: undefined,
      provider: undefined,
      loading: {
        browserWalletLoading: false,
        ledgerLoading: false,
        ledgerEthLoading: false,
        metamaskLoading: false,
        metaMaskFlaskLoading: false,
        walletConnectLoading: false,
        foxWalletLoading: false,
        tokenPocketLoading: false,
        okxWalletLoading: false
      },
      connectType: '',
      isCopy: false,
      isF4Copy: false,
      isF1Copy: false,
      metaMaskInstall: true,
      okxWalletInstall: true,
      metaMaskFlaskInstall: true,
      foxWalletInstall: true,
      languageOptions: [{
        value: 'en',
        label: 'English',
        disabled: false
      }, {
        value: 'zh',
        label: '中文',
        disabled: false
      }],
      netModeValueOption: [{
        value: 'main',
        label: 'Mainnet'
      }, {
        value: 'test',
        label: 'Calibration'
      }],
      netModeValue: process.env.VUE_APP_CURRENTMODE,
      languageValue: 'en',
      dialogWidth: 550,
      isMobile: false,
      isShowMenu: false,
      currentUserAgent: '',
      showLanguageTips: false,
      connectWalletErrTip: '',
      editApiMode: false,
      transferDrawerFlag: false,
      transferDrawerWidth: 430,
      identiconImgBase64: '',
      ledgerDeviceIsDisconnected: true
    };
  },
  watch: {
    activeConnector(val) {
      console.log(val);
    },
    '$i18n.locale'() {
      this.initMenus();
      this.initComputerFilDays();
      this.locale = window.localStorage.getItem('localeLanguage');
      this.languageValue = this.locale;
      this.initReceiveOptions();
    },
    connectWalletModelShow() {
      this.connectWalletErrTip = '';
    },
    liquidityRate() {
      this.computerAPYValue = formatPrice(formatUnits(this.liquidityRate, 25), 2);
    },
    receiveValue() {
      this.switchReciveAddress();
    },
    address() {
      this.initReceiveOptions();
      this.initIdenticonImgBase64();
    },
    f1Address() {
      this.initReceiveOptions();
      this.initIdenticonImgBase64();
    }
  },
  async created() {
    this.delOldLedgerAccounts();
    window.localStorage.removeItem('locale');
    this.initMenus();
    this.initComputerFilDays();
    this.initLanguage();
    this.check();
    this.initCurrentUserAgent();
    this.connectType = getLocal('connectType');
    await this.initWeb3();
    this.checkNetWork();
    this.initCommonData();
    this.addCommonTiming();
    if (this.timingIndex <= 0) {
      this.initCommonTiming();
      this.$store.commit('SET_TIMING_INDEX', {
        timingIndex: this.timingIndex + 1
      });
    }
    this.$store.dispatch('init_set_splp_is_paused');
  },
  mounted() {
    this.checkIsMobile();
    this.initDialogWidth();
    window.addEventListener('resize', () => {
      this.initDialogWidth();
      this.checkIsMobile();
    });
    window.addEventListener("scroll", this.scrolling);
    this.initPageHideListen();
    this.initReceiveOptions();
  },
  methods: {
    initIdenticonImgBase64() {
      let options = {
        foreground: [255, 255, 255, 255],
        // rgba black
        background: [103, 82, 240, 255],
        // rgba white
        margin: 0.2,
        // 20% margin
        size: 50,
        // 420px square
        format: 'svg' // use SVG instead of PNG
      };

      if (!isEmpty(this.f1Address)) {
        this.identiconImgBase64 = new Identicon(this.f1Address, options).toString();
      } else if (!isEmpty(this.address)) {
        this.identiconImgBase64 = new Identicon(this.address, options).toString();
      }
    },
    initReceiveOptions() {
      this.receiveOptions = [];
      if (!isEmpty(this.address) && this.address.toString().startsWith("0x") && !this.address.toString().toLowerCase().startsWith("0xff00000")) {
        this.receiveOptions.push({
          label: `0x${this.$t('Referrals.Address')}`,
          value: '0x'
        });
      }
      if (!isEmpty(this.f1Address)) {
        this.receiveOptions.push({
          label: `f1${this.$t('Referrals.Address')}`,
          value: 'f1'
        });
      }
      if (!isEmpty(this.f4Address)) {
        this.receiveOptions.push({
          label: `f4${this.$t('Referrals.Address')}`,
          value: 'f4'
        });
      }
      if (this.receiveOptions.length > 0) {
        this.receiveValue = this.receiveOptions[0].value;
      }
    },
    addCommonTiming() {
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.updateAllData,
        pageNames: ['analytics']
      });
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.initFilePrice,
        pageNames: ['stake', 'stake-ref', 'stake-old', 'analytics']
      });
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.initStakeInfo,
        pageNames: ['stake', 'stake-ref', 'stake-old', 'analytics']
      });
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.getDailyEarnings,
        pageNames: ['stake', 'stake-ref', 'stake-old', 'analytics']
      });
    },
    initFilePrice() {
      this.$store.dispatch('initFilePrice');
    },
    initPageHideListen() {
      document.addEventListener('visibilitychange', () => {
        let pageVisibility = document.visibilityState;
        // 页面变为可见时触发
        if (pageVisibility === 'visible') {
          this.$store.commit('SET_TIMING_FLAG', {
            timingFlag: true
          });
          this._commonTiming(false);
        }
      });
    },
    initCommonTiming() {
      setTimeout(() => {
        this.$store.commit('SET_TIMING_FLAG', {
          timingFlag: true
        });
        let pageIsHide = document.visibilityState === 'hidden';
        if (!pageIsHide) {
          this._commonTiming(true);
        } else {
          console.log('page hide . no timing');
          this.initCommonTiming();
        }
      }, 30000);
    },
    _commonTiming(deep = true) {
      let pageName = this.$router.currentRoute.value.name;
      let len = this.timingFun.length;
      try {
        for (let i = 0; i < len; i++) {
          let {
            fun,
            pageNames
          } = this.timingFun[i];
          if (pageNames.find(v => v === pageName) !== undefined) {
            fun();
          }
        }
        if (deep) {
          this.initCommonTiming();
        }
      } catch (e) {
        console.log(e);
      }
    },
    initCommonData() {
      this.updateAllData();
      this.initStakeInfo();
      this.getDailyEarnings();
    },
    updateAllData() {
      this.$store.dispatch('updateAllData');
    },
    initStakeInfo() {
      this.$store.dispatch('initStakeInfo');
    },
    getDailyEarnings() {
      this.$store.dispatch('getDailyEarnings');
    },
    toUsd(val) {
      let _fil = parseFloat(this.filPrice);
      if (_fil <= 0) {
        return '';
      }
      let usd = toBigInt(val) * toBigInt(Math.floor(parseFloat(this.filPrice) * 100));
      let _uds = parseFloat(formatUnits(usd.toString(), 20));
      return "$" + _uds.toFixed(2);
    },
    scrolling() {
      if (this.$refs['menuRef']) {
        this.$refs['menuRef'].hide();
      }
    },
    setComputeIconAnimatio() {
      this.computeIconAnimatio = true;
      setTimeout(() => {
        this.computeIconAnimatio = false;
      }, 501);
    },
    setComputerFilValue(val) {
      this.cancelComputerResult();
      this.setComputeIconAnimatio();
      this.computerFilValue = val;
      this.computerFileResult();
    },
    setBalanceComputerFilValue() {
      if (!this.isConnected) {
        return;
      }
      this.cancelComputerResult();
      this.setComputeIconAnimatio();
      this.computerFilValue = parseFloat(formatUnits(this.balance, 18)).toFixed(4);
      this.computerFileResult();
    },
    computerFilResultValueInput(value) {
      this.computerFilResultValue = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
      this.computerFileResultToFil();
    },
    computerAPYtValueInput(value) {
      this.computerAPYValue = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
      this.computerFileResult();
    },
    gotoStake() {
      this.closeApyComputerShowDialog();
      this.$router.push({
        path: '/'
      });
    },
    computerFilValueInput(value) {
      this.computerFilValue = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
      this.computerFileResult();
    },
    selectDay(index) {
      this.setComputeIconAnimatio();
      this.computerFilDayIndex = index;
      if (this.editComputer) {
        this.computerFileResultToFil();
      } else {
        this.computerFileResult();
      }
    },
    computerFileResultToFil() {
      if (isEmpty(this.computerFilResultValue)) {
        return;
      }
      let _computerFilResultValue = parseFloat(this.computerFilResultValue);
      let _liquidityRate = parseFloat(this.computerAPYValue) / 100;
      let day = this.computerFilDays[this.computerFilDayIndex].value;
      let rate = Math.pow(_liquidityRate / 365 + 1, day) - 1;
      let res = _computerFilResultValue / rate;
      this.computerFilValue = res.toFixed(4);
      let _fil = parseFloat(this.filPrice);
      if (_fil > 0) {
        this.computerFilResultValueUsd = (_computerFilResultValue * _fil).toFixed(6);
      }
    },
    computerFileResult() {
      if (isEmpty(this.computerFilValue)) {
        return;
      }
      let _liquidityRate = parseFloat(this.computerAPYValue) / 100;
      let day = this.computerFilDays[this.computerFilDayIndex].value;
      let rate = Math.pow(_liquidityRate / 365 + 1, day) - 1;
      let res = parseFloat(this.computerFilValue) * rate;
      this.computerFilResultValue = res.toFixed(6);
      let _fil = parseFloat(this.filPrice);
      if (_fil > 0) {
        this.computerFilResultValueUsd = (res * _fil).toFixed(6);
      }
    },
    formatUnits,
    formatPrice,
    changeAPYMode() {
      this.editApiMode = true;
    },
    cancelChangeAPYMode() {
      this.editApiMode = false;
    },
    allowChangeLanguage() {
      let localLanguage = navigator.language;
      if (!isEmpty(localLanguage)) {
        this.locale = LOCALE_DIC[localLanguage];
        if (isEmpty(this.locale)) {
          this.locale = 'en';
        }
        this.languageValue = this.locale;
        this.$i18n.locale = this.languageValue;
        window.localStorage.setItem('localeLanguage', this.languageValue);
      }
      window.localStorage.setItem('allowChangeLanguage', 'ok');
      this.showLanguageTips = false;
    },
    closeChangeLanguage() {
      window.localStorage.setItem('allowChangeLanguage', 'ok');
      this.showLanguageTips = false;
    },
    initComputerFilDays() {
      this.computerFilDays = [{
        value: 1,
        label: this.$t('computer.1D')
      }, {
        value: 15,
        label: this.$t('computer.15D')
      }, {
        value: 30,
        label: this.$t('computer.30D')
      }, {
        value: 365,
        label: this.$t('computer.1Y')
      }, {
        value: 1095,
        label: this.$t('computer.3Y')
      }];
    },
    initMenus() {
      this.menus = [{
        label: this.$t('common.Stake'),
        path: '/',
        isNew: false
      }, {
        label: this.$t('common.Farm'),
        path: '/farm',
        isNew: false
      }, {
        label: this.$t('common.Dashboard'),
        path: '/analytics',
        isNew: false
      }, {
        label: this.$t('common.Governance'),
        path: 'https://forum.stfil.io',
        isNew: false
      }, {
        label: this.$t('common.Referrals'),
        path: '/referrals',
        isNew: false,
        phone: true
      }, {
        label: 'NFT',
        path: '/nft',
        isNew: false,
        phone: true
      }, {
        label: this.$t('common.More'),
        path: '',
        isNew: false,
        phone: false,
        more: true,
        child: [{
          label: this.$t('common.Referrals'),
          path: '/referrals',
          isNew: false
        }, {
          label: 'NFT',
          path: '/nft',
          isNew: false
        }]
      }];
    },
    // 用来判断钱包是否应该展示。fox 和 metamask 是相互兼容的。所以只要展示一个
    // 当初与fox钱包浏览器时。
    isShowWallet(label) {
      if (this.currentUserAgent === '') {
        return true;
      }
      if (this.currentUserAgent === label) {
        return true;
      }
      return false;
    },
    isShowBrowserWallet() {
      if (this.currentUserAgent === '' || !this.isMobile) {
        return true;
      }
      return !(this.currentUserAgent === 'metamask' || this.currentUserAgent === 'foxWallet' || this.currentUserAgent === 'tokenPocket' || this.currentUserAgent === 'okxApp');
    },
    initCurrentUserAgent() {
      let userAgent = navigator.userAgent.toString().toLowerCase();
      if (userAgent.indexOf('metamask') >= 0) {
        this.currentUserAgent = 'metamask';
        return;
      }
      if (userAgent.indexOf('foxwallet') >= 0) {
        this.currentUserAgent = 'foxWallet';
        return;
      }
      if (userAgent.indexOf('tokenpocket') >= 0) {
        this.currentUserAgent = 'tokenPocket';
        return;
      }
      if (userAgent.indexOf('okapp') >= 0) {
        this.currentUserAgent = 'okxApp';
        return;
      }
      this.currentUserAgent = '';
    },
    initLanguage() {
      if (isEmpty(this.locale)) {
        let localLanguage = navigator.language;
        let localLanguageLocal = LOCALE_DIC[localLanguage];
        if (!isEmpty(localLanguage) && localLanguageLocal === 'zh') {
          let allowChangeLanguage = window.localStorage.getItem('allowChangeLanguage');
          console.log('allowChangeLanguage', allowChangeLanguage);
          if (allowChangeLanguage !== 'ok') {
            this.showLanguageTips = true;
          }
        }
      }
      if (isEmpty(this.locale)) {
        this.locale = 'en';
      }
      this.languageValue = this.locale;
      this.$i18n.locale = this.languageValue;
    },
    languageChange(val) {
      // if (process.env.VUE_APP_CURRENTMODE !== 'test') {
      //   return
      // }
      this.$i18n.locale = val;
      window.localStorage.setItem('localeLanguage', val);
    },
    netModeChange(val) {
      if (val !== process.env.VUE_APP_CURRENTMODE) {
        if (val === 'test') {
          window.location.href = 'https://calibration-app.stfil.io';
        } else {
          window.location.href = 'https://app.stfil.io';
        }
      }
    },
    showMenu() {
      this.isShowMenu = true;
    },
    hideMenu() {
      this.isShowMenu = false;
    },
    disableTestnet() {
      window.location.href = 'https://app.stfil.io';
    },
    checkIsMobile() {
      this.isMobile = isMobile();
    },
    initDialogWidth() {
      let clientWidth = document.body.clientWidth;
      if (clientWidth < 550) {
        this.dialogWidth = clientWidth;
        this.transferDrawerWidth = '100%';
      } else {
        this.dialogWidth = 550;
        this.transferDrawerWidth = 430;
      }
    },
    async check() {
      if (!isInstallMetamask()) {
        this.metaMaskInstall = false;
      } else {
        this.metaMaskInstall = true;
      }
      let _isInstallMetamaskFlask = await isInstallMetamaskFlask();
      if (!_isInstallMetamaskFlask) {
        this.metaMaskFlaskInstall = false;
      } else {
        this.metaMaskFlaskInstall = true;
      }
      if (isInstallFoxWallet()) {
        this.foxWalletInstall = true;
      } else {
        this.foxWalletInstall = false;
      }
      if (isInstallOkxWallet()) {
        this.okxWalletInstall = true;
      } else {
        this.okxWalletInstall = false;
      }
    },
    async initWeb3() {
      const chains = [filChain];
      const {
        provider
      } = configureChains(chains, [walletConnectProvider({
        projectId: "ec217442a0dcd42b786be90246dfdb30"
      })]);
      this.wagmiClient = createClient({
        autoConnect: false,
        connectors: modalConnectors({
          projectId: "ec217442a0dcd42b786be90246dfdb30",
          version: "2",
          // or "2"
          appName: "web3Modal",
          chains
        }),
        provider
      });
      this.provider = provider;
      const ethereumClient = new EthereumClient(this.wagmiClient, chains);
      let web3modal = new Web3Modal({
        projectId: "ec217442a0dcd42b786be90246dfdb30",
        themeZIndex: 9999
      }, ethereumClient);
      this.$store.commit('SET_WEB3MODAL', web3modal);
      this.$store.commit('SET_ETHEREUMCLIENT', ethereumClient);
      this.web3modal = web3modal;
      this.ethereumClient = ethereumClient;
      watchAccount(res => {
        if (res.isDisconnected) {
          this.setConnectType('');
        }
        this.$store.dispatch('initAccount');
      });
      watchNetwork(res => {
        if (!isEmpty(this.connectType) && (this.connectType.toString().startsWith("Ledger") || this.connectType.toString().startsWith("MetaMaskFlask"))) {
          return;
        }
        if (res && res.chain) {
          let chainId = res.chain.id;
          if (chainId !== filChain.id) {
            this.switchNetModelShow = true;
          } else {
            this.switchNetModelShow = false;
          }
        }
      });
      if (this.connectType === 'MetaMask') {
        await this.metaMaskConnect();
      } else if (this.connectType === 'foxWallet') {
        await this.foxWalletConnect();
      } else if (this.connectType === 'WalletConnect') {
        await this.WalletConnectConnect();
      } else if (this.connectType === 'Ledger') {
        let accountList = this.getLedgerAccounts();
        if (!accountList || accountList.length === 0) {
          await this.ledgerFileCoinConnect();
        } else {
          await this.ledgerFileCoinOffLineConnect();
        }
      } else if (this.connectType === 'LedgerEth') {
        await this.ledgerEthConnect();
      } else if (this.connectType === 'MetaMaskFlask') {
        await this.metaMaskFlaskConnect(true);
      } else if (this.connectType === 'TokenPocket') {
        await this.tokenPocketConnect();
      } else if (this.connectType === 'BrowserWallet') {
        await this.browserWalletConnect();
      }
    },
    async checkNetWork() {
      if (!isEmpty(this.connectType) && (this.connectType.toString().startsWith("Ledger") || this.connectType.toString().startsWith("MetaMaskFlask"))) {
        return;
      }
      let net = await getNetwork();
      let {
        chain
      } = net;
      if (!chain) {
        return;
      }
      let {
        chainId
      } = chain;
      if (chainId !== filChain.id) {
        this.switchNetModelShow = true;
      } else {
        this.switchNetModelShow = false;
      }
    },
    checkMenu(menu) {
      if (menu) {
        if (menu.path.toString().startsWith("http")) {
          window.open(menu.path);
          return;
        }
        this.$router.push({
          path: menu.path
        });
        console.log('this.$refs[\'menuRef\']', this.$refs['menuRef']);
        this.$refs['menuRef'].hide();
      }
    },
    headerActivityClass({
      path,
      more,
      child
    }) {
      if (more) {
        for (let i = 0; i < child.length; i++) {
          let c = child[i];
          path = c.path;
          if (this.$route.path.toString().startsWith(path)) {
            return this._headerActivityClass(path);
          }
        }
      }
      return this._headerActivityClass(path);
    },
    _headerActivityClass(path) {
      if (this.$route.path.toString().startsWith(path) && path !== '/') {
        return 'nav-link-activity';
      }
      if (this.$route.path.toString() === path) {
        return 'nav-link-activity';
      }
      return 'nav-link';
    },
    _headerActivityClass2(path) {
      if (this.$route.path.toString().startsWith(path) && path !== '/') {
        return {
          color: '#6752F0'
        };
      }
      if (this.$route.path.toString() === path) {
        return {
          color: '#6752F0'
        };
      }
      return {};
    },
    headerSimpleActivityClass(path) {
      if (this.$route.path.toString().startsWith(path) && path !== '/') {
        return 'menu-link-activity';
      }
      if (this.$route.path.toString() === path) {
        return 'menu-link-activity';
      }
      return '';
    },
    handleEnter() {
      this.connectWalletHoverFlag = true;
    },
    handleLeave() {
      this.connectWalletHoverFlag = false;
    },
    handleEnterArr(index) {
      this.hoverFlag[index] = true;
    },
    handleLeaveArr(index) {
      this.hoverFlag[index] = false;
    },
    hoverClass(index) {
      if (this.hoverFlag[index]) {
        return 'connect-wallet-head-btn-icon-hover';
      }
      return '';
    },
    copyF1Address() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.f1Address;
        }
      });
      clipboard.on('success', () => {
        this.isF1Copy = true;
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isF1Copy = false;
        clipboard.destroy();
      });
    },
    copyAddress() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.$store.state.address;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    copyF4Address() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.f4Address;
        }
      });
      clipboard.on('success', () => {
        this.isF4Copy = true;
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isF4Copy = false;
        clipboard.destroy();
      });
    },
    connectWallet() {
      this.check();
      this.connectWalletModelShow = true;
    },
    setConnectType(type) {
      this.connectType = type;
      setLocal('connectType', type);
    },
    setLedgerAccounts(accountList) {
      setLocal('LedgerAccountAndPathList', accountList);
    },
    getLedgerAccounts() {
      return getLocal('LedgerAccountAndPathList');
    },
    delOldLedgerAccounts() {
      delLocal('LedgerAccountList');
    },
    async foxWalletConnect() {
      this.connectWalletErrTip = '';
      if (this.isMobile && !this.foxWalletInstall) {
        let url = window.location.origin;
        let ref = getLocal("referralCode");
        if (!isEmpty(ref)) {
          let jumpUrl = url + '/#/ref/' + btoa(ref.toString().trim());
          window.open(`https://link.foxwallet.com/dapp?url=${jumpUrl}`);
        } else {
          window.open(`https://link.foxwallet.com/dapp?url=${url}`);
        }
        return;
      }
      this.loading.foxWalletLoading = true;
      const connector = new MetaMaskConnector({
        chains: [filChain],
        options: {
          name: 'foxWallet'
        }
      });
      this.setConnectType('foxWallet');
      connect({
        connector: connector,
        chainId: filChain.id
      }).then(() => {
        this.connectWalletModelShow = false;
      }).catch(err => {
        let errName = err.name;
        if (errName === 'ConnectorNotFoundError') {
          window.open('https://foxwallet.com/');
        }
      }).finally(() => {
        this.loading.foxWalletLoading = false;
      });
    },
    async ledgerFileCoinOffLineConnect() {
      this.connectWalletErrTip = '';
      let accountList = this.getLedgerAccounts();
      let ledgerFileCoinSuper = new LedgerFileCoinSuper(this.$store.state.lotusApi, this.$t);
      ledgerFileCoinSuper.setAccountList(accountList);
      await ledgerFileCoinSuper.parseAccount();
      this.$store.commit('SET_LEDGER_FILECOIN_SUPER', ledgerFileCoinSuper);
      this.$store.commit('SET_LEDGER_ACCOUNT_LIST', ledgerFileCoinSuper.getAccountList());
      this.$store.dispatch('initByLedgerAccount');
      this.onLedgerDevice();
      this.setConnectType('Ledger');
    },
    onLedgerDevice() {
      this.ledgerFileCoinSuper.onDisconnected(() => {
        this.ledgerDeviceIsDisconnected = true;
      });
      this.ledgerFileCoinSuper.onConnect(() => {
        this.ledgerDeviceIsDisconnected = false;
      });
    },
    async ledgerFileCoinConnect() {
      this.connectWalletErrTip = '';
      try {
        this.loading.ledgerLoading = true;
        if (this.ledgerFileCoinSuper) {
          await this.ledgerFileCoinSuper.connect();
          let accountList = this.ledgerFileCoinSuper.getAccountList();
          this.$store.commit('SET_LEDGER_ACCOUNT_LIST', accountList);
          this.setLedgerAccounts(accountList);
        } else {
          let ledgerFileCoinSuper = new LedgerFileCoinSuper(this.$store.state.lotusApi, this.$t);
          await ledgerFileCoinSuper.connect();
          this.$store.commit('SET_LEDGER_FILECOIN_SUPER', ledgerFileCoinSuper);
          this.$store.commit('SET_LEDGER_ACCOUNT_LIST', ledgerFileCoinSuper.getAccountList());
          let accountList = ledgerFileCoinSuper.getAccountList();
          this.setLedgerAccounts(accountList);
        }
        this.ledgerDeviceIsDisconnected = false;
        this.onLedgerDevice();
        this.$store.dispatch('initByLedgerAccount');
        this.setConnectType('Ledger');
        this.connectWalletModelShow = false;
        this.ledgerIsFail = false;
        this.ledgerIsConnect = true;
        this.loading.ledgerLoading = false;
      } catch (err) {
        NotificationErr(parseErr(err, this.$t), "ledgerFileCoinConnect");
        this.ledgerIsConnect = false;
        this.loading.ledgerLoading = false;
      }
    },
    addLedgerAccount() {
      this.isAddLedgerAccount = true;
      let accountList = this.ledgerFileCoinSuper.getAccountList();
      this.addLedgerAddress = `m/44'/461'/${accountList.length}'/0/0`;
    },
    async onConfirmAddLedgerAccount() {
      if (isEmpty(this.addLedgerAddress)) {
        this.addLedgerAddressErrTip = this.$t('ledgerSp.LedgerAddAddressFormatErrors');
        return;
      }
      this.addLedgerAddressErrTip = '';
      try {
        this.addLedgerAddressLoading = true;
        await this.ledgerFileCoinSuper.addAccountByPath(this.addLedgerAddress);
        let accountList = this.ledgerFileCoinSuper.getAccountList();
        this.$store.commit('SET_LEDGER_ACCOUNT_LIST', accountList);
        this.setLedgerAccounts(accountList);
        this.addLedgerAddress = `m/44'/461'/${accountList.length}'/0/0`;
      } catch (err) {
        if (err instanceof LedgerAddAddressFormatErrors) {
          this.addLedgerAddressErrTip = this.$t('ledgerSp.LedgerAddAddressFormatErrors');
        } else {
          NotificationErr(parseErr(err, this.$t));
        }
      } finally {
        this.addLedgerAddressLoading = false;
      }
    },
    clearAddLedgerAccount() {
      this.isAddLedgerAccount = false;
      this.addLedgerAddress = '';
      this.addLedgerAddressErrTip = '';
    },
    async changeLedgerAccount(address) {
      await this.ledgerFileCoinSuper.setAccount(address);
      await this.$store.dispatch('initByLedgerAccount');
    },
    visibleLedgerAccount(visible) {
      if (!visible) {
        this.clearAddLedgerAccount();
      }
    },
    async ledgerEthConnect() {
      this.connectWalletErrTip = '';
      this.loading.ledgerEthLoading = true;
      const connector = new LedgerConnector({
        chains: [mainnet]
      });
      this.setConnectType('LedgerEth');
      this.connectWalletModelShow = false;
      connect({
        connector: connector,
        chainId: mainnet.id
      }).then(() => {
        this.connectWalletModelShow = false;
      }).catch(err => {
        this.setConnectType('');
        console.log(err);
      }).finally(() => {
        this.loading.ledgerEthLoading = false;
      });
    },
    async metaMaskFlaskConnect(autConnect = false) {
      this.connectWalletErrTip = '';
      if (!this.metaMaskFlaskInstall) {
        window.open('https://metamask.io/flask/');
      }
      this.loading.metaMaskFlaskLoading = true;
      let metamaskFlaskFilsnapSuper = new MetamaskFlaskFilsnapSuper(this.$store.state.lotusApi, this.$t);
      try {
        await metamaskFlaskFilsnapSuper.connect(this.env === 'test' ? 't' : 'f');
        this.$store.commit('SET_METAMASK_FLASK_FILSNAP_SUPER', metamaskFlaskFilsnapSuper);
        this.$store.dispatch('initByMetamaskFlaskAccount');
        this.setConnectType('MetaMaskFlask');
        this.connectWalletModelShow = false;
        this.loading.metaMaskFlaskLoading = false;
      } catch (e) {
        if (autConnect === false) {
          NotificationErr(e.message, "metaMaskFlaskConnect" + ` ${getLocal('connectType')}`);
        }
        this.connectWalletErrTip = e.message;
        this.loading.metaMaskFlaskLoading = false;
      }
    },
    async tokenPocketConnect() {
      this.connectWalletErrTip = '';
      if (this.isMobile && this.currentUserAgent !== 'tokenPocket') {
        let url = "https://stfil.io";
        let ref = getLocal("referralCode");
        if (!isEmpty(ref)) {
          url = url + '/#/ref/' + btoa(ref.toString().trim());
        }
        let params = {
          url: url,
          chain: 'FIL'
        };
        window.open(`tpdapp://open?params=${encodeURIComponent(JSON.stringify(params))}`);
        return;
      }
      this.loading.tokenPocketLoading = true;
      let connector = new MetaMaskConnector({
        chains: [filChain],
        options: {
          getProvider: () => typeof window !== 'undefined' ? window.ethereum : undefined,
          shimDisconnect: false
        }
      });
      console.log('step 1');
      connect({
        connector: connector,
        chainId: filChain.id
      }).then(() => {
        this.setConnectType('TokenPocket');
        this.connectWalletModelShow = false;
      }).catch(err => {
        console.log(err);
        this.connectWalletErrTip = err.name;
      }).finally(() => {
        this.loading.tokenPocketLoading = false;
      });
    },
    async metaMaskConnect() {
      this.connectWalletErrTip = '';
      console.log('metaMaskConnect', window.location.host, this.$route.query.ref);
      if (this.isMobile && !this.metaMaskInstall) {
        let url = window.location.host;
        let ref = getLocal("referralCode");
        if (!isEmpty(ref)) {
          let jumpUrl = url + '/#/ref/' + btoa(ref.toString().trim());
          window.open(`https://metamask.app.link/dapp/${jumpUrl}`);
        } else {
          window.open(`https://metamask.app.link/dapp/${url}`);
        }
        return;
      }
      this.loading.metamaskLoading = true;
      const connector = new MetaMaskConnector({
        chains: [filChain]
      });
      connect({
        connector: connector,
        chainId: filChain.id
      }).then(() => {
        this.setConnectType('MetaMask');
        this.connectWalletModelShow = false;
      }).catch(err => {
        let errName = err.name;
        if (errName === 'ConnectorNotFoundError') {
          window.open('https://metamask.io/');
        }
      }).finally(() => {
        this.loading.metamaskLoading = false;
      });
    },
    async okxWalletConnect() {
      this.connectWalletErrTip = '';
      if (this.isMobile && !this.okxWalletInstall) {
        let url = "https://stfil.io";
        let ref = getLocal("referralCode");
        if (!isEmpty(ref)) {
          url = url + '/#/ref/' + btoa(ref.toString().trim());
        }
        window.open(`okx://wallet/dapp/details?dappUrl=${url}`);
        return;
      }
      if (!this.okxWalletInstall) {
        window.open('https://www.okx.com/web3');
        return;
      }
      this.loading.okxWalletLoading = true;
      const connector = new MetaMaskConnector({
        chains: [filChain]
      });
      connect({
        connector: connector,
        chainId: filChain.id
      }).then(() => {
        this.setConnectType('OkxWallet');
        this.connectWalletModelShow = false;
      }).catch(err => {
        let errName = err.name;
        if (errName === 'ConnectorNotFoundError') {
          window.open('https://www.okx.com/web3');
        }
      }).finally(() => {
        this.loading.okxWalletLoading = false;
      });
    },
    async browserWalletConnect() {
      console.log('browserWalletConnect');
      this.connectWalletErrTip = '';
      this.loading.browserWalletLoading = true;
      let connector = new MetaMaskConnector({
        chains: [filChain],
        options: {
          getProvider: () => typeof window !== 'undefined' ? window.ethereum : undefined,
          shimDisconnect: false
        }
      });
      connect({
        connector: connector,
        chainId: filChain.id
      }).then(() => {
        this.setConnectType('BrowserWallet');
        this.connectWalletModelShow = false;
      }).catch(err => {
        if (err.name === 'ConnectorNotFoundError') {
          this.connectWalletErrTip = this.$t('common.Wallet not detected');
        } else {
          this.connectWalletErrTip = err.name;
        }
      }).finally(() => {
        this.loading.browserWalletLoading = false;
      });
    },
    async WalletConnectConnect() {
      this.connectWalletErrTip = '';
      this.loading.walletConnectLoading = true;
      const connector = new WalletConnectConnector({
        chains: [filChain],
        options: {
          projectId: 'ec217442a0dcd42b786be90246dfdb30',
          qrModalOptions: {
            'themeVariables': {
              '--wcm-z-index': '9999'
            }
          }
        }
      });
      connect({
        connector: connector,
        chainId: filChain.id
      }).then(() => {
        this.setConnectType('WalletConnect');
        this.connectWalletModelShow = false;
      }).catch(err => {
        console.log(err);
        if (err.message.toString().indexOf("transportOpen") >= 0) {
          this.connectWalletErrTip = this.$t('common.Connection timed out');
        }
      }).finally(() => {
        this.loading.walletConnectLoading = false;
      });
    },
    disconnect() {
      if (this.connectType === 'Ledger') {
        this.setConnectType('');
        this.setLedgerAccounts([]);
        this.ledgerFileCoinSuper.disConnect();
        this.$store.dispatch('disLedgerConnect');
        this.closeSwitchNetDialog();
      } else if (this.connectType === 'MetaMaskFlask') {
        this.setConnectType('');
        this.metamaskFlaskFilsnapSuper.disConnect();
        this.$store.dispatch('disMetamaskFlaskConnect');
        this.closeSwitchNetDialog();
      } else {
        disconnect();
      }
    },
    closeSwitchNetDialog() {
      this.switchNetModelShow = false;
    },
    showApyComputerShowDialog(computerFilValue = '', computerFilDayIndex = 0) {
      this.apyComputerShow = true;
      if (!isEmpty(computerFilValue)) {
        this.computerFilValue = computerFilValue;
        this.computerFilDayIndex = computerFilDayIndex;
        this.computerFilValueInput(this.computerFilValue.toString());
      }
    },
    closeApyComputerShowDialog() {
      this.apyComputerShow = false;
      this.computerFilResultValue = '0.00';
      this.computerFilResultValueUsd = '0.00';
      this.computerFilValue = '';
      this.computerFilDayIndex = 0;
      this.computerAPYValue = formatPrice(formatUnits(this.liquidityRate, 25), 2);
    },
    async switchNetworkFun() {
      this.closeSwitchNetDialog();
      switchNetwork({
        chainId: filChain.id
      }).then(res => {
        console.log('switchNetwork', res);
      }).catch(err => {
        console.log('switchNetwork err', err);
        NotificationErr("Failed to switch networks\n" + "switch the network in your wallet’s settings.");
      });
    },
    simpleValue,
    isEmpty,
    formatFilPrice,
    formatWalletFilPrice,
    editComputerResult() {
      this.setComputeIconAnimatio();
      this.editComputer = true;
      if (this.computerFilResultValue === '0.00') {
        this.computerFilResultValue = '';
      }
      this.$nextTick(() => {
        this.$refs['computerFilResultValueRef'].focus();
      });
    },
    cancelComputerResult() {
      this.setComputeIconAnimatio();
      if (this.computerFilResultValue === '') {
        this.computerFilResultValue = '0.00';
      }
      this.editComputer = false;
    },
    showHome() {
      this.initCopy();
      this.innerDialog = 'none';
      let qrAddress = this.$refs.qrAddress;
      if (qrAddress && qrAddress.firstChild) {
        while (qrAddress.firstChild) {
          qrAddress.removeChild(qrAddress.firstChild);
        }
      }
    },
    initCopy() {
      this.isF4Copy = false;
      this.isCopy = false;
      this.isF1Copy = false;
    },
    switchReciveAddress() {
      this.initCopy();
      switch (this.receiveValue) {
        case "0x":
          this.showReceive(this.address);
          break;
        case "f4":
          this.showReceive(this.f4Address);
          break;
        case "f1":
          this.showReceive(this.f1Address);
          break;
      }
    },
    receiveAddress() {
      this.innerDialog = 'showAddress';
      let address = undefined;
      switch (this.receiveValue) {
        case "0x":
          address = this.address;
          break;
        case "f4":
          address = this.f4Address;
          break;
        case "f1":
          address = this.f1Address;
          break;
      }
      if (address) {
        this.showReceive(address);
      }
    },
    showReceive(address) {
      let qrAddress = this.$refs.qrAddress;
      if (!qrAddress) {
        return;
      }
      if (qrAddress.firstChild) {
        while (qrAddress.firstChild) {
          qrAddress.removeChild(qrAddress.firstChild);
        }
      }
      new QRCode(this.$refs.qrAddress, {
        text: JSON.stringify(address),
        // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.Q
      });
    },
    transfer() {
      if (this.$refs['wallectPopoverRef']) {
        this.$refs['wallectPopoverRef'].hide();
      }
      this.transferDrawerFlag = true;
    },
    transferDrawerClose() {
      this.transferDrawerFlag = false;
    }
  },
  computed: {
    timingFun() {
      return this.$store.state.timingFun;
    },
    accountListOption() {
      let accountList = this.$store.state.ledger.accountList;
      let accountListOption = [];
      for (let i = 0; i < accountList.length; i++) {
        accountListOption.push({
          label: accountList[i].address,
          value: accountList[i].address
        });
      }
      return accountListOption;
    },
    ledgerFileCoinSuper() {
      return this.$store.state.ledger.ledgerFileCoinSuper;
    },
    metamaskFlaskFilsnapSuper() {
      return this.$store.state.metamaskFlask.metamaskFlaskFilsnapSuper;
    },
    f1Address() {
      return this.$store.state.f1Address;
    },
    f0Address() {
      return this.$store.state.f0Address;
    },
    filPrice() {
      return this.$store.state.filPrice;
    },
    liquidityRate() {
      return this.$store.state.publicInfo.liquidityRate;
    },
    connectWalletHover() {
      if (this.connectWalletHoverFlag) {
        return 'connect-wallet-head-btn-icon-hover';
      }
      return '';
    },
    address() {
      return this.$store.state.address;
    },
    f4Address() {
      return this.$store.state.f4Address;
    },
    env() {
      return this.$store.state.evn;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    balance() {
      return this.$store.state.balance;
    },
    balanceLoading() {
      return this.$store.state.balanceLoading;
    },
    stFilBalanceLoading() {
      return this.$store.state.stFilBalanceLoading;
    },
    stFilBalance() {
      return this.$store.state.stFilBalance;
    },
    wstFilBalanceLoading() {
      return this.$store.state.wstFilBalanceLoading;
    },
    wstFilBalance() {
      return this.$store.state.wstFilBalance;
    },
    stFilInterest() {
      return this.$store.state.stFilInterest;
    },
    stFilInterestLoading() {
      return this.$store.state.stFilInterestLoading;
    },
    fnsName() {
      return this.$store.state.fnsName;
    },
    timingFlag() {
      return this.$store.state.timingFlag;
    },
    timingIndex() {
      return this.$store.state.timingIndex;
    }
  }
};