import Filecoin, {CommonLedgerError, LedgerProvider, TransportWrapper} from "@glif/filecoin-wallet-provider";
import {
    LEDGER_VERSION_MAJOR,
    LEDGER_VERSION_MINOR,
    LEDGER_VERSION_PATCH
} from "@/components/induction/singer/ledger/config";
import {loutsRpc, walletProvider} from "@/utils/LoutsRpc";
import {toBigInt} from "@/utils/NumU";
import {getActorAddress, NotificationSuccess, NotificationWarning} from "@/utils/common";
import {Message} from '@glif/filecoin-message'
import {ethers} from "ethers";
import {base64Encode, hexDecode} from "@/utils/contracts/singer/utils";
import {encode as dagCborEncode} from "@ipld/dag-cbor";
import {PushProposalValue, WaitProposal} from "@/utils/contracts/singer/singer";
import {GasEstimateMessageGas, MpoolGetNonce} from "@/utils/FilecoinApi";
import {parseErr} from "@/components/induction/singer/ledger/Errors";
import {isEmpty} from "@/utils/model";
import FilecoinApp from "@/components/header/ledgerHelper";
import {errors as walletProviderErrors} from "@glif/filecoin-wallet-provider/dist/errors";
import {
    LedgerAddAddressExistErrors,
    LedgerAddAddressFormatErrors,
    LedgerOpenExpertModeModeErrors,
    LedgerSignFairErrors,
    LedgerTransactionRejectedErrors
} from "@/components/header/ledgerHelper/innerLedgerError";
import EventEmitter from "events";

let _CreateMessage = async (from, to, value, method, params, Nonce) => {
    let msg = {
        "From": from,
        "To": to,
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": method,
        "Params": params,
        'Version': 0,
        'Nonce': Nonce,
        'Value': value
    }
    let data = undefined
    try {
        data = await loutsRpc(GasEstimateMessageGas(msg))
    } catch (e) {
        throw new Error(e.error.message)
    }
    if (!data) {
        throw new Error("Null")
    }
    if (data.error) {
        throw new Error(data.error)
    }
    return data.result

}

function handleLedgerResponseErrors(response) {
    if (response.device_locked) {
        throw new walletProviderErrors.LedgerDeviceLockedError
    }

    if (
        response.error_message &&
        response.error_message.toLowerCase().includes('no errors')
    ) {
        return response
    }
    if (
        response.error_message &&
        response.error_message
            .toLowerCase()
            .includes('transporterror: invalid channel')
    ) {
        throw new walletProviderErrors.LedgerLostConnectionError()
    }

    throw new walletProviderErrors.WalletProviderError({message: response.error_message})
}


export class LedgerFileCoinSuper {
    ledgerSubProvider
    transportWrapperSingleton
    isConnected = false
    filecoin
    rpc
    account
    accountList
    f0Address
    ethAddress
    $t
    eventEmitter
    startTimerAccount = false
    deviceIsDisconnect = false

    _events = new EventEmitter();

    constructor(rpc, $t) {
        this.$t = $t
        this.rpc = rpc
        this.eventEmitter = new EventTarget()
        this.transportWrapperSingleton = new TransportWrapper()
    }

    onDisconnected(call) {
        this._events.on('disconnect', call)
    }

    onConnect(call) {
        this._events.on('connect', call)
    }

    _listen() {
        this.transportWrapperSingleton.transport.on('disconnect', () => {
            this.deviceIsDisconnect = false
            this._events.emit('disconnect')
        })
    }

    async setAccount(address) {
        this.account = address
        let res
        try {
            res = await walletProvider.lookupId(this.account)
        } catch (e) {
            res = ''
        }
        this.f0Address = res
        if (this.f0Address !== '') {
            this.ethAddress = getActorAddress(toBigInt(res.substr(2)))
        } else {
            this.ethAddress = ''
        }
        this.startTimerAccount = true
    }

    getProvider() {
        return this.ledgerSubProvider
    }

    setAccountList(accountList) {
        this.accountList = accountList
    }

    _parseAccountList() {
        if (this.filecoin !== undefined && this.accountList !== undefined && this.accountList.length > 0) {
            for (let i = 0; i < this.accountList.length; i++) {
                let {address, path} = this.accountList[i]
                this.filecoin.wallet.setAccountPath(address, path)
            }
        }
    }

    getAccountList() {
        return this.accountList
    }

    onAccount(call) {
        this.eventEmitter.addEventListener('onAccountChange', (event) => {
            call(event)
        });
    }

    getConnected() {
        return this.isConnected
    }

    getAddress() {
        return this.account
    }

    getF0Address() {
        return this.f0Address
    }

    getEthAddress() {
        return this.ethAddress
    }

    async connect() {
        try {
            await this.transportWrapperSingleton.connect()
            if (this.ledgerSubProvider) {
                await this.ledgerSubProvider.resetTransport(this.transportWrapperSingleton.transport)
            } else {
                this.ledgerSubProvider = new LedgerProvider({
                    transport: this.transportWrapperSingleton.transport,
                    minLedgerVersion: {
                        major: LEDGER_VERSION_MAJOR,
                        minor: LEDGER_VERSION_MINOR,
                        patch: LEDGER_VERSION_PATCH
                    }
                })
            }
            await this.ledgerSubProvider.ready()
            this.filecoin = new Filecoin(this.ledgerSubProvider, {
                apiAddress: this.rpc
            })
            if (this.accountList !== undefined && this.accountList.length > 0) {
                this._parseAccountList()
            } else {
                await this.initAccount()
            }
            this.deviceIsDisconnect = true
            this._events.emit('connect')
            this._listen()
            this.isConnected = true
        } catch (e) {
            this.ledgerSubProvider = undefined
            this.filecoin = undefined
            this.isConnected = false
            console.log(e)
            throw e
        }
    }

    async addAccountByPath(path) {
        if (!this.isConnected) {
            try {
                await this.connect()
            } catch (e) {
                let msg = parseErr(e, this.$t)
                throw new Error(msg)
            }
        }
        if (this.filecoin.wallet.ledgerBusy) throw new walletProviderErrors.LedgerDeviceBusyError()
        this.filecoin.wallet.ledgerBusy = true
        try {
            let filecoinApp = new FilecoinApp(this.transportWrapperSingleton.transport)
            const {addrString} = handleLedgerResponseErrors(
                await filecoinApp.getAddressAndPubKey(path)
            )
            let find = this.accountList.find(_addr => _addr.address === addrString)
            if (find) {
                throw new LedgerAddAddressExistErrors()
            }
            this.filecoin.wallet.setAccountPath(addrString, path)
            this.accountList.push({
                address: addrString,
                path
            })
        } catch (err) {
            if (err instanceof LedgerAddAddressExistErrors) {
                throw err
            } else {
                throw new LedgerAddAddressFormatErrors()
            }
        } finally {
            this.filecoin.wallet.ledgerBusy = false
        }
        return true
    }

    async _getAccounts(start, end) {
        if (this.filecoin.wallet.ledgerBusy) throw new walletProviderErrors.LedgerDeviceBusyError()
        this.filecoin.wallet.ledgerBusy = true
        const paths = []
        for (let i = start; i < end; i += 1) {
            let code = `m/44'/461'/${i}'/0/0`
            paths.push(code)
        }
        let app = new FilecoinApp(this.transportWrapperSingleton.transport)
        let res = []
        for (let i = 0; i < paths.length; i++) {
            try {
                let path = paths[i]
                const {addrString} = handleLedgerResponseErrors(
                    await app.getAddressAndPubKey(path)
                )
                this.filecoin.wallet.setAccountPath(addrString, path)
                res.push({
                    address: addrString,
                    path
                })
            } catch (err) {
                if (err instanceof Error) {
                    throw CommonLedgerError(err)
                } else {
                    throw new walletProviderErrors.LedgerReplugError()
                }
            } finally {
                this.filecoin.wallet.ledgerBusy = false
            }
        }
        this.filecoin.wallet.ledgerBusy = false
        return res
    }

    async initAccount() {
        this.accountList = await this._getAccounts(0, 5)
        await this.parseAccount()
    }

    async parseAccount() {
        this.account = this.accountList[0].address
        let res
        try {
            res = await walletProvider.lookupId(this.account)
        } catch (e) {
            res = ''
        }
        this.f0Address = res
        if (res !== '') {
            this.ethAddress = getActorAddress(toBigInt(res.substr(2)))
        } else {
            this.ethAddress = ''
        }
        this.startTimerAccount = true
    }

    async disConnect() {
        this.startTimerAccount = false
        if (this.sub) {
            this.sub.unsubscribe()
        }
    }

    timerAccount() {
        setTimeout(() => {
            const event = new CustomEvent('onAccountChange', {address: this.account});
            this.eventEmitter.dispatchEvent(event);
            if (this.startTimerAccount) {
                this.timerAccount()
            }
        }, 15000)

    }

    async simpleSignMessage(msg) {
        if (!this.isConnected) {
            try {
                await this.connect()
            } catch (e) {
                let msg = parseErr(e, this.$t)
                throw new Error(msg)
            }
        }
        let filecoinApp = new FilecoinApp(this.transportWrapperSingleton.transport)
        let path = this._getPath(this.account)
        try {
            const pkResponse = await filecoinApp.getAddressAndPubKey(path)
            const pk = Buffer.from(Uint8Array.from(pkResponse.compressed_pk))
            if (msg.toString().toLowerCase().startsWith("0x")) {
                msg = msg.toString().substr(2)
            }
            const raw_bytes = Buffer.from(msg, 'hex')
            const prefix = Buffer.from("Filecoin Sign Bytes:\n");
            const txBlob = Buffer.concat([prefix, raw_bytes]);
            NotificationWarning(this.$t("ledgerSp.Please approve the request on your Ledger device"))
            let res = await filecoinApp.signRawBytes(path, txBlob)
            if (parseInt(res.return_code) === 36864 && res.error_message === 'No errors') {
                let signatureBuffer = Buffer.from(res.signature_compact)
                return Buffer.concat([signatureBuffer, pk]).toString('base64')
            } else {
                if (parseInt(res.return_code) === 27014) {
                    throw new LedgerTransactionRejectedErrors();
                }
                throw new LedgerSignFairErrors("signRawBytes err: " + res.error_message)
            }
        } catch (e) {
            console.log('err', e.message)
            if (e.message.toString().indexOf("Usupported transaction type") >= 0) {
                throw new LedgerOpenExpertModeModeErrors()
            }
            throw e
        }
    }

    _getPath(address) {
        return this.filecoin.wallet.getPath(address)
    }

    async signMessage(option, confirmCall = undefined) {
        if (!this.isConnected) {
            try {
                await this.connect()
            } catch (e) {
                let msg = parseErr(e, this.$t)
                throw new Error(msg)
            }
        }
        let {overrides, functionName, args, abi, f0address} = option
        const Interface = new ethers.utils.Interface(abi);
        const invokeContractMethod = 3844450837;// 固定值
        let params = base64Encode(dagCborEncode(hexDecode(Interface.encodeFunctionData(functionName, args))))
        let nonce = (await loutsRpc(MpoolGetNonce(this.account))).result
        let singerMsg = {}
        singerMsg = await _CreateMessage(this.account, f0address, (overrides && overrides.value) ? overrides.value.toString() : '0', invokeContractMethod, params, nonce)
        const newMessage = new Message({
            to: singerMsg.To,
            from: singerMsg.From,
            nonce: singerMsg.Nonce,
            value: singerMsg.Value,
            method: singerMsg.Method,
            params: singerMsg.Params,
            gasPremium: singerMsg.GasPremium,
            gasFeeCap: singerMsg.GasFeeCap,
            gasLimit: singerMsg.GasLimit
        })
        const lotusMessage = newMessage.toLotusType()
        NotificationWarning(this.$t("ledgerSp.Please approve the request on your Ledger device"))
        const signedMessage = await this.filecoin.wallet.sign(
            singerMsg.From,
            lotusMessage
        )
        NotificationSuccess(this.$t("ledgerSp.Waiting up chain"))
        let res = await PushProposalValue(signedMessage)
        if (res && res.error && res.error.message) {
            throw new Error(res.error.message)
        }
        if (confirmCall && !isEmpty(res.result['/'])) {
            confirmCall({
                hash: res.result['/']
            })
        }
        if (!res.result) {
            throw Error('Invalid Signature!')
        }
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                let data = {}
                try {
                    data = await WaitProposal(res.result)
                    resolve(data)
                } catch (e) {
                    reject(e)
                }
            }, 2000)
        })
    }
}
