import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.array.push.js";
import { CircleCheckFilled, CircleClose, CopyDocument, Refresh, SuccessFilled, Warning, Share, CirclePlus } from '@element-plus/icons-vue';
import { mapState } from "vuex";
import { isEmpty } from "@/utils/model";
import { BigNumber } from "ethers";
import { formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { getLocal, NotificationErr, NotificationSuccess, NotificationWarning, simpleDateFormatByMin } from "@/utils/common";
import Clipboard from "clipboard";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { signMessage } from "@wagmi/core";
import { LeveLabel } from "@/views/referrals/label";
import { parseErr } from "@/components/induction/singer/ledger/Errors";
export default {
  name: "Subordinate-page",
  components: {
    CopyDocument,
    Refresh,
    CircleClose,
    Warning,
    CircleCheckFilled,
    SuccessFilled,
    Share,
    CirclePlus
  },
  data() {
    return {
      LeveLabel,
      canSaveImage: true,
      posterImgList: [],
      posterDialogWidth: '375px',
      posterDialogHeight: '626px',
      posterSelectCode: '',
      posterLoading: false,
      posterDialogVisible: false,
      showFreshBtn: true,
      checkReferralsCodeLoading: false,
      whiteAddressLoading: false,
      showGenerateCode: false,
      showWhileAddressInput: false,
      generateCode: '',
      whiteAddress: '',
      referralCodeErrTips: '',
      referralCodeButtonTips: '',
      addWhileListErrTips: '',
      generateCodeInputTime: 0,
      generateCodeFinish: false,
      whiteAddressFinish: false,
      isCopyIndex: -1,
      page: 1,
      size: 10,
      isCopySharLine: false
    };
  },
  created() {
    this.$store.dispatch('getReferralCodeLimit');
    this.initWidth();
    window.onresize = () => {
      this.initWidth();
    };
    this.initUserAgent();
    this.init();
  },
  watch: {
    address() {
      this.init();
    }
  },
  methods: {
    initUserAgent() {
      let userAgent = navigator.userAgent.toString().toLowerCase();
      if (userAgent.indexOf('metamask') >= 0) {
        this.canSaveImage = false;
        return;
      }
      if (userAgent.indexOf('foxwallet') >= 0) {
        this.canSaveImage = false;
        return;
      }
      this.canSaveImage = true;
    },
    toBigInt,
    changeHistory(val) {
      this.$store.dispatch('historyCollections', {
        address: this.address,
        page: val
      });
    },
    changeWithdraw(val) {
      this.$store.dispatch('initWithdrawList', {
        address: this.address,
        page: val,
        type: 2
      });
    },
    changeSnapshoot(val) {
      this.$store.dispatch('initSnapshoot', {
        address: this.address,
        type: 2,
        page: val
      });
    },
    initWhileList() {
      this.$store.dispatch('getWhileList', {
        address: this.address
      });
    },
    showWhileAddressInputFun() {
      this.showWhileAddressInput = true;
    },
    createPoster(code) {
      let clipboard = new Clipboard('.copy-text2', {
        text: () => {
          console.log('copu');
          return `https://share.stfil.io/#/home/${this.$i18n.locale === 'zh' ? 'cn' : 'en'}/${code}`;
        }
      });
      clipboard.on('success', () => {
        let userAgent = navigator.userAgent.toString().toLowerCase();
        if (userAgent.indexOf('metamask') >= 0 || userAgent.indexOf('foxwallet') >= 0) {
          NotificationSuccess(this.$t('common.Copy and share poster successfully ready to jump2'));
        } else {
          NotificationSuccess(this.$t('common.Copy and share poster successfully ready to jump'));
          setTimeout(() => {
            window.open(`https://share.stfil.io/#/home/${this.$i18n.locale === 'zh' ? 'cn' : 'en'}/${code}`);
          }, 1500);
        }
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        clipboard.destroy();
      });
    },
    gotoTwitter(index, code) {
      if (this.env === 'test') {
        let h = `I%27m%20currently%20%23staking%20my%20FIL%20on%20%40stfil_io%20%0a%0aNo%20lock-up%20period!%20%E2%9C%85%20%0aEnjoying%2010%2B%25%20APY!%E2%9C%85%20%0aEarning%20exclusive%20%23NFT%20rewards.%20%E2%9C%85%20%0a%0aIf%20you%20also%20have%20FIL%2C%20why%20not%20give%20it%20a%20try%3A%20https%3A%2F%2Fhyperspace-app.stfil.io%2F%23%2F%3Fref%3D${btoa(code.toString())}%20%0a%0a%23STFIL%20%23FIL%20%20%23NFTs`;
        window.open('https://twitter.com/intent/tweet?text=' + h);
      } else {
        let h = `I%27m%20currently%20%23staking%20my%20FIL%20on%20%40stfil_io%20%0a%0aNo%20lock-up%20period!%20%E2%9C%85%20%0aEnjoying%2010%2B%25%20APY!%E2%9C%85%20%0aEarning%20exclusive%20%23NFT%20rewards.%20%E2%9C%85%20%0a%0aIf%20you%20also%20have%20FIL%2C%20why%20not%20give%20it%20a%20try%3A%20https%3A%2F%2Fapp.stfil.io%2F%23%2F%3Fref%3D${btoa(code.toString())}%20%0a%0a%23STFIL%20%23FIL%20%20%23NFTs`;
        window.open('https://twitter.com/intent/tweet?text=' + h);
      }
    },
    async refreshCode() {
      if (!isEmpty(this.address)) {
        try {
          await this.$store.dispatch('updateReferralCode', {
            address: this.address
          });
        } catch (e) {
          NotificationErr(e.msg);
          return;
        }
        this.$store.dispatch('viewOwnerCollections', {
          address: this.address
        });
      }
    },
    saveImage() {
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = `stfil-${this.posterSelectCode}.png`;
      a.href = this.posterImgList[0];
      a.dispatchEvent(event);
      a.remove();
    },
    copySharLink() {
      let code = this.addressReferralCodes[0].referral;
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          let host = window.location.origin;
          return `${host}/#/?ref=${btoa(code.toString())}`;
        }
      });
      clipboard.on('success', () => {
        this.isCopySharLine = true;
        setTimeout(() => {
          this.isCopySharLine = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopySharLine = false;
        clipboard.destroy();
      });
    },
    copy(index, code) {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          let host = window.location.origin;
          return `${host}/#/?ref=${btoa(code.toString())}`;
        }
      });
      clipboard.on('success', () => {
        this.isCopyIndex = index;
        setTimeout(() => {
          if (this.isCopyIndex === index) {
            this.isCopyIndex = -1;
          }
        }, 500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopyIndex = -1;
        clipboard.destroy();
      });
    },
    formatWalletFilPrice,
    simpleDateFormatByMin,
    initWidth() {
      let width = document.body.clientWidth;
      if (width > 420) {
        this.posterDialogWidth = '375px';
        this.posterDialogHeight = '626px';
      } else {
        let w = Math.floor(width * 0.8);
        this.posterDialogWidth = `${w}px`;
        this.posterDialogHeight = `${Math.floor(w * 1.6686)}px`;
      }
    },
    init() {
      if (!isEmpty(this.address)) {
        this.generateCodeHandleClose();
        this.$store.dispatch('viewOwnerCollections', {
          address: this.address
        });
        this.$store.dispatch('historyCollections', {
          address: this.address
        });
        this.$store.dispatch('initWithdrawList', {
          address: this.address,
          type: 2
        });
        this.$store.dispatch('initSnapshoot', {
          address: this.address,
          type: 2
        });
        this.$store.dispatch('initMintWlCheck', {
          address: this.address
        });
        this.initWhileList();
      } else {
        this.$store.commit('CLEAR_REFERRALS_VALUE');
        this.generateCodeHandleClose();
      }
    },
    generateCodeHandleClose() {
      this.showGenerateCode = false;
      this.generateCode = '';
      this.generateCodeFinish = false;
      this.isHaveWhiteList = false;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    generateCodeShow() {
      if (!this.isConnected) {
        this.$store.state.refs['iHeader'].connectWallet();
        return;
      }
      this.showGenerateCode = true;
    },
    inputGenerateCode(val) {
      this.generateCodeFinish = false;
      this.generateCode = val.replace(/[^0-9.]/g, '');
      let wait = 500;
      this.generateCodeInputTime = new Date().getTime() + wait;
      setTimeout(() => {
        let _generateCode = parseInt(this.generateCode);
        if (_generateCode > this.maxReferralCode || _generateCode < this.minReferralCode) {
          this.referralCodeErrTips = this.$t('Referrals.GenerateCodeInputErr').replaceAll('$$$$1', this.minReferralCode).replaceAll('$$$$2', this.maxReferralCode);
          return;
        }
        this.referralCodeErrTips = '';
        let now = new Date().getTime();
        if (now - this.generateCodeInputTime >= 0) {
          // this.inputTime拿的是最新的值，e.timeStamp拿的是零点五秒前函数的e.timeStamp
          if (!isEmpty(this.generateCode)) {
            this.checkReferralsCodeLoading = true;
            this.referralCodeButtonTips = this.$t('Referrals.Checking code');
            this.$store.dispatch('checkReferralCode', {
              referralCode: parseInt(this.generateCode)
            }).then(val => {
              let num = BigNumber.from(val).toBigInt() & toBigInt("0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
              if (num <= 0n) {
                this.generateCodeFinish = true;
              } else {
                this.referralCodeButtonTips = this.$t('Referrals.Code already token');
              }
            }).finally(() => {
              this.checkReferralsCodeLoading = false;
              this.$refs['generateCodeInput'].focus();
              if (this.generateCodeFinish) {
                this.referralCodeButtonTips = '';
              }
            });
          }
        }
      }, wait);
    },
    createReferralsCode() {
      this.checkReferralsCodeLoading = true;
      this.$store.dispatch('registerReferralCode', {
        referralCode: parseInt(this.generateCode)
      }).then(() => {
        setTimeout(() => {
          NotificationSuccess(`Recommended code creation success`);
          this.showFreshBtn = false;
          setTimeout(() => {
            this.$store.dispatch('updateReferralCode', {
              address: this.address
            }).then(() => {
              setTimeout(() => {
                this.showFreshBtn = true;
              }, 10000);
            }).catch(() => {
              this.showFreshBtn = true;
            });
          }, 2000);
          setTimeout(() => {
            this.init();
          }, 3500);
          this.generateCodeHandleClose();
        }, 5000);
      }).catch(e => {
        NotificationErr(e.message, "createReferralsCode" + ` ${getLocal('connectType')}`);
        this.checkReferralsCodeLoading = false;
      }).finally(() => {
        setTimeout(() => {
          this.checkReferralsCodeLoading = false;
        }, 5000);
      });
    },
    async singMessageFun(message) {
      let signature = '';
      if (getLocal('connectType') === 'MetaMaskFlask') {
        signature = await this.metamaskFlaskFilsnapSuper.signMessageLocal(message);
      } else if (getLocal('connectType') === 'Ledger') {
        signature = await this.ledgerFileCoinSuper.simpleSignMessage(message);
      } else {
        signature = await signMessage({
          message
        });
      }
      return signature;
    },
    async withdraw() {
      this.$store.commit('SET_ADD_WITHDRAW_LOADING', {
        addWithdrawLoading: true
      });
      let signature = '';
      try {
        signature = await this.singMessageFun(this.address);
      } catch (e) {
        if (getLocal('connectType') === 'Ledger') {
          NotificationErr(parseErr(e, this.$t));
        } else {
          NotificationErr(e.toString(), "subordinate withdraw" + ` ${getLocal('connectType')}`);
        }
        this.$store.commit('SET_ADD_WITHDRAW_LOADING', {
          addWithdrawLoading: false
        });
        return;
      }
      let params = {
        address: this.address,
        type: 2,
        signature
      };
      if (getLocal('connectType') === 'MetaMaskFlask') {
        params['f1Address'] = this.metamaskFlaskFilsnapSuper.getF1Address();
      } else if (getLocal('connectType') === 'Ledger') {
        params['f1Address'] = this.ledgerFileCoinSuper.getAddress();
      }
      this.$store.dispatch('addUserWithdrawn', params).then(() => {
        setTimeout(() => {
          this.$store.dispatch('initWithdrawList', {
            address: this.address,
            type: 2
          });
        }, 1000);
        NotificationSuccess(this.$t('Referrals.Successfully added to queue'));
      }).catch(err => {
        if (err.msg === 'You have a record of not waiting for withdrawal processing') {
          NotificationSuccess(this.$t('Referrals.Successfully added to queue'));
        } else if (err.msg === 'The minimum withdrawal amount for commissions is 1 FIL') {
          NotificationWarning(this.$t('Referrals.The minimum withdrawal amount for commissions'));
        } else {
          NotificationErr(err.msg);
        }
      }).finally(() => {
        this.$store.commit('SET_ADD_WITHDRAW_LOADING', {
          addWithdrawLoading: false
        });
      });
    },
    gotoNft2() {
      this.$router.push({
        path: '/nft'
      });
    }
  },
  computed: mapState({
    ledgerFileCoinSuper() {
      return this.$store.state.ledger.ledgerFileCoinSuper;
    },
    metamaskFlaskFilsnapSuper() {
      return this.$store.state.metamaskFlask.metamaskFlaskFilsnapSuper;
    },
    env() {
      return this.$store.state.evn;
    },
    perMaxReferralCodeNum: state => state.referrals.perMaxReferralCodeNum,
    minReferralCode: state => state.referrals.minReferralCode,
    maxReferralCode: state => state.referrals.maxReferralCode,
    addressReferralCodes: state => state.referrals.addressReferralCodes,
    addressReferralCodesLoading: state => state.referrals.addressReferralCodesLoading,
    addressReferralHistoryLoading: state => state.referrals.addressReferralHistoryLoading,
    addWhileListLoading: state => state.referrals.addWhileListLoading,
    isHaveWhiteList: state => state.referrals.isHaveWhiteList,
    isFineWhiteListMint: state => state.referrals.isFineWhiteListMint,
    addressReferralHistory: state => state.referrals.addressReferralHistory,
    totalInterest: state => state.referrals.info.totalInterest,
    referralsCount: state => state.referrals.info.referralsCount,
    superTarget: state => state.referrals.info.superTarget,
    commission: state => state.referrals.info.commission,
    withdrawn: state => state.referrals.info.withdrawn,
    addWithdrawLoading: state => state.referrals.addWithdrawLoading,
    withdrawHistory: state => state.referrals.withdrawInfo[2].withdrawHistory,
    withdrawHistoryLoading: state => state.referrals.withdrawHistoryLoading,
    withdrawHistoryTotal: state => state.referrals.withdrawInfo[2].total,
    snapshootList: state => state.referrals.snapshootInfo[2].snapshootList,
    snapshootInfoLoading: state => state.referrals.snapshootInfo[2].loading,
    snapshootInfoTotal: state => state.referrals.snapshootInfo[2].total,
    wlMintState: state => state.nft.wlMintState,
    minRebateRatio: state => state.referrals.userRateInfo.minRebateRatio,
    maxRebateRatio: state => state.referrals.userRateInfo.maxRebateRatio,
    fixedTarget: state => state.referrals.userRateInfo.fixedTarget,
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isCanWithdraw() {
      return toBigInt(this.commission) - toBigInt(this.withdrawn) > 0n;
    },
    interestRate() {
      let res = PercentageMath.div(toBigInt(this.totalInterest), toBigInt(this.superTarget));
      if (res > 10000n) {
        return 100.00;
      }
      return (parseInt(res) / 100.0).toFixed(2);
    },
    subordinateRatio: state => {
      let ratio = state.referrals.myInfo.subordinateRatio;
      return (ratio / 100.0).toFixed(1);
    },
    supLevel: state => state.referrals.myInfo.supLevel,
    level: state => state.referrals.myInfo.level,
    interest: state => state.referrals.info.totalInterest,
    allTotalInterest: state => state.referrals.info.allTotalInterest,
    totalStake: state => state.referrals.info.totalStake,
    stfilBalance: state => state.referrals.myInfo.stfilBalance,
    targetRebates: state => state.referrals.myInfo.targetRebates,
    nextEpochShow: state => state.referrals.settlementInfo.nextEpochShow,
    isFinalSuperTarget: state => state.referrals.myInfo.isFinalSuperTarget,
    isSkipFinalSuperTarget: state => state.referrals.myInfo.isSkipFinalSuperTarget,
    superTargetStFilBalance: state => state.referrals.myInfo.superTargetStFilBalance,
    stFilBalance() {
      return this.$store.state.stFilBalance;
    },
    superTargetStFilBalanceIsReach() {
      return toBigInt(this.stFilBalance) >= toBigInt(this.superTargetStFilBalance);
    },
    highWlMintState: state => state.nft.highWlMintState,
    canHighWlMintState: state => state.nft.canHighWlMintState,
    isWaitHighWlMintState: state => state.nft.isWaitHighWlMintState
  })
};