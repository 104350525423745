export default {
  name: "NextBtnGroup",
  props: {
    showPre: {
      type: Boolean,
      default: true
    },
    showNext: {
      type: Boolean,
      default: true
    },
    needConnect: {
      type: Boolean,
      default: true
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    preFun() {
      this.$emit('pre');
    },
    nextFun() {
      this.$emit('next');
    }
  },
  computed: {
    isConnected() {
      return this.$store.state.isConnected;
    }
  }
};