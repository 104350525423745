import "core-js/modules/es.array.push.js";
import PageHead from '@/components/header/PageHead';
import { Search } from '@element-plus/icons-vue';
import SearchNode from '@/components/induction/SearchNode';
import NodeInfo from '@/components/induction/NodeInfo';
import NodeInfo2 from '@/components/induction/NodeInfo2';
import NodeInfo3 from '@/components/induction/NodeInfo3';
export default {
  name: "InductionPage",
  components: {
    PageHead,
    SearchNode,
    NodeInfo,
    NodeInfo2,
    NodeInfo3
  },
  data() {
    return {
      input1: '',
      search: Search,
      currentStep: 0,
      haveSp: false,
      showNextBtnGroup: true
    };
  },
  methods: {
    back() {
      this.$refs.searchNode.isLock = false;
      this.resetNodeRes();
    },
    searchNode({
      haveSp
    }) {
      this.haveSp = haveSp;
      this.$refs.nodeInfo.searchOk = true;
      if (!this.haveSp) {
        this.$refs.nodeInfo.showNext();
      } else {
        this.$refs.nodeInfo.hiddenNext();
      }
    },
    resetNodeRes() {
      this.currentStep = 0;
      this.haveSp = false;
      this.$refs.nodeInfo.searchOk = false;
      this.$refs.nodeInfo.hiddenNext();
      this.$store.commit('RESET_NODE_INFO');
    },
    viewNode() {
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    nextFun() {
      this.$refs.searchNode.isLock = true;
      this.currentStep++;
    },
    preFun() {
      this.currentStep--;
      if (this.currentStep === 0) {
        this.$refs.searchNode.isLock = false;
      }
    }
  },
  computed: {
    nodeId() {
      return this.$store.state.node.nodeInfo.id;
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};