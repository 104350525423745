import {fetchBalance} from "@wagmi/core";
import {isEmpty} from "@/utils/model";

let ledger = {
    state: {
        ledgerFileCoinSuper: undefined, // ledger 钱包质押使用
        accountList: []
    },
    getters: {},
    mutations: {
        SET_LEDGER_ACCOUNT_LIST(state,accountList) {
            state.accountList = accountList
        },
        SET_LEDGER_FILECOIN_SUPER(state, ledgerFileCoinSuper) {
            state.ledgerFileCoinSuper = ledgerFileCoinSuper
        },
    },
    actions: {
        async disLedgerConnect({commit}) {
            commit('SET_LEDGER_ACCOUNT_LIST', [])
            commit('SET_ISCONNECTED', false)
            commit('SET_BALANCE', 0)
            commit('SET_STFIL_BALANCE', 0n)
            commit('SET_WSTFIL_BALANCE', 0n)
            commit('SET_LEDGER_F1_ADDRESS', {f1Address: ''})
            commit('SET_ADDRESS', '')
            commit('SET_F0_ADDRESS', '')
            commit('INIT_FNS_NAME')
        },
        async disMetamaskFlaskConnect({commit}) {
            commit('SET_ISCONNECTED', false)
            commit('SET_BALANCE', 0)
            commit('SET_STFIL_BALANCE', 0n)
            commit('SET_WSTFIL_BALANCE', 0n)
            commit('SET_METAMASK_FLASK_F1_ADDRESS', {f1Address: ''})
            commit('SET_ADDRESS', '')
            commit('SET_F0_ADDRESS', '')
            commit('INIT_FNS_NAME')
        },
        async initByLedgerAccount({commit, dispatch, state}) {
            commit('INIT_FNS_NAME')
            let isConnected = state.ledgerFileCoinSuper.getConnected()
            let f0Address = state.ledgerFileCoinSuper.getF0Address()
            let address = state.ledgerFileCoinSuper.getEthAddress()
            let f1Address = state.ledgerFileCoinSuper.getAddress()
            commit('SET_LEDGER_F1_ADDRESS', {f1Address})
            commit('SET_ADDRESS', address)
            commit('SET_F0_ADDRESS', {f0Address})
            commit('SET_ISCONNECTED', {isConnected})
            if (!isEmpty(address)) {
                commit('SET_BALANCE_LOADING', {balanceLoading: true})
                const balance = await fetchBalance({
                    address: address,
                })
                commit('SET_BALANCE_LOADING', {balanceLoading: false})
                commit('SET_BALANCE', balance.value.toBigInt())
                dispatch('initStFilBalance', {address: address})
                dispatch('initWstFilBalance', {address: address})
                dispatch('initFnsName', {address: address})
            } else {
                commit('SET_BALANCE', 0n)
                commit('SET_STFIL_BALANCE', 0n)
                commit('SET_WSTFIL_BALANCE', 0n)
            }

        },
    },
    modules: {}
}

export default ledger
