import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41d15a2c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "row"
};
const _hoisted_2 = {
  class: "col-12"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = {
  key: 0,
  class: "col-12 d-flex align-items-center mb-2"
};
const _hoisted_5 = {
  class: "ml-1"
};
const _hoisted_6 = {
  class: "col-lg-6 col-12 d-flex justify-content-between align-items-center mb-2"
};
const _hoisted_7 = {
  class: "d-flex align-items-center fw-extra-bold fsi-30"
};
const _hoisted_8 = {
  class: "col-lg-6 col-12"
};
const _hoisted_9 = {
  class: "d-flex align-items-center align-content-center justify-content-lg-end justify-content-start h-100 mb-2 mb-lg-0 ml-2 ml-lg-0"
};
const _hoisted_10 = {
  class: "col-12 d-flex"
};
const _hoisted_11 = {
  class: "pl-2 fw-medium fsi-14"
};
const _hoisted_12 = {
  class: "content-page-head",
  ref: "contentPageHead"
};
const _hoisted_13 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowLeftBold = _resolveComponent("ArrowLeftBold");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.showReturn ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
    class: "fw-bold fsi-20 cursor-pointer return d-flex align-items-center",
    style: {
      "line-height": "1.4"
    },
    onClick: _cache[0] || (_cache[0] = (...args) => $options.back && $options.back(...args))
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_ArrowLeftBold)]),
    _: 1
  }), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('common.return')), 1)])])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "icon", {}, undefined, true), _renderSlot(_ctx.$slots, "title", {}, undefined, true)])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_renderSlot(_ctx.$slots, "title-right-tool", {}, undefined, true)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("span", {
    innerHTML: $data.showContent
  }, null, 8, _hoisted_13), $props.showMoreFlag ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "link-text ml-2",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.moreFlag && $options.moreFlag(...args))
  }, "..." + _toDisplayString(_ctx.$t('common.more')), 1)) : _createCommentVNode("", true), $data.showMore ? (_openBlock(), _createElementBlock("span", {
    key: 1,
    class: "link-text ml-2",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.more && $options.more(...args))
  }, "..." + _toDisplayString(_ctx.$t('common.more')), 1)) : _createCommentVNode("", true)], 512), _renderSlot(_ctx.$slots, "tool", {}, undefined, true)])])]);
}