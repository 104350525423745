import "core-js/modules/es.array.push.js";
import NextBtnGroup from './NextBtnGroup';
import { formatBit, externalFormatFilPrice, formatPrice, toBigInt } from '@/utils/NumU';
import AdCard2 from "@/components/tip/AdCard2";
import Tooltip from '@/components/tip/Tooltip';
import { mapState } from "vuex";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { formatUnits } from "ethers/lib/utils";
import { isEmpty } from "@/utils/model";
export default {
  name: "NodeInfo",
  components: {
    AdCard2,
    NextBtnGroup,
    Tooltip
  },
  props: {
    haveSp: {
      type: Boolean
    },
    poolAddress: {
      type: String,
      default: ''
    },
    isStakingPool: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showNextFlag: false,
      searchOk: false
    };
  },
  methods: {
    next() {
      this.$emit('next');
    },
    formatPrice,
    externalFormatFilPrice,
    formatBit,
    formatUnits,
    showNext() {
      this.showNextFlag = true;
    },
    hiddenNext() {
      this.showNextFlag = false;
    },
    viewNode() {
      if (this.isStakingPool) {
        this.$router.push({
          path: '/farm/provider/' + this.nodeInfo.id.toString().substr(2)
        });
        return;
      }
      if (isEmpty(this.poolAddress)) {
        this.$router.push({
          path: '/farm/provider/' + this.nodeInfo.id.toString().substr(2)
        });
      } else {
        this.$router.push({
          path: '/farm/lendingPool/provider/' + this.poolAddress + '/' + this.nodeInfo.id.toString().substr(2)
        });
      }
    }
  },
  computed: mapState({
    positionValue() {
      return toBigInt(this.currentNode.position_value);
    },
    maxLeverage() {
      return toBigInt(this.currentNode.max_leverage);
    },
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    equityValue() {
      return this.positionValue - this.debtValue;
    },
    safetyBuffer() {
      return toBigInt(this.currentNode.safetyBuffer);
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    debtRatio() {
      if (this.debtValue === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(this.debtValue, this.positionValue);
    },
    liquidationThreshold() {
      return toBigInt(this.currentNode.liquidation_threshold);
    },
    maxDebtRatio() {
      return PercentageMath.div(toBigInt(this.currentNode.max_leverage) - toBigInt(10000), toBigInt(this.currentNode.max_leverage));
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    loading() {
      return this.$store.state.node.searchLoading;
    }
  })
};