import PageHead from "@/components/header/PageHead";
import OffBoardingOwner from '@/components/offboarding/OffBoardingOwner';
import { getPrefix } from "@/utils/common";
export default {
  name: "OffboardingPage",
  components: {
    PageHead,
    OffBoardingOwner
  },
  data() {
    return {
      nodeId: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.nodeId = getPrefix() + this.$route.params.provider;
    }
  },
  computed: {
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};