import Subordinate from './Subordinate';
import Superior from './Superior';
import { mapState } from "vuex";
import { blockU } from "@/utils/common";
export default {
  name: "ReferralsPage",
  components: {
    Subordinate,
    Superior
  },
  data() {
    return {
      activeName: 'Superior',
      timerFlag: true
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    this.timerFlag = false;
  },
  methods: {
    more() {
      window.open(`${this.docsUrlPrefix}referral-program/introduction-to-referral`);
    },
    async init() {
      await this.$store.dispatch('initSettlementInfo');
      this.initNextEpochShow();
    },
    initNextEpochShow() {
      this.initNextEpochTimer = setTimeout(() => {
        if (!this.timerFlag) {
          return;
        }
        if (!this._initNextEpochShow()) {
          return;
        }
        this.initNextEpochShow();
      }, 1000);
    },
    _initNextEpochShow() {
      if (this.settlementIng) {
        this.$store.commit('SET_SETTLEMENT_NEXT_EPOCH_SHOW', {
          nextEpochShow: '结算中..'
        });
        return false;
      }
      if (this.nextEpoch >= 0) {
        this.$store.commit('SET_SETTLEMENT_NEXT_EPOCH_SHOW', {
          nextEpochShow: blockU.formatSecondsFull(Math.floor(this.nextEpoch / 1000))
        });
        this.$store.commit('SET_SETTLEMENT_NEXT_EPOCH');
        return true;
      } else {
        this.$store.commit('SET_SETTLEMENT_NEXT_EPOCH_SHOW', {
          nextEpochShow: '结算中..'
        });
        return false;
      }
    }
  },
  computed: mapState({
    nextEpoch: state => state.referrals.settlementInfo.nextEpoch,
    settlementIng: state => state.referrals.settlementInfo.settlementIng,
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  })
};