export default {
  name: "ScrollNumber",
  props: {
    customSignStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    prefixStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    prefix: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    },
    unitMargin: {
      type: String,
      default: '0 0 0 5px'
    },
    unitStyle: {
      type: Object,
      default: () => {
        return {
          margin: '0 0 0 5px'
        };
      }
    },
    number: {
      type: [Number, String],
      required: true,
      default: 0
    },
    h: {
      type: Number,
      // 数字高度
      default: 24
    },
    color: {
      type: [String],
      default: '#101B52'
    },
    customStyle: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    number() {
      this.numberToArray = this.number.toString().split('');
    }
  },
  data() {
    return {
      getBindValue: undefined,
      numberBox: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','],
      symbolBox: ['.', ':', ' ', ','],
      ignoreStr: ['.', ' ', '-', ':', ',', '1'],
      numberToArray: []
    };
  },
  created() {
    this.numberToArray = this.number.toString().replaceAll(/\d/g, "0").split("");
  },
  mounted() {
    let lady = 100;
    setTimeout(() => {
      this.$nextTick(() => {
        this.numberToArray = this.number.toString().split('');
      });
    }, lady);
  },
  methods: {
    animateStyle(item) {
      const index = item.trim().length === 0 ? 9999 : this.numberBox.findIndex(i => item === i);
      return {
        transform: `translate(0,-${index * this.h}px)`
      };
    }
  },
  computed: {
    numStyle() {
      return {
        ...this.customStyle,
        height: `${this.h}px`,
        fontSize: `${this.h}px`
      };
    },
    timingFlag() {
      return this.$store.state.timingFlag;
    }
  }
};