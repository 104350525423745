import "core-js/modules/es.array.push.js";
import Loading from '@/components/Loading';
import Empty from '@/components/Empty';
import { proposalStatusMap } from "@/utils/model";
export default {
  name: 'VotePage',
  components: {
    Loading,
    Empty
  },
  data() {
    return {
      loading: false,
      proposalStatusMap: proposalStatusMap,
      type: [{
        label: this.$t('vote.hexin'),
        value: 'core'
      }, {
        label: this.$t('vote.shequ'),
        value: 'community'
      }, {
        label: this.$t('vote.suoyou'),
        value: 'all'
      }],
      state: [{
        label: this.$t('vote.lijietoupiao'),
        value: 'immediately'
      }, {
        label: this.$t('vote.jijiangkaishi'),
        value: 'upcoming'
      }, {
        label: this.$t('vote.yiguanbi'),
        value: 'close'
      }],
      check: {
        type: 'core',
        status: 'immediately',
        proposal: {}
      },
      detail: {
        show: false
      }
    };
  },
  watch: {
    '$i18n.locale'() {
      this.type = [{
        label: this.$t('vote.hexin'),
        value: 'core'
      }, {
        label: this.$t('vote.shequ'),
        value: 'community'
      }, {
        label: this.$t('vote.suoyou'),
        value: 'all'
      }];
      this.state = [{
        label: this.$t('vote.lijietoupiao'),
        value: 'immediately'
      }, {
        label: this.$t('vote.jijiangkaishi'),
        value: 'upcoming'
      }, {
        label: this.$t('vote.yiguanbi'),
        value: 'close'
      }];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initProposal();
    },
    async initProposal() {
      this.loading = true;
      await this.$store.dispatch('getProposalList', {
        type: this.check.type === 'all' ? '' : this.check.type,
        status: this.check.status
      });
      this.loading = false;
    },
    showDetail(proposal) {
      this.$store.commit('setProposalDetail', proposal);
      this.$router.push({
        path: '/vote/proposal/' + proposal.id
      });
    },
    checkType(val) {
      this.check.type = val;
      this.initProposal();
    },
    checkState(val) {
      this.check.status = val;
      this.initProposal();
    }
  },
  computed: {
    proposalData() {
      return this.$store.state.proposal.data;
    }
  }
};