import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ce840e46"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-class",
  style: {
    "display": "flex",
    "align-items": "center",
    "user-select": "none"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    style: _normalizeStyle([{
      ...$props.prefixStyle,
      fontSize: `${$props.h}px`
    }, {
      "margin-right": "2px"
    }])
  }, _toDisplayString($props.prefix), 5), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.numberToArray, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(['number', {
        sign: $data.ignoreStr.includes(item)
      }, {
        sign2: item === '1'
      }]),
      style: _normalizeStyle({
        ...$options.numStyle,
        ...($data.ignoreStr.includes(item) ? $props.customSignStyle : {})
      })
    }, [_createElementVNode("div", {
      class: "scroll-container",
      style: _normalizeStyle({
        ...$options.animateStyle(item)
      })
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.numberBox, (v, i) => {
      return _openBlock(), _createElementBlock("div", {
        key: i,
        class: "scroll-single",
        style: _normalizeStyle({
          color: $props.color,
          height: `${$props.h}px`
        })
      }, _toDisplayString(v), 5);
    }), 128))], 4)], 6);
  }), 128)), _createElementVNode("div", {
    style: _normalizeStyle({
      ...$props.unitStyle,
      fontSize: `${$props.h}px`
    })
  }, _toDisplayString($props.unit), 5)]);
}