import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_skeleton = _resolveComponent("el-skeleton");
  return _openBlock(), _createBlock(_component_el_skeleton, {
    loading: $data.loading,
    animated: ""
  }, {
    template: _withCtx(() => [_renderSlot(_ctx.$slots, "template")]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["loading"]);
}