import "core-js/modules/es.array.push.js";
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { ElIcon } from 'element-plus';
import { isEmpty } from "@/utils/model";
export default {
  name: "PageHead",
  components: {
    ArrowLeftBold,
    ElIcon
  },
  props: {
    showReturn: {
      type: Boolean,
      default: false
    },
    backPath: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    maxLine: {
      type: Number,
      default: 4
    },
    showMoreFlag: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    content() {
      this.bakContent = this.content;
      this.showContent = this.content;
      this.$nextTick(() => {
        this.contentComputed();
      });
    }
  },
  mounted() {
    this.bakContent = this.content;
    this.showContent = this.content;
    this.$nextTick(() => {
      this.contentComputed();
    });
  },
  data() {
    return {
      showMore: false,
      textHeight: '',
      bakContent: '',
      showContent: ''
    };
  },
  methods: {
    moreFlag() {
      this.$emit('more');
    },
    more() {
      this.showContent = this.bakContent;
      this.showMore = false;
    },
    contentComputed() {
      let contentPageHead = this.$refs['contentPageHead'];
      let height = contentPageHead.clientHeight;
      let line = Math.floor(height / 23);
      if (line > this.maxLine) {
        let len = this.bakContent.toString().length;
        let size = Math.floor(len / line * this.maxLine);
        let end = size;
        for (let i = size; i < len; i++) {
          if (this.bakContent.charAt(i) === ' ') {
            end = i;
            break;
          }
        }
        this.showContent = this.bakContent.substr(0, end);
        this.showMore = true;
      } else {
        this.showContent = this.bakContent;
        this.showMore = false;
      }
    },
    back() {
      if (!isEmpty(this.backPath)) {
        this.$router.push({
          path: this.backPath
        });
        return;
      }
      if (Boolean(this.$store.state.preRouterPath) && Boolean(this.$store.state.preRouterPath.href)) {
        this.$router.back();
      } else {
        this.$router.push({
          path: '/farm'
        });
      }
    }
  }
};