import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center justify-content-end"
};
const _hoisted_2 = {
  class: "mr-1 fw-bold",
  style: {
    "color": "#db3636"
  }
};
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-items-center justify-content-end"
};
const _hoisted_4 = {
  class: "mr-1 fw-bold",
  style: {
    "color": "#db3636"
  }
};
const _hoisted_5 = {
  style: {
    "color": "#e00c2c"
  }
};
const _hoisted_6 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Top = _resolveComponent("Top");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_Bottom = _resolveComponent("Bottom");
  return _openBlock(), _createElementBlock("div", null, [$props.oldValue < $props.newValue ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.prefix), 1), $props.showIcon ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    color: "#00CE92",
    style: {
      "margin-right": "5px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_Top)]),
    _: 1
  })) : _createCommentVNode("", true), _createElementVNode("div", {
    style: _normalizeStyle(`color: ${$props.prefix === '' ? '#00CE92' : '#db3636'}`)
  }, _toDisplayString($options.formatPrice($options.formatUnits($props.newValue, $props.units), $props.toFixed)) + " " + _toDisplayString($props.suffix), 5)])) : _createCommentVNode("", true), $props.oldValue > $props.newValue ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($props.prefix), 1), $props.showIcon ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    color: "#db3636",
    style: {
      "margin-right": "5px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_Bottom)]),
    _: 1
  })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, _toDisplayString($options.formatPrice($options.formatUnits($props.newValue, $props.units), $props.toFixed)) + " " + _toDisplayString($props.suffix), 1)])) : _createCommentVNode("", true), $props.oldValue === $props.newValue ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($options.formatPrice($options.formatUnits($props.newValue, $props.units), $props.toFixed)) + " " + _toDisplayString($props.suffix), 1)) : _createCommentVNode("", true)]);
}