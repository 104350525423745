export default {
  name: "AdCard2",
  props: {
    effect: {
      type: String,
      default: 'light'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'ok'
    },
    iconWidth: {
      type: String,
      default: '75px'
    },
    content: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    mainBtnText: {
      type: String,
      default: ''
    }
  },
  methods: {
    btnClick() {
      this.$emit('btnClick');
    },
    mainBtnClick() {
      this.$emit('mainBtnClick');
    }
  }
};