import { ArrowDown } from '@element-plus/icons-vue';
import { isEmpty } from "@/utils/model";
import { SuccessFilled } from '@element-plus/icons-vue';
import { mapState } from "vuex";
export default {
  name: "IFooter",
  components: {
    ArrowDown,
    SuccessFilled
  },
  watch: {
    '$i18n.locale'() {
      this.locale = window.localStorage.getItem('localeLanguage');
      this.languageValue = this.locale;
    }
  },
  data() {
    return {
      emailAddress: '',
      languageValue: 'en',
      netModelValue: '',
      subLoading: false,
      subOk: false,
      locale: window.localStorage.getItem('localeLanguage'),
      languageOptions: [{
        value: 'en',
        label: 'English',
        disabled: false
      }, {
        value: 'zh',
        label: '中文',
        disabled: false
      }],
      netModeValueOption: [{
        value: 'main',
        label: 'Mainnet'
      }, {
        value: 'test',
        label: 'Calibration'
      }]
    };
  },
  created() {
    this.initLanguage();
    console.log(document.getElementById("emailForm"));
    this.netModelValue = process.env.VUE_APP_CURRENTMODE === 'main' ? 'Mainnet' : 'Calibration';
  },
  computed: mapState({
    referralCode: state => {
      if (state.evn === 'test') {
        return state.stakeInfo.referralCode;
      } else {
        return '';
      }
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }),
  methods: {
    netModeChange(val) {
      if (val !== process.env.VUE_APP_CURRENTMODE) {
        if (val === 'test') {
          window.location.href = 'https://calibration-app.stfil.io';
        } else {
          window.location.href = 'https://app.stfil.io';
        }
      }
    },
    handleSubmit(e) {
      this.subOk = false;
      this.subLoading = true;
      const myForm = e.target;
      const formData = new FormData(myForm);
      this.$store.dispatch('subscribeEmail', {
        email: formData.get("email")
      }).then(() => {
        this.subOk = true;
      }).finally(() => {
        this.subLoading = false;
      });
    },
    changeLanguage(val) {
      let {
        value
      } = val;
      this.$i18n.locale = value;
      window.localStorage.setItem('localeLanguage', value);
    },
    getLanguagetLabel(val) {
      let lan = this.languageOptions.find(item => item.value === val);
      if (lan) {
        return lan.label;
      }
      return 'English';
    },
    initLanguage() {
      if (isEmpty(this.locale)) {
        this.locale = 'en';
      }
      this.languageValue = this.locale;
      this.$i18n.locale = this.languageValue;
    }
  }
};