import "core-js/modules/es.array.push.js";
import Tip from '@/components/tip/Tip';
import { formatBit, formatPrice, formatWalletFilPrice, toBigInt } from '@/utils/NumU';
import { formatUnits } from "ethers/lib.esm/utils";
import { gotoAddress } from "@/utils/common";
import { mapState } from "vuex";
import { WadRayMath } from "@/utils/contracts/WadRayMath";
import { isEmpty } from "@/utils/model";
import SubPoolSearchNode from './SubPoolSearchNode';
import PercentageMath from "@/utils/contracts/PercentageMath";
export default {
  name: "SubPoolNodeInfo2",
  components: {
    Tip,
    SubPoolSearchNode
  },
  data() {
    return {
      showNext: false,
      radio1MapVar: {
        'Owner': 'ownerInfo',
        'Beneficiary': 'beneficiaryInfo'
      },
      searchOk: false,
      haveSp: false,
      isStakingPool: false
    };
  },
  props: {
    initCurrentPoolInfoLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    address() {
      this.init();
    }
  },
  created() {
    this.$store.commit('SET_NODE_ROLE', {
      nodeRole: 'Owner'
    });
    this.init();
    console.log('this.currentPoolInfo', this.currentPoolInfo);
  },
  methods: {
    init() {},
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    formatBit,
    formatPrice,
    formatUnits,
    gotoAddress,
    formatWalletFilPrice,
    calcFree() {},
    next() {
      this.$emit('next');
    },
    pre() {
      this.$emit('pre');
    },
    searchResult({
      haveSp,
      isStakingPool
    }) {
      this.haveSp = haveSp;
      this.isStakingPool = isStakingPool;
      this.searchOk = true;
    },
    searchResultFail() {
      this.searchOk = false;
    },
    viewNode() {
      if (this.isStakingPool) {
        this.$router.push({
          path: '/farm/provider/' + this.nodeInfo.id.toString().substr(2)
        });
        return;
      }
      if (isEmpty(this.currentPoolAddress)) {
        this.$router.push({
          path: '/farm/provider/' + this.nodeInfo.id.toString().substr(2)
        });
      } else {
        this.$router.push({
          path: '/farm/lendingPool/provider/' + this.currentPoolAddress + '/' + this.nodeInfo.id.toString().substr(2)
        });
      }
    }
  },
  computed: mapState({
    isCanNext() {
      return this.nodeInfo.owner === this.nodeInfo.beneficiary && this.nodeCount < this.maxNodeCount;
    },
    isOnlyNewNodeCanNext() {
      if (this.onlyAcceptsNewNodes) {
        return this.positionValue < 1000000000000000000n;
      } else {
        return true;
      }
    },
    onlyAcceptsNewNodes() {
      return this.currentPoolInfo.onlyAcceptsNewNodes;
    },
    showIsSameAdminAndAddress() {
      if (isEmpty(this.address) || isEmpty(this.poolAdmin)) {
        return false;
      }
      return this.address.toString().toLowerCase() === this.poolAdmin.toString().toLowerCase();
    },
    availableBorrowingAmount() {
      return PercentageMath.mul(this.positionValue, this.maxSealLoadLeverage / WadRayMath.WAD_2 - 10000n);
    },
    availableWithdrawLoadAmount() {
      return PercentageMath.mul(this.positionValue, this.maxWithdrawLoadLeverage / WadRayMath.WAD_2 - 10000n);
    },
    currentPoolInfo: state => state.subpool.currentPool,
    poolPosition() {
      return this.currentPoolInfo.totalPosition;
    },
    poolDebt() {
      return this.currentPoolInfo.totalDebt;
    },
    maxSealLoadLeverage() {
      return this.currentPoolInfo.maxSealLoadLeverage;
    },
    maxLeverage() {
      return toBigInt(this.currentPoolInfo.max_leverage);
    },
    poolAdmin() {
      return this.currentPoolInfo.admin;
    },
    maxWithdrawLoadLeverage() {
      return toBigInt(this.currentPoolInfo.maxWithdrawLoadLeverage);
    },
    currentPoolAddress: state => state.subpool.currentPool.address.eth,
    positionValue() {
      return toBigInt(this.nodeInfo.positionValueBigInt);
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    nodeCount() {
      return this.currentPoolInfo.nodeCount;
    },
    maxNodeCount() {
      return 20;
    },
    loading() {
      return this.$store.state.node.searchLoading;
    },
    radio1() {
      return this.$store.state.node.nodeRole;
    },
    poolInfoLoading() {
      return this.initCurrentPoolInfoLoading;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  })
};