import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import "core-js/modules/es.array.push.js";
import { ElStep, ElSteps } from 'element-plus';
import { CircleCheckFilled } from '@element-plus/icons-vue';
import Tip from '@/components/tip/Tip';
import AdCard2 from '@/components/tip/AdCard2';
import { readContract } from '@wagmi/core';
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { contracts } from "@/utils/contracts/config";
import { BigNumber } from "ethers";
import { loutsRpc, retryFun, walletProvider } from "@/utils/LoutsRpc";
import { ChainHead, ChainReadObj, StateAccountKey, StateGetActor } from "@/utils/FilecoinApi";
import { decode as dagCborDecode } from "@ipld/dag-cbor";
import { newAddressFromBytes } from "@/utils/contracts/singer/filecoin_utils";
import { encode as addressToString } from "@glif/filecoin-address";
import Singer from '@/components/induction/singer/Singer';
import { toBigInt } from "@/utils/NumU";
import { gotoAddress, NotificationErr } from "@/utils/common";
import { NodeConfiguration } from "@/utils/contracts/NodeConfiguration";
export default {
  name: "OffBoardingStep1",
  components: {
    ElStep,
    ElSteps,
    Tip,
    AdCard2,
    Singer
  },
  data() {
    return {
      canOffboarding: true,
      haveGuaranteeNodeId: false,
      radio1: 'Node Owner',
      CircleCheckFilled,
      active: 1,
      isConnected: false,
      connectErr: false,
      sendProposal: false,
      approvedProposalRes: false,
      inputs: {
        input1: 'f01234',
        input2: 0,
        input3: 10101
      },
      radio2: 'Content',
      singerTables: [],
      contractParams: [],
      contractMethod: 'undelegateOwner',
      changeAddress: '',
      isActive: false,
      // 判断是否执行了第一步
      isFineOffBoard: false,
      // 判断是否真的离职了
      showNextStepBtn: false
    };
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getNode();
      if (!this.canOffboarding) {
        return;
      }
      try {
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.evn === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    showNextStepBtnFun() {
      this.showNextStepBtn = true;
    },
    initActorState() {
      let networkPrefix = this.nodeId[0];
      // owner 和受益人 地址
      loutsRpc(StateGetActor(this.nodeInfo.newOwner)).then(res => {
        loutsRpc(ChainReadObj(res.result.Head)).then(async res => {
          let stateRawbytes = Uint8Array.from(Buffer.from(res.result, 'base64'));
          let msgSigState = dagCborDecode(stateRawbytes);
          // 有更简单的直接调用api接口得方式
          let signersRawbytes = msgSigState[0];
          if (Array.isArray(signersRawbytes)) {
            let head = loutsRpc(ChainHead());
            this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
              singerMultipleThreshold: signersRawbytes.length
            });
            this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
              singerMultipleNum: msgSigState[1]
            });
            this.singerTables = [];
            for (let i = 0; i < signersRawbytes.length; i++) {
              let signerBytes = signersRawbytes[i];
              let signerIdAddress = newAddressFromBytes(signerBytes);
              let idAddressStr = addressToString(networkPrefix, signerIdAddress);
              let nonIdAddressStr = await loutsRpc(StateAccountKey(idAddressStr, head.Cids)); // 测试网用t, 主网用f
              this.singerTables.push({
                isOk: false,
                isCheck: false,
                address: nonIdAddressStr.result,
                id: idAddressStr,
                index: i
              });
            }
            this.$store.commit('SET_SINGER_MULTIPLE', {
              singerMultiple: true
            });
          } else {
            this.$store.commit('SET_SINGER_MULTIPLE', {
              singerMultiple: false
            });
          }
          setTimeout(() => {
            if (!this.canOffboarding) {
              return;
            }
            if (this.isActive) {
              this.contractParams = [];
              this.contractParams.push(parseInt(this.nodeInfo.id.toString().substr(2)));
              this.$refs['singer'].init();
            } else {
              this.sendProposalFun();
            }
          }, 100);
        });
      });
    },
    async getNode() {
      const data = await readContract({
        address: contracts[this.evn].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [this.nodeId.toString().substr(2)]
      });
      let _nodeInfo = {
        id: '',
        positionValue: 0,
        availableBalance: 0,
        lockedRewards: 0,
        pledge: 0,
        adjustedPower: 0,
        rawBytePower: 0,
        sectorCount: 0,
        beneficiary: '',
        owner: '',
        sectorSize: 0,
        active: 0,
        faulty: 0,
        live: 0,
        adjustedPowerRate: 0,
        ownerAddress: '',
        beneficiaryAddress: '',
        ownerInfo: {
          availableBorrowingAmount: 0,
          maxDebtRatio: 0,
          maximumLeverage: 0,
          LiqThreshold: 0
        },
        beneficiaryInfo: {
          availableBorrowingAmount: 0,
          maxDebtRatio: 0,
          maximumLeverage: 0,
          LiqThreshold: 0
        }
      };
      console.log('data', data);
      let _guaranteeNodeId = BigNumber.from(data[4]).toBigInt() & toBigInt("0xFFFFFFFFFFF");
      if (_guaranteeNodeId !== 0n) {
        this.canOffboarding = false;
        this.haveGuaranteeNodeId = true;
        return;
      }
      let configuration = data[0]['configuration']['data'];
      let nodeConfiguration = new NodeConfiguration(configuration);
      // eslint-disable-next-line no-undef
      let actorId = (BigNumber.from(data[0].owner).toBigInt() & BigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
      if (data[0].safetyBuffer.toBigInt() !== 0n) {
        this.canOffboarding = false;
        return;
      }
      this.isActive = nodeConfiguration.isActive();
      let minerInfo = undefined;
      console.log('nodeConfiguration', this.isActive, data[0].safetyBuffer.toBigInt(), actorId);
      if (!this.isActive) {
        minerInfo = await retryFun(() => walletProvider.minerInfo(this.nodeId.toString()), 3);
        let nowOwner = minerInfo.Owner.toString().substr(2);
        if (actorId === '0' || nowOwner === actorId || nowOwner !== contracts[this.evn].StakingPool.id.substr(2)) {
          this.isFineOffBoard = true;
          setTimeout(() => {
            this.$router.replace({
              path: '/farm/provider/' + this.nodeId.toString().substr(2),
              query: {
                isActive: false
              }
            });
          }, 1500);
        } else {
          this.isFineOffBoard = false;
        }
      }
      // eslint-disable-next-line no-undef
      _nodeInfo.newOwner = this.getNetworkPrefix() + '0' + (BigNumber.from(data[0].owner).toBigInt() & BigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
      _nodeInfo.id = this.nodeId;
      let role = data[0].role;
      if (role === 1) {
        this.contractMethod = 'undelegateOwner';
        _nodeInfo.owner = _nodeInfo.newOwner;
        this.changeAddress = _nodeInfo.newOwner;
      } else {
        if (!minerInfo) {
          minerInfo = await retryFun(() => walletProvider.minerInfo(this.nodeId.toString()), 3);
        }
        let nowOwner = minerInfo.Owner.toString().substr(2);
        _nodeInfo.newOwner = this.getNetworkPrefix() + '0' + nowOwner;
        this.contractMethod = 'undelegateBeneficiary';
        _nodeInfo.beneficiary = _nodeInfo.newOwner;
        this.changeAddress = _nodeInfo.newOwner;
      }
      this.$store.commit('SET_NODE_ROLE', {
        nodeRole: role === 1 ? 'Owner' : 'Beneficiary'
      });
      this.$store.commit('SET_NODE_INFO', _nodeInfo);
    },
    pushProposalNext() {
      this.active++;
    },
    connectWallet() {
      this.isConnected = true;
    },
    connectWalletErr() {
      this.connectErr = true;
      this.isConnected = true;
    },
    sendProposalFun() {
      this.showNextStepBtn = false;
      this.sendProposal = true;
      this.active++;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    approvedProposal() {
      this.active++;
    },
    approvedProposal2() {
      this.approvedProposalRes = true;
    },
    gotoAddress,
    unBindNode() {
      this.$router.push({
        path: '/farm/unCollateralNode/' + this.nodeId.toString().substr(2)
      });
    }
  },
  computed: {
    evn() {
      return this.$store.state.evn;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    nodeRole: {
      get() {
        return this.$store.state.node.nodeRole;
      },
      set(nodeRole) {
        this.$store.commit('SET_NODE_ROLE', {
          nodeRole
        });
      }
    },
    singerMultiple() {
      return this.$store.state.node.singerMultiple;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    }
  }
};