import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "row"
};
const _hoisted_2 = {
  class: "col-12 mb-2 mt-4"
};
const _hoisted_3 = {
  class: "i-layer"
};
const _hoisted_4 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_5 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_6 = {
  class: "fw-medium fs-12 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_7 = {
  class: "mr-1 fw-medium fsi-14"
};
const _hoisted_8 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_9 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_10 = {
  class: "fw-medium fs-12 d-flex flex-column justify-content-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_11 = {
  class: "flex-center flex-column pl-4 pr-4 pt-4 pb-4 text-center",
  style: {
    "background-color": "rgb(229, 249, 230)",
    "border-radius": "5px",
    "color": "rgb(0, 123, 57)"
  }
};
const _hoisted_12 = {
  class: "mr-1 fw-medium fsi-14"
};
const _hoisted_13 = {
  class: "fw-bold"
};
const _hoisted_14 = {
  class: "fw-bold"
};
const _hoisted_15 = {
  class: "mr-1 fw-medium fsi-14"
};
const _hoisted_16 = {
  class: "fw-bold"
};
const _hoisted_17 = {
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_18 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_19 = {
  class: "fw-medium fs-12 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_20 = {
  class: "mr-1 fw-medium fsi-14"
};
const _hoisted_21 = {
  key: 2,
  class: "fw-medium fsi-14 d-flex align-items-center mb-2",
  style: {
    "line-height": "1.6"
  }
};
const _hoisted_22 = {
  class: "mr-1 fw-bold fsi-16"
};
const _hoisted_23 = {
  key: 3,
  class: "w-100 p-2 pt-4 pb-4 flex-center mt-3",
  style: {
    "background-color": "#FC6D6F",
    "border-radius": "5px"
  }
};
const _hoisted_24 = {
  style: {
    "transform": "translateY(2px)",
    "color": "rgb(102, 0, 0)"
  },
  class: "mr-2"
};
const _hoisted_25 = {
  style: {
    "color": "rgb(102, 0, 0)"
  }
};
const _hoisted_26 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WarnTriangleFilled = _resolveComponent("WarnTriangleFilled");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [!$data.ledgerIsConnect ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('ledgerSp.Connect with Ledger Device')), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('ledgerSp.Please unlock your Ledger device and open the Filecoin App')), 1)])], 64)) : _createCommentVNode("", true), $data.ledgerIsConnect ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$data.waitMessageSignLoading ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('ledgerSp.Awaiting confirmation')), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_12, [_createTextVNode(_toDisplayString(_ctx.$t('ledgerSp.To complete the transaction please review the recipient and amount on your')) + " ", 1), _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('ledgerSp.Ledger Device')), 1), _createTextVNode(_toDisplayString(_ctx.$t('ledgerSp.If the details match what you see in select')), 1), _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('ledgerSp.Approve')), 1), _createTextVNode(". ")])]), _createElementVNode("div", null, [_createElementVNode("span", _hoisted_15, [_createTextVNode(_toDisplayString(_ctx.$t('ledgerSp.Remember')) + ": ", 1), _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('ledgerSp.Once approved the message is submitted transactions are final')), 1)])])])])], 64)) : _createCommentVNode("", true), !$data.waitMessageSignLoading && !$data.ledgerIsFail ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("div", _hoisted_17, [_createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('ledgerSp.Sign Message')), 1)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_20, _toDisplayString($props.waitPushResult ? _ctx.$t('ledgerSp.Please do not close this page Waiting for message to link up') : _ctx.$t('ledgerSp.Sign the message with your Ledger by clicking the below')), 1)])])], 64)) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), $data.ledgerIsFail ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('ledgerSp.Something went wrong')), 1)])) : _createCommentVNode("", true), $data.ledgerIsFail ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("span", _hoisted_24, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_WarnTriangleFilled)]),
    _: 1
  })]), _createElementVNode("span", _hoisted_25, _toDisplayString($data.errMsg), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_26, [$data.searchAccountLoading ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    class: "btn btn-primary w-100 i-button",
    loading: true
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('ledgerSp.Search Account')), 1)]),
    _: 1
  })) : _createCommentVNode("", true), !$data.searchAccountLoading ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [!$props.waitPushResult ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [!$data.ledgerIsConnect ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    class: "btn btn-primary w-100 i-button",
    onClick: $options.connect
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('ledgerSp.Connect Ledger')), 1)]),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true), $data.ledgerIsConnect ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    loading: $data.waitMessageSignLoading,
    class: "btn btn-primary w-100 i-button",
    onClick: $options.signMessage
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.waitMessageSignLoading ? _ctx.$t('ledgerSp.Wait Approve') : _ctx.$t('ledgerSp.SignMessage')), 1)]),
    _: 1
  }, 8, ["loading", "onClick"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), $props.waitPushResult ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    loading: true,
    class: "btn btn-primary w-100 i-button"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('ledgerSp.Waiting up chain')), 1)]),
    _: 1
  })) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)])])])]);
}