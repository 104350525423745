import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  style: {
    "line-height": "0.9"
  },
  class: "cursor-pointer d-inline-block"
};
const _hoisted_2 = {
  style: {
    "width": "250px"
  }
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_QuestionFilled = _resolveComponent("QuestionFilled");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tooltip, {
    trigger: "click",
    class: "box-item",
    effect: $props.effect,
    placement: "top"
  }, {
    content: _withCtx(() => [_createElementVNode("div", _hoisted_2, [$props.html !== '' ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      innerHTML: $props.html
    }, null, 8, _hoisted_3)) : _createCommentVNode("", true), $props.html === '' ? _renderSlot(_ctx.$slots, "default", {
      key: 1
    }) : _createCommentVNode("", true)])]),
    default: _withCtx(() => [_createElementVNode("span", null, [_createVNode(_component_el_icon, {
      color: "#aaaaaa"
    }, {
      default: _withCtx(() => [_createVNode(_component_QuestionFilled)]),
      _: 1
    })])]),
    _: 3
  }, 8, ["effect"])]);
}