import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center justify-content-end"
};
const _hoisted_2 = {
  style: {
    "color": "#00CE92"
  }
};
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-items-center justify-content-end"
};
const _hoisted_4 = {
  style: {
    "color": "#db3636"
  }
};
const _hoisted_5 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Top = _resolveComponent("Top");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_Bottom = _resolveComponent("Bottom");
  return _openBlock(), _createElementBlock("div", null, [$props.oldValue < $props.newValue ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_icon, {
    color: "#00CE92",
    style: {
      "margin-right": "5px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_Top)]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, _toDisplayString($options.externalFormatFilPrice($props.newValue, $props.fixed)), 1)])) : _createCommentVNode("", true), $props.oldValue > $props.newValue ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_icon, {
    color: "#db3636",
    style: {
      "margin-right": "5px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_Bottom)]),
    _: 1
  }), _createElementVNode("div", _hoisted_4, _toDisplayString($options.externalFormatFilPrice($props.newValue, $props.fixed)), 1)])) : _createCommentVNode("", true), $props.oldValue === $props.newValue ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($options.externalFormatFilPrice($props.newValue, $props.fixed)), 1)) : _createCommentVNode("", true)]);
}