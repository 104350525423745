import PageHead from "@/components/header/PageHead";
import PoolChangeWorkerComponent from '@/components/subpool/change/PoolChangeWorkerComponent';
import { getPrefix } from "@/utils/common";
export default {
  name: "PoolChangeWorker",
  components: {
    PageHead,
    PoolChangeWorkerComponent
  },
  data() {
    return {
      nodeId: '',
      poolAddress: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.nodeId = getPrefix() + this.$route.params.provider;
      this.poolAddress = this.$route.params.poolAddress;
      this.$store.dispatch('getNodePublicInfo', {
        nodeId: this.nodeId
      });
    }
  },
  computed: {
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};