import {fetchBalance, readContract} from "@wagmi/core";
import {contracts} from "@/utils/contracts/config";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import VariableDebtTokenABI from "@/utils/abi/VariableDebtToken.json";
import StableDebtTokenABI from "@/utils/abi/StableDebtToken.json";
import InterestRateStrategyABI from '@/utils/abi/InterestRateStrategy.json'
import {PoolConfiguration} from "@/utils/contracts/PoolConfiguration";
import nodejsHttp from "@/utils/http/nodejsHttp";

let publicGlobalInfo = {
    state: {
        liquidityRate: 0, // NET APY
        variableBorrowRate: 0n,
        stableBorrowRate: 0,
        variableBorrowIndex: 0,
        liquidityIndex: 0,
        stableRateEnabled: true,
        poolIsPaused: false,
        borrowBalance: 0,
        variableDebtTokenTotalSupply: 0n,
        stableDebtTokenTotalSupply: 0n,
        maxBorrowableLiquidityAmount: 0n,
        liquidityReserveRatio: 0n,
        currentAPR: 0n,
        maxBorrowRate: 0n,
        reserveRatioBorrowVariableRate: 0n,
        optimalBorrowRate: 0n,
        optimalUtilizationRateIntervalStart: 0n,
        optimalUtilizationRateIntervalEnd: 0n,
        baseVariableBorrowRate: 0n,
        rewardFee: 0n,
        riskReserve: 0n,
        // 池子的STFIL余额
        poolStFilBalance: 0n,
        loading: false,
        tvl: 0n,
        tvlLoading: false,
        tvlAllMinerValue: 0n,
        tvlPoolStFilBalance: 0n,
        tvlAllMinerValueUsd: '0',
        tvlPoolStFilBalanceUsd: '0',
        tvlTotalBorrowed: 0,
        tvlWithdrawLoadAmountUsd: '0',
        nodeCount: '0',
        stakeCount: '0',
        reserveDataLoading: false,
        stableRateEnabledLoading: false,
        poolIsPausedLoading: false,
        variableDebtTokenTotalSupplyLoading: false,
        stableDebtTokenTotalSupplyLoading: false,
        rewardFeeLoading: false,
        riskReserveLoading: false,
        poolStFilBalanceLoading: false,
    },
    getters: {},
    mutations: {
        SET_PUBLIC_INFO_LOADING(state, {loading}) {
            state.loading = loading
        },
        SET_TVL(state, {
            tvl,
            allMinerValue,
            poolStFilBalance,
            allMinerValueUsd,
            poolStFilBalanceUsd,
            nodeCount,
            stakeCount,
            totalBorrowed,
            withdrawLoadAmountUsd
        }) {
            state.tvl = tvl
            state.tvlAllMinerValue = allMinerValue
            state.tvlPoolStFilBalance = poolStFilBalance
            state.tvlAllMinerValueUsd = allMinerValueUsd
            state.tvlPoolStFilBalanceUsd = poolStFilBalanceUsd
            state.nodeCount = nodeCount
            state.stakeCount = stakeCount
            state.tvlTotalBorrowed = totalBorrowed
            state.tvlWithdrawLoadAmountUsd = withdrawLoadAmountUsd ? withdrawLoadAmountUsd : '0'
        },
        SET_TVL_LOADING(state, {tvlLoading}) {
            state.tvlLoading = tvlLoading
        },
        SET_BORROW_RATE(state, {
            currentAPR,
            maxBorrowRate,
            optimalBorrowRate,
            optimalUtilizationRateIntervalStart,
            optimalUtilizationRateIntervalEnd,
            baseVariableBorrowRate,
            reserveRatioBorrowVariableRate
        }) {
            state.currentAPR = currentAPR
            state.maxBorrowRate = maxBorrowRate
            state.optimalBorrowRate = optimalBorrowRate
            state.optimalUtilizationRateIntervalStart = optimalUtilizationRateIntervalStart
            state.optimalUtilizationRateIntervalEnd = optimalUtilizationRateIntervalEnd
            state.baseVariableBorrowRate = baseVariableBorrowRate
            state.reserveRatioBorrowVariableRate = reserveRatioBorrowVariableRate
        },
        SET_RESERVE_DATA(state, reserveData) {
            state.liquidityRate = reserveData.currentLiquidityRate.toBigInt()
            state.variableBorrowRate = reserveData.currentVariableBorrowRate.toBigInt()
            state.stableBorrowRate = reserveData.currentStableBorrowRate.toBigInt()
            state.variableBorrowIndex = reserveData.variableBorrowIndex
            state.liquidityIndex = reserveData.liquidityIndex
        },
        SET_RESERVE_STABLE_RATE_ENABLED(state, {stableRateEnabled}) {
            state.stableRateEnabled = stableRateEnabled
        },
        SET_RESERVE_POOL_IS_PAUSED(state, {poolIsPaused}) {
            state.poolIsPaused = poolIsPaused
        },
        SET_LIQUIDITY_RESERVE_RATIO(state,{liquidityReserveRatio}) {
            state.liquidityReserveRatio = liquidityReserveRatio
        },
        SET_MAX_BORROWABLE_LIQUIDITY_AMOUNT(state, {maxBorrowableLiquidityAmount}) {
            state.maxBorrowableLiquidityAmount = maxBorrowableLiquidityAmount
        },
        SET_RESERVE_VARIABLE_DEBT_TOKEN_TOTAL_SUPPLY(state, {variableDebtTokenTotalSupply}) {
            state.variableDebtTokenTotalSupply = variableDebtTokenTotalSupply
        },
        SET_RESERVE_STABLE_DEBT_TOKEN_TOTAL_SUPPLY(state, {stableDebtTokenTotalSupply}) {
            state.stableDebtTokenTotalSupply = stableDebtTokenTotalSupply
        },
        SET_RESERVE_REWARD_FEE(state, {rewardFee}) {
            state.rewardFee = rewardFee
        },
        SET_RESERVE_RISK_RESERVE(state, {riskReserve}) {
            state.riskReserve = riskReserve
        },
        SET_RESERVE_POOL_STFIL_BALANCE(state, {poolStFilBalance}) {
            state.poolStFilBalance = poolStFilBalance
        },
        SET_RESERVE_DATA_LOADING(state, {reserveDataLoading}) {
            state.reserveDataLoading = reserveDataLoading
        },
        SET_RESERVE_STABLE_RATE_ENABLED_LOADING(state, {stableRateEnabledLoading}) {
            state.stableRateEnabledLoading = stableRateEnabledLoading
        },
        SET_RESERVE_POOL_IS_PAUSED_LOADING(state, {poolIsPausedLoading}) {
            state.poolIsPausedLoading = poolIsPausedLoading
        },
        SET_RESERVE_VARIABLE_DEBT_TOKEN_TOTAL_SUPPLY_LOADING(state, {variableDebtTokenTotalSupplyLoading}) {
            state.variableDebtTokenTotalSupplyLoading = variableDebtTokenTotalSupplyLoading
        },
        SET_RESERVE_STABLE_DEBT_TOKEN_TOTAL_SUPPLY_LOADING(state, {stableDebtTokenTotalSupplyLoading}) {
            state.stableDebtTokenTotalSupplyLoading = stableDebtTokenTotalSupplyLoading
        },
        SET_RESERVE_REWARD_FEE_LOADING(state, {rewardFeeLoading}) {
            state.rewardFeeLoading = rewardFeeLoading
        },
        SET_RESERVE_RISK_RESERVE_LOADING(state, {riskReserveLoading}) {
            state.riskReserveLoading = riskReserveLoading
        },
        SET_RESERVE_POOL_STFIL_BALANCE_LOADING(state, {poolStFilBalanceLoading}) {
            state.poolStFilBalanceLoading = poolStFilBalanceLoading
        },
    },
    actions: {
        async initTvl({commit}) {
            try {
                commit('SET_TVL_LOADING', {tvlLoading: true})
                let infos = await nodejsHttp.get('info')
                let {
                    tvl,
                    allMinerValue,
                    poolStFilBalance,
                    allMinerValueUsd,
                    poolStFilBalanceUsd,
                    nodeCount,
                    stakeCount,
                    totalBorrowed,
                    withdrawLoadAmountUsd
                } = infos
                if (tvl !== '0') {
                    commit('SET_TVL', {
                        tvl,
                        allMinerValue,
                        poolStFilBalance,
                        allMinerValueUsd,
                        poolStFilBalanceUsd,
                        nodeCount,
                        stakeCount,
                        totalBorrowed,
                        withdrawLoadAmountUsd
                    })
                }
            } catch (e) {
                console.log('err info')
            } finally {
                commit('SET_TVL_LOADING', {tvlLoading: false})
            }
        },
        async updateAllData({dispatch, commit}) {
            const asyncTask = []
            commit('SET_PUBLIC_INFO_LOADING', {loading: true})
            asyncTask.push(dispatch('updateReserveData'))
            asyncTask.push(dispatch('initPublicBorrowRate'))
            asyncTask.push(dispatch('initTvl'))
            await Promise.all(asyncTask)
            commit('SET_PUBLIC_INFO_LOADING', {loading: false})
        },
        async initPublicBorrowRate({commit, rootState},) {
            let currentAPR = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'currentAPR'
            })
            let maxBorrowRate = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'maxBorrowVariableRate'
            })
            let optimalBorrowRate = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'optimalBorrowRate'
            })
            let optimalUtilizationRateIntervalStart = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'optimalUtilizationRateIntervalStart'
            })
            let optimalUtilizationRateIntervalEnd = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'optimalUtilizationRateIntervalEnd'
            })
            let baseVariableBorrowRate = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'baseVariableBorrowRate'
            })
            let reserveRatioBorrowVariableRate = await readContract({
                address: contracts[rootState.evn].InterestRateStrategy.eth,
                abi: InterestRateStrategyABI.abi,
                functionName: 'reserveRatioBorrowVariableRate'
            })


            commit('SET_BORROW_RATE', {
                currentAPR: currentAPR.toBigInt(),
                maxBorrowRate: maxBorrowRate.toBigInt(),
                optimalBorrowRate: optimalBorrowRate.toBigInt(),
                optimalUtilizationRateIntervalStart: optimalUtilizationRateIntervalStart.toBigInt(),
                optimalUtilizationRateIntervalEnd: optimalUtilizationRateIntervalEnd.toBigInt(),
                baseVariableBorrowRate: baseVariableBorrowRate.toBigInt(),
                reserveRatioBorrowVariableRate: reserveRatioBorrowVariableRate.toBigInt()
            })

        },
        async updatePoolStFilBalance({rootState, commit}, {haveLoading = true}) {
            if (haveLoading) {
                commit('SET_RESERVE_POOL_STFIL_BALANCE_LOADING', {poolStFilBalanceLoading: true})
            }
            const poolStFilBalance = await fetchBalance({
                address: contracts[rootState.evn].StakingPool.eth,
            })
            commit('SET_RESERVE_POOL_STFIL_BALANCE', {poolStFilBalance: poolStFilBalance.value.toBigInt()})
            commit('SET_RESERVE_POOL_STFIL_BALANCE_LOADING', {poolStFilBalanceLoading: false})
        },

        async updateReserveData({rootState, commit, dispatch}) {
            commit('SET_RESERVE_DATA_LOADING', {reserveDataLoading: true})
            commit('SET_RESERVE_POOL_IS_PAUSED_LOADING', {poolIsPausedLoading: true})
            commit('SET_RESERVE_VARIABLE_DEBT_TOKEN_TOTAL_SUPPLY_LOADING', {variableDebtTokenTotalSupplyLoading: true})
            commit('SET_RESERVE_STABLE_DEBT_TOKEN_TOTAL_SUPPLY_LOADING', {stableDebtTokenTotalSupplyLoading: true})
            commit('SET_RESERVE_STABLE_RATE_ENABLED_LOADING', {stableRateEnabledLoading: true})
            commit('SET_RESERVE_REWARD_FEE_LOADING', {rewardFeeLoading: true})
            commit('SET_RESERVE_RISK_RESERVE_LOADING', {riskReserveLoading: true})
            commit('SET_RESERVE_POOL_STFIL_BALANCE_LOADING', {poolStFilBalanceLoading: true})

            let reserveData = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPoolABI.abi,
                functionName: 'getReserveData'
            })
            commit('SET_RESERVE_DATA', reserveData)
            commit('SET_RESERVE_DATA_LOADING', {reserveDataLoading: false})

            let poolIsPaused = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPoolABI.abi,
                functionName: 'paused'
            })

            commit('SET_RESERVE_POOL_IS_PAUSED', {poolIsPaused})
            commit('SET_RESERVE_POOL_IS_PAUSED_LOADING', {poolIsPausedLoading: false})

            await dispatch('updatePoolStFilBalance', {haveLoading: false})

            let variableDebtTokenTotalSupply = await readContract({
                address: contracts[rootState.evn].VariableDebtToken.eth,
                abi: VariableDebtTokenABI.abi,
                functionName: 'totalSupply'
            })

            let stableDebtTokenTotalSupply = await readContract({
                address: contracts[rootState.evn].StableDebtToken.eth,
                abi: StableDebtTokenABI.abi,
                functionName: 'totalSupply'
            })

            let maxBorrowableLiquidityAmount = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPoolABI.abi,
                functionName: 'maxBorrowableLiquidityAmount'
            })
            let liquidityReserveRatio = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPoolABI.abi,
                functionName: 'liquidityReserveRatio'
            })

            commit('SET_LIQUIDITY_RESERVE_RATIO', {liquidityReserveRatio: liquidityReserveRatio.toBigInt()})
            commit('SET_MAX_BORROWABLE_LIQUIDITY_AMOUNT', {maxBorrowableLiquidityAmount: maxBorrowableLiquidityAmount.toBigInt()})
            commit('SET_RESERVE_VARIABLE_DEBT_TOKEN_TOTAL_SUPPLY', {variableDebtTokenTotalSupply: variableDebtTokenTotalSupply.toBigInt()})
            commit('SET_RESERVE_STABLE_DEBT_TOKEN_TOTAL_SUPPLY', {stableDebtTokenTotalSupply: stableDebtTokenTotalSupply.toBigInt()})
            commit('SET_RESERVE_VARIABLE_DEBT_TOKEN_TOTAL_SUPPLY_LOADING', {variableDebtTokenTotalSupplyLoading: false})
            commit('SET_RESERVE_STABLE_DEBT_TOKEN_TOTAL_SUPPLY_LOADING', {stableDebtTokenTotalSupplyLoading: false})

            let poolConfiguration = new PoolConfiguration(reserveData.configuration.data)
            let stableRateEnabled = poolConfiguration.getStableRateEnabled();

            commit('SET_RESERVE_STABLE_RATE_ENABLED', {stableRateEnabled})
            commit('SET_RESERVE_STABLE_RATE_ENABLED_LOADING', {stableRateEnabledLoading: false})

            let rewardFee = poolConfiguration.getFee()

            commit('SET_RESERVE_REWARD_FEE', {rewardFee})
            commit('SET_RESERVE_REWARD_FEE_LOADING', {rewardFeeLoading: false})

            const riskReserve = await fetchBalance({
                address: '0xff00000000000000000000000000000000000063',
                token: contracts[rootState.evn].STFILToken.eth
            })

            commit('SET_RESERVE_RISK_RESERVE', {riskReserve: riskReserve.value.toBigInt()})
            commit('SET_RESERVE_RISK_RESERVE_LOADING', {riskReserveLoading: false})
        }
    },
    modules: {}
}

export default publicGlobalInfo
