export class LedgerSignFairErrors extends Error {
}

export class LedgerTransactionRejectedErrors extends Error {
}
export class LedgerFailedOpenExpertModeErrors extends Error {
}
export class LedgerOpenExpertModeModeErrors extends Error {
}
export class LedgerAddAddressExistErrors extends Error {
}
export class LedgerAddAddressFormatErrors extends Error {
}
