import { ElSlider } from 'element-plus';
import RadioBtn from '@/components/RadioBtn';
import { ethTo, externalFormatFilPrice, floatToFixed, formatPrice, formatSubEthPrice, toBigInt, toEth } from "@/utils/NumU";
import { mapState } from "vuex";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { contracts } from "@/utils/contracts/config";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { getLocal, gotoHash, NotificationErr, NotificationSuccess } from "@/utils/common";
import { iWriteContract } from "@/utils/contracts/opertion";
import { BigNumber, ethers } from "ethers";
import IndicatorValue from './indicator/IndicatorValue';
import IndicatorRay from './indicator/IndicatorRay';
import { E23 } from "@/utils/contracts/WadRayMath";
import { formatUnits } from "ethers/lib.esm/utils";
export default {
  name: "AdjustP",
  components: {
    ElSlider,
    RadioBtn,
    IndicatorValue,
    IndicatorRay
  },
  data() {
    return {
      radio1: 'By Leverage',
      radio2: 'Borrowing',
      radio3: 'Variable',
      sliderValue: 0,
      oldSliderValue: 0,
      firstSliderValue: 0,
      loading: false,
      adjustBorrowingValue: '',
      adjustRepayingValue: '',
      tabOptions1: [],
      tabOptions2: [],
      tabOptions3: [],
      btnText: '',
      hash: '',
      mainSliderWidth: 0
    };
  },
  created() {
    this.init();
  },
  watch: {
    'currentNode.debt_value'() {
      this.initSliderValue();
    },
    '$i18n.locale'() {
      this.initTabOption();
    },
    adjustBorrowingValue() {},
    radio3() {
      this.$store.commit('SET_BORROW_RATE_TYPE', {
        borrowRateType: this.radio3
      });
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    radio2() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    radio1() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    currentLoading() {
      if (!this.currentLoading) {
        this.setMainSliderWidth();
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.setMainSliderWidth();
    });
  },
  methods: {
    setMainSliderWidth() {
      this.$nextTick(() => {
        if (this.$refs['main-slider']) {
          this.mainSliderWidth = this.$refs['main-slider'].offsetWidth - 16;
        }
      });
    },
    initTabOption() {
      this.tabOptions1 = [{
        label: this.$t('farm.console.ByLeverage'),
        value: 'By Leverage'
      }, {
        label: this.$t('farm.console.ByAmount'),
        value: 'By Amount'
      }];
      this.tabOptions2 = [{
        label: this.$t('farm.console.Borrowing'),
        value: 'Borrowing'
      }, {
        label: this.$t('farm.console.Repayment'),
        value: 'Repayment'
      }];
      this.tabOptions3 = [{
        label: this.$t('farm.console.Variable'),
        value: 'Variable'
      }, {
        label: this.$t('farm.console.Stable'),
        value: 'Stable'
      }];
    },
    formatUnits,
    formatPrice,
    init() {
      this.initTabOption();
      this.$store.commit('SET_BORROW_RATE_TYPE', {
        borrowRateType: 'Variable'
      });
      this.sliderValue = 0;
      if (toBigInt(this.currentNode.debt_value) > toBigInt(0)) {
        this.initSliderValue();
      }
    },
    initSliderValue() {
      let leverage = this.currentLeverage;
      this.sliderValue = parseFloat((parseInt((leverage - toBigInt(10000)).toString()) / parseInt((this.maxLeverage - toBigInt(10000)).toString()) * 100 + 0.0001).toFixed(4));
      if (this.sliderValue > 100) {
        this.sliderValue = 100;
      }
      this.firstSliderValue = this.sliderValue;
    },
    maxRepay() {
      if (this.loading) {
        return;
      }
      if (this.radio3 === 'Variable') {
        let variable_debt_value = ethTo(this.currentNode.variable_debt_value);
        this.adjustRepayingValue = floatToFixed(variable_debt_value, 4);
      } else if (this.radio3 === 'Stable') {
        let stable_debt_value = ethTo(this.currentNode.stable_debt_value);
        this.adjustRepayingValue = floatToFixed(stable_debt_value, 4);
      }
      this.calcSliderValue(ethers.utils.parseEther((this.adjustRepayingValue * -1).toString()).toBigInt());
    },
    borrowMax() {
      if (this.loading) {
        return;
      }
      let debt_value = toBigInt(this.currentNode.debt_value);
      // 最大可借金额
      let leftDebtValue = PercentageMath.mul(this.equityValue, this.maxLeverage - toBigInt(10000)) - debt_value;
      leftDebtValue = leftDebtValue > this.maxBorrowableLiquidityAmount ? this.maxBorrowableLiquidityAmount : leftDebtValue;
      if (leftDebtValue < 0n) {
        return;
      }
      if (this.radio3 !== 'Variable') {
        leftDebtValue = leftDebtValue > this.stableBorrowingAmount ? this.stableBorrowingAmount : leftDebtValue;
      }
      if (leftDebtValue > this.poolStFilBalance) {
        this.adjustBorrowingValue = ethTo(this.poolStFilBalance.toString()).toFixed(4);
      } else {
        this.adjustBorrowingValue = ethTo(leftDebtValue.toString()).toFixed(4);
      }
      this.calcSliderValue(ethers.utils.parseEther(this.adjustBorrowingValue.toString()).toBigInt());
    },
    adjustRepayingValueChange() {
      if (this.adjustRepayingValue < 0) {
        this.adjustRepayingValue = 0;
      }
      if (this.radio3 === 'Variable') {
        let variable_debt_value = ethTo(this.currentNode.variable_debt_value);
        if (this.adjustRepayingValue > variable_debt_value) {
          this.adjustRepayingValue = variable_debt_value.toFixed(4);
        } else {
          this.adjustRepayingValue = floatToFixed(this.adjustRepayingValue, 4);
        }
      } else if (this.radio3 === 'Stable') {
        let stable_debt_value = ethTo(this.currentNode.stable_debt_value);
        if (this.adjustRepayingValue > stable_debt_value) {
          this.adjustRepayingValue = stable_debt_value.toFixed(4);
        } else {
          this.adjustRepayingValue = floatToFixed(this.adjustRepayingValue, 4);
        }
      }
      this.calcSliderValue(ethers.utils.parseEther((this.adjustRepayingValue * -1).toString()).toBigInt());
    },
    adjustBorrowingValueChange() {
      if (this.adjustBorrowingValue === '') {
        this.sliderValue = this.firstSliderValue;
        return;
      }
      if (this.adjustBorrowingValue < 0) {
        this.adjustBorrowingValue = 0;
      }
      let debt_value = this.debtValue;
      // 最大可借金额
      let leftDebtValue = PercentageMath.mul(this.equityValue, this.maxLeverage - toBigInt(10000)) - debt_value;
      leftDebtValue = leftDebtValue > this.maxBorrowableLiquidityAmount ? this.maxBorrowableLiquidityAmount : leftDebtValue;
      if (this.radio3 !== 'Variable') {
        leftDebtValue = leftDebtValue > this.stableBorrowingAmount ? this.stableBorrowingAmount : leftDebtValue;
      }
      let bigIntAdjustBorrowingValue = toEth(this.adjustBorrowingValue.toString());

      // 超过最大可借
      if (bigIntAdjustBorrowingValue > leftDebtValue) {
        if (leftDebtValue > this.poolStFilBalance) {
          this.adjustBorrowingValue = ethTo(this.poolStFilBalance.toString()).toFixed(4);
        } else {
          this.adjustBorrowingValue = ethTo(leftDebtValue).toFixed(4);
        }
      } else {
        this.adjustBorrowingValue = floatToFixed(this.adjustBorrowingValue, 4);
      }
      this.calcSliderValue(ethers.utils.parseEther(this.adjustBorrowingValue.toString()).toBigInt());
    },
    calcSliderValue(val) {
      let debt_value = this.debtValue;
      let new_debt_value = debt_value + val;
      let leverage = toBigInt(0);
      if (val >= 0) {
        leverage = this.calcCurrentLeverage(new_debt_value);
      } else {
        leverage = PercentageMath.div(this.equityValue + new_debt_value, this.equityValue);
      }
      this.sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
    },
    calcCurrentLeverage(new_debt_value) {
      return PercentageMath.div(this.equityValue + new_debt_value, this.equityValue);
    },
    changeSliderValue(val) {
      if (this.youAreBorrowing === 0n) {
        this.sliderValue = this.firstSliderValue;
        this.oldSliderValue = val;
        return;
      }
      if (!this.isBorrowed && this.radio3 === 'Variable') {
        let variable_debt_value = this.variableDebtValue;
        if (this.guaranteeNodeId !== 0) {
          variable_debt_value = this.guaranteeCurrenVariableDebtValue;
        }
        if (BigNumber.from(this.youAreBorrowing.toString()).abs().toBigInt() >= variable_debt_value) {
          // 这里 * 1.0001 为了让还款时杠杆能到底
          this.sliderValue = this.firstSliderValue - this.valueToSliderValue(PercentageMath.mul(variable_debt_value, 10004n));
          return;
        }
      } else if (!this.isBorrowed && this.radio3 === 'Stable') {
        let stable_debt_value = this.stableDebtValue;
        if (this.guaranteeNodeId !== 0) {
          stable_debt_value = this.guaranteeCurrenStableDebtValue;
        }
        if (BigNumber.from(this.youAreBorrowing.toString()).abs().toBigInt() >= stable_debt_value) {
          // 这里 * 1.0001 为了让还款时杠杆能到底
          this.sliderValue = this.firstSliderValue - this.valueToSliderValue(PercentageMath.mul(stable_debt_value, 10004n));
          return;
        }
      }
      if (this.isBorrowed) {
        if (this.radio3 === 'Stable') {
          let leftDebtValue = this.maxBorrowableLiquidityAmount > this.stableBorrowingAmount ? this.stableBorrowingAmount : this.maxBorrowableLiquidityAmount;
          if (this.youAreBorrowing >= leftDebtValue) {
            this.sliderValue = this.firstSliderValue + this.valueToSliderValue(leftDebtValue);
            return;
          }
        }
        if (this.youAreBorrowing >= this.maxBorrowableLiquidityAmount) {
          this.sliderValue = this.firstSliderValue + this.valueToSliderValue(this.maxBorrowableLiquidityAmount);
          return;
        }
        if (this.youAreBorrowing >= this.poolStFilBalance) {
          this.sliderValue = this.firstSliderValue + this.valueToSliderValue(this.poolStFilBalance);
          return;
        }
      }
      this.oldSliderValue = val;
    },
    valueToSliderValue(val) {
      let leverage = PercentageMath.div(this.equityValue + val, this.equityValue);
      let diff = leverage - 10000n;
      let maxDiff = this.maxLeverage - 10000n;
      let res = (parseInt(diff.toString()) / parseInt(maxDiff.toString()) * 100).toFixed(4);
      return parseFloat(res);
    },
    sliderFormatTip(val) {
      let res = val / 100 * (this.maxLeverageInt - 10000) + 10000;
      return (res / 10000).toFixed(4) + 'x';
    },
    gotoHash,
    connectWallet() {
      if (this.approveOperator()) {
        return;
      }
      this.$store.state.refs['iHeader'].connectWallet();
    },
    approveOperator() {
      if (this.operator === '0x0000000000000000000000000000000000000000') {
        this.$emit('approveOperator');
        return true;
      }
      if (this.isConnected && this.operator !== this.address) {
        NotificationErr('The current wallet is not the operator of this node');
        return true;
      }
      return false;
    },
    repay() {
      if (this.approveOperator()) {
        return;
      }
      if (this.youAreBorrowing >= 0) {
        NotificationErr('Please enter the amount repay');
        return;
      }
      let isAllVariableDebtValue = false;
      let isAllStableDebtValue = false;
      this.loading = true;
      let repayData = BigNumber.from(this.youAreBorrowing).abs();
      if (this.sliderValue === 0) {
        repayData = repayData.add(ethers.utils.parseEther('1'));
        console.log('repay all');
        if (this.radio3 === 'Variable') {
          isAllVariableDebtValue = true;
        } else {
          isAllStableDebtValue = true;
        }
      } else {
        if (this.radio3 === 'Variable') {
          console.log('repay all');
          console.log(this.youAreBorrowing, this.variableDebtValue, this.youAreBorrowing + this.variableDebtValue);
          if (this.youAreBorrowing + this.variableDebtValue <= 1000000000000000n) {
            repayData = repayData.add(ethers.utils.parseEther('1'));
            isAllVariableDebtValue = true;
          }
        } else {
          console.log('repay all');
          if (this.youAreBorrowing + this.stableDebtValue <= 1000000000000000n) {
            repayData = repayData.add(ethers.utils.parseEther('1'));
            isAllStableDebtValue = true;
          }
        }
      }
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: contracts[this.$store.state.evn].StakingPool.eth,
        f0address: contracts[this.$store.state.evn].StakingPool.id,
        abi: StakingPoolABI.abi,
        functionName: "repay",
        args: [this.currentNode.node, repayData, this.getRoteMode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          if (isAllVariableDebtValue) {
            NotificationSuccess(`You have successfully paid off your variable debt.`);
          } else if (isAllStableDebtValue) {
            NotificationSuccess(`You have successfully paid off your stable debt.`);
          } else {
            NotificationSuccess(`You have successfully repaid ${parseFloat(ethers.utils.formatEther(repayData)).toFixed(6)}FIL.`);
          }
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentNode.node
          });
          this.$emit('updateInfo');
          this.loading = false;
          this.adjustRepayingValue = '';
          this.adjustBorrowingValue = '';
          this.clearContent();
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message, "normal repayment" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.clearContent();
      });
    },
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    borrow() {
      if (this.approveOperator()) {
        return;
      }
      if (this.youAreBorrowing <= 0) {
        NotificationErr('Please enter the amount borrowed');
        return;
      }
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      let youAreBorrowing = this.youAreBorrowing;
      if (this.sliderValue >= 99) {
        youAreBorrowing = this.youAreBorrowing - 99999999999999n;
      }
      iWriteContract({
        address: contracts[this.$store.state.evn].StakingPool.eth,
        f0address: contracts[this.$store.state.evn].StakingPool.id,
        abi: StakingPoolABI.abi,
        functionName: "borrow",
        args: [this.currentNode.node, BigNumber.from(youAreBorrowing), this.getRoteMode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          NotificationSuccess(`You have successfully borrowed ${parseFloat(ethers.utils.formatEther(this.youAreBorrowing)).toFixed(6)}FIL.`);
          this.loading = false;
          this.clearContent();
          this.adjustRepayingValue = '';
          this.adjustBorrowingValue = '';
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentNode.node
          });
          this.$emit('updateInfo');
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message, "Ordinary Borrowing" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.clearContent();
      });
    },
    externalFormatFilPrice,
    formatSubEthPrice
  },
  computed: mapState({
    nodeDataLoading: state => state.nodeData.loading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.nodeDataLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    operator() {
      return this.currentNode.operator;
    },
    isBorrowed() {
      return this.sliderValue >= this.firstSliderValue;
    },
    sliderChangeClass() {
      if (this.sliderValue >= this.firstSliderValue) {
        return '';
      } else {
        return 'i-auto-slider';
      }
    },
    afterVariableRate: state => {
      return state.nodeData.afterVariableRate;
    },
    afterStableRate() {
      if (this.youAreBorrowing > 0n) {
        return PercentageMath.div(1000000000000000n + PercentageMath.mul(this.userStableSimpleRate, this.stableDebtValue) + PercentageMath.mul(this.youAreBorrowing, this.stableBorrowSimpleRate), this.stableDebtValue + this.youAreBorrowing);
      }
      if (this.youAreBorrowing + this.stableDebtValue <= 0) {
        return 0n;
      }
      return this.userStableSimpleRate;
    },
    afterAvailableBorrowingAmount() {
      let res = this.availableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    borrowMode() {
      return this.$store.state.nodeData.borrowMode;
    },
    afterVariableDebtValue() {
      if (this.borrowMode === 0) {
        return this.variableDebtValue;
      }
      if (this.borrowMode === 2) {
        if (this.variableDebtValue + this.youAreBorrowing <= 1000000000000000n) {
          return 0n;
        }
        return this.variableDebtValue + this.youAreBorrowing;
      } else {
        return this.variableDebtValue;
      }
    },
    afterStableDebtValue() {
      if (this.borrowMode === 1) {
        if (this.stableDebtValue + this.youAreBorrowing <= 1000000000000000n) {
          return 0n;
        }
        return this.stableDebtValue + this.youAreBorrowing;
      } else {
        return this.stableDebtValue;
      }
    },
    leftAvailableBorrowingAmount() {
      let res = this.availableBorrowingAmount;
      if (res > this.maxBorrowableLiquidityAmount) {
        return this.maxBorrowableLiquidityAmount;
      }
      return res;
    },
    afterLeftAvailableBorrowingAmount() {
      let res = this.leftAvailableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    availableBorrowingAmount() {
      let res = PercentageMath.mul(this.equityValue, this.maxLeverage - toBigInt(10000)) - this.debtValue;
      if (res < 0) {
        return 0n;
      }
      if (res >= this.poolStFilBalance) {
        return this.poolStFilBalance;
      }
      return res;
    },
    stableRateEnabled: state => state.publicInfo.stableRateEnabled,
    poolIsPaused: state => state.publicInfo.poolIsPaused,
    isActive: state => state.currentNode.is_active === '1',
    getRoteMode() {
      if (this.radio3 === 'Variable') {
        return 2;
      }
      return 1;
    },
    address() {
      return this.$store.state.address;
    },
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    stableDebtValue() {
      return toBigInt(this.currentNode.stable_debt_value);
    },
    variableDebtValue() {
      return toBigInt(this.currentNode.variable_debt_value);
    },
    disableRate() {
      if (!this.stableRateEnabled) {
        return ['Stable'];
      }
      return [];
    },
    marksMax() {
      let leverage = this.currentLeverage;
      let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
      if (sliderValue > 100) {
        return sliderValue;
      } else {
        return 100;
      }
    },
    marks() {
      let maxLeverage = this.maxLeverageInt;
      let res = {
        0: {
          style: {
            top: '0'
          },
          label: '1.00x'
        },
        100: {
          style: {
            top: '0'
          },
          label: (maxLeverage / 10000).toFixed(2) + 'x'
        }
      };
      if (this.currentLeverage > 1) {
        let leverage = this.currentLeverage;
        let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0) {
          res[0].style.top = '17px';
          res[100].style.top = '17px';
          res[sliderValue] = {
            style: {
              color: '#00CE92',
              bottom: '17px'
            },
            label: `${parseInt(leverage.toString()) / 10000}x`
          };
        }
      }
      if (this.guaranteeLeverage > 10000n) {
        let sliderValue = parseInt(PercentageMath.div(this.guaranteeLeverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0) {
          res[sliderValue] = {
            style: {
              color: '#8b8b8b',
              bottom: '-25px',
              fontSize: '12px'
            },
            label: `${parseInt(this.guaranteeLeverage.toString()) / 10000}x`
          };
        }
      }
      return res;
    },
    isYouAreRePayingAll() {
      if (this.youAreBorrowing < 0n) {
        if (this.radio3 === 'Variable') {
          if (this.youAreBorrowing + this.variableDebtValue <= 1000000000000000n) {
            return true;
          }
        } else {
          if (this.youAreBorrowing + this.stableDebtValue <= 1000000000000000n) {
            return true;
          }
        }
      }
      return false;
    },
    youAreBorrowing() {
      let youAreBorrowing = 0n;
      if (this.radio2 === 'Borrowing' && this.radio1 === 'By Amount') {
        youAreBorrowing = toEth(this.adjustBorrowingValue);
      } else if (this.radio2 === 'Repayment' && this.radio1 === 'By Amount') {
        youAreBorrowing = toEth(this.adjustRepayingValue) * -1n;
        if (this.radio3 === 'Variable') {
          if (youAreBorrowing + this.variableDebtValue <= 0 || youAreBorrowing + this.variableDebtValue <= 99999999999999n) {
            youAreBorrowing = this.variableDebtValue * -1n;
          }
        } else {
          if (youAreBorrowing + this.stableDebtValue <= 0 || youAreBorrowing + this.variableDebtValue <= 99999999999999n) {
            youAreBorrowing = this.stableDebtValue * -1n;
          }
        }
      } else {
        let leverage = parseInt((this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000).toString());
        if (this.currentLeverage === toBigInt(leverage)) {
          this.$store.commit('SET_YOU_ARE_BORROWING', {
            youAreBorrowing: 0n,
            borrowMode: this.getRoteMode
          });
          return toBigInt(0);
        }
        let position_value = toBigInt(this.currentNode.position_value) - toBigInt(this.currentNode.debt_value);
        youAreBorrowing = PercentageMath.mul(toBigInt(leverage), position_value) - position_value - toBigInt(this.currentNode.debt_value);
        if (youAreBorrowing < 0n) {
          if (this.radio3 === 'Variable') {
            if (youAreBorrowing + this.variableDebtValue <= 0) {
              youAreBorrowing = this.variableDebtValue * -1n;
            }
          } else {
            if (youAreBorrowing + this.stableDebtValue <= 0) {
              youAreBorrowing = this.stableDebtValue * -1n;
            }
          }
        } else {
          if (youAreBorrowing > this.poolStFilBalance) {
            youAreBorrowing = this.poolStFilBalance;
          }
          if (youAreBorrowing > this.maxBorrowableLiquidityAmount) {
            youAreBorrowing = this.maxBorrowableLiquidityAmount;
          }
        }
      }
      this.$store.commit('SET_YOU_ARE_BORROWING', {
        youAreBorrowing,
        borrowMode: this.getRoteMode
      });
      return youAreBorrowing;
    },
    currentLeverage() {
      let res = PercentageMath.div(this.equityValue + toBigInt(this.currentNode.debt_value), this.equityValue);
      this.$store.commit('SET_CURRENT_LEVERAGE', {
        currentLeverage: res
      });
      return res;
    },
    // 扣除流动性储备最大可借
    maxBorrowableLiquidityAmount: state => state.publicInfo.maxBorrowableLiquidityAmount,
    availableBalance() {
      return toBigInt(this.currentNode.availableBalance);
    },
    expectedAvailableBalance() {
      return this.availableBalance + this.youAreBorrowing;
    },
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    maxLeverage() {
      return toBigInt(this.currentNode.max_leverage);
    },
    maxLeverageInt() {
      return this.currentNode.max_leverage;
    },
    positionValue() {
      return toBigInt(this.currentNode.position_value);
    },
    afterPositionValue() {
      return toBigInt(this.currentNode.position_value);
    },
    guaranteeCurrentPositionValue() {
      return this.guaranteeCurrenNode.position_value;
    },
    poolStFilBalance: state => state.publicInfo.poolStFilBalance,
    debtRatio() {
      if (this.debtValue === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(this.debtValue, this.positionValue);
    },
    firstExpectedInitialBalance() {
      let debt = this.debtValue;
      let tmp = PercentageMath.div(debt, this.maxLeverage - toBigInt(10000));
      return debt + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    expectedInitialBalance() {
      let debt = this.debtValue + this.youAreBorrowing;
      let tmp = PercentageMath.div(debt, this.maxLeverage - toBigInt(10000));
      return debt + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    safetyBuffer() {
      return toBigInt(this.currentNode.safetyBuffer);
    },
    afterSafetyBuffer() {
      if (this.youAreBorrowing <= 0) {
        if (this.youAreBorrowing + this.debtValue <= 0) {
          return 0n;
        }
        return this.safetyBuffer + this.youAreBorrowing;
      } else {
        let _debtValue = this.debtValue + this.youAreBorrowing;
        let tmp = PercentageMath.div(_debtValue, this.maxLeverage - toBigInt(10000));
        let sb1 = _debtValue + tmp;
        let sb2 = this.positionValue - this.availableBalance + this.youAreBorrowing;
        return sb1 > sb2 ? sb1 : sb2;
      }
    },
    expectedExpectedPower() {
      return 0;
    },
    expectedIncreasedEarnings() {
      return 0;
    },
    summaryPositionValue() {
      let res = this.positionValue + this.youAreBorrowing;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    summaryDebtValue() {
      return this.debtValue + this.youAreBorrowing;
    },
    summaryDebtRatio() {
      let debt = this.debtValue + this.youAreBorrowing;
      if (debt === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(debt, this.positionValue);
    },
    summaryLeverage() {
      return toBigInt(this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000);
    },
    summarySafetyBuffer() {
      return 0;
    },
    equityValue() {
      return this.positionValue - this.debtValue;
    },
    afterEquityValue() {
      return this.summaryPositionValue - this.summaryDebtValue;
    },
    variableBorrowRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    variableBorrowSimpleRate: state => {
      return toBigInt(state.publicInfo.variableBorrowRate / E23);
    },
    stableBorrowSimpleRate: state => {
      return toBigInt(state.publicInfo.stableBorrowRate / E23);
    },
    userStableRate() {
      return this.currentNode.userStableRate;
    },
    userStableSimpleRate() {
      return toBigInt(this.userStableRate / E23);
    },
    currentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.positionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    afterCurrentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.summaryPositionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    maxBorrowRate: state => state.publicInfo.maxBorrowRate,
    currentBorrowingInterestAPR() {
      let _variable = PercentageMath.mul(this.variableDebtValue, toBigInt(this.variableBorrowRate / E23));
      let _stable = PercentageMath.mul(this.stableDebtValue, toBigInt(this.userStableRate / E23));
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    afterCurrentBorrowingInterestAPR() {
      let addVariable = 0n;
      let addStable = 0n;
      if (this.radio3 === 'Variable') {
        addVariable = this.youAreBorrowing;
      } else {
        addStable = this.youAreBorrowing;
      }
      let _variable = PercentageMath.mul(this.variableDebtValue + addVariable, this.afterVariableRate);
      let _stable = PercentageMath.mul(this.stableDebtValue + addStable, this.afterStableRate);
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    totalAPR() {
      return this.currentFarmAPR - this.currentBorrowingInterestAPR;
    },
    afterTotalAPR() {
      return this.afterCurrentFarmAPR - this.afterCurrentBorrowingInterestAPR;
    },
    dailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.totalAPR.toString(), 2)) / 365 * 1000000));
    },
    afterDailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.afterTotalAPR.toString(), 2)) / 365 * 1000000));
    },
    estimatedDailyEarnings() {
      return PercentageMath.mul(this.positionValue, this.dailyAPR) / 10000n;
    },
    afterEstimatedDailyEarnings() {
      return PercentageMath.mul(this.summaryPositionValue, this.afterDailyAPR) / 10000n;
    },
    maxStableRateBorrowSizePercent: state => state.stakeInfo.maxStableRateBorrowSizePercent,
    // 稳定利率最大可借
    stableBorrowingAmount() {
      let res = PercentageMath.mul(this.poolStFilBalance, this.maxStableRateBorrowSizePercent) - 1000000000000000n;
      if (res < 0) {
        return 0n;
      }
      if (res > this.maxBorrowableLiquidityAmount) {
        return this.maxBorrowableLiquidityAmount;
      }
      return res;
    },
    afterStableBorrowingAmount() {
      let res = this.stableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    guaranteeNodeId() {
      return this.currentNode.guaranteeNodeId;
    },
    guaranteeNode() {
      return this.currentNode.guarantee.guaranteeNode;
    },
    guaranteeCurrenNode() {
      return this.currentNode.guarantee.currentNode;
    },
    guaranteePositionValue() {
      return this.guaranteeNode.position_value;
    },
    guaranteeVariableDebtValue() {
      return this.guaranteeNode.variable_debt_value;
    },
    guaranteeVtableDebtValue() {
      return this.guaranteeNode.stable_debt_value;
    },
    guaranteeDebtValue() {
      return this.guaranteeNode.debt_value;
    },
    guaranteeLeverage() {
      return PercentageMath.div(this.equityValue + toBigInt(this.guaranteeDebtValue), this.equityValue);
    },
    guaranteeCurrenVariableDebtValue() {
      return this.guaranteeCurrenNode.variable_debt_value;
    },
    guaranteeCurrenStableDebtValue() {
      return this.guaranteeCurrenNode.stable_debt_value;
    },
    guaranteeCurrenDebtValue() {
      return this.guaranteeCurrenNode.debt_value;
    },
    guaranteeLeverageSliderWidth() {
      return `${parseFloat((this.guaranteeLeverage - 10000n).toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    assetsLoading: state => state.assetsLoading,
    liquidityReserveRatio: state => {
      return state.publicInfo.liquidityReserveRatio / E23;
    }
  })
};