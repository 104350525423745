import {contractConfig, contracts, PERCENTAGE_FACTOR} from "@/utils/contracts/config";
import {loutsRpc} from "@/utils/LoutsRpc";
import {StateMinerInfo, StateMinerPower, StateMinerSectorCount, StateReadState} from "@/utils/FilecoinApi";
import {BigNumber, ethers} from "ethers";
import {DEFAULT_NODE_INFO} from "@/utils/model";
import {readContract} from "@wagmi/core";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import {NodeConfiguration} from "@/utils/contracts/NodeConfiguration";
import nodejsHttp from "@/utils/http/nodejsHttp";
import {checkNodeIsEntry} from "@/utils/NodeUtils";
import {toBigInt} from "@/utils/NumU";

let node = {
    state: {
        nodeInfo: {
            id: '',
            positionValue: 0,
            positionValueBigInt: 0n,
            availableBalance: 0,
            lockedRewards: 0,
            pledge: 0,
            adjustedPower: 0,
            rawBytePower: 0,
            sectorCount: 0,
            beneficiary: '',
            owner: '',
            worker: '',
            controlAddresses: [],
            sectorSize: 0,
            active: 0,
            faulty: 0,
            live: 0,
            adjustedPowerRate: 0,
            ownerAddress: '',
            beneficiaryAddress: '',
            ownerInfo: {
                availableBorrowingAmount: 0,
                maxDebtRatio: 0,
                maximumLeverage: 0,
                LiqThreshold: 0,
            },
            beneficiaryInfo: {
                availableBorrowingAmount: 0,
                maxDebtRatio: 0,
                maximumLeverage: 0,
                LiqThreshold: 0,
            },
        },
        searchLoading: false,
        searchFine: false,
        nodeRole: 'Owner',
        singerMultiple: false,
        singerMultipleThreshold: 1,
        singerMultipleNum: 1,
        farmActiveName: 'first',
    },
    getters: {},
    mutations: {
        SET_FARM_ACTIVE_NAME(state, {farmActiveName}) {
            state.farmActiveName = farmActiveName
        },
        INIT_FARM_ACTIVE_NAME(state) {
            state.farmActiveName = 'first'
        },
        SET_SINGER_MULTIPLE_NUM(state, {singerMultipleNum}) {
            state.singerMultipleNum = singerMultipleNum
        },
        SET_SINGER_MULTIPLE_THRESHOLD(state, {singerMultipleThreshold}) {
            state.singerMultipleThreshold = singerMultipleThreshold
        },
        SET_SINGER_MULTIPLE(state, {singerMultiple}) {
            state.singerMultiple = singerMultiple
        },
        SET_NODE_INFO(state, nodeInfo) {
            state.nodeInfo = nodeInfo
        },
        UPDATE_NODE_INFO(state, nodeInfo) {
            state.nodeInfo = Object.assign(state.nodeInfo, nodeInfo)
        },
        SET_NODE_ROLE(state, {nodeRole}) {
            state.nodeRole = nodeRole
        },
        SET_NODE_INFO_CONTRACT_INFO(state, {ownerInfo, beneficiaryInfo}) {
            state.nodeInfo.ownerInfo = ownerInfo
            state.nodeInfo.beneficiaryInfo = beneficiaryInfo
        },
        SET_NODE_SEARCH_LOADING(state, {loading}) {
            state.searchLoading = loading
        },
        SET_SEARCH_FINE(state, {fine}) {
            state.searchFine = fine
        },
        RESET_NODE_INFO(state) {
            state.nodeInfo = Object.assign(state.nodeInfo, DEFAULT_NODE_INFO)
        }
    },
    actions: {
        getNodePublicInfo({commit, dispatch, rootState}, {nodeId}) {
            commit('SET_NODE_SEARCH_LOADING', {loading: true})

            return new Promise((resolve, reject) => {

                loutsRpc(StateMinerPower(nodeId)).then(async res => {

                    await dispatch('initCurrentNodeData', {nodeId: nodeId.toString().substr(2)})

                    res = res.result
                    let _nodeInfo = {}
                    let {QualityAdjPower, RawBytePower} = res.MinerPower
                    _nodeInfo.id = nodeId
                    _nodeInfo.adjustedPower = QualityAdjPower
                    _nodeInfo.rawBytePower = RawBytePower

                    _nodeInfo.adjustedPowerRate = ((_nodeInfo.adjustedPower / res.TotalPower.QualityAdjPower) * 100).toFixed(2)

                    let stateMinerInfo = await loutsRpc(StateMinerInfo(nodeId))
                    let {Owner, Beneficiary, SectorSize, Worker, ControlAddresses} = stateMinerInfo.result
                    _nodeInfo.owner = Owner
                    _nodeInfo.beneficiary = Beneficiary
                    _nodeInfo.sectorSize = SectorSize
                    _nodeInfo.worker = Worker
                    _nodeInfo.controlAddresses = ControlAddresses

                    let stateMinerSectorCount = await loutsRpc(StateMinerSectorCount(nodeId))
                    let {Active, Faulty, Live} = stateMinerSectorCount.result
                    _nodeInfo.active = Active
                    _nodeInfo.faulty = Faulty
                    _nodeInfo.live = Live
                    _nodeInfo.sectorCount = Active + Faulty

                    let stateReadState = await loutsRpc(StateReadState(nodeId))
                    let {Balance} = stateReadState.result
                    let {LockedFunds, InitialPledge} = stateReadState.result.State
                    _nodeInfo.positionValue = ethers.utils.formatEther(Balance)
                    _nodeInfo.lockedRewards = ethers.utils.formatEther(LockedFunds)
                    _nodeInfo.pledge = ethers.utils.formatEther(InitialPledge)

                    _nodeInfo.availableBalance = ethers.utils.formatEther(BigNumber.from(Balance).sub(BigNumber.from(LockedFunds)).sub(BigNumber.from(InitialPledge)))
                    commit('SET_NODE_INFO', _nodeInfo)
                    commit('SET_SEARCH_FINE', {fine: true})
                    dispatch('initNodeInitialInfo', {positionValue: _nodeInfo.positionValue})
                    resolve({haveSp: rootState.nodeData.currentNode.is_active})
                }).catch(() => {
                    commit('SET_SEARCH_FINE', {fine: false})
                    reject('Invalid Address!')
                    commit('SET_NODE_SEARCH_LOADING', {loading: false})
                }).finally(() => {
                    commit('SET_NODE_SEARCH_LOADING', {loading: false})
                })
            })
        },
        getNodePublicInfoBySubPool({commit, dispatch}, {nodeId, poolAddress}) {
            commit('SET_NODE_SEARCH_LOADING', {loading: true})

            return new Promise((resolve, reject) => {

                loutsRpc(StateMinerPower(nodeId)).then(async res => {

                    res = res.result
                    let _nodeInfo = {}
                    let {QualityAdjPower, RawBytePower} = res.MinerPower
                    _nodeInfo.id = nodeId
                    _nodeInfo.adjustedPower = QualityAdjPower
                    _nodeInfo.rawBytePower = RawBytePower

                    _nodeInfo.adjustedPowerRate = ((_nodeInfo.adjustedPower / res.TotalPower.QualityAdjPower) * 100).toFixed(2)

                    let stateMinerInfo = await loutsRpc(StateMinerInfo(nodeId))
                    let {Owner, Beneficiary, SectorSize, Worker, ControlAddresses} = stateMinerInfo.result
                    _nodeInfo.owner = Owner
                    _nodeInfo.beneficiary = Beneficiary
                    _nodeInfo.sectorSize = SectorSize
                    _nodeInfo.worker = Worker
                    _nodeInfo.controlAddresses = ControlAddresses

                    let stateMinerSectorCount = await loutsRpc(StateMinerSectorCount(nodeId))
                    let {Active, Faulty, Live} = stateMinerSectorCount.result
                    _nodeInfo.active = Active
                    _nodeInfo.faulty = Faulty
                    _nodeInfo.live = Live
                    _nodeInfo.sectorCount = Active + Faulty

                    let stateReadState = await loutsRpc(StateReadState(nodeId))
                    let {Balance} = stateReadState.result
                    let {LockedFunds, InitialPledge} = stateReadState.result.State
                    _nodeInfo.positionValue = ethers.utils.formatEther(Balance)
                    _nodeInfo.positionValueBigInt = toBigInt(Balance)
                    _nodeInfo.lockedRewards = ethers.utils.formatEther(LockedFunds)
                    _nodeInfo.pledge = ethers.utils.formatEther(InitialPledge)

                    _nodeInfo.availableBalance = ethers.utils.formatEther(BigNumber.from(Balance).sub(BigNumber.from(LockedFunds)).sub(BigNumber.from(InitialPledge)))
                    commit('SET_NODE_INFO', _nodeInfo)
                    commit('SET_SEARCH_FINE', {fine: true})
                    dispatch('initNodeInitialInfo', {positionValue: _nodeInfo.positionValue})
                    let {isEntry, isStakingPool} = await checkNodeIsEntry(nodeId, poolAddress)
                    resolve({haveSp: isEntry, isStakingPool})
                }).catch(() => {
                    commit('SET_SEARCH_FINE', {fine: false})
                    reject('Invalid Address!')
                    commit('SET_NODE_SEARCH_LOADING', {loading: false})
                }).finally(() => {
                    commit('SET_NODE_SEARCH_LOADING', {loading: false})
                })
            })
        },
        initNodeInitialInfo({commit}, {positionValue}) {
            let beneficiaryInfo = {
                availableBorrowingAmount: 0,
                maxDebtRatio: 0,
                maximumLeverage: 0,
                LiqThreshold: 0,
            }
            let ownerInfo = {
                availableBorrowingAmount: 0,
                maxDebtRatio: 0,
                maximumLeverage: 0,
                LiqThreshold: 0,
            }
            ownerInfo.availableBorrowingAmount = positionValue * (contractConfig.OWNER_DEFAULT_MAX_LEVERAGE - PERCENTAGE_FACTOR) / PERCENTAGE_FACTOR
            ownerInfo.maxDebtRatio = (contractConfig.OWNER_DEFAULT_MAX_LEVERAGE - PERCENTAGE_FACTOR) / contractConfig.OWNER_DEFAULT_MAX_LEVERAGE
            ownerInfo.maximumLeverage = contractConfig.OWNER_DEFAULT_MAX_LEVERAGE / PERCENTAGE_FACTOR
            ownerInfo.LiqThreshold = contractConfig.OWNER_LIQUIDATION_THRESHOLD / PERCENTAGE_FACTOR

            beneficiaryInfo.availableBorrowingAmount = positionValue * (contractConfig.BENEFICIARY_MAX_LEVERAGE - PERCENTAGE_FACTOR) / PERCENTAGE_FACTOR
            beneficiaryInfo.maxDebtRatio = (contractConfig.BENEFICIARY_MAX_LEVERAGE - PERCENTAGE_FACTOR) / contractConfig.BENEFICIARY_MAX_LEVERAGE
            beneficiaryInfo.maximumLeverage = contractConfig.BENEFICIARY_MAX_LEVERAGE / PERCENTAGE_FACTOR
            beneficiaryInfo.LiqThreshold = contractConfig.BENEFICIARY_LIQUIDATION_THRESHOLD / PERCENTAGE_FACTOR

            commit('SET_NODE_INFO_CONTRACT_INFO', {ownerInfo, beneficiaryInfo})

        },
        async getNodeIsActive({rootState}, {nodeId}) {

            let contractNodeData = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPoolABI.abi,
                functionName: 'getNodeData',
                args: [nodeId],
            })

            let configuration = contractNodeData[0]['configuration']['data']
            let nodeConfiguration = new NodeConfiguration(configuration)
            return nodeConfiguration.isActive();

        },
        // eslint-disable-next-line no-empty-pattern
        async getNodeInterest({}, {nodeId}) {
            let data = await nodejsHttp.get(`node/interest/${nodeId}`)
            return data.interest
        },
        // eslint-disable-next-line no-empty-pattern
        async getNodesByOwner({}, {owner, role}) {
            return await nodejsHttp.get(`node/byOwner`, {
                params: {
                    owner,
                    role
                }
            })
        }
    },
    modules: {}
}

export default node
