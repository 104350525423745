import {readContract} from "@wagmi/core";
import {contracts} from "@/utils/contracts/config";
import STFILTokenABI from "@/utils/abi/STFILToken.json";
import StakingPooLABI from '@/utils/abi/StakingPool.json'
import nodejsHttp from "@/utils/http/nodejsHttp";
import {toBigInt} from "@/utils/NumU";
import WSTFILTokenABI from "@/utils/abi/WSTFILToken.json";

let stakeInfo = {
    state: {
        totalStakingTokens: 0n,
        accumulatedEarnings: 0n,
        maxStableRateBorrowSizePercent: 2000n,
        stakers: 0,
        loading: false,
        referralCode: 0,
        stakeNumber: 0,
    },
    getters: {},
    mutations: {
        SET_STAKE_NUMBER(state, {stakeNumber}) {
            if (isNaN(stakeNumber)) {
                state.stakeNumber = 0
            } else {
                state.stakeNumber = stakeNumber
            }
        },
        SET_MAX_STABLE_RATE_BORROW_SIZE_PERCENT(state, {maxStableRateBorrowSizePercent}) {
            state.maxStableRateBorrowSizePercent = maxStableRateBorrowSizePercent
        },
        SET_STAKE_REFERRAL_CODE(state, {referralCode}) {
            state.referralCode = referralCode
        },
        SET_STAKE_INFO(state, stakeInfo) {
            state.totalStakingTokens = stakeInfo.totalSupply
        },
        SET_STAKE_INFO_LOADING(state, {loading}) {
            state.loading = loading
        },
        SET_ACCUMULATED_EARNINGS(state, {accumulatedEarnings}) {
            state.accumulatedEarnings = accumulatedEarnings
        }
    },
    actions: {
        async initStakeInfo({rootState, commit}) {
            commit('SET_STAKE_INFO_LOADING', {loading: true})
            let totalSupply = await readContract({
                address: contracts[rootState.evn].STFILToken.eth,
                abi: STFILTokenABI.abi,
                functionName: 'totalSupply'
            })
            commit('SET_STAKE_INFO_LOADING', {loading: false})
            commit('SET_STAKE_INFO', {totalSupply: totalSupply.toBigInt()})
        },
        async initMaxStableRateBorrowSizePercent({rootState, commit}) {
            let maxStableRateBorrowSizePercent = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPooLABI.abi,
                functionName: 'MAX_STABLE_RATE_BORROW_SIZE_PERCENT'
            })
            commit('SET_MAX_STABLE_RATE_BORROW_SIZE_PERCENT', {maxStableRateBorrowSizePercent: maxStableRateBorrowSizePercent.toBigInt()})
        },
        async accumulatedEarnings({commit}, {address}) {
            try {
                if (!address) {
                    return
                }
                let data = await nodejsHttp.get('accumulatedEarnings/' + address)
                console.log('data', data)
                commit('SET_ACCUMULATED_EARNINGS', {accumulatedEarnings: toBigInt(data)})
            } catch (e) {
                console.log(e)
            }
        },
        async getSTFILTokenAllowance({rootState}, {address}) {
            return await readContract({
                address: contracts[rootState.evn].STFILToken.eth,
                abi: STFILTokenABI.abi,
                functionName: 'allowance',
                args: [address, contracts[rootState.evn].WSTFILToken.eth],
            })
        },
        async stFILPerToken({rootState}) {
            let stFILPerToken = await readContract({
                address: contracts[rootState.evn].WSTFILToken.eth,
                abi: WSTFILTokenABI.abi,
                functionName: 'getwstFILBystFIL',
                args: [1000000000000000000n],
            })
            return stFILPerToken.toBigInt()
        },
        async wstFILPerToken({rootState}) {
            let wstFILPerToken = await readContract({
                address: contracts[rootState.evn].WSTFILToken.eth,
                abi: WSTFILTokenABI.abi,
                functionName: 'getstFILBywstFIL',
                args: [1000000000000000000n],
            })
            return wstFILPerToken.toBigInt()
        },
    },
    modules: {}
}

export default stakeInfo
