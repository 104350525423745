import "core-js/modules/web.dom-exception.stack.js";
import IHeader from '@/components/header/IHeader';
import IFooter from '@/components/footer/IFooter';
import { isEmpty } from "@/utils/model";
import { getLocal, setLocal } from "@/utils/common";
export default {
  name: 'App',
  components: {
    IHeader,
    IFooter
  },
  data() {
    return {
      heraldVersion: "3",
      showHerald: false
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.$store.commit('SET_REFS', {
      name: 'iHeader',
      ref: this.$refs['iHeader']
    });
    this.$watch(() => this.$route.query, () => {
      this.initReferralCode();
    });
    this.initScreenWidth();
  },
  methods: {
    init() {
      this.initHerald();
      this.$store.dispatch('initFilePrice');
      window.addEventListener('load', () => {
        this.$store.commit('SET_ASSETS_LOADING', {
          assetsLoading: false
        });
      });
    },
    // 获取屏幕宽度
    initScreenWidth() {
      this.$store.commit('SET_SCREEN_WIDTH', {
        screenWidth: window.innerWidth
      });
      window.addEventListener('resize', () => {
        this.$store.commit('SET_SCREEN_WIDTH', {
          screenWidth: window.innerWidth
        });
      });
    },
    async initReferralCode() {
      let ref = this.$route.query.ref;
      if (isEmpty(ref)) {
        ref = this.$route.params.ref;
        if (isEmpty(ref)) {
          ref = getLocal("referralCode");
          if (isEmpty(ref)) {
            return;
          }
        }
      }
      await this._initReferralCode(ref);
    },
    async _initReferralCode(ref) {
      let referralCode = parseInt(ref);
      if (isNaN(referralCode)) {
        referralCode = atob(ref);
        referralCode = parseInt(referralCode);
        if (isNaN(referralCode)) {
          return;
        }
      }
      let address = await this.$store.dispatch('checkReferralCode', {
        referralCode
      });
      if (address === '0x0000000000000000000000000000000000000000') {
        return;
      }
      console.log('referralCode', referralCode);
      this.$store.commit('SET_STAKE_REFERRAL_CODE', {
        referralCode
      });
      setLocal("referralCode", referralCode);
    },
    initHerald() {
      // 关闭的版本
      let _heraldVersion = getLocal("isCloseHeraldVersion");
      if (_heraldVersion) {
        this.showHerald = _heraldVersion !== this.heraldVersion;
      } else {
        if (this.heraldVersion) {
          this.showHerald = true;
        }
      }
    },
    closeHerald() {
      if (this.heraldVersion) {
        setLocal("isCloseHeraldVersion", this.heraldVersion);
      } else {
        this.showHerald = false;
      }
      this.initHerald();
    }
  },
  computed: {}
};