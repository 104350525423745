export default {
  name: "AdCard",
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  methods: {
    btnClick() {
      this.$emit('btnClick');
    }
  }
};