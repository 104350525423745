export default {
  name: "radio-btn",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    tabArr: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    disableTabs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    modelValue: {
      type: String
    },
    theme: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  methods: {
    innerClass(tab) {
      if (this.disabled) {
        return 'radio-disable-btn';
      }
      if (this.disableTabs.findIndex(i => i === tab) >= 0) {
        return 'radio-disable-btn';
      }
      if (this.modelValue === tab) {
        if (this.theme === 1) {
          return 'radio-btn-checked';
        } else if (this.theme === 2) {
          return 'radio-btn-checked-theme-2';
        } else {
          return 'radio-btn-checked';
        }
      }
      return '';
    },
    checked(val) {
      if (this.disabled) {
        return;
      }
      if (this.disableTabs.findIndex(i => i === val) >= 0) {
        return;
      }
      this.$emit('update:modelValue', val);
    }
  }
};