import RadioBtn from '@/components/RadioBtn';
import Tip from '@/components/tip/Tip';
import NextBtnGroup from './NextBtnGroup';
import { formatBit, formatPrice, toBigInt } from '@/utils/NumU';
import { formatUnits } from "ethers/lib.esm/utils";
import Tooltip from '@/components/tip/Tooltip';
import PercentageMath from "@/utils/contracts/PercentageMath";
import { gotoAddress } from "@/utils/common";
export default {
  name: "NodeInfo",
  components: {
    RadioBtn,
    Tip,
    NextBtnGroup,
    Tooltip
  },
  data() {
    return {
      radio1MapVar: {
        'Owner': 'ownerInfo',
        'Beneficiary': 'beneficiaryInfo'
      }
    };
  },
  created() {
    this.$store.commit('SET_NODE_ROLE', {
      nodeRole: 'Owner'
    });
  },
  methods: {
    formatBit,
    formatPrice,
    formatUnits,
    gotoAddress,
    calcFree() {},
    next() {
      this.$emit('next');
    },
    pre() {
      this.$emit('pre');
    }
  },
  computed: {
    positionValue() {
      return toBigInt(this.currentNode.position_value);
    },
    maxLeverage() {
      return toBigInt(this.currentNode.max_leverage);
    },
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    safetyBuffer() {
      return toBigInt(this.currentNode.safetyBuffer);
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    debtRatio() {
      if (this.debtValue === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(this.debtValue, this.positionValue);
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    loading() {
      return this.$store.state.node.searchLoading;
    },
    radio1: {
      get() {
        console.log(this.$store.state.node.nodeRole);
        return this.$store.state.node.nodeRole;
      },
      set(nodeRole) {
        this.$store.commit('SET_NODE_ROLE', {
          nodeRole
        });
      }
    }
  }
};