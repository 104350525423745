export default {
  name: "TipC",
  props: {
    level: {
      type: String,
      default: 'tips'
    }
  },
  data() {
    return {
      contentWidth: '',
      opacity: ''
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.calcWidth();
    });
    this.calcWidth();
  },
  methods: {
    calcWidth() {
      let width = this.$refs['tipRef']?.offsetWidth;
      if (width < 500) {
        this.contentWidth = '95%';
        this.opacity = '0.2';
      } else {
        this.contentWidth = '75%';
        this.opacity = '1';
      }
    },
    leftColor() {
      switch (this.level) {
        case 'tips':
          return '#00CE92';
        case 'warring':
          return '#e08714';
        case 'error':
          return '#ea1717';
      }
    },
    bgColor() {
      switch (this.level) {
        case 'tips':
          return 'rgba(0,206,146,0.1)';
        case 'warring':
          return 'rgba(224,135,20,0.2)';
        case 'error':
          return 'rgba(234,23,23,0.2)';
      }
    }
  }
};