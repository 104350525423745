import "core-js/modules/es.array.push.js";
import nodejsHttp from "@/utils/http/nodejsHttp";
import { isEmpty } from "@/utils/model";
import { SuccessFilled } from '@element-plus/icons-vue';
import { externalFormatFilPrice, toBigInt } from "@/utils/NumU";
import { getPrefix, NotificationErr, simpleValue } from "@/utils/common";
import SingerV2 from '@/components/induction/singer/SingerV2';
import { loutsRpc, walletProvider } from "@/utils/LoutsRpc";
import { getF4AddressByEth } from "@/utils/contracts/singer/filecoin_utils";
import store from "@/store";
import StorageProviderLendingPool from "@/utils/abi/StorageProviderLendingPool.json";
import { StateReadState } from "@/utils/FilecoinApi";
import { readContract } from "@wagmi/core";
import { contracts } from "@/utils/contracts/config";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { BigNumber } from "ethers";
import { Search } from '@element-plus/icons-vue';
export default {
  name: "MigratorC",
  components: {
    SuccessFilled,
    SingerV2
  },
  data() {
    return {
      Search,
      completeFlag: false,
      readySign: false,
      loadingPool: false,
      isMigrator: false,
      poolList: [],
      selectPoolAddress: '',
      poolHealthyErr: false,
      checkIndex: -1,
      showCancel: false,
      loading: false,
      contractMethod: '',
      contractABI: {},
      contractParams: [],
      toF0Address: '',
      fromAddress: '',
      originalOwnerF0: '',
      originalOwner: '',
      singerMultiple: false,
      search: {
        page: 1,
        size: 15,
        total: 0,
        data: '',
        generateCodeFinish: false,
        generateCodeInputTime: 0,
        checkSerchLoading: false
      }
    };
  },
  props: {
    nodeId: {
      type: String,
      default: '',
      required: true
    }
  },
  created() {
    this.init();
  },
  watch: {
    address() {
      this.init();
    }
  },
  methods: {
    init() {
      if (!isEmpty(this.address)) {
        this.getPoolList();
      } else {
        this.initPoolList();
      }
    },
    initPoolList() {
      this.poolList = [];
      this.search.total = 0;
      this.search.page = 1;
      this.search.size = 15;
      this.search.data = '';
      this.search.generateCodeFinish = false;
      this.search.generateCodeInputTime = 0;
      this.search.checkSerchLoading = false;
      this.checkIndex = -1;
      this.readySign = false;
      this.completeFlag = false;
    },
    async migrator() {
      this.readySign = false;
      this.loading = true;
      await this.initPool();
      this.loading = false;
      this.readySign = true;
      this.$nextTick(() => {
        this.$refs['singer'].init().then(() => {
          this.showCancel = true;
        });
      });
    },
    async initOriginalOwner(originalOwnerF0) {
      this.originalOwnerF0 = originalOwnerF0;
      let stateReadState = await loutsRpc(StateReadState(this.originalOwnerF0));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        this.singerMultiple = true;
        this.originalOwner = this.originalOwnerF0;
      } else {
        if (!stateReadStateRes.State.Address) {
          NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
          // this.singleSignatureInitErr()
          return;
        }
        this.originalOwner = stateReadStateRes.State.Address;
      }
    },
    async initPool() {
      this.contractMethod = 'migrator';
      this.contractParams = [parseInt(this.nodeId)];
      this.contractABI = StorageProviderLendingPool;
      this.toEthAddress = this.poolList[this.checkIndex].address;
      let f4address = getF4AddressByEth(this.toEthAddress, store.state.evn);
      this.toF0Address = await walletProvider.lookupId(f4address);
      let nodeData = await readContract({
        address: contracts[this.evn].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [parseInt(this.nodeId)]
      });
      let {
        owner
      } = nodeData[0];
      let ownerId = getPrefix() + (BigNumber.from(owner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      await this.initOriginalOwner(ownerId);
      if (!this.originalOwner) {
        return;
      }
      this.fromAddress = this.originalOwner;
    },
    reset() {
      this.readySign = false;
      this.showCancel = false;
    },
    complete() {
      this.completeFlag = true;
    },
    externalFormatFilPrice,
    simpleValue,
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    checkPool(index) {
      this.checkIndex = index;
    },
    pageChange(page) {
      this.search.page = page;
      this.getPoolList();
    },
    changeSearch() {
      this.search.generateCodeFinish = false;
      let wait = 500;
      this.search.generateCodeInputTime = new Date().getTime() + wait;
      setTimeout(() => {
        let now = new Date().getTime();
        if (now - this.search.generateCodeInputTime >= 0) {
          this.search.checkSerchLoading = true;
          this.search.page = 1;
          this.getPoolList();
          this.search.checkSerchLoading = false;
        }
      }, wait);
    },
    async getPoolList() {
      if (!this.isConnected) {
        return;
      }
      this.loadingPool = true;
      let res = await nodejsHttp.get('pool', {
        params: {
          admin: this.address,
          poolAddress: this.search.data,
          page: this.search.page,
          size: this.search.size,
          onlyNewNode: 1,
          onlyAdmin: this.address
        }
      });
      this.poolList = res.list;
      this.search.total = parseInt(res.total);
      this.loadingPool = false;
    },
    viewNode() {
      this.$router.push({
        path: '/farm/lendingPool/provider/' + this.poolList[this.checkIndex].address + '/' + this.nodeId
      });
    },
    back() {
      this.$router.push({
        path: `/farm/provider/${this.nodeId}`
      });
    }
  },
  computed: {
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    evn() {
      return this.$store.state.evn;
    }
  }
};