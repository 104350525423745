import "core-js/modules/es.array.push.js";
import { ElStep, ElSteps } from 'element-plus';
import { CircleCheckFilled } from '@element-plus/icons-vue';
import Tip from '@/components/tip/Tip';
import AdCard2 from '@/components/tip/AdCard2';
import { readContract } from '@wagmi/core';
import SingerV2 from '@/components/induction/singer/SingerV2';
import { toBigInt } from "@/utils/NumU";
import { gotoAddress, NotificationErr } from "@/utils/common";
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
import { WadRayMath } from "@/utils/contracts/WadRayMath";
import { _calcDebtRatio } from "@/utils/NodeUtils";
import { loutsRpc, retryFun, walletProvider } from "@/utils/LoutsRpc";
import { getF4AddressByEth } from "@/utils/contracts/singer/filecoin_utils";
import store from "@/store";
import { BigNumber } from "ethers";
import { StateReadState } from "@/utils/FilecoinApi";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "SubPoolOffBoarding",
  components: {
    ElStep,
    ElSteps,
    Tip,
    AdCard2,
    SingerV2
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      canOffboarding: true,
      haveGuaranteeNodeId: false,
      radio1: 'Node Owner',
      CircleCheckFilled,
      active: 1,
      isConnected: false,
      connectErr: false,
      sendProposal: false,
      approvedProposalRes: false,
      inputs: {
        input1: 'f01234',
        input2: 0,
        input3: 10101
      },
      radio2: 'Content',
      singerTables: [],
      contractParams: [],
      contractMethod: 'undelegate',
      changeAddress: '',
      originalOwner: '',
      originalOwnerF0: '',
      isActive: true,
      // 判断是否执行了第一步
      isFineOffBoard: false,
      // 判断是否真的离职了
      showNextStepBtn: false,
      contractABI: StorageProviderLendingPoolABI,
      oneStepToEthAddress: '',
      oneStepToF0Address: '',
      poolF4Address: '',
      poolF0Address: '',
      towStepFromAddress: '',
      towStepToF0Address: '',
      singerMultiple: false,
      oneStepSignType: 'evm',
      signLoading: true
    };
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    },
    poolAddress: {
      type: String,
      default: ''
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.initPoolAddress();
      await this.checkNodeUndelegateCondition();
      if (!this.canOffboarding) {
        return;
      }
      try {
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    async initPoolAddress() {
      this.poolF4Address = getF4AddressByEth(this.poolAddress, store.state.evn);
      this.poolF0Address = await walletProvider.lookupId(this.poolF4Address);
      this.originalOwner = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'nodeOwner',
        args: [parseInt(this.nodeId.substr(2))]
      });
      this.originalOwnerF0 = this.getNetworkPrefix() + '0' + (BigNumber.from(this.originalOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      let stateReadState = await loutsRpc(StateReadState(this.originalOwnerF0));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        this.singerMultiple = true;
        this.originalOwner = this.originalOwnerF0;
      } else {
        this.originalOwner = stateReadStateRes.State.Address;
      }
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.evn === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    initSignLoading(loading) {
      console.log('initSignLoading', loading);
      this.signLoading = loading;
    },
    showNextStepBtnFun() {
      this.showNextStepBtn = true;
    },
    async checkOwnerSign(oneStepSignType) {
      this.oneStepSignType = oneStepSignType;
      await this.initActorState();
    },
    async initActorState() {
      if (this.isActive) {
        this.contractParams = [];
        this.contractParams.push(parseInt(this.nodeId.toString().substr(2)));
        this.contractMethod = 'undelegate';
        this.oneStepToEthAddress = this.poolAddress;
        this.oneStepToF0Address = this.poolF0Address;
        this.$nextTick(async () => {
          this.$refs['singer-' + this.oneStepSignType].init();
        });
      } else {
        this.sendProposalFun();
      }
    },
    // 检查节点是否到达离职条件
    async checkNodeUndelegateCondition() {
      let _actorId = parseInt(this.nodeId.substr(2));
      let _isDelegated = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'isDelegated',
        args: [_actorId]
      });
      if (!_isDelegated) {
        let minerInfo = await retryFun(() => walletProvider.minerInfo(this.nodeId), 3);
        let nowOwner = minerInfo.Owner.toString().substr(2);
        let poolF4Address = getF4AddressByEth(this.poolAddress, store.state.evn);
        let poolF0Address = await walletProvider.lookupId(poolF4Address);
        this.isActive = false;
        if (nowOwner !== poolF0Address.substr(2)) {
          this.isFineOffBoard = true;
          setTimeout(() => {
            this.$router.replace({
              path: `/farm/lendingPool/provider/${this.poolAddress}/${this.nodeId.toString().substr(2)}`,
              query: {
                isActive: false
              }
            });
          }, 1500);
        }
        return;
      }
      this.isActive = true;
      let _nodeDebt = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'debt',
        args: [_actorId]
      });
      if (_nodeDebt.toBigInt() !== 0n) {
        this.canOffboarding = false;
        return;
      }
      let _totalPoolDebt = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'totalDebt',
        args: []
      });
      if (_totalPoolDebt.toBigInt() > 0n) {
        let _nodePosition = await readContract({
          address: this.poolAddress,
          abi: StorageProviderLendingPoolABI.abi,
          functionName: 'position',
          args: [_actorId]
        });
        let _totalPoolPosition = await readContract({
          address: this.poolAddress,
          abi: StorageProviderLendingPoolABI.abi,
          functionName: 'totalPosition',
          args: []
        });
        console.log('_totalPoolPosition.toBigInt()', _totalPoolPosition.toBigInt());
        console.log('_nodePosition.toBigInt()', _nodePosition.toBigInt());
        if (_totalPoolPosition.toBigInt() < _nodePosition.toBigInt()) {
          this.canOffboarding = false;
          return;
        }
        let _maxLeverage = await readContract({
          address: this.poolAddress,
          abi: StorageProviderLendingPoolABI.abi,
          functionName: 'maxLeverage',
          args: []
        });
        let _maxDebtRatio = WadRayMath.wadDiv(_maxLeverage.toBigInt() - WadRayMath.WAD, _maxLeverage.toBigInt());
        let _nodeDebtRatio = _calcDebtRatio(_nodeDebt.toBigInt(), _totalPoolPosition.toBigInt() - _nodePosition.toBigInt());
        console.log('_maxDebtRatio', _maxDebtRatio);
        console.log('_nodeDebtRatio', _nodeDebtRatio);
        if (_nodeDebtRatio > _maxDebtRatio) {
          this.canOffboarding = false;
        }
      }
    },
    pushProposalNext() {
      this.active++;
    },
    connectWallet() {
      this.isConnected = true;
    },
    connectWalletErr() {
      this.connectErr = true;
      this.isConnected = true;
    },
    sendProposalFun() {
      this.changeAddress = this.originalOwnerF0;
      this.contractMethod = '';
      this.contractParams = [];
      this.towStepFromAddress = this.originalOwner;
      this.towStepToF0Address = this.nodeId;
      this.showNextStepBtn = false;
      this.sendProposal = true;
      this.active++;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    approvedProposal() {
      this.active++;
    },
    approvedProposal2() {
      this.approvedProposalRes = true;
    },
    gotoAddress,
    unBindNode() {
      this.$router.push({
        path: '/farm/unCollateralNode/' + this.nodeId.toString().substr(2)
      });
    }
  },
  computed: {
    evn() {
      return this.$store.state.evn;
    },
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    nodeRole: {
      get() {
        return this.$store.state.node.nodeRole;
      },
      set(nodeRole) {
        this.$store.commit('SET_NODE_ROLE', {
          nodeRole
        });
      }
    }
  }
};