import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-39431584"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "evm-tip-content"
};
const _hoisted_2 = {
  class: "d-flex align-items-center"
};
const _hoisted_3 = {
  style: {
    "transform": "translateY(2px)",
    "margin-right": "4px"
  }
};
const _hoisted_4 = {
  style: {
    "color": "rgb(77, 56, 0)",
    "font-weight": "bold"
  }
};
const _hoisted_5 = {
  style: {
    "color": "rgb(77, 56, 0)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WarningFilled = _resolveComponent("WarningFilled");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_icon, {
    color: "rgb(77, 56, 0)"
  }, {
    default: _withCtx(() => [_createVNode(_component_WarningFilled)]),
    _: 1
  })]), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("transfer.contractTipTile")), 1)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])]);
}