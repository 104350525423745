import { ElSkeleton } from 'element-plus';
export default {
  name: "SkeletonC",
  components: {
    ElSkeleton
  },
  data() {
    return {
      loading: false
    };
  }
};