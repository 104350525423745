import RadioBtn from '@/components/RadioBtn';
import { mapState } from "vuex";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { ethTo, externalFormatFilPrice, floatToFixed, formatWalletFilPrice, toBigInt, toEth } from "@/utils/NumU";
import { E23, WadRayMath } from "@/utils/contracts/WadRayMath";
import { formatUnits } from "ethers/lib.esm/utils";
import { BigNumber, ethers } from "ethers";
import IndicatorValue from '@/components/farm/overview/indicator/IndicatorValue';
import { getLocal, getPrefix, gotoAddress, gotoHash, NotificationErr, NotificationSuccess, simpleValue } from "@/utils/common";
import { iWriteContract } from "@/utils/contracts/opertion";
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
export default {
  name: "ExitReserve",
  components: {
    RadioBtn,
    IndicatorValue
  },
  data() {
    return {
      loading: false,
      radio3: 'Variable',
      radio1: 'By Leverage',
      tabOptions: [],
      tabOptions1: [],
      sliderValue: 0,
      oldSliderValue: 0,
      firstSliderValue: 0,
      adjustBorrowingValue: '',
      hash: '',
      btnText: '',
      mainSliderWidth: 0
    };
  },
  watch: {
    '$i18n.locale'() {
      this.initTabOption();
    },
    radio3() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
    },
    radio1() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
    }
  },
  created() {
    this.init();
  },
  methods: {
    goToRepay() {
      this.$emit('gotoRepay');
    },
    gotoHash,
    formatUnits,
    gotoAddress,
    connectWallet() {
      if (this.approveOperator()) {
        return;
      }
      this.$store.state.refs['iHeader'].connectWallet();
    },
    externalFormatFilPrice,
    formatWalletFilPrice,
    init() {
      this.setMainSliderWidth();
      this.initTabOption();
      this.$store.commit('SET_BORROW_RATE_TYPE', {
        borrowRateType: 'Variable'
      });
      this.sliderValue = 0;
      this.initSliderValue();
    },
    setMainSliderWidth() {
      this.$nextTick(() => {
        if (this.$refs['main-slider']) {
          this.mainSliderWidth = this.$refs['main-slider'].offsetWidth - 16;
        }
      });
    },
    calcCurrentLeverage(new_debt_value) {
      return this.currentLeverage + PercentageMath.div(new_debt_value - this.withdrawLoadAmount, this.equityValue);
    },
    valueToSliderValue(val) {
      let leverage = PercentageMath.div(this.equityValue + val, this.equityValue);
      let diff = leverage - 10000n;
      let maxDiff = this.maxLeverage - 10000n;
      let res = (parseInt(diff.toString()) / parseInt(maxDiff.toString()) * 100).toFixed(4);
      return parseFloat(res);
    },
    initSliderValue() {
      let leverage = this.currentLeverage;
      this.sliderValue = parseFloat((parseInt((leverage - toBigInt(10000)).toString()) / parseInt((this.maxLeverage - toBigInt(10000)).toString()) * 100 + 0.0001).toFixed(4));
      if (this.sliderValue > 100) {
        this.sliderValue = 100;
      }
      this.firstSliderValue = this.sliderValue;
    },
    sliderFormatTip(val) {
      let res = val / 100 * (this.maxLeverageInt - 10000) + 10000;
      return (res / 10000).toFixed(4) + 'x';
    },
    initTabOption() {
      this.tabOptions = [{
        label: this.$t('farm.console.Variable'),
        value: 'Variable'
      }, {
        label: this.$t('farm.console.Stable'),
        value: 'Stable'
      }];
      this.tabOptions1 = [{
        label: this.$t('farm.console.ByLeverage'),
        value: 'By Leverage'
      }, {
        label: this.$t('farm.console.ByAmount'),
        value: 'By Amount'
      }];
    },
    changeSliderValue(val) {
      if (this.youAreBorrowing === 0n || !this.isBorrowed) {
        this.sliderValue = this.firstSliderValue;
        this.oldSliderValue = val;
        return;
      }
      if (this.isBorrowed) {
        if (this.radio3 === 'Stable') {
          let leftDebtValue = this.maxBorrowableLiquidityAmount > this.stableBorrowingAmount ? this.stableBorrowingAmount : this.maxBorrowableLiquidityAmount;
          if (this.youAreBorrowing >= leftDebtValue) {
            this.sliderValue = this.firstSliderValue + this.valueToSliderValue(leftDebtValue);
            return;
          }
        }
        if (this.youAreBorrowing >= this.maxBorrowableLiquidityAmount) {
          this.sliderValue = this.firstSliderValue + this.valueToSliderValue(this.maxBorrowableLiquidityAmount);
          return;
        }
        if (this.youAreBorrowing >= this.poolStFilBalance) {
          this.sliderValue = this.firstSliderValue + this.valueToSliderValue(this.poolStFilBalance);
          return;
        }
      }
      this.oldSliderValue = val;
    },
    borrowMax() {
      if (this.loading) {
        return;
      }
      // 最大可借金额
      let leftDebtValue = this.maxWithdrawLoanAmount;
      leftDebtValue = leftDebtValue > this.maxBorrowableLiquidityAmount ? this.maxBorrowableLiquidityAmount : leftDebtValue;
      if (leftDebtValue < 0n) {
        return;
      }
      if (this.radio3 !== 'Variable') {
        leftDebtValue = leftDebtValue > this.stableBorrowingAmount ? this.stableBorrowingAmount : leftDebtValue;
      }
      if (leftDebtValue > this.poolStFilBalance) {
        this.adjustBorrowingValue = ethTo(this.poolStFilBalance.toString()).toFixed(4);
      } else {
        this.adjustBorrowingValue = ethTo(leftDebtValue.toString()).toFixed(4);
      }
      this.calcSliderValue(ethers.utils.parseEther(this.adjustBorrowingValue.toString()).toBigInt());
    },
    calcSliderValue(val) {
      let debt_value = this.withdrawLoadAmount;
      let new_debt_value = debt_value + val;
      let leverage = toBigInt(0);
      if (val >= 0) {
        leverage = this.calcCurrentLeverage(new_debt_value);
      } else {
        leverage = PercentageMath.div(this.equityValue + new_debt_value, this.equityValue);
      }
      this.sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
    },
    adjustBorrowingValueChange() {
      if (this.adjustBorrowingValue === '') {
        this.sliderValue = this.firstSliderValue;
        return;
      }
      if (this.adjustBorrowingValue < 0) {
        this.adjustBorrowingValue = 0;
      }
      let debt_value = this.withdrawLoadAmount;
      // 最大可借金额
      let leftDebtValue = PercentageMath.mul(this.equityValue, this.maxLeverage - toBigInt(10000)) - debt_value;
      console.log('this.maxBorrowableLiquidityAmount', this.maxBorrowableLiquidityAmount);
      leftDebtValue = leftDebtValue > this.maxBorrowableLiquidityAmount ? this.maxBorrowableLiquidityAmount : leftDebtValue;
      if (this.radio3 !== 'Variable') {
        leftDebtValue = leftDebtValue > this.stableBorrowingAmount ? this.stableBorrowingAmount : leftDebtValue;
      }
      let bigIntAdjustBorrowingValue = toEth(this.adjustBorrowingValue.toString());

      // 超过最大可借
      if (bigIntAdjustBorrowingValue > leftDebtValue) {
        if (leftDebtValue > this.poolStFilBalance) {
          this.adjustBorrowingValue = ethTo(this.poolStFilBalance.toString()).toFixed(4);
        } else {
          this.adjustBorrowingValue = ethTo(leftDebtValue).toFixed(4);
        }
      } else {
        this.adjustBorrowingValue = floatToFixed(this.adjustBorrowingValue, 4);
      }
      this.calcSliderValue(ethers.utils.parseEther(this.adjustBorrowingValue.toString()).toBigInt());
    },
    approveOperator() {
      if (this.operator === '0x0000000000000000000000000000000000000000') {
        this.$emit('approveOperator');
        return true;
      }
      if (this.isConnected && this.operator !== this.address) {
        NotificationErr(this.$t('subpool.You are not the creator of the loan pool'));
        return true;
      }
      return false;
    },
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    exitReserve() {
      if (this.paused) {
        return;
      }
      if (this.approveOperator()) {
        return;
      }
      if (this.youAreBorrowing <= 0) {
        NotificationErr('Please enter the amount borrowed');
        return;
      }
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      let youAreBorrowing = this.youAreBorrowing;
      if (this.sliderValue >= 99) {
        youAreBorrowing = this.youAreBorrowing - 99999999999999n;
      }
      iWriteContract({
        address: this.currentPool.address.eth,
        f0address: this.currentPool.address.f0address,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: "withdrawLoan",
        args: [this.currentPoolNode.node, BigNumber.from(youAreBorrowing), this.getRoteMode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          NotificationSuccess(`You have successfully Withdraw loan ${parseFloat(ethers.utils.formatEther(this.youAreBorrowing)).toFixed(6)}FIL.`);
          this.loading = false;
          this.clearContent();
          this.adjustRepayingValue = '';
          this.adjustBorrowingValue = '';
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentPoolNode.node
          });
          this.$emit('updateInfo');
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message, "exitReserve" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.clearContent();
      });
    }
  },
  computed: mapState({
    poolInfoLoading: state => state.subpool.loading.initCurrentPoolInfoLoading,
    poolNodeInfoLoading: state => state.subpool.loading.initCurrentPoolNodeDataLoading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.poolInfoLoading || this.poolNodeInfoLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    operator() {
      return this.currentPool.admin;
    },
    isBorrowed() {
      return this.sliderValue >= this.firstSliderValue;
    },
    sliderChangeClass() {
      if (this.sliderValue >= this.firstSliderValue) {
        return '';
      } else {
        return 'i-auto-slider';
      }
    },
    maxBorrowableLiquidityAmount: state => state.publicInfo.maxBorrowableLiquidityAmount,
    totalStakeTokens: state => {
      return toBigInt(state.stakeInfo.totalStakingTokens);
    },
    afterVariableRate: state => {
      return state.nodeData.afterVariableRate;
    },
    afterStableRate() {
      if (this.youAreBorrowing > 0n) {
        return PercentageMath.div(1000000000000000n + PercentageMath.mul(this.userStableSimpleRate, this.stableDebtValue) + PercentageMath.mul(this.youAreBorrowing, this.stableBorrowSimpleRate), this.stableDebtValue + this.youAreBorrowing);
      }
      if (this.youAreBorrowing + this.stableDebtValue <= 0) {
        return 0n;
      }
      return this.userStableSimpleRate;
    },
    afterAvailableBorrowingAmount() {
      let res = this.availableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    borrowMode() {
      return this.$store.state.nodeData.borrowMode;
    },
    afterVariableDebtValue() {
      if (this.borrowMode === 0) {
        return this.variableDebtValue;
      }
      if (this.borrowMode === 2) {
        if (this.variableDebtValue + this.youAreBorrowing <= 1000000000000000n) {
          return 0n;
        }
        return this.variableDebtValue + this.youAreBorrowing;
      } else {
        return this.variableDebtValue;
      }
    },
    afterStableDebtValue() {
      if (this.borrowMode === 1) {
        if (this.stableDebtValue + this.youAreBorrowing <= 1000000000000000n) {
          return 0n;
        }
        return this.stableDebtValue + this.youAreBorrowing;
      } else {
        return this.stableDebtValue;
      }
    },
    availableBorrowingAmount() {
      return this.maxWithdrawLoanAmount;
    },
    leftAvailableBorrowingAmount() {
      let res = this.maxWithdrawLoanAmount;
      if (res > this.maxBorrowableLiquidityAmount) {
        return this.maxBorrowableLiquidityAmount;
      }
      return res;
    },
    afterLeftAvailableBorrowingAmount() {
      let res = this.leftAvailableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    stableRateEnabled: state => state.publicInfo.stableRateEnabled,
    poolIsPaused: state => state.publicInfo.poolIsPaused,
    isActive: state => state.currentNode.is_active === '1',
    getRoteMode() {
      if (this.radio3 === 'Variable') {
        return 2;
      }
      return 1;
    },
    address() {
      return this.$store.state.address;
    },
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    currentPoolNode() {
      return this.$store.state.subpool.currentPoolNode;
    },
    currentPool() {
      return this.$store.state.subpool.currentPool;
    },
    stableDebtValue() {
      return this.currentPoolNode.stable_debt_value;
    },
    variableDebtValue() {
      return this.currentPoolNode.variable_debt_value;
    },
    disableRate() {
      if (!this.stableRateEnabled) {
        return ['Stable'];
      }
      return [];
    },
    marksMax() {
      let leverage = this.currentLeverage;
      let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
      if (sliderValue > 100) {
        return sliderValue;
      } else {
        return 100;
      }
    },
    marks() {
      let maxLeverage = this.maxLeverageInt;
      let res = {
        0: {
          style: {
            top: '0'
          },
          label: '1.00x'
        },
        100: {
          style: {
            top: '0'
          },
          label: (maxLeverage / 10000).toFixed(2) + 'x'
        }
      };
      if (this.currentLeverage > 1) {
        let leverage = this.currentLeverage;
        let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0) {
          res[0].style.top = '17px';
          res[100].style.top = '17px';
          res[sliderValue] = {
            style: {
              color: '#00CE92',
              bottom: '17px'
            },
            label: `${parseInt(leverage.toString()) / 10000}x`
          };
        }
      }
      if (this.totalLeftGuarantee < this.muLeftWithdrawGuarantee) {
        let myTotalGuarantee = PercentageMath.div(this.entity, this.maxWithdrawLoadLeverage - 10000n);
        let leverage = PercentageMath.div(this.muLeftWithdrawGuarantee - this.totalLeftGuarantee, myTotalGuarantee) + 10000n;
        let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0 && !res[sliderValue]) {
          res[sliderValue] = {
            style: {
              color: '#8b8b8b',
              bottom: '-25px',
              fontSize: '12px'
            },
            label: `${parseInt(leverage.toString()) / 10000}x`
          };
        }
      }
      return res;
    },
    isYouAreRePayingAll() {
      if (this.youAreBorrowing < 0n) {
        if (this.radio3 === 'Variable') {
          if (this.youAreBorrowing + this.variableDebtValue <= 1000000000000000n) {
            return true;
          }
        } else {
          if (this.youAreBorrowing + this.stableDebtValue <= 1000000000000000n) {
            return true;
          }
        }
      }
      return false;
    },
    youAreBorrowing() {
      let youAreBorrowing = 0n;
      if (this.radio1 === 'By Amount') {
        youAreBorrowing = toEth(this.adjustBorrowingValue);
      } else {
        let leverage = parseInt((this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000).toString());
        if (this.currentLeverage === toBigInt(leverage)) {
          this.$store.commit('SET_YOU_ARE_BORROWING', {
            youAreBorrowing: 0n,
            borrowMode: this.getRoteMode
          });
          return toBigInt(0);
        }
        youAreBorrowing = PercentageMath.mul(toBigInt(leverage) - this.currentLeverage, this.equityValue);
        if (youAreBorrowing < 0n) {
          if (this.radio3 === 'Variable') {
            if (youAreBorrowing + this.variableDebtValue <= 0) {
              youAreBorrowing = this.variableDebtValue * -1n;
            }
          } else {
            if (youAreBorrowing + this.stableDebtValue <= 0) {
              youAreBorrowing = this.stableDebtValue * -1n;
            }
          }
        } else {
          if (leverage >= this.maxLeverage) {
            youAreBorrowing = this.availableBorrowingAmount;
          }
          if (youAreBorrowing > this.poolStFilBalance) {
            youAreBorrowing = this.poolStFilBalance;
          }
          if (youAreBorrowing > this.maxBorrowableLiquidityAmount) {
            youAreBorrowing = this.maxBorrowableLiquidityAmount;
          }
        }
      }
      this.$store.commit('SET_YOU_ARE_BORROWING', {
        youAreBorrowing,
        borrowMode: this.getRoteMode
      });
      return youAreBorrowing;
    },
    availableBalance() {
      return this.currentPoolNode.availableBalance;
    },
    expectedAvailableBalance() {
      return this.availableBalance + this.youAreBorrowing;
    },
    debtValue() {
      return this.currentPoolNode.debt_value;
    },
    poolDebtValue() {
      return this.currentPool.totalDebt;
    },
    poolMaxSealedLoadAmount() {
      return this.currentPool.maxSealedLoadAmount;
    },
    maxWithdrawLoanAmount() {
      return this.currentPoolNode.maxWithdrawLoanAmount;
    },
    withdrawLoadAmount() {
      return this.currentPoolNode.withdrawLoadAmount;
    },
    afterwithdrawLoadAmount() {
      return this.withdrawLoadAmount + this.youAreBorrowing;
    },
    maxLeverage() {
      return toBigInt(this.currentPool.maxWithdrawLoadLeverage) / WadRayMath.WAD_2;
    },
    maxWithdrawLoadLeverage() {
      return toBigInt(this.currentPool.maxWithdrawLoadLeverage) / WadRayMath.WAD_2;
    },
    maxSealLoadLeverage() {
      return this.currentPool.maxSealLoadLeverage / WadRayMath.WAD_2;
    },
    poolPositionValue() {
      return this.currentPool.totalPosition;
    },
    maxLeverageInt() {
      return parseInt(this.maxLeverage.toString());
    },
    positionValue() {
      return this.currentPoolNode.position_value;
    },
    afterPositionValue() {
      return this.currentPoolNode.position_value;
    },
    guaranteeCurrentPositionValue() {
      return this.guaranteeCurrenNode.position_value;
    },
    poolStFilBalance: state => state.publicInfo.poolStFilBalance,
    isExceedMaxDebtRate() {
      return this.debtRatio > this.poolMaxDebtRatio;
    },
    poolMaxDebtRatio() {
      return this.currentPool.maxDebtRatio / WadRayMath.WAD_2;
    },
    debtRatio() {
      if (this.poolDebtValue === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(this.poolDebtValue, this.poolPositionValue);
    },
    firstExpectedInitialBalance() {
      let withdrawLoadAmount = this.withdrawLoadAmount;
      let tmp = PercentageMath.div(withdrawLoadAmount, this.maxLeverage - toBigInt(10000));
      return withdrawLoadAmount + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    expectedInitialBalance() {
      let withdrawLoadAmount = this.withdrawLoadAmount + this.youAreBorrowing;
      let tmp = PercentageMath.div(withdrawLoadAmount, this.maxLeverage - toBigInt(10000));
      return withdrawLoadAmount + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    safetyBuffer() {
      return this.currentPoolNode.safetyBuffer;
    },
    afterSafetyBuffer() {
      if (this.youAreBorrowing <= 0) {
        if (this.youAreBorrowing + this.withdrawLoadAmount <= 0) {
          return 0n;
        }
        return this.safetyBuffer + this.youAreBorrowing;
      } else {
        let _debtValue = this.withdrawLoadAmount + this.youAreBorrowing;
        let tmp = PercentageMath.div(_debtValue, this.maxLeverage - toBigInt(10000));
        let sb1 = _debtValue + tmp;
        let sb2 = this.positionValue - this.availableBalance + this.youAreBorrowing;
        return sb1 > sb2 ? sb1 : sb2;
      }
    },
    expectedExpectedPower() {
      return 0;
    },
    expectedIncreasedEarnings() {
      return 0;
    },
    summaryPositionValue() {
      let res = this.positionValue + this.youAreBorrowing;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    summaryDebtValue() {
      return this.withdrawLoadAmount + this.youAreBorrowing;
    },
    summaryDebtRatio() {
      let withdrawLoadAmount = this.withdrawLoadAmount + this.youAreBorrowing;
      if (withdrawLoadAmount === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(withdrawLoadAmount, this.positionValue);
    },
    summaryLeverage() {
      return toBigInt(this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000);
    },
    summarySafetyBuffer() {
      return 0;
    },
    equityValue() {
      return this.positionValue - this.debtValue + this.withdrawLoadAmount;
    },
    afterEquityValue() {
      return this.summaryPositionValue - this.summaryDebtValue + this.withdrawLoadAmount;
    },
    variableBorrowRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    variableBorrowSimpleRate: state => {
      return toBigInt(state.publicInfo.variableBorrowRate / E23);
    },
    stableBorrowSimpleRate: state => {
      return toBigInt(state.publicInfo.stableBorrowRate / E23);
    },
    userStableRate() {
      return this.currentPoolNode.userStableRate;
    },
    userStableSimpleRate() {
      return toBigInt(this.userStableRate / E23);
    },
    currentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.positionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    afterCurrentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.summaryPositionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    maxBorrowRate: state => state.publicInfo.maxBorrowRate,
    currentBorrowingInterestAPR() {
      let _variable = PercentageMath.mul(this.variableDebtValue, toBigInt(this.variableBorrowRate / E23));
      let _stable = PercentageMath.mul(this.stableDebtValue, toBigInt(this.userStableRate / E23));
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    afterCurrentBorrowingInterestAPR() {
      let addVariable = 0n;
      let addStable = 0n;
      if (this.radio3 === 'Variable') {
        addVariable = this.youAreBorrowing;
      } else {
        addStable = this.youAreBorrowing;
      }
      let _variable = PercentageMath.mul(this.variableDebtValue + addVariable, this.afterVariableRate);
      let _stable = PercentageMath.mul(this.stableDebtValue + addStable, this.afterStableRate);
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    totalAPR() {
      return this.currentFarmAPR - this.currentBorrowingInterestAPR;
    },
    afterTotalAPR() {
      return this.afterCurrentFarmAPR - this.afterCurrentBorrowingInterestAPR;
    },
    dailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.totalAPR.toString(), 2)) / 365 * 1000000));
    },
    afterDailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.afterTotalAPR.toString(), 2)) / 365 * 1000000));
    },
    estimatedDailyEarnings() {
      return PercentageMath.mul(this.positionValue, this.dailyAPR) / 10000n;
    },
    afterEstimatedDailyEarnings() {
      return PercentageMath.mul(this.summaryPositionValue, this.afterDailyAPR) / 10000n;
    },
    maxStableRateBorrowSizePercent: state => state.stakeInfo.maxStableRateBorrowSizePercent,
    // 稳定利率最大可借
    stableBorrowingAmount() {
      let res = PercentageMath.mul(this.poolStFilBalance, this.maxStableRateBorrowSizePercent) - 1000000000000000n;
      if (res < 0) {
        return 0n;
      }
      if (res > this.maxBorrowableLiquidityAmount) {
        return this.maxBorrowableLiquidityAmount;
      }
      return res;
    },
    afterStableBorrowingAmount() {
      let res = this.stableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    guaranteeNodeId() {
      return this.currentNode.guaranteeNodeId;
    },
    guaranteeNode() {
      return this.currentNode.guarantee.guaranteeNode;
    },
    guaranteeCurrenNode() {
      return this.currentNode.guarantee.currentNode;
    },
    guaranteePositionValue() {
      return this.guaranteeNode.position_value;
    },
    guaranteeVariableDebtValue() {
      return this.guaranteeNode.variable_debt_value;
    },
    guaranteeVtableDebtValue() {
      return this.guaranteeNode.stable_debt_value;
    },
    guaranteeDebtValue() {
      return this.guaranteeNode.debt_value;
    },
    guaranteeLeverage() {
      return PercentageMath.div(this.equityValue + toBigInt(this.guaranteeDebtValue), this.equityValue);
    },
    guaranteeCurrenVariableDebtValue() {
      return this.guaranteeCurrenNode.variable_debt_value;
    },
    guaranteeCurrenStableDebtValue() {
      return this.guaranteeCurrenNode.stable_debt_value;
    },
    guaranteeCurrenDebtValue() {
      return this.guaranteeCurrenNode.debt_value;
    },
    otherLeverageSliderWidth() {
      let myTotalGuarantee = PercentageMath.div(this.entity, this.maxWithdrawLoadLeverage - 10000n);
      let leverage = PercentageMath.div(this.muLeftWithdrawGuarantee - this.totalLeftGuarantee, myTotalGuarantee) + 10000n;
      return `${parseFloat((leverage - 10000n).toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    assetsLoading: state => state.assetsLoading,
    leverageRate() {
      return PercentageMath.div(this.currentPool.maxWithdrawLoadLeverage - WadRayMath.WAD, this.currentPool.maxSealLoadLeverage - WadRayMath.WAD);
    },
    totalWithdrawLoadAmount() {
      return this.currentPool.withdrawLoadTotalAmount;
    },
    totalWithdrawLoadLeverage() {
      return PercentageMath.div(this.totalWithdrawLoadAmount, this.equityValue) + 10000n;
    },
    totalOriginalDebt() {
      return this.poolDebtValue - this.totalWithdrawLoadAmount;
    },
    totalOriginalLeverage() {
      return PercentageMath.mul(PercentageMath.div(this.totalOriginalDebt, this.equityValue), this.leverageRate) + 10000n;
    },
    currentLeverage() {
      let leverage = 0n;
      let myTotalGuarantee = PercentageMath.div(this.entity, this.maxWithdrawLoadLeverage - 10000n);
      if (this.totalLeftGuarantee >= this.muLeftWithdrawGuarantee) {
        leverage = PercentageMath.div(this.myWithdrawGuarantee, myTotalGuarantee) + 10000n;
      } else {
        leverage = PercentageMath.div(this.myWithdrawGuarantee + (this.muLeftWithdrawGuarantee - this.totalLeftGuarantee), myTotalGuarantee) + 10000n;
      }
      this.$store.commit('SET_CURRENT_LEVERAGE', {
        currentLeverage: leverage
      });
      return leverage;
    },
    nodeDebtValue() {
      return this.currentPoolNode.debt_value;
    },
    nodeOriginalDebt() {
      return this.nodeDebtValue - this.nodeWithdrawLoadAmount;
    },
    nodeOriginalDebtLeverage() {
      return PercentageMath.mul(PercentageMath.div(this.nodeOriginalDebt, this.equityValue), this.leverageRate) + 10000n;
    },
    nodeWithdrawLoadAmount() {
      return this.currentPoolNode.withdrawLoadAmount;
    },
    nodeWithdrawLoadLeverage() {
      return PercentageMath.div(this.nodeWithdrawLoadAmount, this.equityValue) + 10000n;
    },
    nodeLeverage() {
      return this.nodeOriginalDebtLeverage + this.nodeWithdrawLoadLeverage - 10000n;
    },
    otherNodeOriginalDebt() {
      return this.totalOriginalDebt - this.nodeOriginalDebt;
    },
    otherNodeDebt() {
      return this.otherNodeOriginalDebt + this.otherNodeWithdrawLoadAmount;
    },
    otherNodeOriginalDebtLeverage() {
      return PercentageMath.mul(PercentageMath.div(this.otherNodeOriginalDebt, this.equityValue), this.leverageRate) + 10000n;
    },
    otherNodeWithdrawLoadAmount() {
      return this.totalWithdrawLoadAmount - this.nodeWithdrawLoadAmount;
    },
    otherNodeWithdrawLoadLeverage() {
      return PercentageMath.div(this.otherNodeWithdrawLoadAmount, this.equityValue) + 10000n;
    },
    otherNodeLeverage() {
      return this.otherNodeOriginalDebtLeverage + this.otherNodeWithdrawLoadLeverage - 10000n;
    },
    otherNodeLeverageSliderWidth() {
      return `${parseFloat((this.otherNodeLeverage - 10000n).toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    // 总担保价值 (可能包括我自己的担保)
    totalGuaranteeValue() {
      let totalDebtGuarantee = PercentageMath.div(this.totalOriginalDebt, this.maxSealLoadLeverage - 10000n);
      let totalWithdrawLoadGuarantee = PercentageMath.div(this.totalWithdrawLoadAmount, this.maxWithdrawLoadLeverage - 10000n);
      return totalDebtGuarantee + totalWithdrawLoadGuarantee;
    },
    // 总权益价值
    totalEntity() {
      return this.currentPool.totalEntity;
    },
    // 剩余的可担保部分
    totalLeftGuarantee() {
      return this.totalEntity - this.totalGuaranteeValue;
    },
    // 我的权益价值
    entity() {
      return this.currentPoolNode.entity;
    },
    // 我的提现借款担保
    myWithdrawGuarantee() {
      return PercentageMath.div(this.withdrawLoadAmount, this.maxWithdrawLoadLeverage - 10000n);
    },
    // 我剩余的借款提现担保
    muLeftWithdrawGuarantee() {
      return this.entity - this.myWithdrawGuarantee;
    },
    paused: state => state.SPLP_IS_PAUSED,
    oldOwner() {
      return this.currentPoolNode.oldOwner;
    },
    oldOwnerShow() {
      if (this.oldOwner && this.oldOwner.toString().toLowerCase().startsWith("0xff00000000000")) {
        return getPrefix() + eval(this.oldOwner.toString().toLowerCase().replace("0xff", "0x")).toString(10);
      } else {
        return simpleValue(this.oldOwner ? this.oldOwner.toString() : null, 8, 8);
      }
    }
  })
};