import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import "core-js/modules/es.array.push.js";
import { isEmpty } from "@/utils/model";
import { externalFormatFilPrice, toBigInt } from "@/utils/NumU";
import { formatUnits } from "ethers/lib.esm/utils";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { readContract } from "@wagmi/core";
import { contracts } from "@/utils/contracts/config";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { getPrefix, NotificationErr } from "@/utils/common";
import { BigNumber } from "ethers";
import { loutsRpc } from "@/utils/LoutsRpc";
import { ChainHead, ChainReadObj, StateAccountKey, StateGetActor } from "@/utils/FilecoinApi";
import { decode as dagCborDecode } from "@ipld/dag-cbor";
import { newAddressFromBytes } from "@/utils/contracts/singer/filecoin_utils";
import { encode as addressToString } from "@glif/filecoin-address";
import Singer from '@/components/induction/singer/Singer';
import { CopyDocument } from '@element-plus/icons-vue';
import Clipboard from "clipboard";
export default {
  name: "UnBindNodeComponent",
  components: {
    Singer,
    CopyDocument
  },
  data() {
    return {
      sameOwnerNodes: [],
      loadingNode: true,
      loading: false,
      checkIndex: -1,
      contractMethod: '',
      readySign: false,
      nodeRole: '',
      singerMultiple: false,
      contractParams: [],
      singerTables: [],
      showCancel: false,
      completeFlag: false,
      guaranteeNodeId: '',
      isCopy: false,
      noGuaranteeNode: false,
      noGuaranteeNodeErr: '',
      noGuaranteeNodeErrNodeId: ''
    };
  },
  props: {
    nodeId: {
      type: String,
      default: '',
      required: true
    }
  },
  watch: {},
  created() {},
  methods: {
    isEmpty,
    externalFormatFilPrice,
    async init() {
      this.loadingNode = true;
      await this.getNode();
      this.loadingNode = false;
    },
    formatUnits,
    debtRatio({
      debt_value,
      position_value
    }) {
      let debtValue = toBigInt(debt_value);
      if (debtValue === 0n) {
        return 0n;
      }
      let positionValue = toBigInt(position_value);
      return PercentageMath.div(debtValue, positionValue);
    },
    debtRatioColor({
      debt_value,
      position_value,
      max_debt_ratio,
      liq_threshold
    }) {
      let _debtRatio = this.debtRatio({
        debt_value,
        position_value
      });
      position_value = toBigInt(position_value);
      max_debt_ratio = PercentageMath.div(toBigInt(max_debt_ratio) - 10000n, toBigInt(max_debt_ratio));
      liq_threshold = toBigInt(liq_threshold);
      if (_debtRatio === 0n || _debtRatio < max_debt_ratio) {
        return 'debtRatio-color-green';
      } else if (_debtRatio >= max_debt_ratio && _debtRatio < liq_threshold) {
        return 'debtRatio-color-yellow';
      } else {
        return 'debtRatio-color-red';
      }
    },
    async initNode() {
      try {
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    async checkDept(guaranteeNodeId, data) {
      let stableDebt = data[2].toBigInt();
      let variableDebt = data[3].toBigInt();
      if (stableDebt + variableDebt > 0n) {
        this.noGuaranteeNode = true;
        this.noGuaranteeNodeErr = `${this.$t('farm.There are outstanding debts The guarantee cannot be released')}`;
        this.noGuaranteeNodeErrNodeId = this.nodeId;
      } else {
        const _data = await readContract({
          address: contracts[this.env].StakingPool.eth,
          abi: StakingPoolABI.abi,
          functionName: 'getNodeData',
          args: [guaranteeNodeId]
        });
        stableDebt = _data[2].toBigInt();
        variableDebt = _data[3].toBigInt();
        if (stableDebt + variableDebt > 0n) {
          this.noGuaranteeNode = true;
          this.noGuaranteeNodeErr = `${this.$t('farm.There are outstanding debts The guarantee cannot be released')}`;
          this.noGuaranteeNodeErrNodeId = guaranteeNodeId;
        }
      }
    },
    async getNode() {
      const data = await readContract({
        address: contracts[this.env].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [this.nodeId]
      });
      console.log('data --------------', data);
      let _guaranteeNodeId = BigNumber.from(data[4]).toBigInt() & toBigInt("0xFFFFFFFFFFF");
      if (_guaranteeNodeId !== 0n) {
        this.guaranteeNodeId = getPrefix() + _guaranteeNodeId.toString();
        this.noGuaranteeNode = false;
        await this.checkDept(_guaranteeNodeId, data);
      } else {
        this.noGuaranteeNode = true;
        this.noGuaranteeNodeErr = `${this.$t('farm.The current node is not collateralized with any other nodes')}`;
      }
      let _nodeInfo = {
        id: '',
        beneficiary: '',
        owner: '',
        ownerAddress: '',
        beneficiaryAddress: ''
      };
      // eslint-disable-next-line no-undef
      let owner = getPrefix() + (BigNumber.from(data[0].owner).toBigInt() & BigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
      _nodeInfo.id = this.nodeId;
      _nodeInfo.owner = owner;
      let role = data[0].role;
      if (role === 1) {
        this.nodeRole = 'Owner';
      } else {
        this.nodeRole = 'Beneficiary';
      }
      this.$store.commit('SET_NODE_ROLE', {
        nodeRole: role === 1 ? 'Owner' : 'Beneficiary'
      });
      this.contractMethod = 'terminateGuaranteeForNode';
      this.$store.commit('UPDATE_NODE_INFO', _nodeInfo);
    },
    initActorState() {
      loutsRpc(StateGetActor(this.nodeInfo.owner)).then(res => {
        loutsRpc(ChainReadObj(res.result.Head)).then(async res => {
          let stateRawbytes = Uint8Array.from(Buffer.from(res.result, 'base64'));
          let msgSigState = dagCborDecode(stateRawbytes);
          // 有更简单的直接调用api接口得方式
          let signersRawbytes = msgSigState[0];
          if (Array.isArray(signersRawbytes)) {
            let head = loutsRpc(ChainHead());
            this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
              singerMultipleThreshold: signersRawbytes.length
            });
            this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
              singerMultipleNum: msgSigState[1]
            });
            this.singerTables = [];
            for (let i = 0; i < signersRawbytes.length; i++) {
              let signerBytes = signersRawbytes[i];
              let signerIdAddress = newAddressFromBytes(signerBytes);
              let idAddressStr = addressToString(getPrefix()[0], signerIdAddress);
              let nonIdAddressStr = await loutsRpc(StateAccountKey(idAddressStr, head.Cids)); // 测试网用t, 主网用f
              this.singerTables.push({
                isOk: false,
                isCheck: false,
                address: nonIdAddressStr.result,
                id: idAddressStr,
                index: i
              });
            }
            this.singerMultiple = true;
          } else {
            this.singerMultiple = false;
          }
          setTimeout(() => {
            this.contractParams = [];
            this.contractParams.push(this.nodeId);
            this.showCancel = false;
            this.$refs['singer'].init().then(() => {
              this.showCancel = true;
            });
          }, 100);
        });
      });
    },
    checkNode(index) {
      this.checkIndex = index;
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.guaranteeNodeId;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    async reapprove() {
      this.readySign = false;
      this.loading = true;
      await this.initNode();
      this.loading = false;
      this.readySign = true;
    },
    gotoGuaranteeNode() {
      this.$router.replace({
        path: '/farm/collateralNode/' + this.nodeId
      });
    },
    back() {
      this.$router.push({
        path: `/farm/provider/${this.nodeId}`
      });
    },
    complete() {
      this.completeFlag = true;
    },
    viewNode() {
      this.$store.commit('SET_FARM_ACTIVE_NAME', {
        farmActiveName: 'first'
      });
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    reset() {
      this.readySign = false;
      this.showCancel = false;
    },
    gotoOtherNode() {
      this.$store.commit('SET_FARM_ACTIVE_NAME', {
        farmActiveName: 'first'
      });
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};