import "core-js/modules/es.array.push.js";
import Clipboard from "clipboard";
import { CircleCheckFilled, CopyDocument } from '@element-plus/icons-vue';
import { loutsRpc, walletProvider } from "@/utils/LoutsRpc";
import { StateReadState } from "@/utils/FilecoinApi";
import { getActorAddress, getPrefix, NotificationErr } from "@/utils/common";
import { readContract } from "@wagmi/core";
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
import { BigNumber } from "ethers";
import { toBigInt } from "@/utils/NumU";
import { isEmpty } from "@/utils/model";
import { decode } from "@glif/filecoin-address";
import { getF4AddressByEth } from "@/utils/contracts/singer/filecoin_utils";
import store from "@/store";
export default {
  name: "PoolChangeOwnerComponent",
  components: {
    CopyDocument
  },
  data() {
    return {
      CircleCheckFilled,
      active: 1,
      isCopy: false,
      originalOwnerF0: '',
      originalOwner: '',
      singerMultiple: true,
      newPoolAddressErr: false,
      newPoolAddressErrStr: '',
      newOwner: '',
      newOwnerF0: '',
      newOwnerEth: '',
      newOwnerIsOk: false,
      resultOk: false,
      loading: {
        initPoolLoading: false,
        checkIsStepTowLoading: false,
        initOriginalOwnerLoading: false,
        checkAddress: false
      },
      one: {
        contractMethod: '',
        contractParams: [],
        ABI: StorageProviderLendingPoolABI,
        fromAddress: '',
        toEthAddress: '',
        toF0Address: ''
      },
      tow: {
        contractMethod: '',
        contractParams: [],
        ABI: StorageProviderLendingPoolABI,
        fromAddress: '',
        toEthAddress: '',
        toF0Address: ''
      }
    };
  },
  props: {
    poolAddress: {
      type: String,
      default: ''
    },
    nodeId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.initPoolAddress();
      await this.checkIsStepTow();
      await this.initOriginalOwner();
    },
    nextSign() {
      this.initTow();
    },
    async checkIsStepTow() {
      this.loading.checkIsStepTowLoading = true;
      let nodePendingOwner = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'nodePendingOwner',
        args: [this.nodeId]
      });
      if (nodePendingOwner.toString() === '0x0000000000000000000000000000000000000000') {
        this.loading.checkIsStepTowLoading = false;
        return;
      }
      let nodePendingOwnerId = getPrefix() + (BigNumber.from(nodePendingOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      this.newOwnerF0 = nodePendingOwnerId.replaceAll("t", "f");
      let {
        singerMultiple,
        address
      } = await this.addressIsSingerMultiple(this.newOwnerF0);
      this.singerMultiple = singerMultiple;
      if (singerMultiple) {
        this.newOwner = this.newOwnerF0;
      } else {
        this.newOwner = address;
      }
      this.newOwner = await walletProvider.accountKey(this.newOwnerF0);
      this.newOwnerEth = getActorAddress(this.newOwnerF0.trim().substring(2));
      this.newOwnerIsOk = true;
      this.newPoolAddressErr = false;
      this.newPoolAddressErrStr = '';
      this.loading.checkIsStepTowLoading = false;
      this.initTow();
    },
    async initPoolAddress() {
      this.loading.initPoolLoading = true;
      this.poolF4Address = getF4AddressByEth(this.poolAddress, store.state.evn);
      this.poolF0Address = await walletProvider.lookupId(this.poolF4Address);
      this.loading.initPoolLoading = false;
    },
    signOk() {
      this.resultOk = true;
    },
    initOne() {
      this.active = 1;
      this.one.contractMethod = 'transferNodeOwnership';
      this.one.contractParams = [this.nodeId, this.newOwnerEth];
      this.one.fromAddress = this.originalOwner;
      this.one.toEthAddress = this.poolAddress;
      this.one.toF0Address = this.poolF0Address;
      this.$nextTick(() => {
        this.$refs['singer'].init();
      });
    },
    initTow() {
      this.active = 3;
      this.tow.contractMethod = 'acceptNodeOwnership';
      this.tow.contractParams = [this.nodeId];
      this.tow.fromAddress = this.newOwner;
      this.tow.toEthAddress = this.poolAddress;
      this.tow.toF0Address = this.poolF0Address;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    async checkAddress() {
      if (isEmpty(this.newOwner.trim())) {
        this.newPoolAddressErr = true;
        this.newOwnerIsOk = false;
        this.newPoolAddressErrStr = this.$t('subpool.newPoolAdminErrTip');
        return false;
      }
      if (this.newOwner.startsWith("f4")) {
        this.newPoolAddressErrStr = this.$t('subpool.new owner err f4 tip');
        this.newOwnerIsOk = false;
        this.newPoolAddressErr = true;
        return false;
      }
      if (this.newOwner.startsWith("f2")) {
        this.newPoolAddressErrStr = this.$t('subpool.new owner err f2 tip');
        this.newOwnerIsOk = false;
        this.newPoolAddressErr = true;
        return false;
      }
      if (!this.newOwner.startsWith("f0") && !this.newOwner.startsWith("f1") && !this.newOwner.startsWith("f3")) {
        this.newOwnerIsOk = false;
        this.newPoolAddressErr = true;
        this.newPoolAddressErrStr = this.$t('subpool.newPoolAdminErrTip');
        return false;
      }
      try {
        decode(this.newOwner).bytes;
      } catch (err) {
        this.newOwnerIsOk = false;
        this.newPoolAddressErr = true;
        this.newPoolAddressErrStr = this.$t('subpool.newPoolAdminErrTip');
        return false;
      }
      try {
        if (this.newOwner.startsWith("f0")) {
          let res = await this.addressIsSingerMultiple(this.newOwner);
          if (res === null) {
            this.newOwnerIsOk = false;
            this.newPoolAddressErr = true;
            this.newPoolAddressErrStr = this.$t('Onboarding.F4 address does not support entry');
            return false;
          }
          this.singerMultiple = res.singerMultiple;
          this.newOwnerF0 = this.newOwner;
          if (!this.singerMultiple) {
            this.newOwner = res.address;
          }
        } else {
          this.newOwnerF0 = await walletProvider.lookupId(this.newOwner);
        }
        this.newOwnerEth = getActorAddress(this.newOwnerF0.trim().substring(2));
      } catch (e) {
        this.newOwnerIsOk = false;
        this.newPoolAddressErr = true;
        this.newPoolAddressErrStr = e.message;
        return false;
      }
      this.newOwnerIsOk = true;
      this.newPoolAddressErr = false;
      this.newPoolAddressErrStr = '';
      return true;
    },
    viewNode() {
      this.$router.push({
        path: '/farm/lendingPool/provider/' + this.poolAddress + '/' + this.nodeId
      });
    },
    async affirm() {
      this.loading.checkAddress = true;
      if (!(await this.checkAddress())) {
        this.loading.checkAddress = false;
        return;
      }
      this.loading.checkAddress = false;
      this.initOne();
    },
    async addressIsSingerMultiple(f0Address) {
      let stateReadState = await loutsRpc(StateReadState(f0Address));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        return {
          singerMultiple: true
        };
      } else {
        if (!stateReadStateRes.State.Address) {
          return null;
        }
        return {
          singerMultiple: false,
          address: stateReadStateRes.State.Address
        };
      }
    },
    async initOriginalOwner() {
      this.loading.initOriginalOwnerLoading = true;
      let _originalOwner = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'nodeOwner',
        args: [this.nodeId]
      });
      this.originalOwnerF0 = this.getNetworkPrefix() + '0' + (BigNumber.from(_originalOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      let res = await this.addressIsSingerMultiple(this.originalOwnerF0);
      if (res === null) {
        NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
      } else {
        let {
          singerMultiple,
          address
        } = res;
        this.singerMultiple = singerMultiple;
        if (!this.singerMultiple) {
          this.originalOwner = address;
        } else {
          this.originalOwner = this.originalOwnerF0;
        }
      }
      this.loading.initOriginalOwnerLoading = false;
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.env === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    copy(value) {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return value;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};