import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import { CircleCheckFilled, CirclePlus, Remove } from '@element-plus/icons-vue';
import AdCard2 from '@/components/tip/AdCard2';
import { readContract } from '@wagmi/core';
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { contracts } from "@/utils/contracts/config";
import { BigNumber } from "ethers";
import { loutsRpc, walletProvider } from "@/utils/LoutsRpc";
import { ChainHead, ChainReadObj, StateAccountKey, StateGetActor } from "@/utils/FilecoinApi";
import { decode as dagCborDecode } from "@ipld/dag-cbor";
import { newAddressFromBytes } from "@/utils/contracts/singer/filecoin_utils";
import { encode as addressToString } from "@glif/filecoin-address";
import Singer from '@/components/induction/singer/Singer';
import { getPrefix, NotificationErr } from "@/utils/common";
import Tip from '@/components/tip/Tip';
import Clipboard from "clipboard";
import EvmErrTip from '@/components/header/EvmErrTip';
import { isEmpty } from "@/utils/model";
export default {
  name: "ChangeControlComponent",
  components: {
    AdCard2,
    Singer,
    Tip,
    EvmErrTip,
    CirclePlus,
    Remove
  },
  data() {
    return {
      radio1: 'Node Owner',
      CircleCheckFilled,
      active: 1,
      sendProposal: false,
      approvedProposalRes: false,
      inputs: {
        input1: 'f01234',
        input2: 0,
        input3: 10101
      },
      radio2: 'Content',
      singerTables: [],
      contractParams: [],
      contractMethod: 'approveOperator',
      changeAddress: '',
      nodeRole: 'Owner',
      singerMultiple: false,
      newControl: '',
      newControls: [''],
      newControlsIsErr: [''],
      affirmControl: false,
      showNewControl: false,
      showCurrentControl: false,
      loading: false,
      initLoading: true,
      isHaveActorId: true,
      isCopy: false,
      isNewControllErr: false,
      newControllErrTips: false
    };
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  watch: {
    nodeInfo() {
      if (this.nodeInfo.controlAddresses !== null && this.nodeInfo.controlAddresses.length > 0) {
        this.showCurrentControl = true;
        this.showNewControl = false;
      } else {
        this.showCurrentControl = false;
        this.showNewControl = true;
      }
      this.initLoading = false;
    }
  },
  created() {},
  methods: {
    async init() {
      try {
        await this.getNode();
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    isEmpty,
    viewNode() {
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.env === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    initActorState() {
      loutsRpc(StateGetActor(this.nodeInfo.owner)).then(res => {
        loutsRpc(ChainReadObj(res.result.Head)).then(async res => {
          let stateRawbytes = Uint8Array.from(Buffer.from(res.result, 'base64'));
          let msgSigState = dagCborDecode(stateRawbytes);
          // 有更简单的直接调用api接口得方式
          let signersRawbytes = msgSigState[0];
          if (Array.isArray(signersRawbytes)) {
            let head = loutsRpc(ChainHead());
            this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
              singerMultipleThreshold: signersRawbytes.length
            });
            this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
              singerMultipleNum: msgSigState[1]
            });
            this.singerTables = [];
            for (let i = 0; i < signersRawbytes.length; i++) {
              let signerBytes = signersRawbytes[i];
              let signerIdAddress = newAddressFromBytes(signerBytes);
              let idAddressStr = addressToString(getPrefix()[0], signerIdAddress);
              let nonIdAddressStr = await loutsRpc(StateAccountKey(idAddressStr, head.Cids)); // 测试网用t, 主网用f
              this.singerTables.push({
                isOk: false,
                isCheck: false,
                address: nonIdAddressStr.result,
                id: idAddressStr,
                index: i
              });
            }
            this.singerMultiple = true;
          } else {
            this.singerMultiple = false;
          }
          let _newControls = this.newControl.split(',');
          _newControls = _newControls.map(i => parseInt(i.substring(2)));
          console.log('_newControls', _newControls, this.worker);
          setTimeout(() => {
            this.contractParams = [];
            this.contractParams.push(this.nodeId, parseInt(this.worker.substring(2)), _newControls);
            this.$refs['singer'].init();
          }, 100);
        });
      });
    },
    async getNode() {
      const data = await readContract({
        address: contracts[this.env].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [this.nodeId]
      });
      let _nodeInfo = {
        id: '',
        beneficiary: '',
        owner: '',
        ownerAddress: '',
        beneficiaryAddress: ''
      };

      // eslint-disable-next-line no-undef
      let owner = getPrefix() + (BigNumber.from(data[0].owner).toBigInt() & BigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
      _nodeInfo.id = this.nodeId;
      this.contractMethod = 'changeWorkerAddress';
      _nodeInfo.owner = owner;
      this.$store.commit('UPDATE_NODE_INFO', _nodeInfo);
    },
    pushProposalNext() {
      this.active++;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    setCurrentWallet() {
      this.newControl = this.address;
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.currentNode.operator;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    sendProposalFun() {
      this.sendProposal = true;
      this.active++;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    approvedProposal() {
      this.active++;
    },
    approvedProposal2() {
      this.approvedProposalRes = true;
    },
    addControllId() {
      if (this.affirmControl) {
        return;
      }
      this.newControls.push('');
      this.newControlsIsErr.push('');
      this.clearErr();
    },
    delControllId(index) {
      if (this.affirmControl) {
        return;
      }
      this.newControls.splice(index, 1);
      this.clearErr();
    },
    clearErr(index) {
      this.newControlsIsErr[index] = '';
      this.isNewControllErr = false;
      this.newControllErrTips = '';
    },
    reapprove() {
      this.showNewControl = true;
    },
    async checkControlAddress() {
      return false;
    },
    async checkNewControlInput() {
      let len = this.newControls.length;
      for (let i = 0; i < len; i++) {
        let inputVal = this.newControls[i].trim();
        if (inputVal === '') {
          this.newControlsIsErr[i] = this.$t('change.Please enter Worker ID');
          this.isNewControllErr = true;
          this.newControllErrTips = this.newControlsIsErr[i];
          return false;
        }
        try {
          await walletProvider.getActor(inputVal);
        } catch (e) {
          this.newControlsIsErr[i] = this.$t('change.controlInputErr');
          this.isNewControllErr = true;
          this.newControllErrTips = this.newControlsIsErr[i];
          return false;
        }
      }
      this.newControllErrTips = '';
      this.isNewControllErr = false;
      return true;
    },
    reset() {
      this.affirmControl = false;
    },
    async affirm() {
      let flag = await this.checkNewControlInput();
      if (!flag) {
        return;
      }
      this.newControl = this.newControls.join(",");
      this.loading = true;
      await this.init();
      this.affirmControl = true;
      this.showCurrentControl = false;
      this.loading = false;
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    worker() {
      return this.$store.state.node.nodeInfo.worker;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};