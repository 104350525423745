import "core-js/modules/es.array.push.js";
import { blockU, dateFormat, getPrefix, gotoAddress, isMobile, simpleDateByDayFormat, simpleValue } from "@/utils/common";
import { externalFormatFilPrice, formatBit, formatWalletFilPrice, formatWalletFilPriceInterest, toBigInt } from "@/utils/NumU";
import { formatUnits } from "ethers/lib.esm/utils";
import { mapState } from "vuex";
import { isEmpty } from "@/utils/model";
import { CopyDocument, Search, Edit } from "@element-plus/icons-vue";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { WadRayMath } from "@/utils/contracts/WadRayMath";
import Clipboard from "clipboard";
import Sort from '@/views/farm/Sort';
import ChartBlock from '@/components/ChartBlock';
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "PoolInfo",
  components: {
    CopyDocument,
    Sort,
    ChartBlock,
    Edit
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      poolAddress: '',
      Search: Search,
      initCurrentPoolInfoLoading: false,
      nodeType: 1,
      nodeTypeOptions: [{
        value: 1,
        label: 'Active'
      }, {
        value: 0,
        label: 'Closed'
      }],
      loading: false,
      onboardingBtnLoading: false,
      nodesTemplateData: [],
      dataNodesTemplateData: [],
      // 记录在职的信息
      serch: {
        data: ''
      },
      fixedSortType: {},
      showViewBtn: {},
      optionBorrowInfo: {
        title: {
          show: false
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          data: ['Borrow,stable', 'Borrow,variable']
        },
        xAxis: {
          data: [],
          axisLabel: {
            formatter: function (value) {
              return simpleDateByDayFormat(value);
            }
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          top: '70px;',
          left: '50px',
          right: '25px'
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: function (value) {
              let _uds = Math.floor(value);
              if (_uds >= 1000000) {
                let res = parseFloat(value) / 1000000;
                if (res === 1) {
                  return '1M';
                }
                return (parseFloat(value) / 1000000).toFixed(1) + 'M';
              }
              if (_uds >= 100000) {
                return Math.floor(_uds / 1000) + 'K';
              }
              return _uds;
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            if (params[0].dataIndex === this.optionBorrowInfo.xAxis.data.length - 1) {
              return `${dateFormat(params[0].name, true)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #1619d7;margin-right: 5px;margin-bottom: 2px;"></div>${params[1].seriesName}  ${params[1].value} FIL <br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e86016;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value} FIL`;
            } else {
              return `${dateFormat(params[0].name)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #1619d7;margin-right: 5px;margin-bottom: 2px;"></div>${params[1].seriesName}  ${params[1].value} FIL <br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e86016;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value} FIL`;
            }
          }
        },
        series: [{
          name: 'Borrow,stable',
          type: 'line',
          data: [],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e86016'
          }
        }, {
          name: 'Borrow,variable',
          type: 'line',
          data: [],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#1619d7'
          }
        }]
      }
    };
  },
  watch: {
    isConnected() {
      this.initPoolList();
    },
    nodeType() {
      this.initPoolList();
    },
    '$i18n.locale'() {
      this.nodeTypeOptions = [{
        value: 1,
        label: this.$t('farm.Active')
      }, {
        value: 0,
        label: this.$t('farm.Closed')
      }];
      this.initOptionBorrowInfo();
    },
    chartTotalVariableBorrow(val) {
      if (Array.isArray(val.time) && val.time.length > 0) {
        this.$refs.chartBorrow?.unLoading();
        this.optionBorrowInfo.xAxis.data = val.time;
        this.optionBorrowInfo.series[1].data = val.data;
        this.optionBorrowInfo.series[0].data = this.chartTotalStableBorrow.data;
      } else {
        this.$refs.chartBorrow?.noDataShow();
      }
    }
  },
  created() {
    this.init();
    this.initOptionBorrowInfo();
  },
  mounted() {
    this.initTotalBorrowed();
  },
  methods: {
    gotoAddress,
    transferOwnership() {
      this.$router.push({
        path: `/farm/lendingPool/transferOwner/${this.poolAddress}`
      });
    },
    copyAddress(address) {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return address;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', e => {
        console.error(e);
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    async init() {
      this.poolAddress = this.$route.params.poolAddress;
      this.$store.commit('INIT_FARM_ACTIVE_NAME');
      this.nodeTypeOptions = [{
        value: 1,
        label: this.$t('farm.Active')
      }, {
        value: 0,
        label: this.$t('farm.Closed')
      }];
      // 这里使用本组件的loading 因为使用全局容易出现，池子切换数据缓存问题。
      this.initCurrentPoolInfoLoading = true;
      this.$store.dispatch('initCurrentPoolInfo', {
        poolAddress: this.poolAddress
      }).then(() => {
        this.initCurrentPoolInfoLoading = false;
      }).catch(() => {
        this.initCurrentPoolInfoLoading = false;
      });
      await this.initPoolList();
    },
    async initPoolList() {
      this.loading = true;
      await this.$store.dispatch('getPoolNodeList', {
        poolAddress: this.poolAddress,
        delegate: this.nodeType,
        user: !this.address ? "" : this.address
      });
      this.nodesTemplateData = Object.assign([], this.poolNodes);
      if (this.nodeType === 1) {
        this.dataNodesTemplateData = Object.assign([], this.poolNodes);
      }
      this.changeSearch(this.serch.data);
      this.loading = false;
    },
    initOptionBorrowInfo() {
      this.optionBorrowInfo.legend.data = [this.$t('dashboard.BorrowStable'), this.$t('dashboard.BorrowVariable')];
      this.optionBorrowInfo.series[0].name = this.$t('dashboard.BorrowStable');
      this.optionBorrowInfo.series[1].name = this.$t('dashboard.BorrowVariable');
    },
    onboarding() {
      this.$router.push({
        path: '/farm/lendingPool/induction/' + this.poolAddress
      });
    },
    getPrefix,
    handleEnter(node) {
      this.showViewBtn[node] = true;
    },
    handleLeave(node) {
      this.showViewBtn[node] = false;
    },
    showView(nodeInfo) {
      let {
        node,
        delegate
      } = nodeInfo;
      if (delegate === '0') {
        this.$router.push({
          path: `/farm/lendingPool/offboarding/${this.poolAddress}/${node}`
        });
      } else {
        this.$router.push({
          path: `/farm/lendingPool/provider/${this.poolAddress}/${node}`
        });
      }
    },
    isNew(node) {
      let {
        delegate_tipset
      } = node;
      let time = blockU.getByBlockNumberToTime(delegate_tipset);
      let nowTime = new Date().getTime();
      let diff = nowTime - time;
      return diff <= 60 * 1000 * 60 * 24 * 3;
    },
    searchNode(node) {
      this.serch.data = getPrefix() + node;
      this.changeSearch(this.serch.data);
    },
    formatBit,
    debtRatioColorValue() {
      return [{
        color: '#00CE92',
        percentage: 100
      }, {
        color: '#D94A1E',
        percentage: 200
      }];
    },
    formatWalletFilPrice,
    externalFormatFilPrice,
    formatUnits,
    simpleValue,
    isMobile,
    toBigInt,
    _formatWalletFilPriceInterest(val) {
      if (val <= 0n) {
        return `< 1FIL`;
      }
      let res = formatWalletFilPriceInterest(val);
      if (res === '0') {
        return `< 1FIL`;
      }
      return res;
    },
    setCollect(node, collect, index) {
      if (this.isConnected) {
        this.$store.dispatch('setCollect', {
          node,
          collect,
          user: this.address
        });
        this.nodesTemplateData[index].collect = collect;
      } else {
        this.$store.state.refs['iHeader'].connectWallet();
      }
    },
    sortFixed(fixed) {
      let sortType = this.fixedSortType[fixed];
      this.fixedSortType = {};
      this.fixedSortType[fixed] = sortType;
      if (sortType === undefined) {
        sortType = 1;
        this.fixedSortType[fixed] = 1;
      } else {
        sortType = sortType + 1;
        if (sortType > 2) {
          sortType = 0;
          this.fixedSortType[fixed] = sortType;
          this.nodesTemplateData = Object.assign([], this.poolNodes);
          return;
        }
        this.fixedSortType[fixed] = sortType;
      }
      this.nodesTemplateData = this.nodesTemplateData.sort((a, b) => {
        let av = toBigInt(a[fixed]);
        let bv = toBigInt(b[fixed]);
        if (sortType === 2) {
          return av > bv ? 1 : av === bv ? 0 : -1;
        } else if (sortType === 1) {
          return av > bv ? -1 : av === bv ? 0 : 1;
        }
      });
    },
    setCollectByMerge(node, collect, index) {
      if (this.isConnected) {
        this.$store.dispatch('setCollect', {
          node: node['currentNode'].node,
          collect,
          user: this.address
        });
        this.$store.dispatch('setCollect', {
          node: node['bindNode'].node,
          collect,
          user: this.address
        });
        this.nodesTemplateData[index]['currentNode'].collect = collect;
        this.nodesTemplateData[index]['bindNode'].collect = collect;
      } else {
        this.$store.state.refs['iHeader'].connectWallet();
      }
    },
    changeSearch(val) {
      if (isEmpty(val)) {
        this.nodesTemplateData = Object.assign([], this.poolNodes);
        return;
      }
      let _val = val.toString().trim();
      this.nodesTemplateData = this.poolNodes.filter(nodeItem => {
        let {
          node
        } = nodeItem;
        let nodeS = getPrefix() + node;
        return nodeS.toString().indexOf(_val) >= 0;
      });
    },
    chartBorrowSelect({
      value
    }) {
      this.$store.commit('SET_POOL_BORROW_RESOLUTIONINHOURS', {
        resolutionInHours: value
      });
      this.initTotalBorrowed(true);
    },
    async initTotalBorrowed(operation = false) {
      let _timingFlag = this.timingFlag;
      if (!_timingFlag || operation) {
        this.$refs.chartBorrow?.loading();
      }
      await this.$store.dispatch('initPoolBorrowedChart', {
        poolAddress: this.poolAddress
      });
      if (!_timingFlag || operation) {
        setTimeout(() => {
          this.$refs.chartBorrow?.unLoading();
        }, 500);
      }
    },
    toUsdByObj(val, showOriginal = false) {
      let _fil = parseFloat(this.filPrice);
      if (_fil <= 0) {
        if (showOriginal) {
          return val;
        }
        return '';
      }
      let value = '0';
      let suffix = '';
      let usd = toBigInt(val) * toBigInt(Math.floor(parseFloat(this.filPrice) * 100));
      let _uds = parseFloat(formatUnits(usd.toString(), 20));
      if (isNaN(_uds)) {
        return {
          prefix: '$',
          value: value,
          suffix: suffix
        };
      }
      if (_uds > 1000000) {
        value = (_uds / 1000000).toFixed(2);
        suffix = 'M';
      } else if (_uds > 100000) {
        value = (_uds / 1000).toFixed(2);
        suffix = 'K';
      } else {
        value = _uds.toFixed(4);
        suffix = '';
      }
      return {
        prefix: '$',
        value: value,
        suffix: suffix
      };
    }
  },
  computed: mapState({
    totalPower() {
      let res = 0n;
      for (let i = 0; i < this.dataNodesTemplateData.length; i++) {
        let node = this.dataNodesTemplateData[i];
        res = res + toBigInt(node['quality_adj_power']);
      }
      return res;
    },
    totalPosition() {
      let res = 0n;
      for (let i = 0; i < this.dataNodesTemplateData.length; i++) {
        let node = this.dataNodesTemplateData[i];
        res = res + toBigInt(node['position_value']);
      }
      return res;
    },
    chartTotalVariableBorrow: state => state.chartData.totalPoolVariableBorrow,
    chartTotalStableBorrow: state => state.chartData.totalPoolStableBorrow,
    stableDebtTokenTotalSupply: state => state.chartData.totalPoolStableData,
    variableDebtTokenTotalSupply: state => state.chartData.totalPoolVariableData,
    variableDebtTokenTotalSupplyLoading: state => state.publicInfo.variableDebtTokenTotalSupplyLoading,
    stableDebtTokenTotalSupplyLoading: state => state.publicInfo.stableDebtTokenTotalSupplyLoading,
    reserveDataLoadingLoading: state => state.publicInfo.reserveDataLoading,
    totalStakingTokens: state => state.stakeInfo.totalStakingTokens,
    borrowBalance: state => {
      return state.publicInfo.variableDebtTokenTotalSupply + state.publicInfo.stableDebtTokenTotalSupply;
    },
    variableDebtRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    filPrice() {
      return this.$store.state.filPrice;
    },
    poolNodes() {
      return this.$store.state.subpool.nodes;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    },
    poolNodeCount() {
      return this.currentPoolInfo.nodeCount;
    },
    variableDebtTokenTotalSupplyObj() {
      return this.toUsdByObj(this.variableDebtTokenTotalSupply);
    },
    stableDebtTokenTotalSupplyObj() {
      return this.toUsdByObj(this.stableDebtTokenTotalSupply);
    },
    timingFlag: state => state.timingFlag,
    currentPoolInfo: state => state.subpool.currentPool,
    poolPosition() {
      return this.currentPoolInfo.totalPosition;
    },
    poolEquityValue() {
      return this.poolPosition - this.poolTotalDebt + this.poolWithdrawLoadTotalAmount;
    },
    poolAdmin() {
      return this.currentPoolInfo.admin;
    },
    poolTotalDebt() {
      return this.currentPoolInfo.totalDebt;
    },
    poolMaxDebtRatio() {
      return this.currentPoolInfo.maxDebtRatio / WadRayMath.WAD_2;
    },
    poolDebtRatio() {
      let debtValue = this.poolTotalDebt;
      if (debtValue === 0n) {
        return 0n;
      }
      return PercentageMath.div(debtValue, this.poolPosition);
    },
    maxSealLoadLeverage() {
      return this.currentPoolInfo.maxSealLoadLeverage / WadRayMath.WAD_2;
    },
    maxLeverage() {
      return this.currentPoolInfo.max_leverage / WadRayMath.WAD_2;
    },
    poolMaxWithdrawLoadLeverage() {
      return this.currentPoolInfo.maxWithdrawLoadLeverage / WadRayMath.WAD_2;
    },
    poolWithdrawLoadTotalAmount() {
      return this.currentPoolInfo.withdrawLoadTotalAmount;
    },
    poolSeadLoadTotalAmount() {
      return this.poolTotalDebt - this.currentPoolInfo.withdrawLoadTotalAmount;
    },
    poolLeftGuarantee() {
      return this.poolEquityValue - PercentageMath.div(this.poolSeadLoadTotalAmount, this.maxSealLoadLeverage - 10000n) - PercentageMath.div(this.poolWithdrawLoadTotalAmount, this.poolMaxWithdrawLoadLeverage - 10000n);
    },
    poolAvailableSealLoad() {
      return PercentageMath.mul(this.poolLeftGuarantee, this.maxSealLoadLeverage - 10000n);
    },
    poolAvailableWithdrawalLoan() {
      return PercentageMath.mul(this.poolLeftGuarantee, this.poolMaxWithdrawLoadLeverage - 10000n);
    },
    poolOnlyAcceptsNewNodes() {
      return this.currentPoolInfo.onlyAcceptsNewNodes;
    },
    poolLiquidationThreshold() {
      return this.currentPoolInfo.liquidationThreshold / WadRayMath.WAD_2;
    },
    poolMaxSealedLoadAmount() {
      return this.currentPoolInfo.maxSealedLoadAmount;
    },
    poolTotalAvailableBalance() {
      return this.currentPoolInfo.totalAvailableBalance;
    },
    poolAddress() {
      return this.currentPoolInfo.address.eth;
    }
  })
};