import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "d-flex flex-column justify-content-center align-items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CaretTop = _resolveComponent("CaretTop");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_CaretBottom = _resolveComponent("CaretBottom");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_icon, {
    style: {
      "margin-bottom": "-6px"
    },
    size: "11",
    color: $options.topColor
  }, {
    default: _withCtx(() => [_createVNode(_component_CaretTop)]),
    _: 1
  }, 8, ["color"]), _createVNode(_component_el_icon, {
    size: "11",
    color: $options.bottomColor
  }, {
    default: _withCtx(() => [_createVNode(_component_CaretBottom)]),
    _: 1
  }, 8, ["color"])]);
}