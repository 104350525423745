export default {
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        isSuperMini() {
          return this.screenWidth < 300
        },
        isMini() {
            return this.screenWidth < 376
        },
        screenWidth() {
          return this.$store.state.screenWidth
        },
        isMobileWidth() {
            return this.screenWidth < 400
        },
        fixedMaxWidth() {
            if (this.screenWidth < 380) {
                return '50vw'
            }
            if (this.screenWidth < 420) {
                return '60vw'
            }
            if (this.screenWidth < 1200) {
                return '340px'
            }
            if (this.screenWidth < 768) {
                return '360px'
            }
            return '410px'
        }
    }
}
