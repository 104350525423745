import "core-js/modules/es.array.push.js";
import PageHead from '@/components/header/PageHead.vue';
import { Search } from '@element-plus/icons-vue';
import Sort from './Sort';
import { ElInput } from 'element-plus';
import { externalFormatFilPrice, externalFormatFilPriceNoFractional, formatBit, formatBitTopPib, formatPrice, formatSubEthPrice, formatWalletFilPrice, formatWalletFilPriceInterest, toBigInt } from '@/utils/NumU';
import { isEmpty } from "@/utils/model";
import { mapState } from "vuex";
import { formatUnits } from "ethers/lib.esm/utils";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { blockU, getPrefix, isMobile, simpleDateFormat } from "@/utils/common";
import InfoBlock from './InfoBlock';
import InterestRateModel from './InterestRateModel';
import SubPoolList from "@/components/subpool/SubPoolList";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "IndexPage",
  components: {
    PageHead,
    ElInput,
    Sort,
    InfoBlock,
    InterestRateModel,
    SubPoolList
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      serch: {
        data: ''
      },
      Search: Search,
      radio: 3,
      showViewBtn: {},
      showCollectBtn: false,
      nodesTemplateData: [],
      dataNodesTemplateData: [],
      // 记录在职的信息
      loading: false,
      nodeType: 1,
      nodeTypeOptions: [{
        value: 1,
        label: 'Active'
      }, {
        value: 0,
        label: 'Closed'
      }],
      fixedSortType: {},
      headerMarginBottom: "0"
    };
  },
  watch: {
    isConnected() {
      this.init();
    },
    nodeType() {
      this.init();
    },
    '$i18n.locale'() {
      this.nodeTypeOptions = [{
        value: 1,
        label: this.$t('farm.Active')
      }, {
        value: 0,
        label: this.$t('farm.Closed')
      }];
    }
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    isMobile,
    isShowOnboarding(index) {
      let width = window.innerWidth;
      if (width <= 992) {
        return false;
      }
      let len = this.nodesTemplateData.length;
      if (len < 3 && parseInt(index) === len - 1) {
        return true;
      }
      return parseInt(index) === 2;
    },
    formatBit,
    formatBitTopPib,
    toBigInt,
    externalFormatFilPrice,
    formatWalletFilPrice,
    externalFormatFilPriceNoFractional,
    formatSubEthPrice,
    formatPrice,
    formatUnits,
    _formatWalletFilPriceInterest(val) {
      if (val <= 0n) {
        return `< 1FIL`;
      }
      let res = formatWalletFilPriceInterest(val);
      if (res === '0') {
        return `< 1FIL`;
      }
      return res;
    },
    debtRatio({
      debt_value,
      position_value
    }) {
      let debtValue = toBigInt(debt_value);
      if (debtValue === 0n) {
        return 0n;
      }
      let positionValue = toBigInt(position_value);
      return PercentageMath.div(debtValue, positionValue);
    },
    maxDebtRatio(max_leverage) {
      return formatPrice((parseInt(max_leverage) - 10000) / parseInt(max_leverage) * 100, 2);
    },
    liqThreshold(liquidation_threshold) {
      return formatPrice(parseInt(liquidation_threshold) / 100, 1);
    },
    async init() {
      this.$store.commit('INIT_FARM_ACTIVE_NAME');
      this.nodeTypeOptions = [{
        value: 1,
        label: this.$t('farm.Active')
      }, {
        value: 0,
        label: this.$t('farm.Closed')
      }];
      this.loading = true;
      await this.$store.dispatch('getNodesData', {
        delegate: this.nodeType,
        user: !this.address ? "" : this.address
      });
      this.nodesTemplateData = Object.assign([], this.nodesData);
      if (this.nodeType === 1) {
        this.dataNodesTemplateData = Object.assign([], this.nodesData);
      }
      this.loading = false;
      this.changeSearch(this.serch.data);
    },
    isNew(node) {
      let {
        delegate_tipset
      } = node;
      let time = blockU.getByBlockNumberToTime(delegate_tipset);
      let nowTime = new Date().getTime();
      let diff = nowTime - time;
      return diff <= 60 * 1000 * 60 * 24 * 3;
    },
    setCollect(node, collect, index) {
      if (this.isConnected) {
        this.$store.dispatch('setCollect', {
          node,
          collect,
          user: this.address
        });
        this.nodesTemplateData[index].collect = collect;
      } else {
        this.$store.state.refs['iHeader'].connectWallet();
      }
    },
    setCollectByMerge(node, collect, index) {
      if (this.isConnected) {
        this.$store.dispatch('setCollect', {
          node: node['currentNode'].node,
          collect,
          user: this.address
        });
        this.$store.dispatch('setCollect', {
          node: node['bindNode'].node,
          collect,
          user: this.address
        });
        this.nodesTemplateData[index]['currentNode'].collect = collect;
        this.nodesTemplateData[index]['bindNode'].collect = collect;
      } else {
        this.$store.state.refs['iHeader'].connectWallet();
      }
    },
    showView(nodeInfo) {
      let {
        node,
        delegate
      } = nodeInfo;
      if (delegate === '0') {
        this.$router.push({
          path: '/farm/offboarding/' + node
        });
      } else {
        this.$router.push({
          path: '/farm/provider/' + node
        });
      }
    },
    sortFixed(fixed) {
      let sortType = this.fixedSortType[fixed];
      this.fixedSortType = {};
      this.fixedSortType[fixed] = sortType;
      if (sortType === undefined) {
        sortType = 1;
        this.fixedSortType[fixed] = 1;
      } else {
        sortType = sortType + 1;
        if (sortType > 2) {
          sortType = 0;
          this.fixedSortType[fixed] = sortType;
          this.nodesTemplateData = Object.assign([], this.nodesData);
          return;
        }
        this.fixedSortType[fixed] = sortType;
      }
      this.nodesTemplateData = this.nodesTemplateData.sort((a, b) => {
        let av = toBigInt(a[fixed]);
        let bv = toBigInt(b[fixed]);
        if (sortType === 2) {
          return av > bv ? 1 : av === bv ? 0 : -1;
        } else if (sortType === 1) {
          return av > bv ? -1 : av === bv ? 0 : 1;
        }
      });
    },
    join() {
      this.$router.push({
        path: '/farm/induction'
      });
    },
    createPool() {
      this.$router.push({
        path: '/farm/createPool'
      });
    },
    debtRatioColor({
      debtRatio,
      max_debt_ratio,
      liq_threshold
    }) {
      let _debtRatio = debtRatio;
      max_debt_ratio = PercentageMath.div(toBigInt(max_debt_ratio) - 10000n, toBigInt(max_debt_ratio));
      liq_threshold = toBigInt(liq_threshold);
      if (_debtRatio === 0n || _debtRatio < max_debt_ratio) {
        return 'debtRatio-color-green';
      } else if (_debtRatio >= max_debt_ratio && _debtRatio < liq_threshold) {
        return 'debtRatio-color-yellow';
      } else {
        return 'debtRatio-color-red';
      }
    },
    debtRatioColorValue() {
      return [{
        color: '#00CE92',
        percentage: 100
      }, {
        color: '#D94A1E',
        percentage: 200
      }];
    },
    handleEnter(node) {
      this.showViewBtn[node] = true;
    },
    handleLeave(node) {
      this.showViewBtn[node] = false;
    },
    getPrefix,
    searchNode(node) {
      this.serch.data = getPrefix() + node;
      this.changeSearch(this.serch.data);
    },
    changeSearch(val) {
      if (isEmpty(val)) {
        this.nodesTemplateData = Object.assign([], this.nodesData);
        return;
      }
      let _val = val.toString().trim();
      this.nodesTemplateData = this.nodesData.filter(nodeItem => {
        let {
          node
        } = nodeItem;
        let nodeS = getPrefix() + node;
        return nodeS.toString().indexOf(_val) >= 0;
      });
    }
  },
  computed: mapState({
    nextUpdate() {
      let now = new Date();
      let currentUTCHours = now.getUTCHours();
      let utc14 = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 14, 0, 0, 0));
      if (currentUTCHours >= 14) {
        utc14.setUTCDate(utc14.getUTCDate() + 1);
      }
      return simpleDateFormat(utc14.getTime());
    },
    totalPower() {
      let res = 0n;
      for (let i = 0; i < this.allNodes.length; i++) {
        let node = this.allNodes[i];
        res = res + toBigInt(node['quality_adj_power']);
      }
      return res;
    },
    allNodes() {
      return this.$store.state.nodeData.allNodes;
    },
    totalPosition() {
      let res = 0n;
      for (let i = 0; i < this.dataNodesTemplateData.length; i++) {
        let node = this.dataNodesTemplateData[i];
        res = res + toBigInt(node['position_value']);
      }
      return res;
    },
    variableDebtTokenTotalSupplyLoading: state => state.publicInfo.variableDebtTokenTotalSupplyLoading,
    stableDebtTokenTotalSupplyLoading: state => state.publicInfo.stableDebtTokenTotalSupplyLoading,
    reserveDataLoadingLoading: state => state.publicInfo.reserveDataLoading,
    totalStakingTokens: state => state.stakeInfo.totalStakingTokens,
    borrowBalance: state => {
      return state.publicInfo.variableDebtTokenTotalSupply + state.publicInfo.stableDebtTokenTotalSupply;
    },
    variableDebtRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    filPrice() {
      return this.$store.state.filPrice;
    },
    nodesData() {
      return this.$store.state.nodeData.nodes;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    },
    nodeCount: state => state.publicInfo.nodeCount
  })
};