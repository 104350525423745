import nodejsHttp from "@/utils/http/nodejsHttp";
import {getActorAddress, getAddressInfoByEth, getPrefix} from "@/utils/common";
import {retryFun, walletProvider} from "@/utils/LoutsRpc";
import {readContract} from "@wagmi/core";
import {contracts} from "@/utils/contracts/config";
import StableDebtTokenABI from "@/utils/abi/StableDebtToken.json";
import LendingPoolGettersABI from '@/utils/abi/LendingPoolGetters.json'
import {BigNumber} from "ethers";
import {toBigInt, toBigIntDefault} from "@/utils/NumU";
import PercentageMath from "@/utils/contracts/PercentageMath";

let subpool = {
    state: {
        pools: [],
        currentPage: 1,
        currentTotal: 10,
        poolsLoading: false,
        currentPool: {
            address: {
                eth: '',
                f0address: '',
                f4address: ''
            },
            admin: '',
            totalDebt: 0n,
            nodeCount: 0n,
            max_leverage: 0n,
            maxSealLoadLeverage: 0n,
            maxWithdrawLoadLeverage: 0n,
            withdrawLoadTotalAmount: 0n,
            onlyAcceptsNewNodes: 0n,
            liquidationThreshold: 0n,
            maxSealedLoadAmount: 0n,
            totalPosition: 0n,
            totalAvailableBalance: 0n,
            maxDebtRatio: 0n,
            totalEntity: 0n
        },
        currentPoolNode: {
            beneficiary: '0',
            borrowing: '1',
            debt_value: 0n,
            entity: 0n,
            withdrawLoadAmount: 0n,
            maxWithdrawLoanAmount: 0n,
            stable_debt_value: 0n,
            variable_debt_value: 0n,
            delegate: '1',
            node: '',
            owner: '',
            position_value: 0n,
            quality_adj_power: 0n,
            raw_byte_power: 0n,
            sector_active: 0,
            sector_faulty: 0,
            sector_live: 0,
            sector_size: 0,
            safetyBuffer: 0n,
            availableBalance: 0n,
            pledge: 0n,
            lockedRewards: 0n,
            is_active: '1',
            oldOwner: '',
            userStableRate: 0n,
        },
        history: [],
        nodes: [],
        loading: {
            initCurrentPoolNodeDataLoading: false,
            initCurrentPoolInfoLoading: false,
        },
        poolCacheTime: {}
    },
    getters: {
        getCurrentPoolEthAddress(state) {
            return state.currentPool.address.eth
        },
        getCurrentPoolF0Address(state) {
            return state.currentPool.address.eth
        }
    },
    mutations: {
        _SET_POOL_CACHE_TIME(state, {poolAddress, time}) {
            state.poolCacheTime[poolAddress] = time
        },
        SET_POOL_COLLECT(state, {index, collect}) {
            state.pools[index].collect = collect
        },
        SET_POOL_NODE_HISTORY_DATA(state, history) {
            state.history = history
        },
        SET_POOLS_LOADING(state, {poolsLoading}) {
            state.poolsLoading = poolsLoading
        },
        SET_CURRENT_PAGE(state, {currentPage}) {
            state.currentPage = currentPage
        },
        SET_CURRENT_TOTAL(state, {currentTotal}) {
            state.currentTotal = currentTotal
        },
        SET_CURRENT_POOL_NODE(state, currentPoolNode) {
            state.currentPoolNode = currentPoolNode
        },
        SET_INIT_CURRENT_POOL_NODE_DATA_LOADING(state, {loading}) {
            state.loading.initCurrentPoolNodeDataLoading = loading
        },
        SET_INIT_CURRENT_POOL_LOADING(state, {loading}) {
            state.loading.initCurrentPoolInfoLoading = loading
        },
        SET_POOLS(state, pools) {
            state.pools = pools
        },
        SET_CURRENT_POOL(state, currentPool) {
            state.currentPool = currentPool
        },
        SET_POOL_NODE_LIST(state, nodeList) {
            state.nodes = nodeList
        }
    },
    actions: {
        // 当前池子的信息
        async initCurrentPoolInfo({commit, state, getters, rootState}, {poolAddress}) {
            let updateTime = state.poolCacheTime[poolAddress]
            let nowTime = new Date().getTime()
            if (!!updateTime && nowTime - updateTime <= 30 * 1000) {
                return
            }

            nowTime = new Date().getTime()
            commit('_SET_POOL_CACHE_TIME', {poolAddress, time:nowTime})

            commit('SET_INIT_CURRENT_POOL_LOADING', {loading: true})
            let currentPool = {}
            if (getters.getCurrentPoolEthAddress !== poolAddress || getters.getCurrentPoolF0Address === '') {
                currentPool['address'] = await getAddressInfoByEth(poolAddress)
            } else {
                currentPool['address'] = state.currentPool.address
            }

            let poolInfo = await readContract({
                address: contracts[rootState.evn].LendingPoolGetters.eth,
                abi: LendingPoolGettersABI.abi,
                functionName: 'getPoolInfo',
                args: [poolAddress],
            })

            let admin = poolInfo.owner
            let {nodeCount,
                totalDebt,
                maxLeverage,
                maxSealLoadLeverage,
                maxWithdrawLoadLeverage,
                withdrawLoadTotalAmount,
                totalEntity,
                onlyAcceptsNewNodes,
                liquidationThreshold,
                maxSealedLoadAmount,
                totalPosition,
                maxDebtRatio} = poolInfo

            let totalAvailableBalance = 0n

            currentPool['admin'] = admin
            currentPool['totalDebt'] = totalDebt.toBigInt()
            currentPool['max_leverage'] = maxLeverage.toBigInt()
            currentPool['maxSealLoadLeverage'] = maxSealLoadLeverage.toBigInt()
            currentPool['maxWithdrawLoadLeverage'] = maxWithdrawLoadLeverage.toBigInt()
            currentPool['withdrawLoadTotalAmount'] = withdrawLoadTotalAmount.toBigInt()
            currentPool['onlyAcceptsNewNodes'] = onlyAcceptsNewNodes
            currentPool['liquidationThreshold'] = liquidationThreshold.toBigInt()
            currentPool['maxSealedLoadAmount'] = maxSealedLoadAmount.toBigInt()
            currentPool['totalPosition'] = totalPosition.toBigInt()
            currentPool['totalAvailableBalance'] = totalAvailableBalance
            currentPool['nodeCount'] = nodeCount.toBigInt()
            currentPool['maxDebtRatio'] = maxDebtRatio.toBigInt()
            currentPool['totalEntity'] = totalEntity.toBigInt()

            console.log('currentPool', currentPool)
            commit('SET_CURRENT_POOL', currentPool)
            commit('SET_INIT_CURRENT_POOL_LOADING', {loading: false})
            nowTime = new Date().getTime()
            commit('_SET_POOL_CACHE_TIME', {poolAddress, time: nowTime})
        },
        async getPoolList({commit}, {
            poolAddress = '',
            page = 1,
            size = 10,
            admin = '',
            sortFixed = '',
            sort = 0,
            nodeId = '',
            showAll = ''
        }) {
            commit('SET_POOLS_LOADING', {poolsLoading: true})
            let poolRes = await nodejsHttp.get('pool', {
                params: {
                    page,
                    size,
                    poolAddress,
                    admin,
                    sortFixed,
                    sort,
                    nodeId,
                    showAll,
                }
            })
            let pools = poolRes['list']
            let currentTotal = poolRes['total']
            let currentPage = poolRes['page']
            for (let i = 0; i < pools.length; i++) {
                pools[i]['collect'] = pools[i]['collect'] | 0
                let debtValue = toBigIntDefault(pools[i].total_debt, 0n);
                let positionValue = toBigIntDefault(pools[i].total_position, 0n);
                pools[i]['debtRatio'] = PercentageMath.div(debtValue, positionValue)
                let equityValue = positionValue - debtValue
                pools[i]['currentLeverage'] = PercentageMath.div(equityValue + debtValue, equityValue)
            }
            commit('SET_POOLS', pools)
            commit('SET_CURRENT_PAGE', {currentPage})
            commit('SET_CURRENT_TOTAL', {currentTotal})
            commit('SET_POOLS_LOADING', {poolsLoading: false})
        },
        async getPoolNodeList({commit}, {poolAddress, delegate, user}) {
            let nodes = await nodejsHttp.get(`pool/nodes/${poolAddress}`, {params: {delegate, user}})
            let len = nodes.length
            for (let i = 0; i < len; i++) {
                let node = nodes[i]
                nodes[i]['availableBalance'] = toBigInt(node.position_value) - toBigInt(node.locked_funds) - toBigInt(node.initial_pledge) - toBigInt(node.pre_commit_deposits)
            }
            commit('SET_POOL_NODE_LIST', nodes)
        },
        async getPoolNodeHistory({commit}, {actorId, event, poolAddress}) {
            try {
                commit('SET_POOL_NODE_HISTORY_DATA', [])
                let history = await nodejsHttp.get('pool/node/history?actorId=' + actorId + '&event=' + event + '&poolAddress=' + poolAddress)
                if (!history) {
                    history = []
                }
                commit('SET_POOL_NODE_HISTORY_DATA', history)
            } catch (e) {
                console.log('err history')
                // NotificationErr("There's something wrong with the Internet!")
            }
        },
        // 当前池子节点的信息
        async initCurrentPoolNodeData({commit, rootState}, {nodeId, poolAddress}) {
            commit('SET_INIT_CURRENT_POOL_NODE_DATA_LOADING', {loading: true})
            let actorAddress = getActorAddress(nodeId)
            let actorId = getPrefix() + nodeId
            let minerPower = await retryFun(() => walletProvider.minerPower(actorId), 3)
            let minerInfo = await retryFun(() => walletProvider.minerInfo(actorId), 3)
            let minerSectorCount = await retryFun(() => walletProvider.minerSectorCount(actorId), 3)
            let stateReadState = await retryFun(() => walletProvider.readState(actorId), 3)

            let poolNodeInfo = await readContract({
                address: contracts[rootState.evn].LendingPoolGetters.eth,
                abi: LendingPoolGettersABI.abi,
                functionName: 'getPoolNodeInfo',
                args: [poolAddress, nodeId],
            })

            let {stableDebt,
                variableDebt,
                withdrawLoadAmount,
                maxWithdrawLoanAmount,
                nodesSafetyBuffer,
                nodeOwner,
                entity,
            } = poolNodeInfo

            let variableDebtBalance = variableDebt.toBigInt()
            let stableDebtBalance = stableDebt.toBigInt()

            let userStableRate = await readContract({
                address: contracts[rootState.evn].StableDebtToken.eth,
                abi: StableDebtTokenABI.abi,
                functionName: 'getUserStableRate',
                args: [actorAddress],
            })

            let debtValue = variableDebtBalance + stableDebtBalance

            let {Balance} = stateReadState
            let PositionValue = toBigInt(Balance)
            let {Active, Faulty, Live} = minerSectorCount
            let {Beneficiary, Owner, SectorSize} = minerInfo
            let {MinerPower} = minerPower
            let {RawBytePower, QualityAdjPower} = MinerPower

            let {LockedFunds, InitialPledge, PreCommitDeposits} = stateReadState.State

            let availableBalance = BigNumber.from(Balance).sub(BigNumber.from(LockedFunds)).sub(BigNumber.from(InitialPledge)).sub(BigNumber.from(PreCommitDeposits)).toString()

            let currentPoolNode = {
                beneficiary: Beneficiary,
                borrowing: '1',
                withdrawLoadAmount: withdrawLoadAmount.toBigInt(),
                maxWithdrawLoanAmount: maxWithdrawLoanAmount.toBigInt(),
                debt_value: debtValue,
                stable_debt_value: stableDebtBalance,
                variable_debt_value: variableDebtBalance,
                delegate: '1',
                node: nodeId,
                owner: Owner,
                position_value: PositionValue,
                raw_byte_power: toBigInt(RawBytePower),
                quality_adj_power: toBigInt(QualityAdjPower),
                sector_active: Active,
                sector_faulty: Faulty,
                sector_live: Live,
                sector_size: SectorSize,
                safetyBuffer: nodesSafetyBuffer.toBigInt(),
                availableBalance: availableBalance,
                pledge: InitialPledge,
                lockedRewards: LockedFunds,
                is_active: '1',
                oldOwner: nodeOwner,
                userStableRate: userStableRate.toBigInt(),
                entity: entity.toBigInt()
            }

            commit('SET_CURRENT_POOL_NODE', currentPoolNode)

            commit('SET_INIT_CURRENT_POOL_NODE_DATA_LOADING', {loading: false})
        },
    },
    modules: {}
}

export default subpool
