import "core-js/modules/es.array.push.js";
import { EditPen, SuccessFilled } from '@element-plus/icons-vue';
import { isEmpty } from "@/utils/model";
import { signMessage } from "@wagmi/core";
import { getLocal, isValidEthereumAddress, NotificationErr, NotificationSuccess, NotificationWarning, simpleDateFormatByMin, simpleValue } from "@/utils/common";
import { mapState } from "vuex";
import { formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { LeveLabel } from "@/views/referrals/label";
import { parseErr } from "@/components/induction/singer/ledger/Errors";
export default {
  name: "superior-page",
  components: {
    EditPen,
    SuccessFilled
  },
  data() {
    return {
      LeveLabel,
      showWhileAddressInput: false,
      showSetYourReferralCode: false,
      checkReferralsCodeLoading: false,
      generateCodeFinish: false,
      generateCodeInputTime: 0,
      referralCodeButtonTips: '',
      referralCode: '',
      whiteAddress: '',
      addWhileListErrTips: '',
      whiteAddressLoading: false
    };
  },
  watch: {
    address() {
      this.updateMyInfo();
      this.init();
    }
  },
  created() {
    this.updateMyInfo();
    this.init();
  },
  methods: {
    init() {
      if (!isEmpty(this.address)) {
        this.$store.dispatch('initWithdrawList', {
          address: this.address,
          type: 1
        });
        this.$store.dispatch('initSnapshoot', {
          address: this.address,
          type: 1
        });
      } else {
        this.$store.commit('CLEAR_REFERRALS_VALUE');
      }
    },
    setCurrentWallet() {
      if (this.address) {
        this.whiteAddress = this.address;
        this.addWhileListErrTips = '';
      }
    },
    async addWhileList() {
      this.whiteAddressLoading = true;
      if (isEmpty(this.whiteAddress)) {
        this.addWhileListErrTips = '*' + this.$t('Referrals.Please enter the wallet address');
        this.whiteAddressLoading = false;
        return;
      }
      this.whiteAddress = this.whiteAddress.toString().trim();
      if (!isValidEthereumAddress(this.whiteAddress)) {
        this.addWhileListErrTips = '*' + this.$t('Referrals.Please enter the correct wallet address');
        this.whiteAddressLoading = false;
        return;
      }
      this.addWhileListErrTips = '';
      try {
        let signature = '';
        try {
          signature = await this.singMessageFun(this.whiteAddress);
        } catch (e) {
          if (getLocal('connectType') === 'Ledger') {
            NotificationErr(parseErr(e, this.$t));
          } else {
            NotificationErr(e.toString());
          }
          this.whiteAddressLoading = false;
          return;
        }
        let params = {
          address: this.whiteAddress,
          fromAddress: this.address,
          signature
        };
        if (getLocal('connectType') === 'MetaMaskFlask') {
          params['f1Address'] = this.metamaskFlaskFilsnapSuper.getF1Address();
        } else if (getLocal('connectType') === 'Ledger') {
          params['f1Address'] = this.ledgerFileCoinSuper.getAddress();
        }
        await this.$store.dispatch('addWhileList', params);
        NotificationSuccess("The whitelist is added successfully. Procedure");
        setTimeout(() => {
          this.$store.dispatch('getWhileList', {
            address: this.address
          });
          this.$store.dispatch('initMintWlCheck', {
            address: this.address
          });
        }, 200);
        this.whiteAddressLoading = false;
        this.whileAddressInputHandleClose();
      } catch (e) {
        NotificationErr(e.msg);
        this.whiteAddressLoading = false;
      }
    },
    whileAddressInputHandleClose() {
      this.showWhileAddressInput = false;
      this.whiteAddress = '';
    },
    formatWalletFilPrice,
    simpleDateFormatByMin,
    changeWithdraw(val) {
      this.$store.dispatch('initWithdrawList', {
        address: this.address,
        type: 1,
        page: val
      });
    },
    changeSnapshoot(val) {
      this.$store.dispatch('initSnapshoot', {
        address: this.address,
        type: 1,
        page: val
      });
    },
    async updateMyInfo() {
      if (!isEmpty(this.address)) {
        await this.$store.dispatch('initMyReferralInfo', {
          address: this.address
        });
      }
    },
    async singMessageFun(message) {
      let signature = '';
      if (getLocal('connectType') === 'MetaMaskFlask') {
        signature = await this.metamaskFlaskFilsnapSuper.signMessageLocal(message);
      } else if (getLocal('connectType') === 'Ledger') {
        signature = await this.ledgerFileCoinSuper.simpleSignMessage(message);
      } else {
        signature = await signMessage({
          message
        });
      }
      return signature;
    },
    async applyReferralsCode() {
      this.checkReferralsCodeLoading = true;
      let signature = '';
      try {
        signature = await this.singMessageFun(this.referralCode);
      } catch (e) {
        if (getLocal('connectType') === 'Ledger') {
          NotificationErr(parseErr(e, this.$t));
        } else {
          NotificationErr(e.toString());
        }
        this.checkReferralsCodeLoading = false;
        return;
      }
      try {
        let params = {
          address: this.address,
          referralCode: this.referralCode,
          signature
        };
        if (getLocal('connectType') === 'MetaMaskFlask') {
          params['f1Address'] = this.metamaskFlaskFilsnapSuper.getF1Address();
        } else if (getLocal('connectType') === 'Ledger') {
          params['f1Address'] = this.ledgerFileCoinSuper.getAddress();
        }
        let res = await this.$store.dispatch('setReferralCode', params);
        if (res) {
          NotificationSuccess("Your referral code has been successfully set.");
        } else {
          NotificationErr("The current recommendation code does not exist!");
        }
        setTimeout(() => {
          this.updateMyInfo();
          console.log('ok');
        }, 1500);
        this.checkReferralsCodeLoading = false;
        this.closeShowSetYourReferralCode();
      } catch (e) {
        NotificationErr(e.msg);
        this.checkReferralsCodeLoading = false;
      }
    },
    inputReferralCode(val) {
      this.generateCodeFinish = false;
      this.referralCode = val.replace(/[^0-9.]/g, '');
      let wait = 500;
      this.generateCodeInputTime = new Date().getTime() + wait;
      setTimeout(() => {
        this.referralCodeErrTips = '';
        let now = new Date().getTime();
        if (now - this.generateCodeInputTime >= 0) {
          // this.inputTime拿的是最新的值，e.timeStamp拿的是零点五秒前函数的e.timeStamp
          if (!isEmpty(this.referralCode)) {
            this.checkReferralsCodeLoading = true;
            this.referralCodeButtonTips = this.$t('Referrals.Checking code');
            this.$store.dispatch('selectReferralCode', {
              referralCode: parseInt(this.referralCode)
            }).then(val => {
              if (val !== null) {
                this.generateCodeFinish = true;
              } else {
                this.referralCodeButtonTips = this.$t('Referrals.The referral code does not exist');
              }
            }).finally(() => {
              this.checkReferralsCodeLoading = false;
              this.$refs['generateCodeInput'].focus();
              if (this.generateCodeFinish) {
                this.referralCodeButtonTips = '';
              }
            });
          }
        }
      }, wait);
    },
    async withdraw() {
      this.$store.commit('SET_ADD_WITHDRAW_LOADING', {
        addWithdrawLoading: true
      });
      let signature = '';
      try {
        signature = await this.singMessageFun(this.address);
      } catch (e) {
        if (getLocal('connectType') === 'Ledger') {
          NotificationErr(parseErr(e, this.$t));
        } else {
          NotificationErr(e.toString());
        }
        this.$store.commit('SET_ADD_WITHDRAW_LOADING', {
          addWithdrawLoading: false
        });
        return;
      }
      let params = {
        address: this.address,
        type: 1,
        signature
      };
      if (getLocal('connectType') === 'MetaMaskFlask') {
        params['f1Address'] = this.metamaskFlaskFilsnapSuper.getF1Address();
      } else if (getLocal('connectType') === 'Ledger') {
        params['f1Address'] = this.ledgerFileCoinSuper.getAddress();
      }
      this.$store.dispatch('addUserWithdrawn', params).then(() => {
        setTimeout(() => {
          this.$store.dispatch('initWithdrawList', {
            address: this.address,
            type: 1
          });
        }, 1000);
        NotificationSuccess(this.$t('Referrals.Successfully added to queue'));
      }).catch(err => {
        if (err.msg === 'You have a record of not waiting for withdrawal processing') {
          NotificationSuccess(this.$t('Referrals.Successfully added to queue'));
        } else if (err.msg === 'The minimum withdrawal amount for commissions is 1 FIL') {
          NotificationWarning(this.$t('Referrals.The minimum withdrawal amount for commissions'));
        } else {
          NotificationErr(err.msg);
        }
      }).finally(() => {
        this.$store.commit('SET_ADD_WITHDRAW_LOADING', {
          addWithdrawLoading: false
        });
      });
    },
    closeShowSetYourReferralCode() {
      this.referralCode = '';
      this.showSetYourReferralCode = false;
      console.log('this.showSetYourReferralCode', this.showSetYourReferralCode);
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    setYourReferralCode() {
      if (!this.isConnected) {
        this.connectWallet();
        return;
      }
      this.showSetYourReferralCode = true;
      this.$nextTick(() => {
        if (this.myReferral !== null) {
          this.referralCode = this.myReferral.toString();
          this.inputReferralCode(this.referralCode);
        }
      });
    },
    showWhileAddressInputFun() {
      this.showWhileAddressInput = true;
    },
    gotoNft() {
      if (this.$store.state.referrals.whiteListAddress === this.address) {
        this.$router.push({
          path: '/nft'
        });
      }
    },
    gotoNft2() {
      this.$router.push({
        path: '/nft'
      });
    }
  },
  computed: mapState({
    addWithdrawLoading: state => state.referrals.addWithdrawLoading,
    ledgerFileCoinSuper() {
      return this.$store.state.ledger.ledgerFileCoinSuper;
    },
    metamaskFlaskFilsnapSuper() {
      return this.$store.state.metamaskFlask.metamaskFlaskFilsnapSuper;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    },
    myInfoLoading: state => state.referrals.myInfoLoading,
    myReferral: state => state.referrals.myInfo.referral,
    superiorRatio: state => {
      let ratio = state.referrals.myInfo.superiorRatio;
      return (ratio / 100.0).toFixed(1);
    },
    wlMintState: state => state.nft.wlMintState,
    isFineWhiteListMint: state => state.referrals.isFineWhiteListMint,
    addWhileListLoading: state => state.referrals.addWhileListLoading,
    isHaveWhiteList: state => state.referrals.isHaveWhiteList,
    subordinateRatio: state => state.referrals.myInfo.subordinateRatio,
    supLevel: state => state.referrals.myInfo.supLevel,
    level: state => state.referrals.myInfo.level,
    interest: state => state.referrals.myInfo.interest,
    stfilBalance: state => state.referrals.myInfo.stfilBalance,
    targetRebates: state => state.referrals.myInfo.targetRebates,
    isRemote: state => state.referrals.myInfo.isRemote,
    discount: state => state.referrals.myInfo.discount,
    withdrawn: state => state.referrals.myInfo.withdrawn,
    withdrawHistory: state => state.referrals.withdrawInfo[1].withdrawHistory,
    withdrawHistoryLoading: state => state.referrals.withdrawHistoryLoading,
    withdrawHistoryTotal: state => state.referrals.withdrawInfo[1].total,
    snapshootList: state => state.referrals.snapshootInfo[1].snapshootList,
    snapshootInfoLoading: state => state.referrals.snapshootInfo[1].loading,
    snapshootInfoTotal: state => state.referrals.snapshootInfo[1].total,
    whiteListAddress: state => {
      return simpleValue(state.referrals.whiteListAddress, 6, 6);
    },
    interestRate() {
      let res = PercentageMath.div(toBigInt(this.interest), toBigInt(this.targetRebates));
      if (res > 10000n) {
        return 100.00;
      }
      return (parseInt(res) / 100.0).toFixed(2);
    },
    nextEpochShow: state => state.referrals.settlementInfo.nextEpochShow
  })
};