import RadioBtn from '@/components/RadioBtn';
import { BigNumber, ethers } from "ethers";
import { contracts } from "@/utils/contracts/config";
import StakingPoolABI from '@/utils/abi/StakingPool.json';
import STFILTokenABI from '@/utils/abi/STFILToken.json';
import WSTFILTokenABI from '@/utils/abi/WSTFILToken.json';
import Tooltip from '@/components/tip/Tooltip';
import { installToken, installWSTFILToken, isInstallFoxWallet, isInstallMetamask, iWriteContract } from "@/utils/contracts/opertion";
import { getLocal, gotoHash, NotificationErr, NotificationSuccess } from "@/utils/common";
import { externalFormatFilPrice, floatToFixed, formatFilPrice, formatPrice, formatWalletFilPrice, formatWalletFilPrice2, toBigInt, toEth, toNormalNumberString } from "@/utils/NumU";
import { mapState } from "vuex";
import { formatUnits } from "ethers/lib/utils";
import { MAX_UINT256 } from "@/utils/contracts/WadRayMath";
import { WarningFilled } from '@element-plus/icons-vue';
import { filChain } from "@/utils/filChain";
import Clipboard from "clipboard";
import { isEmpty } from "@/utils/model";
import ScrollNumber from '@/components/ScrollNumber.vue';
import PercentageMath from "@/utils/contracts/PercentageMath";
export default {
  name: 'StakeRightStake',
  components: {
    RadioBtn,
    WarningFilled,
    ScrollNumber,
    Tooltip
  },
  data() {
    return {
      isWrap: false,
      panelType: 'staking',
      tabIndex: 0,
      isCopy: false,
      stakeRadio: 'STAKE',
      wrapRadio: 'WRAP',
      radioOptions: [],
      wrapRadioOptions: [],
      connect: undefined,
      connectors: [],
      pendingConnector: undefined,
      activeConnector: undefined,
      data: {
        fil: {
          receive: ''
        },
        stfil: {
          receive: ''
        },
        wstfil: {
          receive: ''
        }
      },
      loading: false,
      oldStFilBalance: 0n,
      oldAccumulatedEarnings: 0n,
      userAccumulatedEarnings: 0n,
      showFlicker: false,
      timerFlag: true,
      unStakeIsMax: false,
      metaMaskInstall: false,
      isUnStakeMax: false,
      isUnWrapMax: false,
      hash: '',
      btnText: '',
      contractAddress: '',
      wstFilContractAddress: '',
      stakeInputIsErr: false,
      unStakeInputIsErr: false,
      stakeInputErr: '',
      unStakeInputErr: '',
      wrapInputIsErr: false,
      wrapInputErr: '',
      unwrapInputIsErr: false,
      unwrapInputErr: '',
      poolStFilBalanceBigBak: undefined,
      stFILPerToken: 100000000000000000n,
      wstFILPerToken: 100000000000000000n,
      perTokenLoading: false,
      approveValue: BigNumber.from("0"),
      approveValueLoading: false
    };
  },
  watch: {
    address() {
      this.check();
      this.initContractAddress();
      this.initAccumulatedEarnings();
    },
    '$i18n.locale'() {
      this.initRadioOptions();
    },
    stakeRadio() {
      if (this.stakeRadio === 'STAKE') {
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: parseFloat(this.data.fil.receive)
        });
      } else {
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: parseFloat(this.data.stfil.receive) * -1
        });
      }
    },
    isWrap() {
      if (this.isWrap) {
        this.initStFILPerToken();
        this.changePanel('wrap');
      } else {
        this.changePanel('staking');
      }
      this.data.fil.receive = '';
      this.data.wstfil.receive = '';
      this.data.stfil.receive = '';
    }
  },
  mounted() {
    let elementsByTagNames = document.getElementsByTagName("input");
    for (let i = 0; i < elementsByTagNames.length; i++) {
      let target = elementsByTagNames[i];
      target.addEventListener('keypress', function (e) {
        let restricted = "+-e";
        let key = e.keyCode || e.which;
        for (let i = 0; i < restricted.length; i++) {
          if (restricted.charCodeAt(i) === key) {
            e.returnValue = false;
          }
        }
        return true;
      });
    }
  },
  created() {
    this.initContractAddress();
    this.initRadioOptions();
    this.timerFlag = true;
    this.check();
    this.init();
  },
  unmounted() {
    this.timerFlag = false;
  },
  methods: {
    async initStFILPerToken() {
      this.perTokenLoading = true;
      this.stFILPerToken = await this.$store.dispatch('stFILPerToken');
      this.wstFILPerToken = await this.$store.dispatch('wstFILPerToken');
      this.perTokenLoading = false;
    },
    async initApproveValue() {
      if (!isEmpty(this.address)) {
        this.approveValueLoading = true;
        this.approveValue = await this.$store.dispatch('getSTFILTokenAllowance', {
          address: this.address
        });
        this.approveValueLoading = false;
      }
    },
    changePanel(panelType) {
      this.panelType = panelType;
    },
    initContractAddress() {
      let connectType = getLocal('connectType');
      if (connectType === 'Ledger') {
        this.contractAddress = contracts[this.$store.state.evn].StakingPool.id;
        this.wstFilContractAddress = contracts[this.$store.state.evn].WSTFILToken.id;
      } else {
        this.contractAddress = contracts[this.$store.state.evn].StakingPool.eth;
        this.wstFilContractAddress = contracts[this.$store.state.evn].WSTFILToken.eth;
      }
    },
    initRadioOptions() {
      this.radioOptions = [{
        label: this.$t('stake.Stake'),
        value: 'STAKE'
      }, {
        label: this.$t('stake.unStake'),
        value: 'UNSTAKE'
      }];
      this.wrapRadioOptions = [{
        label: 'Wrap',
        value: 'WRAP'
      }, {
        label: 'Unwrap',
        value: 'UNWRAP'
      }];
    },
    copyAddress() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          if (this.isWrap) {
            return this.wstFilContractAddress;
          }
          return this.contractAddress;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', e => {
        console.error(e);
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    check() {
      if (isInstallMetamask()) {
        this.metaMaskInstall = true;
      } else {
        if (!isInstallFoxWallet()) {
          this.metaMaskInstall = false;
        } else {
          this.metaMaskInstall = true;
        }
      }
      let connectType = getLocal('connectType');
      if (!isEmpty(connectType) && (connectType.toString().startsWith('Ledger') || connectType.toString().startsWith('MetaMaskFlask'))) {
        this.metaMaskInstall = false;
      }
    },
    theFormat(number) {
      formatFilPrice(number, 1);
    },
    init() {
      this.initAccumulatedEarnings();
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.initAccumulatedEarnings,
        pageNames: ['stake', 'stake-ref', 'stake-old']
      });
    },
    gotoHash,
    initAccumulatedEarningsTimer() {
      if (!this.timerFlag) {
        return;
      }
      setTimeout(() => {
        this.showFlicker = true;
        setTimeout(async () => {
          await this.initAccumulatedEarnings();
          this.showFlicker = false;
          this.initAccumulatedEarningsTimer();
        }, 4000);
      }, 16000);
    },
    async initAccumulatedEarnings() {
      await this.$store.dispatch('initStFilBalance', {
        address: this.address
      });
      await this.$store.dispatch('initWstFilBalance', {
        address: this.address
      });
      this.initStFILPerToken();
      this.initApproveValue();
    },
    formatFilPrice,
    externalFormatFilPrice,
    formatWalletFilPrice2,
    formatWalletFilPrice,
    filReceive() {
      let data = this.data.fil.receive.toString();
      let res = data;
      if (data.indexOf('.') >= 0) {
        let numbers = data.split(".");
        if (numbers[1].length > 8) {
          numbers[1] = numbers[1].toString().substr(0, 8);
          res = numbers[0] + '.' + numbers[1];
        }
      }
      if (parseFloat(res) >= 10000000000) {
        res = 10000000000;
      }
      if (res !== data) {
        this.data.fil.receive = res.toString();
      }
      let max = parseFloat(this.filBalanceFormat);
      if (parseFloat(this.data.fil.receive) > max) {
        this.stakeInputIsErr = true;
        this.stakeInputErr = this.$t('stake.stakeInputErr limit max');
      } else if (max - parseFloat(this.data.fil.receive) < 0.19999) {
        this.stakeInputIsErr = true;
        this.stakeInputErr = this.$t('stake.stakeInputErr gas max');
      } else {
        this.stakeInputIsErr = false;
        this.stakeInputErr = '';
      }
      if (this.data.fil.receive === '') {
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: 0
        });
      } else {
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: parseFloat(this.data.fil.receive)
        });
      }
    },
    addSTFIL() {
      installToken(this.address);
    },
    addWSTFIL() {
      installWSTFILToken(this.address);
    },
    stfilReceive() {
      this.unStakeInputIsErr = false;
      this.unStakeInputErr = '';
      let data = this.data.stfil.receive.toString();
      if (data === '') {
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: 0
        });
        return;
      }
      data = toNormalNumberString(data);
      let res = data;
      if (data.indexOf('.') >= 0) {
        let numbers = data.split(".");
        if (numbers[1].length > 8) {
          numbers[1] = numbers[1].toString().substr(0, 8);
          res = numbers[0] + '.' + numbers[1];
        }
      }
      if (parseFloat(res) >= 10000000000) {
        res = 10000000000;
      }
      if (res !== data) {
        this.data.stfil.receive = res.toString();
      }
      let max = parseFloat(this.stFilBalanceFormat);
      let _receive = parseFloat(this.data.stfil.receive);
      if (_receive >= max) {
        this.unStakeInputIsErr = true;
        this.unStakeInputErr = this.$t('stake.UNSTAKE err 1');
      } else {
        let _poolStFilBalance = parseFloat(floatToFixed(this.poolStFilBalance, 4));
        if (_receive >= _poolStFilBalance) {
          this.unStakeInputIsErr = true;
          this.unStakeInputErr = this.$t('stake.UNSTAKE err');
        } else {
          this.unStakeInputIsErr = false;
          this.unStakeInputErr = '';
        }
        this.isUnStakeMax = false;
      }
      this.$store.commit('SET_STAKE_NUMBER', {
        stakeNumber: _receive * -1
      });
    },
    stfilReceiveByWrap() {
      this.wrapInputIsErr = false;
      this.wrapInputErr = '';
      let data = this.data.stfil.receive;
      if (data === '') {
        return;
      }
      data = toNormalNumberString(data);
      let res = data;
      if (data.indexOf('.') >= 0) {
        let numbers = data.split(".");
        if (numbers[1].length > 8) {
          numbers[1] = numbers[1].toString().substr(0, 8);
          res = numbers[0] + '.' + numbers[1];
        }
      }
      if (parseFloat(res) >= 10000000000) {
        res = 10000000000;
      }
      if (res !== data) {
        this.data.stfil.receive = res.toString();
      }
      let max = parseFloat(this.stFilBalanceFormat);
      data = parseFloat(data);
      if (data >= max) {
        this.wrapInputIsErr = true;
        this.wrapInputErr = this.$t('stake.WRAP err');
      } else {
        this.wrapInputIsErr = false;
        this.wrapInputErr = '';
      }
    },
    wstfilReceive() {
      this.unwrapInputIsErr = false;
      this.unwrapInputErr = '';
      let data = this.data.wstfil.receive;
      if (data === '') {
        return;
      }
      data = toNormalNumberString(data);
      let res = data;
      if (data.indexOf('.') >= 0) {
        let numbers = data.split(".");
        if (numbers[1].length > 8) {
          numbers[1] = numbers[1].toString().substr(0, 8);
          res = numbers[0] + '.' + numbers[1];
        }
      }
      if (parseFloat(res) >= 10000000000) {
        res = 10000000000;
      }
      if (res !== data) {
        this.data.wstfil.receive = res.toString();
      }
      let max = parseFloat(this.wstFilBalanceFormat);
      if (parseFloat(this.data.wstfil.receive) >= max) {
        this.unwrapInputIsErr = true;
        this.unwrapInputErr = this.$t('stake.UNWRAP err');
      } else {
        this.unwrapInputIsErr = false;
        this.unwrapInputErr = '';
      }
    },
    openAvailableliquidity() {
      let contractAddress = '';
      if (this.isWrap) {
        contractAddress = contracts[this.$store.state.evn].WSTFILToken.eth;
      } else {
        contractAddress = contracts[this.$store.state.evn].StakingPool.eth;
      }
      if (this.$store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${this.$i18n.locale}/address/${contractAddress}`);
      } else {
        window.open(`https://filfox.info/${this.$i18n.locale}/address/${contractAddress}`);
      }
    },
    showComputer() {
      this.$store.state.refs['iHeader'].showApyComputerShowDialog(this.data.fil.receive, 2);
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    max() {
      if (this.loading) {
        return;
      }
      if (this.stakeRadio === 'STAKE') {
        let max = parseFloat(this.filBalanceFormat);
        if (max - 0.5 > 0) {
          this.data.fil.receive = floatToFixed(max - 0.5, 8);
        } else {
          this.data.fil.receive = floatToFixed(max, 8);
        }
        this.stakeInputIsErr = false;
        this.stakeInputErr = '';
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: parseFloat(this.data.fil.receive)
        });
      } else {
        let max = parseFloat(this.stFilBalanceFormat);
        if (this.poolStFilBalance < max) {
          this.data.stfil.receive = floatToFixed(this.poolStFilBalance, 4);
          this.isUnStakeMax = false;
        } else {
          this.data.stfil.receive = floatToFixed(max, 8);
          this.isUnStakeMax = true;
        }
        this.unStakeInputIsErr = false;
        this.unStakeInputErr = '';
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: parseFloat(this.data.stfil.receive) * -1
        });
      }
    },
    maxWrap() {
      if (this.loading) {
        return;
      }
      if (this.wrapRadio === 'UNWRAP') {
        let max = parseFloat(this.wstFilBalanceFormat);
        this.data.wstfil.receive = floatToFixed(max, 8);
        this.isUnWrapMax = true;
        this.unwrapInputIsErr = false;
        this.unwrapInputErr = '';
      } else {
        let max = parseFloat(this.stFilBalanceFormat);
        this.data.stfil.receive = floatToFixed(max, 8);
        this.wrapInputIsErr = false;
        this.wrapInputErr = '';
      }
    },
    formatPrice,
    formatUnits,
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    async stake() {
      if (parseFloat(this.data.fil.receive) <= 0) {
        return;
      }
      if (this.stakeInputIsErr) {
        return;
      }
      let filBalance = parseFloat(this.filBalanceFormat);
      if (filBalance - parseFloat(this.data.fil.receive) < 0.49999) {
        NotificationErr(this.$t('stake.Gas may be insufficient Reserve a balance of at least'));
        return;
      }
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        chainId: filChain.id,
        address: contracts[this.$store.state.evn].StakingPool.eth,
        f0address: contracts[this.$store.state.evn].StakingPool.id,
        abi: StakingPoolABI.abi,
        functionName: 'stake',
        overrides: {
          value: ethers.utils.parseEther(this.data.fil.receive.toString())
        },
        args: [this.address, this.referralCode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(async () => {
        NotificationSuccess(this.$t('stake.You have successfully staked FIL').replace('xxxx', this.data.fil.receive.toString()));
        await this.$store.dispatch('initAccount');
        this.$store.dispatch('initStakeInfo');
        this.loading = false;
        this.isUnStakeMax = false;
        this.poolStFilBalanceBigBak = this.afterPoolStFilBalanceBig;
        this.data.fil.receive = 0;
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: 0
        });
        this.clearContent();
      }).catch(err => {
        NotificationErr(err.message, "stake" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.clearContent();
      });
    },
    _approveWSTFIL(amount, call) {
      this.btnText = this.$t('common.wait for approve');
      iWriteContract({
        address: contracts[this.$store.state.evn].STFILToken.eth,
        f0address: contracts[this.$store.state.evn].STFILToken.id,
        abi: STFILTokenABI.abi,
        functionName: 'approve',
        args: [contracts[this.$store.state.evn].WSTFILToken.eth, amount]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Awaiting stFIL Approval');
        this.hash = hash;
      }).then(() => {
        call();
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    _wrap(amount) {
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: contracts[this.$store.state.evn].WSTFILToken.eth,
        f0address: contracts[this.$store.state.evn].WSTFILToken.id,
        abi: WSTFILTokenABI.abi,
        functionName: 'wrap',
        args: [amount]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(async () => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          NotificationSuccess(this.$t('stake.You have successfully wrap stFIL').replace('xxxx', this.data.stfil.receive.toString()));
          this.$store.dispatch('initAccount');
          this.loading = false;
          this.isUnWrapMax = false;
          this.data.stfil.receive = 0;
          this.clearContent();
        }, 20000);
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    async wrap() {
      if (this.data.stfil.receive <= 0) {
        return;
      }
      this.loading = true;
      let amount = ethers.utils.parseEther(this.data.stfil.receive.toString());
      this.approveValue = await this.$store.dispatch('getSTFILTokenAllowance', {
        address: this.address
      });
      console.log('approveValue', this.approveValue.toBigInt());
      if (this.approveValue.gte(amount)) {
        this._wrap(amount);
      } else {
        this._approveWSTFIL(amount, () => {
          this._wrap(amount);
        });
      }
    },
    async unwrap() {
      if (parseFloat(this.data.wstfil.receive) <= 0) {
        return;
      }
      this.loading = true;
      let amount = ethers.utils.parseEther(this.data.wstfil.receive.toString());
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: contracts[this.$store.state.evn].WSTFILToken.eth,
        f0address: contracts[this.$store.state.evn].WSTFILToken.id,
        abi: WSTFILTokenABI.abi,
        functionName: 'unwrap',
        args: [amount]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(async () => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          NotificationSuccess(this.$t('stake.You have successfully unwrap stFIL').replace('xxxx', this.data.fil.receive.toString()));
          this.$store.dispatch('initAccount');
          this.loading = false;
          this.isUnWrapMax = false;
          this.data.wstfil.receive = 0;
          this.clearContent();
        }, 20000);
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    selectTab(v) {
      this.tabIndex = v;
    },
    claim() {
      this.$refs.slideTabs.changeTab(3);
    },
    async unstake() {
      if (this.data.stfil.receive <= 0) {
        return;
      }
      if (this.unStakeInputIsErr) {
        return;
      }
      this.loading = true;
      let amount = ethers.utils.parseEther(this.data.stfil.receive.toString());
      if (this.isUnStakeMax || parseFloat(this.data.stfil.receive).toFixed(6) === parseFloat(this.stFilBalanceFormat).toFixed(6)) {
        amount = BigNumber.from(MAX_UINT256.toString());
      }
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: contracts[this.$store.state.evn].StakingPool.eth,
        f0address: contracts[this.$store.state.evn].StakingPool.id,
        abi: StakingPoolABI.abi,
        functionName: 'unstake',
        args: [amount, this.address]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(async () => {
        NotificationSuccess(this.$t('stake.You have successfully unstaked FIL').replace('xxxx', this.data.stfil.receive.toString()));
        await this.$store.dispatch('initAccount');
        this.$store.dispatch('initStakeInfo');
        this.loading = false;
        this.isUnStakeMax = false;
        this.poolStFilBalanceBigBak = this.poolStFilBalanceBig;
        this.data.stfil.receive = 0;
        this.$store.commit('SET_STAKE_NUMBER', {
          stakeNumber: 0
        });
        this.clearContent();
      }).catch(err => {
        NotificationErr(err.message, "unstake" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.clearContent();
      });
    }
  },
  computed: mapState({
    wrapReceiveWstfil() {
      let receive = this.data.stfil.receive.toString() === '' ? '0' : this.data.stfil.receive.toString();
      let amount = toBigInt(parseInt((parseFloat(receive) * 100000000000).toString()).toString());
      return PercentageMath.mul(amount, this.stFILPerToken) / 10000000n;
    },
    unwrapReceivestfil() {
      let receive = this.data.wstfil.receive.toString() === '' ? '0' : this.data.wstfil.receive.toString();
      let amount = ethers.utils.parseEther(receive).mul(BigNumber.from("1000000000000000000"));
      return amount.div(BigNumber.from(this.stFILPerToken)).toBigInt();
    },
    computerFileResult() {
      let _liquidityRate = formatPrice(formatUnits(this.liquidityRate, 27), 20);
      let day = 30;
      let rate = Math.pow(_liquidityRate / 365 + 1, day) - 1;
      let res = parseFloat(this.data.fil.receive === '' ? 0 : this.data.fil.receive) * rate;
      if (res <= 0) {
        return 0;
      }
      return res.toFixed(2);
    },
    publicInfoLoading: state => state.publicInfo.loading,
    poolStFilBalanceLoading: state => state.publicInfo.poolStFilBalanceLoading,
    accumulatedEarnings: state => state.stakeInfo.accumulatedEarnings,
    poolStFilBalance: state => parseFloat(formatUnits(state.publicInfo.poolStFilBalance.toString(), 18)),
    poolStFilBalanceBig: state => state.publicInfo.poolStFilBalance,
    afterPoolStFilBalanceBig() {
      let poolStFilBalanceBig = this.poolStFilBalanceBig;
      if (this.stakeRadio === 'UNSTAKE') {
        return poolStFilBalanceBig - toEth(this.data.stfil.receive);
      } else {
        return poolStFilBalanceBig + toEth(this.data.fil.receive);
      }
    },
    liquidityRate: state => state.publicInfo.liquidityRate,
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    stFilBalance() {
      return this.$store.state.stFilBalance;
    },
    stFilBalanceFormat() {
      return ethers.utils.formatEther(this.$store.state.stFilBalance);
    },
    wstFilBalance() {
      return this.$store.state.wstFilBalance;
    },
    wstFilBalanceFormat() {
      return ethers.utils.formatEther(this.$store.state.wstFilBalance);
    },
    filBalance() {
      return this.$store.state.balance;
    },
    filBalanceFormat() {
      return ethers.utils.formatEther(this.$store.state.balance);
    },
    address() {
      return this.$store.state.address;
    },
    referralCode: state => {
      let referralCode = state.stakeInfo.referralCode;
      return !referralCode ? 0 : referralCode;
    },
    simpleContractAddress() {
      if (isEmpty(this.contractAddress)) {
        return '';
      }
      if (this.contractAddress.length > 20) {
        return this.contractAddress.toString().substring(0, 10) + '...' + this.contractAddress.toString().substring(this.contractAddress.length - 10);
      } else {
        return this.contractAddress.toString();
      }
    },
    simpleWstFILContractAddress() {
      if (isEmpty(this.wstFilContractAddress)) {
        return '';
      }
      if (this.wstFilContractAddress.length > 20) {
        return this.wstFilContractAddress.toString().substring(0, 10) + '...' + this.wstFilContractAddress.toString().substring(this.wstFilContractAddress.length - 10);
      } else {
        return this.wstFilContractAddress.toString();
      }
    },
    assetsLoading: state => state.assetsLoading
  })
};