import { CaretBottom, CaretTop } from '@element-plus/icons-vue';
export default {
  name: "SortComponent",
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  components: {
    CaretTop,
    CaretBottom
  },
  computed: {
    topColor() {
      if (this.type === 0) {
        return '#bbb';
      } else if (this.type === 1) {
        return '#bbb';
      } else if (this.type === 2) {
        return '#686868';
      } else {
        return '#bbb';
      }
    },
    bottomColor() {
      if (this.type === 0) {
        return '#bbb';
      } else if (this.type === 1) {
        return '#686868';
      } else if (this.type === 2) {
        return '#bbb';
      } else {
        return '#bbb';
      }
    }
  }
};