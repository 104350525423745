let voteSignModel = {
    domain: {
        name: 'STFIL Vote',
        version: '1.0.2',
        chainId: 3141,
        verifyingContract: "0x0000000000000000000000000000000000000000"
    },
    types: {
        Vote: [
            {name: 'voter', type: 'address'},
            {name: 'choice', type: 'uint32'},
            {name: 'vp', type: 'uint64'},
            {name: 'proposalId', type: 'string'},
            {name: 'created', type: 'uint64'},
            {name: 'reason', type: 'string'},
        ],
    }
}

const chainsMap = {
    314159: {
        name: 'Filecoin - Calibration',
        img: 'https://storage.froghub.io/froghub-home-html/assets/filecoin-logo.png'
    },
    undefined: {
        name: 'Filecoin - Calibration',
        img: 'https://storage.froghub.io/froghub-home-html/assets/filecoin-logo.png'
    },
}

let proposalStatusMap = {
    '': {
        label: '',
        className: ''
    },
    'IMMEDIATELY': {
        label: '活跃',
        className: 'badge-primary'
    },
    'CLOSE': {
        label: '关闭',
        className: 'badge-danger'
    },
    'UPCOMING': {
        label: '即将开始',
        className: 'badge-warning'
    }
}

const DEFAULT_NODE_INFO = {
    id: '',
    positionValue: 'NaN',
    availableBalance: 'NaN',
    lockedRewards: 'NaN',
    pledge: 'NaN',
    adjustedPower: 'NaN',
    rawBytePower: 'NaN',
    sectorCount: 'NaN',
    beneficiary: 'NaN',
    owner: 'NaN',
    sectorSize: 'NaN',
    active: 0,
    faulty: 0,
    live: 0,
    adjustedPowerRate: 'NaN'
}

let isEmpty = (val) => {
    return val === null || val === undefined || val === '' || val.length === 0
}

export {voteSignModel, proposalStatusMap, chainsMap, DEFAULT_NODE_INFO, isEmpty}
