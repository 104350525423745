import { mapState } from "vuex";
import { isEmpty } from "@/utils/model";
import { NotificationErr, NotificationSuccess, simpleDateFormatByMin } from "@/utils/common";
import { formatWalletFilPrice } from "@/utils/NumU";
import { Picture as IconPicture, Refresh } from '@element-plus/icons-vue';
export default {
  name: "NFT",
  components: {
    IconPicture,
    Refresh
  },
  data() {
    return {
      mintLoading: false,
      stakeLoading: false,
      mintCount: 1,
      timerFlag: true
    };
  },
  created() {
    this.$store.dispatch('getNftBaseUrl');
    this.initGetNftPublicSaleInfo();
    this.init();
    this.initNftIsOpen();
  },
  watch: {
    address(val) {
      if (!isEmpty(val)) {
        this.init();
      }
    }
  },
  beforeUnmount() {
    this.timerFlag = false;
  },
  methods: {
    _initGetNftPublicSaleInfo() {
      setTimeout(() => {
        if (!this.timerFlag) {
          return;
        }
        this.$store.dispatch('getNftPublicSaleInfo').then(() => {
          this._initGetNftPublicSaleInfo();
        });
      }, 5000);
    },
    initGetNftPublicSaleInfo() {
      this.$store.dispatch('getNftPublicSaleInfo');
      this._initGetNftPublicSaleInfo();
    },
    initNftIsOpen() {
      this.$store.dispatch('initNftIsOpen');
    },
    refresh() {
      this.$store.commit('SET_MY_STAKE_NFT_LIST_TOTAL', {
        myStakeNftListTotal: 0
      });
      this.$store.dispatch('getMyNft', {
        address: this.address
      });
      this.$store.dispatch('getMyStakeNft', {
        address: this.address,
        cursor: 0,
        size: 10000
      });
    },
    formatWalletFilPrice,
    goToTop() {
      this.$nextTick(() => {
        this.$refs.topEl.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      });
    },
    init() {
      this.mintCount = 1;
      this.$store.dispatch('initTreasuryBalance');
      if (!isEmpty(this.address)) {
        this.$store.dispatch('initMintWlCheck', {
          address: this.address
        });
        this.$store.dispatch('initLeftMintNumber', {
          address: this.address
        });
        this.$store.dispatch('getMyNft', {
          address: this.address
        });
        this.$store.dispatch('getMyStakeNft', {
          address: this.address,
          cursor: 0,
          size: 10000
        });
      }
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    mint() {
      if (!this.isConnected) {
        this.connectWallet();
        return;
      }
      if (this.mints.length === 0) {
        NotificationErr("Out of stock");
        return;
      }
      this.mintLoading = true;
      this.$store.dispatch('mintNFT', {
        mintCount: this.mintCount
      }).then(() => {
        NotificationSuccess("Mint Success");
        this.$nextTick(() => {
          this.$refs.myNftList.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        });
        this.init();
      }).catch(err => {
        NotificationErr(err.message);
      }).finally(() => {
        this.mintLoading = false;
      });
    },
    mintHightWl() {
      if (!this.isConnected) {
        this.connectWallet();
        return;
      }
      if (this.highWlMintState && !this.canHighWlMintState) {
        return;
      }
      this.mintLoading = true;
      this.$store.dispatch('mintHightWlNFT').then(() => {
        NotificationSuccess("Mint Success");
        this.$nextTick(() => {
          this.$refs.myNftList.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        });
        this.init();
      }).catch(err => {
        NotificationErr(err.message);
      }).finally(() => {
        this.mintLoading = false;
      });
    },
    mintWl() {
      if (!this.isConnected) {
        this.connectWallet();
        return;
      }
      if (this.wlMintState || !this.canWlMintState) {
        return;
      }
      this.mintLoading = true;
      this.$store.dispatch('mintWlNFT').then(() => {
        NotificationSuccess("Mint Success");
        this.$nextTick(() => {
          this.$refs.myNftList.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        });
        this.init();
      }).catch(err => {
        NotificationErr(err.message);
      }).finally(() => {
        this.mintLoading = false;
      });
    },
    simpleDateFormatByMin,
    stakeNft(tokenId) {
      this.stakeLoading = true;
      this.$store.dispatch('approveNFT', {
        tokenId: tokenId
      }).then(() => {
        this.stakeLoading = true;
        setTimeout(() => {
          this.$store.dispatch('stakeNFT', {
            tokenId: tokenId
          }).then(() => {
            NotificationSuccess("Stake NFT Success");
            setTimeout(() => {
              this.init();
            }, 3000);
          }).catch(err => {
            NotificationErr(err.message);
          }).finally(() => {
            this.stakeLoading = false;
          });
        }, 2000);
      }).catch(err => {
        NotificationErr(err.message);
        this.stakeLoading = false;
      });
    },
    unStakeNft(tokenId) {
      this.stakeLoading = true;
      this.$store.dispatch('unStakeNFT', {
        tokenId: tokenId
      }).then(() => {
        NotificationSuccess("UnStake NFT Success");
        setTimeout(() => {
          this.init();
        }, 3000);
      }).catch(err => {
        NotificationErr(err.message);
      }).finally(() => {
        this.stakeLoading = false;
      });
    }
  },
  computed: mapState({
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    wlMintState: state => state.nft.wlMintState,
    canWlMintState: state => state.nft.canWlMintState,
    // 判断是否可以通过白名单mint
    isWaitWlMintState: state => state.nft.isWaitWlMintState,
    highWlMintState: state => state.nft.highWlMintState,
    canHighWlMintState: state => state.nft.canHighWlMintState,
    whiteListBtnMintLoading: state => state.nft.whiteListBtnMintLoading,
    whiteListInfo: state => state.nft.whiteListInfo,
    treasuryBalance: state => state.nft.treasuryBalance,
    treasuryBalanceLoading: state => state.nft.treasuryBalanceLoading,
    mints: state => state.nft.mints,
    mintBtnLoading: state => state.nft.mintBtnLoading,
    myNftList: state => state.nft.myNftList,
    myNftListLoading: state => state.nft.myNftListLoading,
    myStakeNftList: state => state.nft.myStakeNftList,
    nftList() {
      console.log(this.myStakeNftList.concat(this.myNftList));
      return this.myStakeNftList.concat(this.myNftList);
    },
    myStakeNftListLoading: state => state.nft.myStakeNftListLoading,
    myStakeNftListTotal: state => state.nft.myStakeNftListTotal,
    isNftIsOpen: state => state.nft.isNftIsOpen,
    over: state => state.nft.saleInfo.over,
    nftBaseUrl: state => state.nft.nftBaseUrl,
    open: state => state.nft.saleInfo.open,
    startTime: state => state.nft.saleInfo.startTime,
    endTime: state => state.nft.saleInfo.endTime,
    limit: state => state.nft.saleInfo.limit,
    index: state => state.nft.saleInfo.index,
    publicSale: state => state.nft.saleInfo.publicSale
  })
};