import {CoinType, delegatedFromEthAddress, newAddress} from '@glif/filecoin-address';

function bigNumberToBytes(value) {
    let valueBytes;
    if (!value.isZero()) {
        if (!value.isNegative()) {
            let valueBigNumberBytes = Uint8Array.from(Buffer.from(value.toHexString().replace(/0x/g, ''), 'hex'))
            valueBytes = new Uint8Array(valueBigNumberBytes.length + 1);
            valueBytes[0] = 0;
            valueBytes.set(valueBigNumberBytes, 1);
        } else {
            let valueBigNumberBytes = Uint8Array.from(Buffer.from(value.toHexString().replace(/-0x/g, ''), 'hex'))
            valueBytes = new Uint8Array(valueBigNumberBytes.length + 1);
            valueBytes[0] = 1;
            valueBytes.set(valueBigNumberBytes, 1);
        }
    } else {
        let valueBigNumberBytes = Uint8Array.from(Buffer.from(value.toHexString().replace(/0x/g, ''), 'hex'))
        valueBytes = valueBigNumberBytes;
    }
    return valueBytes;
}

function newAddressFromBytes(bytes) {
    return newAddress(Number.parseInt(bytes[0]), bytes.slice(1));
}

function addressToBytes(address) {
    return address.bytes;
}

function cidToBytes(cid) {
    return cid.bytes;
}

function getF4AddressByEth(address, coinType) {
    if (coinType === 'test'){
        return delegatedFromEthAddress(address, CoinType.TEST);
    }else {
        return delegatedFromEthAddress(address, CoinType.MAIN);
    }
}

export {
    bigNumberToBytes,
    newAddressFromBytes,
    addressToBytes,
    cidToBytes,
    getF4AddressByEth
}
