import "core-js/modules/es.array.push.js";
import { CircleCheckFilled, CirclePlus, Remove } from '@element-plus/icons-vue';
import AdCard2 from '@/components/tip/AdCard2';
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
import StorageProviderLendingPool from "@/utils/abi/StorageProviderLendingPool.json";
import { loutsRpc, walletProvider } from "@/utils/LoutsRpc";
import { getF4AddressByEth } from "@/utils/contracts/singer/filecoin_utils";
import SingerV2 from '@/components/induction/singer/SingerV2';
import { NotificationErr } from "@/utils/common";
import Tip from '@/components/tip/Tip';
import Clipboard from "clipboard";
import EvmErrTip from '@/components/header/EvmErrTip';
import { isEmpty } from "@/utils/model";
import store from "@/store";
import { readContract } from "@wagmi/core";
import { BigNumber } from "ethers";
import { toBigInt } from "@/utils/NumU";
import { StateMinerInfo, StateReadState } from "@/utils/FilecoinApi";
export default {
  name: "PoolChangeControlComponent",
  components: {
    AdCard2,
    SingerV2,
    Tip,
    EvmErrTip,
    CirclePlus,
    Remove
  },
  data() {
    return {
      radio1: 'Node Owner',
      CircleCheckFilled,
      active: 1,
      sendProposal: false,
      approvedProposalRes: false,
      inputs: {
        input1: 'f01234',
        input2: 0,
        input3: 10101
      },
      radio2: 'Content',
      singerTables: [],
      contractParams: [],
      contractMethod: 'approveOperator',
      contractABI: StorageProviderLendingPoolABI,
      changeAddress: '',
      nodeRole: 'Owner',
      singerMultiple: false,
      newControl: '',
      newControls: [''],
      newControlsIsErr: [''],
      affirmControl: false,
      showNewControl: false,
      showCurrentControl: false,
      loading: false,
      initLoading: true,
      isHaveActorId: true,
      isCopy: false,
      isNewControllErr: false,
      newControllErrTips: false,
      fromAddress: '',
      toF0Address: '',
      originalOwnerF0: '',
      originalOwner: '',
      poolF4Address: '',
      poolF0Address: '',
      worker: ''
    };
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    },
    poolAddress: {
      type: String,
      default: ''
    }
  },
  watch: {
    nodeInfo() {
      if (this.nodeInfo.controlAddresses !== null && this.nodeInfo.controlAddresses.length > 0) {
        this.showCurrentControl = true;
        this.showNewControl = false;
      } else {
        this.showCurrentControl = false;
        this.showNewControl = true;
      }
      this.initLoading = false;
    }
  },
  created() {},
  methods: {
    async init() {
      try {
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    isEmpty,
    viewNode() {
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.env === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    async initOriginalOwner(originalOwnerF0) {
      this.originalOwnerF0 = originalOwnerF0;
      let stateReadState = await loutsRpc(StateReadState(this.originalOwnerF0));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        this.singerMultiple = true;
        this.originalOwner = this.originalOwnerF0;
      } else {
        if (!stateReadStateRes.State.Address) {
          NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
          // this.singleSignatureInitErr()
          return;
        }
        this.originalOwner = stateReadStateRes.State.Address;
      }
    },
    async initNodeInfoWork() {
      let stateMinerInfo = await loutsRpc(StateMinerInfo('f0' + this.nodeId));
      let {
        Worker
      } = stateMinerInfo.result;
      this.worker = Worker;
    },
    async initActorState() {
      await this.initNodeInfoWork();
      this.poolF4Address = getF4AddressByEth(this.poolAddress, store.state.evn);
      this.poolF0Address = await walletProvider.lookupId(this.poolF4Address);
      this.contractMethod = 'changeWorkerAddress';
      let _newControls = this.newControl.split(',');
      _newControls = _newControls.map(i => parseInt(i.substring(2)));
      this.contractParams.push(this.nodeId, parseInt(this.worker.substring(2)), _newControls);
      this.contractABI = StorageProviderLendingPool;
      let _originalOwner = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'nodeOwner',
        args: [this.nodeId]
      });
      let _originalOwnerF0 = this.getNetworkPrefix() + '0' + (BigNumber.from(_originalOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      await this.initOriginalOwner(_originalOwnerF0);
      this.fromAddress = this.originalOwner;
      this.toF0Address = this.poolF0Address;
      console.log('this.toF0Address', this.fromAddress, this.toF0Address);
      this.$nextTick(async () => {
        this.$refs['singer'].init();
      });
    },
    pushProposalNext() {
      this.active++;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    setCurrentWallet() {
      this.newControl = this.address;
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.currentNode.operator;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    sendProposalFun() {
      this.sendProposal = true;
      this.active++;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    approvedProposal() {
      this.active++;
    },
    approvedProposal2() {
      this.approvedProposalRes = true;
    },
    addControllId() {
      if (this.affirmControl) {
        return;
      }
      this.newControls.push('');
      this.newControlsIsErr.push('');
      this.clearErr();
    },
    delControllId(index) {
      if (this.affirmControl) {
        return;
      }
      this.newControls.splice(index, 1);
      this.clearErr();
    },
    clearErr(index) {
      this.newControlsIsErr[index] = '';
      this.isNewControllErr = false;
      this.newControllErrTips = '';
    },
    reapprove() {
      this.showNewControl = true;
    },
    async checkControlAddress() {
      return false;
    },
    async checkNewControlInput() {
      let len = this.newControls.length;
      for (let i = 0; i < len; i++) {
        let inputVal = this.newControls[i].trim();
        if (inputVal === '') {
          this.newControlsIsErr[i] = this.$t('change.Please enter Worker ID');
          this.isNewControllErr = true;
          this.newControllErrTips = this.newControlsIsErr[i];
          return false;
        }
        try {
          await walletProvider.getActor(inputVal);
        } catch (e) {
          this.newControlsIsErr[i] = this.$t('change.controlInputErr');
          this.isNewControllErr = true;
          this.newControllErrTips = this.newControlsIsErr[i];
          return false;
        }
      }
      this.newControllErrTips = '';
      this.isNewControllErr = false;
      return true;
    },
    reset() {
      this.affirmControl = false;
    },
    async affirm() {
      let flag = await this.checkNewControlInput();
      if (!flag) {
        return;
      }
      this.newControl = this.newControls.join(",");
      this.loading = true;
      await this.init();
      this.affirmControl = true;
      this.showCurrentControl = false;
      this.loading = false;
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};