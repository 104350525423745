import {createRouter, createWebHashHistory} from 'vue-router'
import Stake from '../views/Stake.vue'
import Farm from "@/views/farm/Index";
import Console from "@/views/farm/Console";
import Vote from "@/views/Vote";
import ProposalDetails from "@/views/ProposalDetails";
import Dashboard from "@/views/Dashboard";
import Induction from '@/views/Induction';
import Offboarding from '@/views/Offboarding'
import Operator from '@/views/Operator'
import Home from '@/views/Home'
import ReferralsPage from '@/views/referrals/ReferralsPage'
import Nft from '@/views/Nft'
import store from '@/store/index'
import ChangeWorker from '@/views/ChangeWorker'
import ChangeControl from "@/views/ChangeControl";
import CollateralNode from '@/views/CollateralNode'
import PoolChangeWorker from "@/views/subpool/PoolChangeWorker";
import PoolChangeControl from "@/views/subpool/PoolChangeControl";
import UnCollateralNode from '@/views/UnCollateralNode'
import CreatePool from "@/views/subpool/CreatePool";
import PoolInfo from "@/views/subpool/PoolInfo";
import PoolConsole from "@/views/subpool/SupPoolConsole";
import SubPoolInduction from '@/views/subpool/SubPoolInduction'
import SubPoolOffboarding from '@/views/subpool/SubPoolOffboarding'
import BeneficiaryToOwnerUpgradePage from "@/views/farm/BeneficiaryToOwnerUpgradePage";
import MigratorPage from "@/views/subpool/MigratorPage";
import PoolTransferOwnershipPage from '@/views/subpool/PoolTransferOwnershipPage.vue'
import PoolChangeOwner from '@/views/subpool/PoolChangeOwner.vue'

const routes = [
  {
    path: '/analytics',
    name: 'analytics',
    component: Dashboard
  }, {
    path: '/',
    name: 'stake',
    component: Stake
  },{
    path: '/ref/:ref',
    name: 'stake-ref',
    component: Stake
  },{
    path: '/stake',
    name: 'stake-old',
    component: Stake
  },{
    path: '/farm',
    name: 'farm',
    component: Farm
  },{
    path: '/farm/provider/:provider',
    name: 'provider',
    component: Console
  },{
    path: '/vote',
    name: 'vote',
    component: Vote
  },{
    path: '/vote/proposal/:id',
    name: 'proposal',
    component: ProposalDetails
  },{
    path: '/farm/induction',
    name: 'induction',
    component: Induction
  },{
    path: '/farm/offboarding/:provider',
    name: 'offboarding',
    component: Offboarding,
  },{
    path: '/farm/operator/:provider',
    name: 'operator',
    component: Operator,
  },{
    path: '/referrals',
    name: 'Referrals',
    component: ReferralsPage
  },{
    path: '/nft',
    name: 'Nft',
    component: Nft,
  },{
    path: '/home',
    name: 'Home',
    component: Home,
  },{
    path: '/farm/changeWorker/:provider',
    name: 'ChangeWorker',
    component: ChangeWorker,
  },{
    path: '/farm/changeControl/:provider',
    name: 'ChangeControl',
    component: ChangeControl,
  },
  {
    path: '/farm/collateralNode/:provider',
    name: 'CollateralNode',
    component: CollateralNode
  },
  {
    path: '/farm/unCollateralNode/:provider',
    name: 'UnCollateralNode',
    component: UnCollateralNode
  },
  {
    path: '/farm/createPool',
    name: 'CreatePool',
    component: CreatePool
  },
  {
    path: '/farm/lendingPool/:poolAddress',
    name: 'PoolInfo',
    component: PoolInfo
  },
  {
    path: '/farm/lendingPool/provider/:poolAddress/:provider',
    name: 'PoolConsole',
    component: PoolConsole
  },
  {
    path: '/farm/lendingPool/induction/:poolAddress',
    name: 'SubPoolInduction',
    component: SubPoolInduction
  },
  {
    path: '/farm/lendingPool/offboarding/:poolAddress/:provider',
    name: 'SubPoolOffboarding',
    component: SubPoolOffboarding,
  },
  {
    path: '/farm/beneficiaryToOwnerUpgrade/:provider',
    name: 'BeneficiaryToOwnerUpgrade',
    component: BeneficiaryToOwnerUpgradePage,
  },
  {
    path: '/farm/migrator/:provider',
    name: 'MigratorPage',
    component: MigratorPage,
  },
  {
    path: '/farm/lendingPool/changeWorker/:poolAddress/:provider',
    name: 'PoolChangeWorker',
    component: PoolChangeWorker,
  },{
    path: '/farm/lendingPool/changeControl/:poolAddress/:provider',
    name: 'PoolChangeControl',
    component: PoolChangeControl,
  },{
    path: '/farm/lendingPool/transferOwner/:poolAddress',
    name: 'PoolTransferOwnershipPage',
    component: PoolTransferOwnershipPage,
  },{
    path: '/farm/lendingPool/changeOwner/:poolAddress/:provider',
    name: 'PoolChangeOwner',
    component: PoolChangeOwner,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
})

router.beforeEach((to, from) => {
  store.commit('SET_PRE_ROUTER_PATH', {path: from})
  return true
})


export default router
