import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "fs-12 mb-2",
  style: {
    "color": "#e00c2c",
    "padding": "2px 20px",
    "border-radius": "20px",
    "background-color": "rgba(224, 12, 44, 0.1)",
    "display": "inline-block"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [$props.newValue > $props.oldValue ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "fs-12 mb-2",
    style: _normalizeStyle([`color: ${$props.compare ? '#00CE92' : '#e00c2c'};background-color: ${$props.compare ? 'rgba(95,224,180,0.2)' : 'rgba(224, 12, 44, 0.1)'}`, {
      "padding": "2px 20px",
      "border-radius": "20px",
      "display": "inline-block"
    }])
  }, _toDisplayString($props.isEth ? $options.externalFormatFilPrice($props.newValue, $props.units) : parseFloat($options.formatUnits($props.newValue, $props.units)).toFixed($props.toFixed) + $props.suffix) + " after ", 5)) : _createCommentVNode("", true), $props.newValue < $props.oldValue ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.isEth ? $options.externalFormatFilPrice($props.newValue, $props.units) : parseFloat($options.formatUnits($props.newValue, $props.units)).toFixed($props.toFixed) + $props.suffix) + " after ", 1)) : _createCommentVNode("", true)]);
}