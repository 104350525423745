import "core-js/modules/es.array.push.js";
import PageHead from '@/components/header/PageHead';
import { Search } from '@element-plus/icons-vue';
import SubPoolNodeInfo2 from '@/components/subpool/induction/SubPoolNodeInfo2';
import SubPoolNodeInfo3 from '@/components/subpool/induction/SubPoolNodeInfo3';
export default {
  name: "SubPoolInductionPage",
  components: {
    PageHead,
    SubPoolNodeInfo2,
    SubPoolNodeInfo3
  },
  data() {
    return {
      input1: '',
      search: Search,
      currentStep: 1,
      haveSp: false,
      isStakingPool: false,
      showNextBtnGroup: true,
      poolAddress: '',
      initCurrentPoolInfoLoading: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.initCurrentPoolInfoLoading = true;
      this.poolAddress = this.$route.params.poolAddress;
      await this.$store.dispatch('initCurrentPoolInfo', {
        poolAddress: this.poolAddress
      });
      this.initCurrentPoolInfoLoading = false;
    },
    back() {
      this.$refs.searchNode.isLock = false;
      this.resetNodeRes();
    },
    searchNode({
      haveSp,
      isStakingPool
    }) {
      this.haveSp = haveSp;
      this.isStakingPool = isStakingPool;
      this.$refs.nodeInfo.searchOk = true;
      if (!this.haveSp) {
        this.$refs.nodeInfo.showNext();
      } else {
        this.$refs.nodeInfo.hiddenNext();
      }
    },
    resetNodeRes() {
      this.currentStep = 0;
      this.haveSp = false;
      this.$refs.nodeInfo.searchOk = false;
      this.$refs.nodeInfo.hiddenNext();
      this.$store.commit('RESET_NODE_INFO');
    },
    viewNode() {
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    nextFun() {
      // this.$refs.searchNode.isLock = true
      this.currentStep++;
    },
    preFun() {
      this.currentStep--;
      if (this.currentStep === 0) {
        this.$refs.searchNode.isLock = false;
      }
    }
  },
  computed: {
    nodeId() {
      return this.$store.state.node.nodeInfo.id;
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  }
};