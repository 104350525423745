import "core-js/modules/es.array.push.js";
import Clipboard from "clipboard";
import { CircleCheckFilled, CopyDocument } from '@element-plus/icons-vue';
import { readContract } from "@wagmi/core";
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
import Tip from '@/components/tip/Tip';
import { isAddress } from "ethers/lib/utils";
import SingerV2 from '@/components/induction/singer/SingerV2';
import { getF4AddressByEth } from "@/utils/contracts/singer/filecoin_utils";
import store from "@/store";
import { walletProvider } from "@/utils/LoutsRpc";
export default {
  name: "PoolTransferOwnership",
  components: {
    CopyDocument,
    Tip,
    SingerV2
  },
  data() {
    return {
      CircleCheckFilled,
      active: 1,
      isCopy: false,
      poolAddressErr: false,
      poolAdmin: '',
      loading: {
        initPoolAdminLoading: false
      },
      newPoolAdmin: '',
      startSign: false,
      newPoolAddressErr: false,
      resultOk: false,
      sign: {
        contractMethod: '',
        contractParams: '',
        fromAddress: '',
        toEthAddress: '',
        toF0Address: '',
        ABI: StorageProviderLendingPoolABI
      },
      poolF4Address: '',
      poolF0Address: ''
    };
  },
  props: {
    poolAddress: {
      type: String,
      default: ''
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.initPoolAdmin();
    },
    async initPoolAddress() {
      this.poolF4Address = getF4AddressByEth(this.poolAddress, store.state.evn);
      this.poolF0Address = await walletProvider.lookupId(this.poolF4Address);
    },
    initSign() {
      this.sign.fromAddress = this.address;
      this.sign.toEthAddress = this.poolAddress;
      this.sign.toF0Address = this.poolF0Address;
      this.sign.contractMethod = 'transferOwnership';
      this.sign.contractParams = [this.newPoolAdmin.trim()];
      this.$nextTick(() => {
        this.$refs['singer'].init();
      });
    },
    viewNode() {
      this.$router.push({
        path: `/farm/lendingPool/${this.poolAddress}`
      });
    },
    affirm() {
      let flag = isAddress(this.newPoolAdmin);
      if (!flag) {
        this.newPoolAddressErr = true;
        return;
      }
      this.newPoolAddressErr = false;
      this.startSign = true;
      this.initSign();
    },
    signOk() {
      this.resultOk = true;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    copy(value) {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return value;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    async initPoolAdmin() {
      this.loading.initPoolAdminLoading = true;
      this.poolAdmin = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'owner',
        args: []
      }).catch(() => {
        this.poolAddressErr = true;
      }).finally(() => {
        this.loading.initPoolAdminLoading = false;
      });
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};