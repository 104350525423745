import "core-js/modules/es.array.push.js";
import ChartBlock from '@/components/ChartBlock.vue';
import { mapState } from "vuex";
import { dateFormat, simpleDateByDayFormat } from "@/utils/common";
import { formatPrice } from "@/utils/NumU";
import { formatUnits } from "ethers/lib/utils";
export default {
  name: 'StakeLeftChart',
  components: {
    ChartBlock
  },
  data() {
    return {
      optionLiquidityRate: {
        title: {
          show: false
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          data: ['APY']
        },
        xAxis: {
          data: ['0', '0', '0', '0', '0', '0'],
          axisLabel: {
            formatter: function (value) {
              return simpleDateByDayFormat(value);
            }
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          left: '35px',
          right: '50px'
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            formatter: '{value}%'
          },
          axisLine: {
            show: true
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            if (params[0].dataIndex === this.optionLiquidityRate.xAxis.data.length - 1) {
              return `${dateFormat(params[0].name, true)}<br/>${params[0].seriesName}  ${params[0].value}%`;
            } else {
              return `${dateFormat(params[0].name)}<br/><div style="display: inline-block;width: 8px; height: 8px;border-radius: 8px;background-color: #e38e8e;margin-right: 5px;margin-bottom: 2px;"></div>${params[0].seriesName}  ${params[0].value}%`;
            }
          }
        },
        series: [{
          name: 'APY',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e38e8e'
          },
          markLine: {
            lineStyle: {
              color: '#e38e8e',
              width: 1
            },
            symbol: ['none', 'none'],
            label: {
              show: true,
              position: 'end',
              distance: 7,
              formatter: params => {
                return `${this.$t('common.AVG')}\n${params.data.coord[1]}%`;
              }
            },
            data: [{
              yAxis: 0
            }]
          }
        }]
      }
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$i18n.locale'() {
      this.initLiquidityRateChart();
    },
    liquidityRate() {
      if (this.optionLiquidityRate.xAxis.data.length > 0) {
        this.optionLiquidityRate.xAxis.data.push(new Date().getTime());
        this.optionLiquidityRate.series[0].data.push(parseFloat(parseFloat(formatUnits(this.liquidityRate, 25)).toFixed(2)));
      }
    },
    chartLiquidityRate(val) {
      this.optionLiquidityRate.xAxis.data = val.time;
      this.optionLiquidityRate.series[0].data = val.data;
      this.optionLiquidityRate.series[0].markLine.data[0].yAxis = this.liquidityRateAvg[this.$store.state.chartData.params.liquidityRate.resolutionInHours];
    }
  },
  methods: {
    init() {
      this.initLiquidityRateChart();
      this.$store.commit('ADD_TIMING_FUN', {
        fun: this.initLiquidityRateChart,
        pageNames: ['stake', 'stake-ref', 'stake-old']
      });
    },
    gotNetAPYTip() {
      window.open(`${this.docsUrlPrefix}tokenomics/stfil`);
    },
    formatPrice,
    formatUnits,
    chartLquidityRateSelect({
      value
    }) {
      this.$store.commit('SET_LIQUIDITY_RATE_RESOLUTIONINHOURS', {
        resolutionInHours: value
      });
      this.initLiquidityRateChart(true);
    },
    async initLiquidityRateChart(operation = false) {
      let _timingFlag = this.timingFlag;
      if (!_timingFlag || operation) {
        this.$refs.liquidityRate?.loading();
      }
      await this.$store.dispatch('liquidityRateChart');
      if (!_timingFlag || operation) {
        setTimeout(() => {
          if (this.$refs.liquidityRate) {
            this.$refs.liquidityRate.unLoading();
          }
        }, 500);
      }
    },
    borrow() {
      this.$router.push({
        path: '/console'
      });
    },
    showComputer() {
      this.$store.state.refs['iHeader'].showApyComputerShowDialog();
    }
  },
  computed: mapState({
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    },
    liquidityRate: state => state.publicInfo.liquidityRate,
    publicInfoLoading: state => state.publicInfo.loading,
    chartLiquidityRate: state => state.chartData.chartLiquidityRate,
    liquidityRateAvg: state => state.chartData.liquidityRateAvg
  })
};