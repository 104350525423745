import store from '@/store/index'
import {ethers} from 'ethers'
import {ElNotification} from "element-plus";
import {isAddress} from "ethers/lib/utils";
import {isEmpty} from "@/utils/model";
import {E23, WadRayMath} from "@/utils/contracts/WadRayMath";
import {getF4AddressByEth} from "@/utils/contracts/singer/filecoin_utils";
import {walletProvider} from "@/utils/LoutsRpc";
import nodejsHttp from "@/utils/http/nodejsHttp";

let getPrefix = () => {
    return store.state.evn === 'test' ? 't0' : 'f0'
}

let getActorAddress = (actorId) => {
    return ethers.utils.solidityPack(
        ["uint32", "uint64", "uint64"],
        [4278190080, 0, actorId]
    )
}

// 获取 合约地址列表，f0 f4
let getAddressInfoByEth = async (poolAddress) => {
    let f4address = getF4AddressByEth(poolAddress, store.state.evn)
    let f0address = await walletProvider.lookupId(f4address)
    return {
        eth: poolAddress,
        f0address,
        f4address
    }
}

let mergeArrAdd = (arrA = [], arrB = []) => {
    let res = []
    for (let i = 0; i < arrA.length; i++) {
        let a = parseFloat(arrA[i])
        let b = parseFloat(arrB[i])
        res.push(a + b)
    }
    return res
}

let simpleDateByDayFormat = function (time) {
    let date = new Date(parseInt(time))
    let month = date.getMonth() + 1
    let day = date.getDate()
    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    return `${month}/${day}`
}

let simpleDateFormat = function (time) {
    let date = new Date(parseInt(time))
    let month = date.getMonth() + 1
    let day = date.getDate()

    let hour = date.getHours()

    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    hour = hour >= 10 ? hour : '0' + hour
    return `${month}/${day} ${hour}:00`
}

let simpleDateFormatByMin = function (time) {
    let date = new Date(parseInt(time))
    let month = date.getMonth() + 1
    let day = date.getDate()

    let hour = date.getHours()
    let minutes = date.getMinutes()

    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    hour = hour >= 10 ? hour : '0' + hour
    minutes = minutes >= 10 ? minutes : '0' + minutes
    return `${month}/${day} ${hour}:${minutes}`
}

let dateFormat = function (time, showMin = false) {
    let date = new Date(parseInt(time))
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let minu = date.getMinutes()

    let hour = date.getHours()

    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    hour = hour >= 10 ? hour : '0' + hour
    minu = minu >= 10 ? minu : '0' + minu

    if (showMin) {
        return `${year}/${month}/${day} ${hour}:${minu} UTC+${date.getTimezoneOffset() * -1 / 60}:00`
    } else {
        return `${year}/${month}/${day} ${hour}:00 UTC+${date.getTimezoneOffset() * -1 / 60}:00`
    }
}

let dateFormatAll = function (time) {
    let date = new Date(parseInt(time))
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    let hour = date.getHours()
    let minu = date.getMinutes()
    let sec = date.getSeconds()

    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    hour = hour >= 10 ? hour : '0' + hour
    minu = minu >= 10 ? minu : '0' + minu
    sec = sec >= 10 ? sec : '0' + sec

    return `${year}-${month}-${day} ${hour}:${minu}:${sec} UTC+${date.getTimezoneOffset() * -1 / 60}:00`
}

let NotificationErr = (msg, action = '') => {
    if (isEmpty(msg)) {
        return
    }
    let title = 'Error'
    if (store.state.filPrice === '0.00') {
        title = 'Error!'
    }
    ElNotification.error({
        title: title,
        showClose: true,
        message: msg,
    })
    _send_err_msg(title, msg, action)
}

let _send_err_msg = async (title, msg, action = '') => {
    let address = store.state.address
    if (isEmpty(address)) {
        address = ''
    }
    let browserInfo = "用户代理: " + navigator.userAgent + "; " +
        "浏览器名称: " + navigator.appName + "; " +
        "浏览器版本: " + navigator.appVersion + "; " +
        "操作系统平台: " + navigator.platform + "; " +
        "首选语言: " + navigator.language + "; " +
        "在线状态: " + (navigator.onLine ? "在线" : "离线") + ";";

    await nodejsHttp.post('collection_error', {data: {address, title, errMsg: msg, action, browserInfo}})
}

let NotificationWarning = (msg) => {
    ElNotification.warning({
        title: 'Warning',
        showClose: true,
        message: msg,
    })
}


let NotificationSuccess = (msg) => {
    ElNotification.success({
        title: 'Successful',
        showClose: true,
        message: msg,
    })
}

let simpleValue = (val, start, end) => {
    if (!val) {
        return val
    }
    let len = val.length
    if (start + end >= len) {
        return val
    }
    return val.substr(0, start) + '...' + val.substr(len - end)
}

let setLocal = (key, val) => {
    localStorage.setItem(key, JSON.stringify(val))
}

let delLocal = (key) => {
    localStorage.removeItem(key)
}

let getLocal = (key) => {
    let data = localStorage.getItem(key)
    if (!data || data === 'undefined') {
        return undefined
    }
    return JSON.parse(data)
}

const _blockInfo = {
    test: {
        anchorTime: 1685696970000,
        anchorBlockNumber: 612353
    },
    main: {
        anchorTime: 1679991240000,
        anchorBlockNumber: 2722828
    }
}


let blockU = {

    getByBlockNumberToTime: (block) => {
        block = parseInt(block)
        let diffBlock = block - _blockInfo[store.state.evn].anchorBlockNumber
        return _blockInfo[store.state.evn].anchorTime + (diffBlock * 30000)
    },

    formatSeconds: (seconds, locale = 'zh') => {

        let yrsL = locale === 'zh' ? '年' : 'yrs'
        let mosL = locale === 'zh' ? '个月' : 'mos'
        let daysL = locale === 'zh' ? '天' : 'days'
        let hrL = locale === 'zh' ? '小时' : 'hr'
        let minsL = locale === 'zh' ? '分钟' : 'mins'
        let secsL = locale === 'zh' ? '秒' : 'secs'
        let agoL = locale === 'zh' ? '前' : 'ago'

        let year = Math.floor(seconds / (24 * 3600 * 365));
        let month = Math.floor(seconds / (24 * 3600 * 30));
        if (year > 0) {
            return `${year} ${yrsL} ${month - (12 * year)} ${mosL} ${agoL}`
        }
        let days = Math.floor(seconds / (24 * 3600));
        if (month > 0) {
            return `${month} ${mosL} ${days - (month * 30)} ${daysL} ${agoL}`
        }
        let hours = Math.floor(seconds / 3600) % 24;
        if (days > 0) {
            return `${days} ${daysL} ${hours} ${hrL} ${agoL}`
        }
        let minutes = Math.floor((seconds % 3600) / 60);
        if (hours > 0) {
            return `${hours} ${hrL} ${minutes} ${minsL} ${agoL}`
        }
        if (seconds <= 60) {
            return `${seconds} ${secsL} ${agoL}`
        }
        let remainingSeconds = seconds % 60;
        return `${minutes} ${minsL} ${remainingSeconds} ${secsL} ${agoL}`
    },
    formatSecondsFull: (seconds) => {
        let days = Math.floor(seconds / (24 * 3600));
        let hours = Math.floor(seconds / 3600) % 24;
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainingSeconds = seconds % 60;
        days = days > 0 ? (days > 9 ? days : '0' + days) : '00'
        hours = hours > 0 ? (hours > 9 ? hours : '0' + hours) : '00'
        minutes = minutes > 0 ? (minutes > 9 ? minutes : '0' + minutes) : '00'
        remainingSeconds = remainingSeconds > 0 ? (remainingSeconds > 9 ? remainingSeconds : '0' + remainingSeconds) : '00'
        return days + 'd:' + hours + "h:" + minutes + "m:" + remainingSeconds + 's';
    }
}

let gotoHash = (hash, locale = 'zh') => {
    if (store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${locale}/message/${hash}`)
    } else {
        window.open(`https://filfox.info/${locale}/message/${hash}`)
    }
}

let gotoAddress = (address, locale = 'zh') => {
    if (isEmpty(address)) {
        return
    }
    if (store.state.evn === 'test') {
        window.open(`https://calibration.filfox.info/${locale}/address/${address}`)
    } else {
        window.open(`https://filfox.info/${locale}/address/${address}`)
    }
}

let isMobile = () => {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}

let isValidEthereumAddress = (address) => {
    return isAddress(address)
}

let calcStableRateFormula = (utilizationRate, optimalBorrowRate, maxBorrowRate) => {
    const utilizationMulti = WadRayMath.rayMultiPow(utilizationRate, 3)
    const vacancyMulti = WadRayMath.rayMultiPow(WadRayMath.RAY - utilizationRate, 3)
    let res = WadRayMath.rayMul(vacancyMulti[3], optimalBorrowRate) +
        WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.THREE_RAY, vacancyMulti[2]), utilizationMulti[1]), optimalBorrowRate) +
        WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.THREE_RAY, vacancyMulti[1]), utilizationMulti[2]), optimalBorrowRate) +
        WadRayMath.rayMul(utilizationMulti[3], maxBorrowRate);
    return res / E23
}

function truncateNumber(num, decimalPlaces) {
    const numStr = num.toString();
    const index = numStr.indexOf('.');
    if (index === -1) return num;
    return parseFloat(numStr.substring(0, index + decimalPlaces + 1));
}

export {
    getPrefix,
    getActorAddress,
    NotificationErr,
    NotificationSuccess,
    NotificationWarning,
    simpleValue,
    setLocal,
    getLocal,
    delLocal,
    blockU,
    dateFormat,
    dateFormatAll,
    gotoAddress,
    gotoHash,
    simpleDateFormat,
    isMobile,
    isValidEthereumAddress,
    simpleDateFormatByMin,
    calcStableRateFormula,
    simpleDateByDayFormat,
    mergeArrAdd,
    getAddressInfoByEth,
    truncateNumber,
    _send_err_msg
}
