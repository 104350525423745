import {createStore} from 'vuex'
import {fetchBalance, getAccount, readContract} from "@wagmi/core";
import proposal from './vote/proposal'
import vote from './vote/vote'
import dashboard from './dashboard/dashboard'
import node from './node/node'
import publicGlobalInfo from './data/publicGlobalInfo'
import nodeData from './data/nodeData'
import {filPrice} from "@/utils/LoutsRpc";
import {contracts} from "@/utils/contracts/config";
import stakeInfo from './stake/stakeInfo'
import chartData from "./data/chartData";
import referrals from "@/store/referrals/referrals";
import nft from "@/store/nft/nft"
import {isEmpty} from "@/utils/model";
import FNS from "@filfox/fnsjs";
import {delLocal, getLocal, setLocal} from "@/utils/common";
import nodejsHttp from "@/utils/http/nodejsHttp";
import ledger from "@/store/ledger";
import metamaskFlask from "@/store/metamaskFlask";
import {getF4AddressByEth} from "@/utils/contracts/singer/filecoin_utils";
import subpool from "@/store/subpool/subpool";
import StorageProviderLendingPoolFactory from "@/utils/abi/StorageProviderLendingPoolFactory.json";

const fns = new FNS(process.env.VUE_APP_CURRENTMODE === 'test' ? 'calibration' : 'mainnet')

export default createStore({
    state: {
        lotusApi: process.env.VUE_APP_CURRENTMODE === 'test' ? 'https://api.calibration.node.glif.io/' : 'https://api.node.glif.io/',
        preRouterPath: '',
        domain: '/api/',
        evn: process.env.VUE_APP_CURRENTMODE,
        address: '',
        f4Address: '',
        f1Address: '',
        f0Address: '',
        fnsName: '',
        isConnected: undefined,
        isConnecting: undefined,
        balance: 0n,
        stFilBalance: 0n,
        wstFilBalance: 0n,
        stFilInterest: 0n,
        stFilInterestLoading: false,
        web3modal: undefined,
        ethereumClient: undefined,
        filPrice: '0.00',
        refs: {},
        showTopVisible: true,
        balanceLoading: false,
        stFilBalanceLoading: false,
        wstFilBalanceLoading: false,
        screenHeight: '0px',
        ledgerProvider: undefined,
        ledgerSubProvider: undefined,
        timingIndex: 0, // 用来标记启动定时任务的个数。如果超过1.则不继续执行定时器，防止开发时多个定时器
        timingFun: [],
        timingFlag: false,
        assetsLoading: false, // 资源加载
        SPLP_IS_PAUSED: false, // 池子暂停
        screenWidth: 0
    },
    getters: {},
    mutations: {
        SET_SCREEN_WIDTH(state, {screenWidth}) {
            state.screenWidth = screenWidth
        },
        SET_SPLP_IS_PAUSED(state, {SPLP_IS_PAUSED}) {
            state.SPLP_IS_PAUSED = SPLP_IS_PAUSED
        },
        SET_ASSETS_LOADING(state, {assetsLoading}) {
            state.assetsLoading = assetsLoading
        },
        SET_TIMING_INDEX(state, {timingIndex}) {
            state.timingIndex = timingIndex
        },
        SET_TIMING_FLAG(state, {timingFlag}) {
            state.timingFlag = timingFlag
        },
        ADD_TIMING_FUN(state, {fun,pageNames}){
            state.timingFun.push({fun,pageNames})
        },
        CLEAR_TIMING_FUN(state) {
            state.timingFun = []
        },
        SET_STFIL_INTEREST_LOADING(state, {stFilInterestLoading}){
            state.stFilInterestLoading = stFilInterestLoading
        },
        SET_STFIL_INTEREST(state, {interest}){
            state.stFilInterest = interest
        },
        SET_LEDGER_F1_ADDRESS(state, {f1Address}){
            state.f1Address = f1Address
        },
        SET_METAMASK_FLASK_F1_ADDRESS(state, {f1Address}){
            state.f1Address = f1Address
        },
        SET_LEDGER_SUB_PROVIDER(state,ledgerSubProvider){
            state.ledgerSubProvider = ledgerSubProvider
        },
        SET_LEDGER_PROVIDER(state,ledgerProvider){
            state.ledgerProvider = ledgerProvider
        },
        SET_SCREEN_HEIGHT(state, {screenHeight}) {
            state.screenHeight = screenHeight
        },
        SET_BALANCE_LOADING(state, {balanceLoading}) {
            state.balanceLoading = balanceLoading
        },
        SET_STFIL_BALANCE_LOADING(state, {stFilBalanceLoading}) {
            state.stFilBalanceLoading = stFilBalanceLoading
        },
        SET_WSTFIL_BALANCE_LOADING(state, {wstFilBalanceLoading}) {
            state.wstFilBalanceLoading = wstFilBalanceLoading
        },
        SET_FNS_NAME(state, {name}) {
            if (!isEmpty(name)) {
                state.fnsName = name.toString().trim()
            }
        },
        INIT_FNS_NAME(state) {
            state.fnsName = ''
        },
        SET_SHOW_TOP_VISIBLE(state, {showTopVisible}) {
            if (!showTopVisible) {
                setLocal('closeStakeTopVisible', new Date().getTime())
            }
            state.showTopVisible = showTopVisible
        },
        INIT_SHOW_TOP_VISIBLE(state) {
            let closeTime = getLocal("closeStakeTopVisible")
            if (!closeTime) {
                state.showTopVisible = true
            } else {
                let nowTime = new Date().getTime()
                let diff = 1000 * 60 * 60 * 24
                if ((nowTime - closeTime) >= diff) {
                    delLocal('closeStakeTopVisible')
                    state.showTopVisible = true
                } else {
                    state.showTopVisible = false
                }
            }
        },
        SET_PRE_ROUTER_PATH(state, {path}) {
            state.preRouterPath = path
        },
        SET_REFS(state, {name, ref}) {
            this.state.refs[name] = ref
        },
        SET_FILPRICE(state, filPrice) {
            state.filPrice = filPrice
        },
        SET_WEB3MODAL(state, web3modal) {
            state.web3modal = web3modal
        },
        SET_ETHEREUMCLIENT(state, ethereumClient) {
            state.ethereumClient = ethereumClient
        },
        SET_ADDRESS(state, address) {
            state.address = address
        },
        SET_F4_ADDRESS(state, {f4Address}){
            state.f4Address = f4Address
        },
        SET_F0_ADDRESS(state, {f0Address}){
            state.f0Address = f0Address
        },
        SET_ISCONNECTED(state, isConnected) {
            state.isConnected = isConnected
        },
        SET_ISCONNECTING(state, isConnecting) {
            state.isConnecting = isConnecting
        },
        SET_BALANCE(state, balance) {
            state.balance = balance
        },
        SET_STFIL_BALANCE(state, stFilBalance) {
            state.stFilBalance = stFilBalance
        },
        SET_WSTFIL_BALANCE(state, wstFilBalance) {
            state.wstFilBalance = wstFilBalance
        }
    },
    actions: {
        async init_set_splp_is_paused({commit, rootState}){
            const paused = await readContract({
                address: contracts[rootState.evn].StorageProviderLendingPoolFactory.eth,
                abi: StorageProviderLendingPoolFactory.abi,
                functionName: 'paused',
                args: [],
            })
            commit('SET_SPLP_IS_PAUSED', {SPLP_IS_PAUSED: paused})
        },
        // eslint-disable-next-line no-empty-pattern
        async getFNSName({}, {address}) {
            let res = await fns.getPrimaryName(address)
            if (isEmpty(res)) {
                let names = await fns.getOwnershipNames(address)
                if (names.length > 0) {
                    res = names[0]
                }
            }
            return res
        },
        async initFnsName({commit, dispatch}, {address}) {
            let name = await dispatch('getFNSName', {address})
            commit('SET_FNS_NAME', {name})
        },
        initReferralCode() {

        },
        initFilePrice({commit}) {
            filPrice().then(res => {
                if (res) {
                    commit('SET_FILPRICE', res)
                }
            })
        },
        async initStFilBalance({commit, state, dispatch}, {address}) {
            if (!address) {
                return
            }
            commit('SET_STFIL_BALANCE_LOADING', {stFilBalanceLoading: true})
            const stFilBalance = await fetchBalance({
                address: address,
                token: contracts[state.evn].STFILToken.eth
            })
            commit('SET_STFIL_BALANCE_LOADING', {stFilBalanceLoading: false})
            commit('SET_STFIL_BALANCE', stFilBalance.value.toBigInt())
            dispatch('updateUserInterest', {address})
        },
        async initWstFilBalance({commit, state}, {address}) {
            if (!address) {
                return
            }
            commit('SET_WSTFIL_BALANCE_LOADING', {wstFilBalanceLoading: true})
            const wstFilBalance = await fetchBalance({
                address: address,
                token: contracts[state.evn].WSTFILToken.eth
            })
            commit('SET_WSTFIL_BALANCE_LOADING', {wstFilBalanceLoading: false})
            commit('SET_WSTFIL_BALANCE', wstFilBalance.value.toBigInt())
        },
        async initAccount({commit, dispatch, state}) {
            let connectType = getLocal('connectType')
            if (connectType === 'Ledger') {
                dispatch('initByLedgerAccount')
                return
            }
            if (connectType === 'MetaMaskFlask') {
                dispatch('initByMetamaskFlaskAccount')
                return
            }
            commit('INIT_FNS_NAME')
            const account = getAccount()
            console.log('account', account)
            commit('SET_ISCONNECTED', account.isConnected)
            commit('SET_ISCONNECTING', account.isConnecting)
            commit('SET_ADDRESS', account.address)
            if (account.address) {
                let f4Address = getF4AddressByEth(account.address, state.evn)
                commit('SET_F4_ADDRESS', {f4Address})
                commit('SET_BALANCE_LOADING', {balanceLoading: true})
                const balance = await fetchBalance({
                    address: account.address,
                })
                commit('SET_BALANCE_LOADING', {balanceLoading: false})
                commit('SET_BALANCE', balance.value.toBigInt())

                dispatch('initWstFilBalance', {address: account.address})
                dispatch('initStFilBalance', {address: account.address})
                dispatch('initFnsName', {address: account.address})
            } else {
                commit('SET_BALANCE', 0)
                commit('SET_STFIL_BALANCE', 0n)
                commit('SET_WSTFIL_BALANCE', 0n)
            }

        },
        // eslint-disable-next-line no-empty-pattern
        async subscribeEmail({}, {email}) {
            try {
                await nodejsHttp.post('subscribe', {data: {email}})
            } catch (e) {
                console.log(e)
            }
        },
        // 更新用户利息
        // eslint-disable-next-line no-empty-pattern
        async updateUserInterest({commit}, {address}) {
            try {
                commit('SET_STFIL_INTEREST_LOADING', {stFilInterestLoading: true})
                let data = await nodejsHttp.get('address/info', {params: {address}})
                commit('SET_STFIL_INTEREST', {interest: data.interest} )
                commit('SET_STFIL_INTEREST_LOADING', {stFilInterestLoading: false})
            } catch (e) {
                console.log(e)
            }
        }
    },
    modules: {
        proposal,
        vote,
        dashboard,
        node,
        nodeData,
        publicInfo: publicGlobalInfo,
        stakeInfo,
        chartData,
        referrals,
        nft,
        ledger,
        metamaskFlask,
        subpool
    }
})
