import Filecoin, { LedgerProvider, TransportWrapper } from "@glif/filecoin-wallet-provider";
import { LEDGER_VERSION_MAJOR, LEDGER_VERSION_MINOR, LEDGER_VERSION_PATCH } from "./config";
import { parseErr } from "@/components/induction/singer/ledger/Errors";
import { WarnTriangleFilled } from '@element-plus/icons-vue';
import { Message } from '@glif/filecoin-message';
import { CoinType } from "@glif/filecoin-address";
import { mapState } from "vuex";
const transportWrapperSingleton = new TransportWrapper();
export default {
  name: "LedgerComponent",
  components: {
    WarnTriangleFilled
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    waitPushResult: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errMsg: '',
      ledgerIsFail: false,
      ledgerIsConnect: false,
      searchAccountIndex: 0,
      searchAccountLoading: false,
      waitMessageSignLoading: false
    };
  },
  created() {
    if (this.filecoin !== undefined) {
      this.ledgerIsConnect = true;
    }
  },
  methods: {
    async connect() {
      try {
        await transportWrapperSingleton.connect();
        if (this.ledgerSubProvider) {
          await this.ledgerSubProvider.resetTransport(transportWrapperSingleton.transport);
        } else {
          let ledgerSubProvider = new LedgerProvider({
            transport: transportWrapperSingleton.transport,
            minLedgerVersion: {
              major: LEDGER_VERSION_MAJOR,
              minor: LEDGER_VERSION_MINOR,
              patch: LEDGER_VERSION_PATCH
            }
          });
          this.$store.commit('SET_LEDGER_SUB_PROVIDER', ledgerSubProvider);
        }
        await this.ledgerSubProvider.ready();
        this.ledgerIsFail = false;
        this.ledgerIsConnect = true;
        let filecoin = new Filecoin(this.ledgerSubProvider, {
          apiAddress: this.$store.state.lotusApi
        });
        this.$store.commit('SET_LEDGER_PROVIDER', filecoin);
      } catch (err) {
        this.ledgerIsFail = true;
        this.ledgerIsConnect = false;
        this.errMsg = parseErr(err, this.$t);
      }
    },
    async signMessage() {
      this.ledgerIsFail = false;
      this.errMsg = '';
      const newMessage = new Message({
        to: this.message.To,
        from: this.message.From,
        nonce: this.message.Nonce,
        value: this.message.Value,
        method: this.message.Method,
        params: this.message.Params,
        gasPremium: this.message.GasPremium,
        gasFeeCap: this.message.GasFeeCap,
        gasLimit: this.message.GasLimit
      });
      const lotusMessage = newMessage.toLotusType();
      let res = await this.searchAccount(this.message.From);
      if (!res) {
        this.waitMessageSignLoading = false;
        this.ledgerIsFail = true;
        this.errMsg = this.$t('ledgerSp.The account address is not found');
        return;
      }
      this.waitMessageSignLoading = true;
      try {
        const signedMessage = await this.filecoin.wallet.sign(this.message.From, lotusMessage);
        this.waitMessageSignLoading = false;
        this.$emit('signedMessage', signedMessage);
        this.ledgerIsFail = false;
        this.errMsg = '';
      } catch (e) {
        console.log(e);
        this.waitMessageSignLoading = false;
        this.ledgerIsFail = true;
        this.errMsg = parseErr(e, this.$t);
      }
    },
    async searchAccount(fromAddress) {
      this.searchAccountLoading = true;
      let end = this.searchAccountIndex + 20;
      let start = this.searchAccountIndex;
      for (let i = start; i < end; i++) {
        let accounts = await this.filecoin.wallet.getAccounts(i, i + 1, CoinType.MAIN);
        if (accounts.findIndex(item => item === fromAddress) >= 0) {
          this.searchAccountIndex = 0;
          this.searchAccountLoading = false;
          return true;
        }
      }
      this.searchAccountIndex = end;
      this.searchAccountLoading = false;
      return false;
    }
  },
  computed: mapState({
    filecoin: state => state.ledgerProvider,
    ledgerSubProvider: state => state.ledgerSubProvider
  })
};