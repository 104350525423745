import nodejsHttp from "@/utils/http/nodejsHttp";

let dashboard = {
    state: {
        dailyEarnings: 0n
    },
    getters: {},
    mutations: {
        SET_DAILY_EARNINGS(state, {dailyEarnings}) {
            state.dailyEarnings = dailyEarnings
        }
    },
    actions: {
        async getDailyEarnings({commit}) {
            let data = await nodejsHttp.get('dailyEarnings')
            commit('SET_DAILY_EARNINGS', {dailyEarnings: data})
        }
    },
    modules: {}
}

export default dashboard
